import { AdminContainer } from 'Container/AdminContainer';
import { Route, Switch } from 'react-router';
import Kycs from 'Views/Kycs/Kycs';
import SingleKyc from 'Views/Kycs/SingleKyc';

export const KycRoute = (props: any) => {
    return (
        <AdminContainer>
            <Switch>
                <Route path={`${props.match?.path}`} exact component={Kycs} />
                <Route path={`${props.match?.path}/:id/:type?/:childId?/:childType?`} exact component={SingleKyc} />
            </Switch>
        </AdminContainer>
    )
}

export default KycRoute;