import { Button, Col, Form, Input, message, Row, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { requestLogin } from 'Redux/AuthRedux/AuthActions'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { takeAdmin } from 'Redux/AdminRedux/AdminApis'
import { fetchUserSuccess } from 'Redux/UserRedux/UserActions'

export const Login = () => {
  const dispatch = useDispatch()
  const AuthReducer = useSelector((state: any) => state.AuthReducer)
  const history = useHistory()
  const { details, detailing } = useSelector((state: any) => state.UserReducer)

  const onFinish = (values: any) => {
    dispatch(requestLogin(values))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    if (AuthReducer?.loginState?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      history.push('/auth/change-password')
    } else if (
      AuthReducer?.loginState ||
      localStorage.getItem('amplify-authenticator-authState') === 'signedIn'
    ) {
      Auth.currentUserInfo()
        .then((user) => {
          getUserInformation(user?.attributes?.[`custom:id`])
        })
        .then((data) => {})
        .catch((err) => {})
    }
  }, [AuthReducer])

  const getUserInformation = async (id: any) => {
    await takeAdmin({ id: id }).then((data) => {
      dispatch(fetchUserSuccess({ data: data?.data }))
    })
  }
  useEffect(() => {
    if (Object.keys(details).length > 0) {
      if (
        details?.role_type?.includes('demat') ||
        details?.role_type?.includes('meroshare')
      ) {
        history.push('/')
      } else {
        history.push('/user')
      }
    }
  }, [details])

  return (
    <Row justify='center' align='middle' style={{ minHeight: '100%' }}>
      <Col lg={12}>
        <Typography.Title className='shadow-text section-title'>
          LOG IN
        </Typography.Title>
        <Form
          layout='vertical'
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label='Username'
            name='username'
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Password'
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Row style={{ marginBottom: 20, marginTop: 5 }} justify='end'>
            <Link to='/auth/reset-password' className='native-link'>
              Forget Password
            </Link>
          </Row>
          <Form.Item>
            <Button
              loading={AuthReducer?.logging || detailing}
              type='default'
              htmlType='submit'
              block
            >
              LOG IN
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default Login
