import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'Config/config';

import { DpDashboardReportParamsProps } from './DashboardType';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
};


export const fetchDashboardReportApi = async (data: DpDashboardReportParamsProps) => {
    return axios.get(`dashboards`, {
      params: data,
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
  };