import { message } from 'antd';
import { AxiosResponse } from 'axios';
import {
  all,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { saveFile } from 'Utils/saveFile';

import {
  generatePdfExcelApi,
  generateReportApi,
} from './ReportApi';
import {
  generatePdfExcelFailure,
  generatePdfExcelSuccess,
  generateReportFailure,
  generateReportSuccess,
} from './ReportsAction';
import {
  GENERATE_PDF_EXCEL,
  GENERATE_REPORT,
  generatePdfExcelProps,
  generateReportProps,
} from './ReportsTypes';

const generateReportAsync = async (data: generateReportProps) => {
  return await generateReportApi(data);
};

function* generateReport(data: any) {
  const { payload } = data;
  try {
    const response: AxiosResponse = yield generateReportAsync(payload);

    if (response) {
      yield put(generateReportSuccess(response.data));
    }
  } catch (error: any) {
    console.log("error", error.response);
    
    const messages = error.response.data.error ?? "Something went wrong";
    yield put(generateReportFailure());
    message.error(messages);
  }
}

function* watchGenerateReport() {
  yield takeLatest(GENERATE_REPORT, generateReport);
}

const generatePdfExcelAsync = async (data: generatePdfExcelProps) => {
  return await generatePdfExcelApi(data);
};

function* generatePdfExcel(data: any) {
  const { payload } = data;

  try {
    const response: AxiosResponse = yield generatePdfExcelAsync(payload);

    if (response) {
      const contentType =
        payload.type === "pdf"
          ? "application/pdf"
          : payload.type === "excel"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : "";
      const extension =
        payload.type === "pdf" ? "pdf" : payload.type === "excel" ? "xlsx" : "";
      saveFile(response.data, contentType, `report.${extension}`);

      yield put(generatePdfExcelSuccess());
    }
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(generatePdfExcelFailure());
    message.error(messages);
  }
}

function* watchGeneratePdfExcel() {
  yield takeLatest(GENERATE_PDF_EXCEL, generatePdfExcel);
}

export default function* DpReportsSagas() {
  yield all([fork(watchGenerateReport), fork(watchGeneratePdfExcel)]);
}
