import { AdminContainer } from 'Container/AdminContainer';
import { Route, Switch } from 'react-router';
import Admins from 'Views/Admins/Admins';
import SingleAdmin from 'Views/Admins/SingleAdmin';

export const AdminRoute = (props: any) => {
    return (
        <Switch>
            <Route path={`${props.match?.path}`} exact component={Admins} />
            <Route path={`${props.match?.path}/:id/:type?/:childId?/:childType?`} exact component={SingleAdmin} />
        </Switch>
    )
}

export default AdminRoute;