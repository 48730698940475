import {
  CLEAR_KYC_FILE_STATE,
  UPDATE_KYC_FILE_FAILURE,
  UPDATE_KYC_FILE_REQUEST,
  UPDATE_KYC_FILE_SUCCESS,
} from "./UploadKycTypes";

export const requestKycFileUpdate = (s3Url: string, accountType: string) => ({
  type: UPDATE_KYC_FILE_REQUEST,
  payload: s3Url,
  accountType,
});

export const successKycFileUpdate = (response: any) => ({
  type: UPDATE_KYC_FILE_SUCCESS,
  payload: response,
});

export const failureKycFileUpdate = () => ({
  type: UPDATE_KYC_FILE_FAILURE,
});

export const clearKycFileState = () => ({
  type: CLEAR_KYC_FILE_STATE,
});
