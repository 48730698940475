import { FormItemTypes } from 'Types/Types';

import {
  designationOptions,
  genderOptions,
  incomeSourceOptions,
  maritialOptions,
  occupationOptions,
  relationshipOptions,
  tradingAccountOptions,
  yesNoOptions,
} from './selectDefaultOptions';

export const tradingGeneralDetailsFields: FormItemTypes[] = [
  {
    label: "User's Name",
    type: 'text',
    name: 'username',
    required: true,
    span: 12,
  },
  // {
  //   label: "Full Name",
  //   type: "text",
  //   name: "full_name",
  //   required: true,
  //   span: 12,
  // },

  {
    label: 'DATE OF BIRTH',
    type: 'date',
    name: 'dob',
    required: true,
    span: 12,
  },
  {
    label: 'NATIONALITY',
    type: 'text',
    name: 'nationality',
    required: true,
    span: 12,
  },
  {
    label: 'GENDER',
    type: 'radio',
    name: 'gender',
    required: true,
    span: 12,
    options: genderOptions,
  },
  {
    label: 'PAN NUMBER',
    type: 'text',
    name: 'pan_number',
    required: false,
    span: 12,
  },
]

export const tradingMinorGeneralDetails: FormItemTypes[] = [
  {
    label: "User's Name",
    type: 'text',
    name: 'username',
    required: true,
    span: 12,
  },
  // {
  //   label: "Full Name",
  //   type: "text",
  //   name: "name",
  //   required: true,
  //   span: 12,
  // },

  {
    label: 'DATE OF BIRTH',
    type: 'date',
    name: 'dob',
    required: true,
    span: 12,
  },
  {
    label: 'GENDER',
    type: 'radio',
    name: 'gender',
    required: true,
    span: 12,
    options: genderOptions,
  },
]

export const BoidInformation: FormItemTypes[] = [
  {
    label: 'BOID',
    type: 'number',
    name: 'boid',
    required: true,
    span: 12,
    pattern: RegExp('^[0-9]{16}'),
    patternError: 'BOID must be 16 characters.',
  },
]
export const tradingCitizenship: FormItemTypes[] = [
  {
    label: 'CITIZENSHIP NUMBER',
    type: 'text',
    name: 'citizenship_id',
    required: true,
    span: 12,
  },
  {
    label: 'ISSUED DATE',
    type: 'date',
    name: 'issued_date',
    required: true,
    span: 12,
  },
  {
    label: 'ISSUE DISTRICT',
    type: 'select',
    name: 'issued_district',
    required: true,
    remoteRoute: 'address',
    selectSearchable: true,
    span: 12,
  },
  {
    label: 'Reference Number',
    type: 'text',
    name: 'reference_number',
    required: true,
    span: 12,
  },
]

export const TradingMinorDetails: FormItemTypes[] = [
  {
    label: 'Birth Certificate Number',
    type: 'text',
    name: 'birth_certificate_number',
    required: true,
    span: 12,
  },
  {
    label: 'ISSUED DATE',
    type: 'date',
    name: 'issued_date',
    required: true,
    span: 12,
  },
  {
    label: 'ISSUE DISTRICT',
    type: 'text',
    name: 'issued_district',
    required: true,
    span: 12,
  },
]

export const TradingFamilyDetails: FormItemTypes[] = [
  {
    label: "FATHER'S NAME",
    type: 'text',
    name: 'father_name',
    required: true,
    span: 12,
  },
  {
    label: "MOTHER'S NAME",
    type: 'text',
    name: 'mother_name',
    required: true,
    span: 12,
  },
  {
    label: "GRANDFATHER'S NAME",
    type: 'text',
    name: 'grandfather_name',
    required: true,
    span: 12,
  },

  {
    label: "GRANDMOTHERS'S NAME",
    type: 'text',
    name: 'grandmother_name',
    required: false,
    span: 12,
  },
  {
    label: 'MARITAL STATUS',
    type: 'radio',
    name: 'married',
    required: true,
    span: 24,
    options: maritialOptions,
  },
]

export const TradingInlawsDetails: FormItemTypes[] = [
  {
    label: 'SPOUSE ',
    type: 'text',
    name: 'Spouse',
    required: true,
    span: 12,
  },
  {
    label: "FATHER'S NAME",
    type: 'text',
    name: 'Father-in-law',
    required: false,
    span: 12,
  },
  {
    label: "MOTHER'S NAME",
    type: 'text',
    name: 'Mother-in-law',
    required: false,
    span: 12,
  },
  {
    label: "GRANDFATHER'S NAME",
    type: 'text',
    name: 'Grandfather-in-law',
    required: false,
    span: 12,
  },

  {
    label: "GRANDMOTHERS'S NAME",
    type: 'text',
    name: 'GrandMother-in-law',
    required: false,
    span: 12,
  },
]

export const tradingBankInformation: FormItemTypes[] = [
  {
    label: 'BANK NAME',
    type: 'text',
    name: 'bank_name',
    required: true,
    span: 12,
  },
  {
    label: 'BRANCH NAME',
    type: 'text',
    name: 'branch',
    required: true,
    span: 12,
  },
  {
    label: 'ACCOUNT NUMBER',
    type: 'text',
    name: 'issued_district',
    required: true,
    span: 12,
  },
  
]

export const tradingFinancialDetail: FormItemTypes[] = [
  {
    label: 'Finance details',
    type: 'number',
    name: 'income',
    required: true,
    span: 12,
  },
]

export const tradingAddress: FormItemTypes[] = [
  {
    label: 'COUNTRY',
    type: 'text',
    name: 'country',
    required: true,
    span: 8,
  },
  {
    label: 'MUNICIPALITY',
    type: 'text',
    name: 'sub',
    required: true,
    span: 8,
  },
  {
    label: 'DISTRICT',
    type: 'text',
    name: 'district',
    required: true,
    span: 8,
  },
  {
    label: 'PROVINCE',
    type: 'text',
    name: 'province',
    required: true,
    span: 8,
  },

  {
    label: 'TOLE',
    type: 'text',
    name: 'tole',
    required: true,
    span: 8,
  },
  {
    label: 'WARD NO',
    type: 'text',
    name: 'ward',
    required: true,
    span: 8,
  },
  {
    label: 'BLOCK NO',
    type: 'text',
    name: 'block',
    required: true,
    span: 8,
  },
]

export const tradingOrganizationInformation: FormItemTypes[] = [
  {
    label: 'OCCUPATION',
    type: 'select',
    name: 'occupation_type',
    required: true,
    span: 12,
    options: occupationOptions,
  },
  {
    label: 'Organization',
    type: 'autocomplete',
    name: 'organization', //remote bata aucha
    required: false,
    span: 12,
    remoteRoute: 'organization',
    selectSearchable: true,
  },
  {
    label: 'DESIGNATION',
    type: 'select',
    name: 'designation',
    required: false,
    span: 12,
    options: designationOptions,
  },
]

export const tradingBankCheque: FormItemTypes[] = [
  {
    label: 'BANK NAME',
    type: 'select',
    name: 'bank_name',
    required: true,
    span: 12,
    remoteRoute: 'bank',
    selectSearchable: true,
  },
  {
    label: 'BRANCH NAME',
    type: 'select',
    name: 'branch',
    required: true,
    span: 12,
    remoteRoute: 'branch',
    selectSearchable: true,
    // remote: true, //to fetch options data from api
    //yah ni remote true huncha
  },
  {
    label: 'ACCOUNT NUMBER',
    type: 'text',
    name: 'account_number',
    required: true,
    span: 12,
  },
  {
    label: 'ACCOUNT TYPE',
    type: 'select',
    name: 'account_type',
    required: true,
    span: 12,
    options: tradingAccountOptions,
  },
]

export const tradingRentField: FormItemTypes[] = [
  {
    label: 'Do you live on rent?',
    type: 'radio',
    name: 'rent',
    required: true,
    span: 12,
    options: yesNoOptions,
  },
]

export const tradingRentDetails: FormItemTypes[] = [
  {
    label: 'HOME OWNER NAME',
    type: 'text',
    name: 'full_name',
    required: true,
    span: 12,
  },
  {
    label: 'MOBILE NUMBER',
    type: 'text',
    name: 'mobile',
    required: true,
    span: 12,
  },
]

export const tradingPoliticsRelated: FormItemTypes[] = [
  {
    label: 'Are you in politics or higher position person ?',
    type: 'radio',
    name: 'poli_high_pos',
    required: true,
    options: yesNoOptions,
    span: 24,
  },
  {
    label: 'Are you engaged with politics or higher position person ?',
    type: 'radio',
    options: yesNoOptions,
    name: 'eng_poli_high_pos',
    required: true,
    span: 24,
  },
  {
    label: 'Do you have beneficiary person ?',
    type: 'radio',
    options: yesNoOptions,
    name: 'benificiary',
    required: true,
    span: 24,
  },
  {
    label: 'Are you verified in any guilt case ?',
    type: 'radio',
    options: yesNoOptions,
    name: 'guilt_case',
    required: true,
    span: 24,
  },
  {
    label:
      'Sampati sudhikaran nibaran yain, 2064 ko dafa 2 ko khada (sha) bamojimkaa kasurharu cha bhaney kripaya ullekh garnuhos :',
    type: 'textarrea',
    name: 'samp_sudhi_2064',
    required: false,
    span: 24,
  },
]

export const tradingPoliticsInfo: FormItemTypes[] = [
  {
    label: 'ENGAGED POLICITICAL PERSON NAME',
    type: 'text',
    name: 'full_name',
    required: true,
    span: 12,
  },
  {
    label: 'YOUR RELATION',
    type: 'select',
    name: 'relation',
    required: true,
    span: 12,
    options: relationshipOptions,
  },
]
export const tradingBeneficiaryPerson: FormItemTypes[] = [
  {
    label: 'BENEFICIARY PERSON NAME',
    type: 'text',
    name: 'full_name',
    required: true,
    span: 12,
  },
  {
    label: 'YOUR RELATION',
    type: 'select',
    name: 'relation',
    required: true,
    span: 12,
    options: relationshipOptions,
  },
]

export const companyName: FormItemTypes[] = [
  {
    label: 'COMPANY NAME',
    type: 'text',
    name: 'company',
    required: false,
    span: 24,
  },
  {
    label: 'Designation',
    type: 'select',
    name: 'designation',
    required: true,
    span: 24,
    options: designationOptions,
  },
]
export const brokerageName: FormItemTypes[] = [
  {
    label: 'Company',
    type: 'select',
    name: 'buss_sec_brok_name',
    required: false,
    span: 24,
    remoteRoute: 'broker',
    selectSearchable: true,
  },
]
export const tradingTransactionRelated: FormItemTypes[] = [
  {
    label: 'INCOME SOURCE',
    type: 'select',
    name: 'income_source',
    required: true,
    span: 12,
    options: incomeSourceOptions,
  },
  {
    label: 'Doing business with other securities brokerage companies ?',
    type: 'radio',
    name: 'buss_sec_brok_com',
    required: true,
    span: 24,
    options: yesNoOptions,
  },
  {
    label:
      'Tapai karjaa suchana kendra lt. ko kaalosuchima rahanu bhayeko cha ?',
    type: 'radio',
    name: 'karja_kalosuchi',
    required: true,
    span: 24,
    options: yesNoOptions,
  },
  {
    label:
      'Involvement in investment companies which were established for securities trading ?',
    type: 'radio',
    name: 'inv_comp_sec_trad',
    required: true,
    span: 24,
    options: yesNoOptions,
  },
]

export const tradingDetailsFields: FormItemTypes[] = [
  {
    label: 'email',
    type: 'text',
    name: 'email',
    required: true,
    span: 12,
    disabled: true,
  },

  {
    label: 'Account Type',
    type: 'text',
    name: 'account_type',
    required: true,
    span: 12,
    disabled: true,
  },
  {
    label: 'Joined Date',
    type: 'text',
    name: 'joined_date',
    required: true,
    span: 12,
    disabled: true,
  },

  {
    label: 'status',
    type: 'text',
    name: 'status',
    required: true,
    span: 12,
    disabled: true,
  },

  {
    label: 'Client Code',
    type: 'text',
    name: 'client_code',
    required: true,
    span: 12,
  },
]

export const dematDetailsFields: FormItemTypes[] = [
  {
    label: 'email',
    type: 'text',
    name: 'email',
    required: true,
    span: 12,
    disabled: true,
  },

  {
    label: 'Account Type',
    type: 'text',
    name: 'account_type',
    required: true,
    span: 12,
    disabled: true,
  },
  {
    label: 'Joined Date',
    type: 'text',
    name: 'joined_date',
    required: true,
    span: 12,
    disabled: true,
  },

  {
    label: 'status',
    type: 'text',
    name: 'status',
    required: true,
    span: 12,
    disabled: true,
  },
  {
    label: 'Client Id',
    type: 'text',
    name: 'clientId',
    required: true,
    span: 12,
  },
]
