import dayjs from 'dayjs';

import { getKeyType } from './descriptionKeyMapping';

export const getFormDataReady = (data: any) => {

  const newData: any = {}
  Object.keys(data)?.map((key: string) => {
    newData[key] = getKeyType(key) === 'date' ? dayjs(data[key]) : data[key]
  })

  return newData
}

export const getFormSubmitDataReady = (data: any) => {
  // let newData: any = {};
  // Object.keys(data)?.map((key: string)=>{
  //     if( getKeyType(key) !== 'date' && typeof data[key] === 'object' && !Array.isArray(data[key])) {
  //         let newData2: any={};
  //         Object.keys(data[key])?.map((keyy: string)=>{
  //             // if( getKeyType(keyy) !== 'date' && typeof data[key] === 'object' && !Array.isArray(data[key])) newData[key] = getFormSubmitDataReady(data[key]);
  //            if(data[key][keyy] && data[key][keyy] != NaN) newData2[key] = getKeyType(key) == 'date' ? data[key][keyy].format('LL') : getKeyType(key) === 'number' ? parseInt(data[key][keyy]) : data[key][keyy];
  //         })
  //         newData[key] = newData2
  //     };
  //    if(data[key] && data[key] != NaN) newData[key] = getKeyType(key) == 'date' ? data[key].format('LL') : getKeyType(key) === 'number' ? parseInt(data[key]) : data[key];
  // })
  // return newData;

  let newData: any = {}

  Object.keys(data)?.map((key: string) => {
    if (getKeyType(key) === 'date')
      newData[key] = data[key]?.format('YYYY-MM-DD')
    else if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
      const insideData = data[key]
      const newInsideData: any = {}

      Object.keys(insideData)?.map((keyy: any) => {
        if (getKeyType(keyy) === 'date') {
          newInsideData[keyy] = insideData[keyy]?.format('YYYY-MM-DD')
        } else if (
          typeof insideData[keyy] === 'object' &&
          !Array.isArray(insideData[keyy])
        ) {
          //
        } else if (
          insideData[keyy] &&
          getKeyType(keyy) === 'number' &&
          !Number.isNaN(insideData[keyy])
        ) {
          newInsideData[keyy] = parseInt(insideData[keyy])
        } else if (getKeyType(keyy) === 'boolean') {
          newInsideData[keyy] = insideData[keyy]
        } else if (getKeyType(keyy) === 'ignored_text') {
        } else if (
          insideData[keyy] &&
          typeof insideData[keyy] === 'string' &&
          getKeyType(keyy) === 'phoneNumber'
        ) {
          newInsideData[keyy] = `+977${insideData[keyy]
            .replace('+977', '')
            .replace('-', '')}`
        } else if (
          insideData[keyy] &&
          typeof insideData[keyy] === 'string' &&
          getKeyType(keyy) !== 'image'
        ) {
          newInsideData[keyy] = insideData[keyy]
        }
      })
      newData[key] = newInsideData
    } else if (
      data[key] &&
      getKeyType(key) === 'number' &&
      !Number.isNaN(data[key])
    ) {
      newData[key] = parseInt(data[key])
    } else if (data[key] && typeof data[key] === 'string') {
      newData[key] = data[key]
    } else if (typeof getKeyType(key) === 'boolean') {
      newData[key] = data[key]
    }
  })

  return newData
}
