import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Card,
  Form,
} from 'antd';
import { useDispatch } from 'react-redux';
import { fetchDPCustomer } from 'Redux/DpRedux/Customer/DpActions';
import { DpCustomerFilterDataProps } from 'Types/Types';
import { dpFilterParams } from 'Utils/dpFilterParams';

import { FilterComponent } from './Components/FIlterComponent';
import { TableComponents } from './Components/TableComponents';

export interface AddEntryModalProps {
  userId: string;
  visible: boolean;
}

export interface EntryFormProps {
  account_holder_name: string;
  boid_number: string;
}

export const Customer = () => {
  const dispatch = useDispatch();

  const [entryForm] = Form.useForm<EntryFormProps>();

  const [filterData, setFilterData] = useState<DpCustomerFilterDataProps>({
    page: 1,
    limit: 10,
  });
  const [showAddEntryModal, setShowAddEntryModal] =
    useState<AddEntryModalProps>({
      userId: "",
      visible: false,
    });

  useEffect(() => {
    if (filterData.page && filterData.limit) {
      const params = dpFilterParams(filterData); // get filter options
      dispatch(
        fetchDPCustomer({
          ...params,
        })
      );
    }
  }, [dispatch, filterData]);

  return (
    <Card className="dp-customer">
      <FilterComponent
        filterData={filterData}
        setFilterData={setFilterData}
        showAddEntryModal={showAddEntryModal}
        setShowAddEntryModal={setShowAddEntryModal}
        entryForm={entryForm}
      />
      <TableComponents
        filterData={filterData}
        setFilterData={setFilterData}
        showAddEntryModal={showAddEntryModal}
        setShowAddEntryModal={setShowAddEntryModal}
        entryForm={entryForm}
      />
    </Card>
  );
};
