import { AdminContainer } from 'Container/AdminContainer'
import { Route, Switch } from 'react-router'
import Users from 'Views/Users/Users'
import SingleUser from 'Views/Users/SingleUser'

export const UserRoute = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match?.path}`} exact component={Users} />
      <Route
        path={`${props.match?.path}/:id/:type?/:childId?/:childType?`}
        exact
        component={SingleUser}
      />
    </Switch>
  )
}

export default UserRoute
