import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  Input,
  Menu,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import { AntFormItem } from 'Components/AntForm/AntForm';
import Modal from 'Components/Modal';
import dayjs from 'dayjs';
import {
  AddUserSuccessIcon,
} from 'Icons/AddUserSuccessIcon/AddUserSuccessIcon';
import ArrowDownBoldIcon from 'Icons/ArrowDownBoldIcon/ArrowDownBoldIcon';
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon';
// import { CsvLogoIcon } from "Icons/CsvLogoIcon/CsvLogoIcon";
import { DownloadIconDark } from 'Icons/DownloadIcon/DownloadIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  createDpCustomer,
  customerAddReference,
  editCustomersData,
  fetchDPCustomer,
  getDpCustomerReport,
  resetStatusValue,
  reuploadDpaFile,
  uploadDpaFile,
} from 'Redux/DpRedux/Customer/DpActions';
import { FilterType } from 'Types/Types';
import { dpFilterParams } from 'Utils/dpFilterParams';
import { dummyRequest } from 'Utils/DummyCall';

import { CopyOutlined } from '@ant-design/icons';

import { AddEntryModalProps } from '../Customer';

// import { PdfLogoIcon } from "Icons/PdfIcon/PdfIcon";

interface FilterComponentProps {
  setFilterData: Dispatch<SetStateAction<FilterType>>;
  filterData: FilterType;
  showAddEntryModal: AddEntryModalProps;
  setShowAddEntryModal: Dispatch<SetStateAction<AddEntryModalProps>>;
  entryForm: FormInstance;
}

const importType = [
  {
    key: "Decremental",
    type: "Import DPA5 DECREMENTAL",
  },
  {
    key: "Incremental",
    type: "Import DPA5 INCREMENTAL",
  },
];

const accountStatusType = ["Active", "Closed"];

export const FilterComponent = ({
  filterData,
  setFilterData,
  showAddEntryModal,
  setShowAddEntryModal,
  entryForm,
}: FilterComponentProps) => {
  const dispatch = useDispatch();

  const formData = new FormData();

  const [showImportModal, setShowImportModal] = useState({
    visible: false,
    key: "",
  });
  const [showExportModal, setShowExportModal] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("");

  const [missMatchedData, setMissMatchedData] = useState(false);
  const [addReferenceModal, setAddReferenceModal] = useState(false);

  const [referencePage, setReferencePage] = useState(1);
  const [boidInputDisable, setBoidInputDisable] = useState(false)

  const dpCustomerReducer = useSelector(
    (state: any) => state.dpCustomerReducer
  );

  const missMatchedTableRef = useRef(null);

  const [addReferenceForm] = Form.useForm();

  entryForm.setFieldsValue({
    boid_number: "13013200"
  })

  const {
    creating,
    uploadDpaError,
    uploadDpaSuccess,
    uploadDpaData,
    created,
    isIncremental,
    downloadingPdfCsv,
    downloadedPdfCsv,
    editingCustomers,
    editedCustomer,
    wrongBoidData,
    correctBoidCount,
    wrongBoidCount,
    uploadDpaLoading,
    correctBoidList,
    addedCustomerReference,
    addingCustomerReference,
  } = dpCustomerReducer;

  const [formFilter] = Form.useForm();

  const accountStatus = (
    <Menu
      onClick={(e) => {
        setSelectedStatus(e.key);
        setFilterData((prevState) => ({
          ...prevState,
          account_status: e.key,
          page: 1,
        }));
      }}
    >
      {accountStatusType?.map((status) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={status}>
            <Typography.Text type="secondary">{status}</Typography.Text>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const menuImport = (
    <Menu
      onClick={(e) => {
        setShowImportModal({
          visible: true,
          key: e.key,
        });
      }}
    >
      {importType.map((item: { key: string; type: string }) => {
        return (
          <Menu.Item key={item.key}>
            <Typography.Text type="secondary">{item.type}</Typography.Text>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  useEffect(() => {
    if (created) {
      setFilterData((prevState) => ({
        ...prevState,
        search: "",
        page: 1,
      }));
    }
  }, [created, setFilterData]);

  useEffect(() => {
    if (downloadedPdfCsv) {
      setShowExportModal(false);
    }
  }, [downloadedPdfCsv]);

  useEffect(() => {
    if (uploadDpaSuccess || isIncremental) {
      const params = dpFilterParams(filterData); // get filter options
      dispatch(
        fetchDPCustomer({
          ...params,
        })
      );
    }
  }, [uploadDpaSuccess, dispatch, filterData, isIncremental]);

  useEffect(() => {
    if (addedCustomerReference) {
      if (referencePage === correctBoidList.length) {
        setAddReferenceModal(false);
        setReferencePage(1);
        setShowImportModal({
          key: "",
          visible: false,
        });
        setShowExportModal(false);
        dispatch(resetStatusValue());
        setMissMatchedData(false);
      } else {
        setReferencePage(referencePage + 1);
      }
      addReferenceForm.resetFields();
    }
  }, [addedCustomerReference]);

  const onFilterSubmit = (value: any) => {
    setFilterData((prevState) => ({
      ...prevState,
      search: value.username,
      page: 1,
    }));
  };

  const handleSubmit = (val: {
    account_holder_name: string;
    boid_number: string;
  }) => {
    if (showAddEntryModal.userId) {
      dispatch(
        editCustomersData({
          body: { ...val },
          userId: showAddEntryModal.userId,
        })
      );
    } else {
      dispatch(
        createDpCustomer({
          ...val,
        })
      );
    }

    setShowAddEntryModal({
      userId: "",
      visible: false,
    });
    entryForm.resetFields();
  };

  const handleBoidInputChange = (e: ChangeEvent<HTMLInputElement>) => {

    if (e.target.value.length < 8) {
      setBoidInputDisable(true)
    } else {
    }
    setBoidInputDisable(false)
  }

  const handleAddReference = (data: any) => {
    const customerDetails = correctBoidList[referencePage - 1];

    dispatch(
      customerAddReference({
        id: customerDetails.customer_id,
        body: data,
      })
    );
  };

  const renderModalComp = () => {
    switch (true) {
      case uploadDpaError:
        return (
          <>
            <Typography.Title type="danger" level={4}>
              Import Failed!
            </Typography.Title>
            <Row>
              <Button
                style={{
                  marginRight: 20,
                }}
                onClick={() =>
                  setShowImportModal({
                    visible: false,
                    key: "",
                  })
                }
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => dispatch(reuploadDpaFile())}
              >
                Reupload File
              </Button>
            </Row>
          </>
        );

      case uploadDpaSuccess:
        return (
          <>
            <Typography.Title type="success" level={4}>
              DPA5 successfully imported
            </Typography.Title>
            <Typography.Text>
              {" "}
              Newly added account- {uploadDpaData.created_count}{" "}
            </Typography.Text>
            <Typography.Text>
              {" "}
              Update account- {uploadDpaData.updated_count}{" "}
            </Typography.Text>
          </>
        );

      case isIncremental:
        const customerColumns = [
          {
            title: "USERNAME",
            dataIndex: "account_name",
            key: "account_name",
            render: (text: any, row: any, index: number) => (
              <Typography.Text>{text}</Typography.Text>
            ),
          },
          {
            title: "BOID",
            dataIndex: "wrong_boid",
            key: "wrong_boid",
            editable: true,
            render: (text: any, row: any, index: number) => (
              <Typography.Text>{text}</Typography.Text>
            ),
          },
        ];

        const handleCopy = (arrayToCopy: []) => {
          const arrayAsString = JSON.stringify(arrayToCopy, null, 2);
          const textarea = document.createElement("textarea");
          textarea.value = arrayAsString;
          document.body.appendChild(textarea);
          textarea.select();
          navigator.clipboard.writeText(textarea.value);
          document.body.removeChild(textarea);
        };

        return (
          <>
            {missMatchedData ? (
              <>
                <div>
                  <Typography.Title
                    level={4}
                    type="danger"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Wrong BOID List
                  </Typography.Title>
                  <Typography.Text
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Copy the below list and edit users accordingly
                  </Typography.Text>
                  <Tooltip title="Copy">
                    <Button
                      onClick={() => handleCopy(wrongBoidData)}
                      icon={<CopyOutlined />}
                      style={{
                        height: "auto",
                        marginLeft: 12,
                      }}
                    />
                  </Tooltip>
                </div>
                <div>
                  <Table
                    ref={missMatchedTableRef}
                    pagination={false}
                    columns={customerColumns}
                    dataSource={wrongBoidData}
                  />
                </div>
              </>
            ) : (
              <>
                <Typography.Title type="success" level={4}>
                  DPA5 successfully imported
                </Typography.Title>
                <Typography.Text>
                  {" "}
                  Update account- {correctBoidCount}{" "}
                </Typography.Text>
                <Typography.Text type="danger">
                  {" "}
                  Wrong BOID account- {wrongBoidCount}{" "}
                </Typography.Text>
                {wrongBoidCount !== 0 ? (
                  <Button
                    type="primary"
                    onClick={() => setMissMatchedData(true)}
                  >
                    See mismatched Data
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => setAddReferenceModal(true)}
                  >
                    Add References
                  </Button>
                )}
              </>
            )}
          </>
        );

      case editedCustomer:
        return (
          <>
            <Typography.Title type="success" level={4}>
              Data Successfully Changes
            </Typography.Title>
            <Typography.Text>Please reupload file!</Typography.Text>

            <Button type="primary" onClick={() => dispatch(reuploadDpaFile())}>
              Reupload
            </Button>
          </>
        );

      default:
        return (
          <Spin spinning={uploadDpaLoading}>
            <Upload
              customRequest={dummyRequest}
              beforeUpload={(file: any) => {
                formData.append("file", file);
                formData.append("file_type", showImportModal.key);

                dispatch(
                  uploadDpaFile({ formData, fileType: showImportModal.key })
                );
              }}
            >
              <Space direction="vertical" align="center" size={"large"}>
                <DownloadIconDark />
                <Typography.Text>Upload a csv file</Typography.Text>
              </Space>
            </Upload>
          </Spin>
        );
    }
  };

  return (
    <div className="filter">
      <Form onFinish={onFilterSubmit} form={formFilter}>
        <div className="filter-top">
          <div className="content search">
            <AntFormItem name="username" className="search-field">
              <Input.Search />
            </AntFormItem>
            <Button
              htmlType="submit"
              type="primary"
              className="primary--alt search-btn"
            >
              Search
            </Button>
          </div>
          <div className="content">
            <Button
              icon={<DownloadIconDark />}
              onClick={() => dispatch(
                getDpCustomerReport(
                  {
                    ...dpFilterParams(filterData),
                  },
                  "csv"
                )
              )}
              loading={downloadingPdfCsv}
              className="export-btn"
            >
              <Typography.Text>{"Export"}</Typography.Text>
            </Button>
            <Dropdown
              trigger={["click"]}
              overlay={menuImport}
              className="import-btn"
            >
              <Row align="middle" justify="space-between">
                <Typography.Text>{"Import"}</Typography.Text>
                <ArrowDownBoldIcon />
              </Row>
            </Dropdown>
            <Button
              className="success"
              icon={<AddUserSuccessIcon />}
              onClick={() =>
                setShowAddEntryModal({
                  userId: "",
                  visible: true,
                })
              }
            >
              New Entry
            </Button>
          </div>
        </div>
        <div className="filter-bottom">
          <Dropdown
            trigger={["click"]}
            overlay={accountStatus}
            className="accounts"
          >
            <Row align="middle" justify="space-between">
              <Typography.Text style={{ textTransform: "capitalize" }}>
                {selectedStatus ? selectedStatus : "Account Status"}
              </Typography.Text>
              <ArrowDownIcon />
            </Row>
          </Dropdown>
          <DatePicker.RangePicker
            className="date-range"
            onChange={(dates) => {
              setFilterData((prevState) => ({
                ...prevState,
                date_filter_min:
                  dates && dayjs(dates?.[0]?.toString()).format("YYYY-MM-DD"),
                date_filter_max:
                  dates && dayjs(dates?.[1]?.toString()).format("YYYY-MM-DD"),
                page: 1,
              }));
            }}
          />
        </div>
      </Form>

      <Modal
        visible={showAddEntryModal.visible}
        onCancel={() => {
          entryForm.resetFields();
          setShowAddEntryModal({
            userId: "",
            visible: false,
          });
        }}
      >
        <div>
          <Typography.Title level={4}>
            {showAddEntryModal.userId ? "Edit" : "New"} Account
          </Typography.Title>
          <Form
            className="style--kbbs"
            layout="vertical"
            form={entryForm}
            onFinish={handleSubmit}
          >
            <Row gutter={[20, 20]} justify="space-between">
              <Col lg={12}>
                <Form.Item
                  colon={false}
                  label="Full Name"
                  name="account_holder_name"
                  rules={[
                    { required: true, message: "Full Name is required!" },
                  ]}
                >
                  <Input placeholder="Add Full Name" />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  colon={false}
                  label="BOID"
                  name="boid_number"
                  rules={[{ required: true, message: "BOID is required!" }, { len: 16, message: "BOID must be of 16 digits" }]}
                // initialValue={"134567890"}
                >
                  <Input placeholder="Add BOID Number" className='boid-validation-input' onChange={handleBoidInputChange} disabled={boidInputDisable} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={4} offset={16}>
                <Button
                  onClick={() =>
                    setShowAddEntryModal({
                      userId: "",
                      visible: false,
                    })
                  }
                >
                  Cancel
                </Button>
              </Col>
              <Col lg={4}>
                <Button type="primary" htmlType="submit" loading={creating}>
                  {showAddEntryModal.userId ? "Update" : "Create"}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>

      <Modal
        visible={showImportModal.visible || showExportModal}
        onCancel={() => {
          setShowImportModal({
            key: "",
            visible: false,
          });
          setShowExportModal(false);
          dispatch(resetStatusValue());
          setMissMatchedData(false);
        }}
        width={"30vw"}
      >
        <Space
          direction="vertical"
          align="center"
          size={"large"}
          style={{
            width: "100%",
          }}
        >
          {renderModalComp()}
        </Space>
      </Modal>

      <Modal
        visible={addReferenceModal}
        onCancel={() => {
          entryForm.resetFields();
          addReferenceForm.resetFields();
          setAddReferenceModal(false);
          setShowImportModal({
            key: "",
            visible: false,
          });
          setShowExportModal(false);
          dispatch(resetStatusValue());
          setMissMatchedData(false);
        }}
      >
        <div>
          <Typography.Title level={4}>
            Add Reference for {correctBoidList[referencePage - 1]?.account_name}
          </Typography.Title>
          <Form
            className="style--kbbs"
            layout="vertical"
            form={addReferenceForm}
            onFinish={handleAddReference}
          >
            <Row gutter={[20, 20]}>
              <Col lg={12}>
                <Form.Item
                  colon={false}
                  label="Full Name"
                  name="reference_name"
                  rules={[
                    { required: true, message: "Full Name is required!" },
                  ]}
                >
                  <Input placeholder="Add Full Name" />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  colon={false}
                  label="Contact Number"
                  name="reference_number"
                  rules={[
                    { required: true, message: "Contact Number is required!" },
                  ]}
                >
                  <Input placeholder="Add Contact Number" />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  colon={false}
                  label="Address"
                  name="reference_address"
                  rules={[{ required: true, message: "Address is required!" }]}
                >
                  <Input placeholder="Add Address " />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  colon={false}
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: true, message: "Remarks is required!" }]}
                >
                  <Input placeholder="Add remarks" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={4} offset={correctBoidCount !== referencePage ? 12 : 16}>
                <Button
                  onClick={() => {
                    entryForm.resetFields();
                    addReferenceForm.resetFields();
                    setAddReferenceModal(false);
                    setShowImportModal({
                      key: "",
                      visible: false,
                    });
                    setShowExportModal(false);
                    dispatch(resetStatusValue());
                    setMissMatchedData(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              {correctBoidCount !== referencePage && (
                <Col lg={4}>
                  <Button onClick={() => setReferencePage(referencePage + 1)}>
                    Skip
                  </Button>
                </Col>
              )}

              <Col lg={4}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={addingCustomerReference}
                >
                  {correctBoidCount === referencePage ? "Save" : "Next"}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
