import { all } from 'redux-saga/effects';
import AdminSagas from 'Redux/AdminRedux/AdminSagas';
import AuthSagas from 'Redux/AuthRedux/AuthSagas';
import BatchFileSagas from 'Redux/BatchFileRedux(downlodFiles)/BatchFileSaga';
import DashboardSaga from 'Redux/DashboardRedux/DashboardSaga';
import AccountSagas from 'Redux/DpRedux/Accounts/AccountSaga';
import DpSagas from 'Redux/DpRedux/Customer/DpSagas';
import DpDashboardReportSagas from 'Redux/DpRedux/Dashboard/DashboardSaga';
import DpReportsSagas from 'Redux/DpRedux/Reports/ReportsSaga';
import DpTransactionSagas from 'Redux/DpRedux/Transaction/TransactionSaga';
import KycSagas from 'Redux/KycRedux/KycSagas';
import UploadKycSaga from 'Redux/UploadKycsFileRedux/UploadKycSaga';
import UserSagas from 'Redux/UserRedux/UserSagas';

export default function* rootSagas() {
  yield all([
    AuthSagas(),
    UserSagas(),
    KycSagas(),
    AdminSagas(),
    BatchFileSagas(),
    UploadKycSaga(),
    DashboardSaga(),
    DpSagas(),
    AccountSagas(),
    DpReportsSagas(),
    DpDashboardReportSagas(),
    DpTransactionSagas()
  ]);
}
