import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import Modal from 'Components/Modal';
import { CsvLogoIcon } from 'Icons/CsvIcon/CsvIcon';
import { DownloadIconDark } from 'Icons/DownloadIcon/DownloadIcon';
import { PdfLogoIcon } from 'Icons/PdfIcon/PdfIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  downloadTransactionReport,
} from 'Redux/DpRedux/Transaction/TransactionAction';
import { dpFilterParams } from 'Utils/dpFilterParams';

import { TransactionListFilterProps } from '../Transaction';

interface ExportComponentProps {
  filterData: TransactionListFilterProps;
}

export const ExportComponent = ({ filterData }: ExportComponentProps) => {
  const dispatch = useDispatch();
  const { downloadingPdfCsv, downloadedPdfCsv } =
    useSelector((state: any) => state.dpTransactionReducer);
  const [showExportModal, setShowExportModal] = useState(false);

  useEffect(() => {
    if (downloadedPdfCsv) {
      setShowExportModal(false);
    }
  }, [downloadedPdfCsv]);

  return (
    <>
      <Button
        icon={<DownloadIconDark />}
        style={{
          fontWeight: 400,
        }}
        onClick={() => setShowExportModal(true)}
      >
        <Typography.Text style={{ textTransform: "capitalize" }}>
          {"Export"}
        </Typography.Text>
      </Button>
      <Modal
        visible={showExportModal}
        width={"30vw"}
        onCancel={() => {
          setShowExportModal(false);
        }}
      >
        <Space
          direction="vertical"
          align="center"
          size={"large"}
          style={{
            width: "100%",
          }}
        >
          <Spin spinning={downloadingPdfCsv}>
            <Typography.Title level={4}> Choose File Format</Typography.Title>
            <Row>
              <Button
                style={{
                  marginRight: 16,
                }}
                onClick={() => {
                  dispatch(
                    downloadTransactionReport(
                      {
                        ...dpFilterParams(filterData),
                      },
                      "pdf"
                    )
                  );
                }}
              >
                <Space>
                  <PdfLogoIcon />
                  <Typography.Text>PDF</Typography.Text>
                </Space>
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    downloadTransactionReport(
                      {
                        ...dpFilterParams(filterData),
                      },
                      "excel"
                    )
                  );
                }}
              >
                <Space>
                  <CsvLogoIcon />
                  <Typography.Text>CSV</Typography.Text>
                </Space>
              </Button>
            </Row>
          </Spin>
        </Space>
      </Modal>
    </>
  );
};
