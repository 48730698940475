import "./customMap.scss";
import {
  Circle,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Button, Spin } from "antd";
import {
  circleOptions,
  googleMapOptions,
  GOOGLE_MAPS_KEY,
  mapContainerStyle,
} from "Config/Map-config";
import { useCallback, useMemo, useRef } from "react";
import { AimOutlined } from "@ant-design/icons";

//map_data_string comes here in the format of longitude/latitude
type Props = {
  map_data_string: string;
};

const CustomMap = ({ map_data_string }: Props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
  });
  const mapRef = useRef<any>();
  const splitMapData = map_data_string?.split("/");

  const longitude = Number(splitMapData[0]);
  const latitude = Number(splitMapData[1]);

  const centerVal: any = useMemo(() => {
    if (latitude === undefined || longitude === undefined) {
      return {
        lat: 27.679667923850467,
        lng: 85.31052126714727,
      };
    } else {
      return {
        lat: latitude,
        lng: longitude,
      };
    }
  }, [latitude, longitude]);

  const pantTo = useCallback(({ lat, lng, zoomLevel = 15 }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(zoomLevel);
  }, []);

  const onMapLoad = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;
      pantTo({
        lat: latitude,
        lng: longitude,
      });
    },
    [latitude, longitude]
  );

  if (splitMapData.length !== 2) return <div>Map Not Available</div>;
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <div className="mainMapContainer">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        options={googleMapOptions}
        center={centerVal}
        ref={mapRef}
        onLoad={onMapLoad}
      >
        {longitude !== undefined && latitude !== undefined && (
          <>
            <Marker
              position={{
                lat: latitude,
                lng: longitude,
              }}
            />
            <Circle center={centerVal} radius={150} options={circleOptions} />
          </>
        )}
      </GoogleMap>
      <div className="recenter--map">
        <Button
          onClick={() =>
            pantTo({
              lat: latitude,
              lng: longitude,
            })
          }
          type="primary"
          className="primary--alt"
          icon={<AimOutlined />}
        >
          Re-center
        </Button>
      </div>
    </div>
  ) : (
    <div className="spinner--map">
      <Spin />
    </div>
  );
};

export default CustomMap;
