import { Reducer } from "react";

import {
  CREATE_CUSTOMER_ACCOUNTS,
  CREATE_CUSTOMER_ACCOUNTS_FAILURE,
  CREATE_CUSTOMER_ACCOUNTS_SUCCESS,
  DELETE_CUSTOMER_ACCOUNTS,
  DELETE_CUSTOMER_ACCOUNTS_FAILURE,
  DELETE_CUSTOMER_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNT_LIST,
  FETCH_ACCOUNT_LIST_FAILURE,
  FETCH_ACCOUNT_LIST_SUCCESS,
  FETCH_ACCOUNT_TYPE_LIST_SUCCESS,
  UPDATE_CUSTOMER_ACCOUNTS,
  UPDATE_CUSTOMER_ACCOUNTS_FAILURE,
  UPDATE_CUSTOMER_ACCOUNTS_SUCCESS,
} from "./AccountTypes";

const INIT_STATE = {
  loading: false,
  list: [],
  totalRecords: 0,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  accountTypeList: [],
};

export const accountReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {
    case FETCH_ACCOUNT_LIST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.results,
        totalRecords: action.payload.count,
      };

    case FETCH_ACCOUNT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        list: [],
      };

    case CREATE_CUSTOMER_ACCOUNTS:
    case UPDATE_CUSTOMER_ACCOUNTS:
      return {
        ...state,
        creating: true,
        created: false,
      };

    case CREATE_CUSTOMER_ACCOUNTS_SUCCESS:
    case UPDATE_CUSTOMER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        creating: false,
        created: true,
      };
    case CREATE_CUSTOMER_ACCOUNTS_FAILURE:
    case UPDATE_CUSTOMER_ACCOUNTS_FAILURE:
      return {
        ...state,
        creating: false,
        created: false,
      };

    case DELETE_CUSTOMER_ACCOUNTS:
      return {
        ...state,
        deleting: true,
        deleted: false,
      };
    case DELETE_CUSTOMER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
      };
    case DELETE_CUSTOMER_ACCOUNTS_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
      };

    case FETCH_ACCOUNT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        accountTypeList: action.payload.results,
      };
    default:
      return { ...state };
  }
};
