import { Modal as AntModal, ModalFuncProps, ModalProps } from "antd";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
} & ModalFuncProps &
  ModalProps;

const Modal = ({ children, ...rest }: Props) => {
  return (
    <AntModal
      centered
      footer={null}
      width={"50vw"}
      //   onCancel={() => {}}
      //   destroyOnClose
      //   closable={false}
      {...rest}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
