import { Reducer } from "react";
import {
  CLEAR_S3URLTODOWNLOAD,
  GENERATE_BOFILE_FAILURE,
  GENERATE_BOFILE_START,
  GENERATE_BOFILE_SUCCESS,
  GENERATE_PAYMENT_FILE_FAILURE,
  GENERATE_PAYMENT_FILE_REQUEST,
  GENERATE_PAYMENT_FILE_SUCCESS,
  SET_BOFILE_POPUP_MESSAGE,
  TOGGLE_BOFILE_POPUP,
} from "./BatchFileTypes";

const INIT_STATE = {
  showDownloadPopup: false,
  downloading: false,
  submitting: false,
  s3UrlToDownload: "",
  downloadFileName: "",
  message: "",
};

export const BatchFileReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {
    case GENERATE_BOFILE_START: {
      return {
        ...state,
        submitting: true,
        s3UrlToDownload: "",
        showDownloadingPopup: true,
        downloadFileName: action.fileName,
        message: "Generating Zip File",
      };
    }
    case GENERATE_BOFILE_SUCCESS: {
      return {
        ...state,
        submitting: false,
        s3UrlToDownload: action.payload,
        showDownloadingPopup: true,
        message: "Zip Generated",
      };
    }
    case GENERATE_BOFILE_FAILURE: {
      return {
        ...state,
        submitting: false,
        s3UrlToDownload: "",
        showDownloadingPopup: false,
        message: "",
      };
    }
    case TOGGLE_BOFILE_POPUP: {
      return {
        ...state,
        showDownloadingPopup: action.payload,
      };
    }

    case CLEAR_S3URLTODOWNLOAD: {
      return {
        ...state,
        s3UrlToDownload: "",
      };
    }

    case SET_BOFILE_POPUP_MESSAGE: {
      return {
        ...state,
        message: action.payload,
      };
    }

    case GENERATE_PAYMENT_FILE_REQUEST: {
      return {
        ...state,
        submitting: true,
        s3UrlToDownload: "",
        showDownloadingPopup: true,
        downloadFileName: "payments.xlsx",
        message: "Generating Excel File",
      };
    }

    case GENERATE_PAYMENT_FILE_SUCCESS: {
      return {
        ...state,
        submitting: false,
        s3UrlToDownload: action.payload,
        showDownloadingPopup: true,
        message: "File Generated",
      };
    }

    case GENERATE_PAYMENT_FILE_FAILURE: {
      return {
        ...state,
        submitting: false,
        s3UrlToDownload: "",
        showDownloadingPopup: false,
        message: "",
      };
    }

    default:
      return { ...state };
  }
};
