import './PageLoading.scss';

import { Empty } from 'antd';
import Loading from 'Components/Loading/Loading';

interface PageLoadingTypes {
	loading: boolean;
	empty?: boolean;
	children?: any;
	style?: React.CSSProperties
}
export const PageLoading: React.FC<PageLoadingTypes> = ({ loading, empty, children, style }) => {
	return <div style={style}>{loading ? <Loading style={{ paddingBottom: '40px' }} /> : <div>{empty ? <Empty /> : children}</div>}</div>;
};

export default PageLoading;
