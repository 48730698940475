import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'Config/config';

import {
  CreateDpCustomerParams,
  CustomerAddReferenceProps,
  FetchCustomerParams,
  FetchCustomerTransactionListProps,
} from './DpTypes';

//create Dp customers
axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const getDpCustomers = async (data: FetchCustomerParams) => {
  return axios.get(`customer/list`, {
    params: data,
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const getDpSingleCustomers = async (id: string) => {
  return axios.get(`customer/${id}/account/detail`, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const makeDpCustomer = async (data: CreateDpCustomerParams) => {
  return axios.post(`customer/create`, data, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const uploadDpaFile = async (data: any) => {
  return axios.post(`customer/upload-dpa5-file`, data, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const downloadPdf = async (
  data: FetchCustomerParams,
  fileType: string
) => {
  return axios.get(`customer/${fileType}/download`, {
    params: data,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const editCustomersDataApi = async (data: any) => {
  const { userId, body } = data;
  return axios.patch(`customer/${userId}/update/customer-info`, body, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const archiveCustomerApi = async (userId: string) => {
  return axios.patch(`customer/${userId}/archieve`, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const customerDetailDownloadApi = async (userId: string) => {
  return axios.get(`customer/${userId}/detail/pdf/download`, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const customerAddReferenceApi = async (
  data: CustomerAddReferenceProps
) => {
  const { id, body } = data;
  return axios.post(`customer/${id}/add-reference`, body, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};


export const getCustomerTransactionsApi = async (userId: string, data: FetchCustomerTransactionListProps) => {
  return axios.get(`accounts/customer/${userId}/voucher/list`, {
    params: data,
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const downloadCustomerTransactionReportApi = async (
  data: FetchCustomerTransactionListProps,
  fileType: string,
  userId: string
) => {

  return axios.get(`accounts/customer/${userId}/voucher/${fileType}/download`, {
    params: data,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};