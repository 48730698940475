import { Rule } from "antd/lib/form";

type Address_Fields =
  | "country"
  | "province"
  | "district"
  | "sub"
  | "tole"
  | "nearest_landmark"
  | "ward";

type Adrress_Validation = {
  [key in Address_Fields]: Rule[] | undefined;
};

export const AddressFormValidation: Adrress_Validation = {
  country: [
    { required: true, message: "Please enter a country" },
    {
      min: 3,
      message: "Country should be atleast 3 characters long",
    },
    {
      max: 20,
      message: "Country can be upto 20 characters long",
    },
  ],
  province: [
    { required: true, message: "Please enter a province" },
    {
      min: 5,
      message: "Province should be atleast 5 characters long",
    },
    {
      max: 30,
      message: "Province can be upto 30 characters long",
    },
  ],
  district: [
    {
      required: true,
      message: "Please select district!",
    },
  ],
  sub: [
    {
      required: true,
      message: "Please select VDC/Municipality/Metropolitan City!",
    },
  ],
  tole: [
    { required: true, message: "Please enter tole." },
    {
      min: 3,
      message: "Tole should be atleast 3 characters long",
    },
    {
      max: 20,
      message: "Tole can be upto 20 characters long",
    },
  ],
  ward: [
    { required: true, message: "Please enter ward number." },
    // {
    //   type: "number",
    //   min: 1,
    //   max: 100,
    //   message: "Ward number must be between 1 to 100",
    // },
  ],
  nearest_landmark: [
    { required: true, message: "Please enter a nearby landmark." },
    {
      min: 5,
      message: "Nearest Landmark should be atleast 5 characters long",
    },
    {
      max: 50,
      message: "Nearest Landmark can be upto 50 characters long",
    },
  ],
};
