import React, { useState } from "react";

import "./FloatLabel.scss";

const FloatLabel: React.FC<any> = (props: any) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value } = props;

  const labelClass =
    // focus || (value && value.length !== 0) ? "f-label f-label-float" : "f-label";
    focus || (value && value.length !== 0) ? "f-label f-label-float" : "f-label f-label-float";

  return (
    <div
      className={label ? "float-label" : ''}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default FloatLabel;
