import { message } from 'antd';
import { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { saveFile } from 'Utils/saveFile';

import {
  archiveCustomerFailure,
  archiveCustomerSuccess,
  createDpCustomerFailure,
  createDpCustomerSuccess,
  customerAddReferenceFailure,
  customerAddReferenceSuccess,
  downloadCustomerInfoFailure,
  downloadCustomerInfoSuccess,
  downloadCustomerTransReportFailure,
  downloadCustomerTransReportSuccess,
  editCustomersDataFailure,
  editCustomersDataSuccess,
  fetchCustomerTransactionFailure,
  fetchCustomerTransactionSuccess,
  fetchDpCustomerFailure,
  fetchDpCustomerSuccess,
  fetchDpSingleCustomerFailure,
  fetchDpSingleCustomerSuccess,
  getDpCustomerReportFailure,
  getDpCustomerReportSuccess,
  uploadDpaFileFailure,
  uploadDpaFileIncremental,
  uploadDpaFileSuccess,
} from './DpActions';
import {
  archiveCustomerApi,
  customerAddReferenceApi,
  customerDetailDownloadApi,
  downloadCustomerTransactionReportApi,
  downloadPdf,
  editCustomersDataApi,
  getCustomerTransactionsApi,
  getDpCustomers,
  getDpSingleCustomers,
  makeDpCustomer,
  uploadDpaFile,
} from './DpApis';
import {
  ARCHIVE_CUSTOMER,
  CREATE_DP_CUSTOMER,
  CreateDpCustomerParams,
  CUSTOMER_ADD_REFERENCE,
  CustomerAddReferenceProps,
  DOWNLOAD_CUSTOMER_INFO,
  DOWNLOAD_CUSTOMER_TRANSACTION_REPORT,
  DOWNLOAD_DP_CUSTOMER_REPORT,
  EDIT_CUSTOMERS_DATA,
  FETCH_DP_CUSTOMER,
  FETCH_DP_SINGLE_CUSTOMER,
  FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS,
  FetchCustomerParams,
  FetchCustomerTransactionListProps,
  UPLOAD_DPA_FILE,
} from './DpTypes';

//fetch dp customer

//get admin
const getDpCustomerAsync = async (data: FetchCustomerParams) =>
  await getDpCustomers(data);

function* getDpCustomer(params: any) {
  const { payload } = params;
  try {
    const dpCustomer: AxiosResponse = yield call(getDpCustomerAsync, payload);
    if (dpCustomer) {
      yield put(fetchDpCustomerSuccess(dpCustomer.data));
    } else {
      yield put(fetchDpCustomerFailure("Could not get list of customers"));
      message.error("Could not get list of customers");
    }
  } catch (error) {
    yield put(fetchDpCustomerFailure("Network Error while getting customers"));
    message.error("Could not get list of customers");
  }
}

function* watchGetDpCustomer() {
  yield takeLatest(FETCH_DP_CUSTOMER, getDpCustomer);
}

//get single customer

const getDpSingleCustomerAsync = async (id: string) =>
  await getDpSingleCustomers(id);

function* generateDpSingleCustomer(params: any) {
  const { payload } = params;

  try {
    const singleCustomer: AxiosResponse = yield call(
      getDpSingleCustomerAsync,
      payload
    );

    if (singleCustomer) {
      yield put(fetchDpSingleCustomerSuccess(singleCustomer.data));
    }
  } catch (error) {
    yield put(fetchDpSingleCustomerFailure());
  }
}

function* watchGetDpSingleCustomer() {
  yield takeLatest(FETCH_DP_SINGLE_CUSTOMER, generateDpSingleCustomer);
}

//create dp customer
const generateDpCustomerAsync = async (data: CreateDpCustomerParams) =>
  await makeDpCustomer(data);

function* generateDpCustomer(params: any) {
  const { payload } = params;
  try {
    const dpCustomer: AxiosResponse = yield call(
      generateDpCustomerAsync,
      payload
    );

    if (dpCustomer) {
      yield put(createDpCustomerSuccess(dpCustomer.data));
      message.success("Customer created successfully");
    } else {
      const messages = "Could not create customer";
      yield put(createDpCustomerFailure(messages));
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error while creating customer";
    yield put(createDpCustomerFailure(messages));
    message.error(messages);
  }
}

function* watchGenerateDpCustomer() {
  yield takeLatest(CREATE_DP_CUSTOMER, generateDpCustomer);
}

//upload dpa file

const uploadFileAsync = async (data: any) => await uploadDpaFile(data);

function* addDpaFile(params: any) {
  const { payload } = params;
  try {
    const uploadDpaFile: AxiosResponse = yield call(
      uploadFileAsync,
      payload.formData
    );
    if (uploadDpaFile) {
      if (payload.fileType === "Incremental") {
        yield put(uploadDpaFileIncremental(uploadDpaFile.data));
      } else {
        yield put(uploadDpaFileSuccess(uploadDpaFile.data));
      }
      message.success("File uploaded successfully");
    } else {
      const messages = "Could not upload file";
      yield put(uploadDpaFileFailure(messages));
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error while uploading file";
    yield put(uploadDpaFileFailure(messages));
    message.error(messages);
  }
}

function* watchAddDpaFile() {
  yield takeLatest(UPLOAD_DPA_FILE, addDpaFile);
}

//download file

const downloadFileAsync = async (data: any, fileType: string) => {
  return await downloadPdf(data, fileType);
};

function* downloadReport(params: any) {
  const { payload, fileType } = params;

  try {
    const downloadReport: AxiosResponse = yield call(
      downloadFileAsync,
      payload,
      fileType
    );
    if (downloadReport) {
      const contentType =
        fileType === "pdf"
          ? "application/pdf"
          : fileType === "csv"
            ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            : "";
      const extension =
        fileType === "pdf" ? "pdf" : fileType === "csv" ? "xlsx" : "";
      saveFile(downloadReport.data, contentType, `customer.${extension}`);
      yield put(getDpCustomerReportSuccess(downloadReport));
      message.success("File downloaded successfully");
    } else {
      const messages = "Could not download file";
      yield put(getDpCustomerReportFailure());
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error while downloading file";
    yield put(getDpCustomerReportFailure());
    message.error(messages);
  }
}

function* watchDownloadFile() {
  yield takeLatest(DOWNLOAD_DP_CUSTOMER_REPORT, downloadReport);
}

//edit customers

const editCustomersAsync = async (data: any) => {
  return await editCustomersDataApi(data);
};

function* editCustomers(params: any) {
  const { payload } = params;

  try {
    const response: AxiosResponse = yield editCustomersAsync(payload);
    message.success("Customers data updated successfully");

    yield put(editCustomersDataSuccess(response.data));
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(editCustomersDataFailure());
    message.error(messages);
  }
}

function* watchEditCustomersData() {
  yield takeLatest(EDIT_CUSTOMERS_DATA, editCustomers);
}

//archive customer

const archiveCustomerAsync = async (id: string) => {
  return await archiveCustomerApi(id);
};

function* archiveCustomer(params: any) {
  const { payload } = params;
  try {
    const response: AxiosResponse = yield archiveCustomerAsync(payload);
    yield put(archiveCustomerSuccess());
    const messages = "Customer removed successfully";
    message.success(messages);
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(archiveCustomerFailure());
    message.error(messages);
  }
}

function* watchArchiveCustomer() {
  yield takeLatest(ARCHIVE_CUSTOMER, archiveCustomer);
}

//download customer info

const downloadCustomerInfoAsync = async (id: string) => {
  return await customerDetailDownloadApi(id);
};

function* downloadCustomerInfo(params: any) {
  const { payload } = params;
  const response: AxiosResponse = yield downloadCustomerInfoAsync(payload);
  saveFile(response.data, "pdf", `customer.pdf`);
  yield put(downloadCustomerInfoSuccess());
  try {
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(downloadCustomerInfoFailure());
    message.error(messages);
  }
}

function* watchDownloadCustomerInfo() {
  yield takeLatest(DOWNLOAD_CUSTOMER_INFO, downloadCustomerInfo);
}

const customerAddReferenceAsync = async (data: CustomerAddReferenceProps) => {
  return await customerAddReferenceApi(data);
};

function* customerAddReference(data: any) {
  const { payload } = data;

  try {
    const response: AxiosResponse = yield customerAddReferenceAsync(payload);
    if (response) {
      yield put(customerAddReferenceSuccess());
    }
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(customerAddReferenceFailure());
    message.error(messages);
  }
}

function* watchCustomerAddReference() {
  yield takeLatest(CUSTOMER_ADD_REFERENCE, customerAddReference);
}


const fetchCustomerTransactionAsync = async (data: FetchCustomerTransactionListProps, userId: string) => {
  return await getCustomerTransactionsApi(userId, data)
}


function* fetchCustomerTransaction(data: any) {
  const { payload, userId } = data;

  try {
    const response: AxiosResponse = yield fetchCustomerTransactionAsync(payload, userId);
    if (response) {
      yield put(fetchCustomerTransactionSuccess(response.data))
    }
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(fetchCustomerTransactionFailure());
    message.error(messages);
  }
}

function* watchFetchCustomerTransaction() {
  yield takeLatest(FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS, fetchCustomerTransaction)
}


const downloadCustomerTransReportAsync = async (data: FetchCustomerTransactionListProps, fileType: string, userId: string) => {
  return await downloadCustomerTransactionReportApi(data, fileType, userId)
}

function* downloadCustomerTransReport(data: any) {
  const { payload, fileType, userId } = data
  try {
    const response: AxiosResponse = yield downloadCustomerTransReportAsync(payload, fileType, userId);
    if (response) {
      const contentType =
        fileType === "pdf"
          ? "application/pdf"
          : fileType === "excel"
            ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            : "";
      const extension =
        fileType === "pdf" ? "pdf" : fileType === "excel" ? "xlsx" : "";
      saveFile(response.data, contentType, `${userId}.${extension}`);
      yield put(downloadCustomerTransReportSuccess());
      message.success("File downloaded successfully");
    }

  } catch (error: any) {

    const messages = error.message ?? "Something went wrong";
    yield put(downloadCustomerTransReportFailure);
    message.error(messages);
  }
}

function* watchDownloadCustomerTransReport() {
  yield takeLatest(DOWNLOAD_CUSTOMER_TRANSACTION_REPORT, downloadCustomerTransReport)
}


export default function* DpSagas() {
  yield all([
    fork(watchGenerateDpCustomer),
    fork(watchGetDpCustomer),
    fork(watchAddDpaFile),
    fork(watchDownloadFile),
    fork(watchGetDpSingleCustomer),
    fork(watchEditCustomersData),
    fork(watchArchiveCustomer),
    fork(watchDownloadCustomerInfo),
    fork(watchCustomerAddReference),
    fork(watchFetchCustomerTransaction),
    fork(watchDownloadCustomerTransReport)
  ]);
}
