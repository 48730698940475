import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Table,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ColumnsType } from 'antd/lib/table';
import { AntFormItem } from 'Components/AntForm/AntForm';
import ListHeader from 'Components/ListHeader/ListHeader';
import Status from 'Components/Status/Status';
import TableActions from 'Components/TableAction/TableAction';
import dayjs from 'dayjs';
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import {
  deleteAdmin,
  fetchAdmins,
  updateAdmin,
} from 'Redux/AdminRedux/AdminActions';
import { FilterType } from 'Types/Types';
import { useDebounce } from 'use-debounce/lib';

export const AdminList = (props: any) => {
  const admins = useSelector((state: any) => state.AdminReducer);
  const { details } = useSelector((state: any) => state.UserReducer);
  const role = details?.role;
  const [filter, setFilter] = useState<FilterType>({
    page: 1,
    limit: 10,
  });
  const [filterText, setFilterText] = useState("");
  const [debounceFilter] = useDebounce(filterText, 500);
  const dispatch = useDispatch();

  const history = useHistory();

  const { loading, list, totalRecords, pageNumber } = admins;

  useEffect(() => {
    setFilter({ ...filter });
  }, [debounceFilter]);

  //Fetch data with filter
  const fetchWithFilter = (data: any) => {
    dispatch(fetchAdmins({ ...data, ...filter }));
  };

  //edit button click handler
  const onEditHandler = (data: any) => {
    history.push(`admin/${data?.id}/edit`);
  };

  const onDeleteHandler = (id: number) => {
    dispatch(deleteAdmin({ id: id }));
  };

  const onSuspendHandler = (row: any) => {
    dispatch(updateAdmin({ status: "Inactive", id: row?.id }));
  };

  const onUnSuspendHandler = (row: any) => {
    dispatch(updateAdmin({ status: "Active", id: row?.id }));
  };

  const onPageChange = (page: number) => {
    setFilter({ ...filter, page });
  };

  const adminColumns: ColumnsType<any[]> = [
    {
      title: "#",
      align: "center",
      render: (row: any, text: any, index: number) => {
        if (filter.page && filter.limit) {
          return (
            <Typography.Text>
              {(filter?.page - 1) * filter.limit + index + 1}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: "Title",
      dataIndex: "",
      key: "title",
      align: "center",
      render: (row: any, text: any, index: number) => (
        <Typography.Link onClick={() => onEditHandler(row)} strong>
          {row?.full_name}
        </Typography.Link>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      align: "center",
    },

    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: "center",
      render: (text: any, row: any, index: number) => <Status status={text} />,
    },

    {
      title: "Admin Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (row: any, text: any, index: number) => {
        return (
          <Typography.Text>{dayjs(text.created_at).format("LLL")}</Typography.Text>
        )
      }
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text: any, row: any, index: number) => <Status status={text} />,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text: any, row: any, index: number) => (
        <TableActions
          noReport
          onDelete={() => onDeleteHandler(row.id)}
          onEdit={() => onEditHandler(row)}
          onSuspend={() => onSuspendHandler(row)}
          onUnSuspend={() => onUnSuspendHandler(row)}
          archieved={true}
          showDeleteBtn={
            role === "Super Admin" || (role === "Admin" && row.role === "User")
          }
          showViewProfile={false}
          adminEdit={
            role === "Super Admin" || (role === "Admin" && row.role === "User")
          }
          showSuspend={
            row.role !== "Super Admin" &&
            (role === "Super Admin" ||
              (role === "Admin" && row.role === "User")) &&
            row.status === "Active"
          }
          showUnSuspend={
            row.role !== "Super Admin" &&
            (role === "Super Admin" ||
              (role === "Admin" && row.role === "User")) &&
            row.status !== "Active"
          }
        />
      ),
    },
  ];

  useEffect(() => {
    if (filter) {
      fetchWithFilter({ ...filter });
    }
  }, [filter]);

  const [form] = useForm();
  const userRoles = ["Super Admin", "Admin", "User", "All"];
  const [userRole, setUserRole] = useState("");
  const userStatuses = ["Active", "Inactive", "Any"];
  const [userStatus, setUserSatus] = useState("");
  const orderByList = ["ascending", "descending", "latest", "oldest"];
  const orderByListFinal: any = {
    ascending: "+full_name",
    descending: "-full_name",
    latest: "-created_at",
    oldest: "+created_at",
  };

  const [orderBy, setOrderBy] = useState("");

  const menu = (
    <Menu
      onClick={(e) => {
        setUserRole(e.key);
        form.setFieldsValue({ role: e.key });
      }}
    >
      {userRoles?.map((role) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={role}>
            {role}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const menuStatus = (
    <Menu
      onClick={(e) => {
        setUserSatus(e.key);
        form.setFieldsValue({ status: e.key });
      }}
    >
      {userStatuses?.map((item) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={item}>
            {item}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const menuOrderBy = (
    <Menu
      onClick={(e) => {
        setOrderBy(e.key);
        form.setFieldsValue({ order_by: e.key });
      }}
    >
      {orderByList?.map((item) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={item}>
            {item}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const onFilterSubmit = (value: any) => {
    const finalFilter = { ...filter };
    if (!value?.role || value?.role === "" || value?.role === "All") {
      delete finalFilter.role;
    } else {
      finalFilter.role = value?.role;
    }

    if (!value?.status || value?.status === "" || value?.status === "Any") {
      delete finalFilter.status;
    } else {
      finalFilter.status = value?.status;
    }

    if (!value?.username || value?.username === "") {
      delete finalFilter.username;
    } else {
      finalFilter.username = value?.username;
    }

    if (!value?.order_by || value?.order_by === "") {
      delete finalFilter.order_by;
    } else {
      finalFilter.order_by = orderByListFinal[value?.order_by.toLowerCase()];
    }

    setFilter({ ...finalFilter, page: 1, limit: 10 });
  };

  return (
    <Card>
      <div>
        <ListHeader
          title="Admin"
          module="admin"
          noAddFeature={role === "User"}
        />
      </div>

      <Form
        onFinish={onFilterSubmit}
        initialValues={{ username: "" }}
        form={form}
      >
        <Row gutter={[20, 20]}>
          <Col lg={5}>
            <AntFormItem name="username">
              <Input.Search placeholder="Search Username" />
            </AntFormItem>
          </Col>

          <Col lg={5}>
            <Form.Item hidden name="role">
              <Input />
            </Form.Item>

            <Dropdown trigger={["click"]} overlay={menu}>
              <Row align="middle" justify="space-between">
                <Typography.Text
                  style={{ textTransform: "capitalize" }}
                  strong={!!userRole}
                >
                  {userRole ? userRole : "Choose User Role"}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </Col>
          <Col lg={5}>
            <Form.Item hidden name="status">
              <Input />
            </Form.Item>

            <Dropdown trigger={["click"]} overlay={menuStatus}>
              <Row align="middle" justify="space-between">
                <Typography.Text
                  style={{ textTransform: "capitalize" }}
                  strong={!!userStatus}
                >
                  {userStatus ? userStatus : "Choose Status"}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </Col>

          <Col lg={5}>
            <Form.Item hidden name="order_by">
              <Input />
            </Form.Item>

            <Dropdown trigger={["click"]} overlay={menuOrderBy}>
              <Row align="middle" justify="space-between">
                <Typography.Text
                  style={{ textTransform: "capitalize" }}
                  strong={!!userStatus}
                >
                  {orderBy ? orderBy : "select order by"}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </Col>

          <Col lg={4}>
            <Button htmlType="submit" type="primary" className="primary--alt">
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        className="style--kbbs"
        columns={adminColumns}
        dataSource={list}
        loading={loading}
        rowKey="id"
        pagination={{
          current: parseInt(pageNumber) || 1,
          total: parseInt(totalRecords),
          onChange: (page: number) => {
            setFilter((prev) => ({
              ...prev,
              page: page
            }))
          },
          showSizeChanger: true,
          onShowSizeChange :  (current, size) => {
            setFilter((prev) => ({
              ...prev,
              limit: size
            }))
            
          },
        }}
        onRow={(record, rowIndex) => {
          return {};
        }}
      />
    </Card>
  );
};

export default AdminList;
