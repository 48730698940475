import { message } from 'antd';
import { AxiosResponse } from 'axios';
import {
  all,
  call,
  delay,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  createAdminFailure,
  createAdminSuccess,
  deleteAdminFailure,
  deleteAdminSuccess,
  fetchAdminFailure,
  fetchAdminsFailure,
  fetchAdminsSuccess,
  fetchAdminSuccess,
  updateAdminFailure,
  updateAdminSuccess,
} from './AdminActions';
import {
  cutAdmin,
  makeAdmin,
  modifyAdmin,
  takeAdmin,
  takeAdmins,
} from './AdminApis';
import {
  AdminDetailsParams,
  AdminFetchParams,
  CREATE_ADMIN,
  CreateAdminParams,
  FETCH_ADMIN,
  FETCH_ADMINS,
  REMOVE_ADMIN,
  UPDATE_ADMIN,
} from './AdminTypes';

//get admin
const getAdminsAsync = async (data: AdminFetchParams) => await takeAdmins(data)

function* getAdmins(params: any) {
  const { payload } = params
  try {
    const Admins: AxiosResponse = yield call(getAdminsAsync, payload)
    if (Admins) {
      yield put(fetchAdminsSuccess(Admins.data))
    } else {
      yield put(fetchAdminsFailure('Could not get list of admin'))
      message.error('Could not get list of admin')
    }
  } catch (error) {
    yield put(fetchAdminsFailure('Network Error while getting Admins'))
    message.error('Could not get list of admin')
  }
}

function* watchGetAdmins() {
  yield takeLatest(FETCH_ADMINS, getAdmins)
}

//get user details
const getAdminAsync = async (data: AdminDetailsParams) => await takeAdmin(data)

function* getAdmin(params: any) {
  const { payload } = params
  try {
    const Admins: AxiosResponse = yield call(getAdminAsync, payload)
    if (Admins) {
      yield put(fetchAdminSuccess(Admins.data))
    } else {
      yield put(fetchAdminFailure('Could not get list of admin'))
      message.error('Could not get list of admin')
    }
  } catch (error) {
    yield put(fetchAdminFailure('Network Error while getting Admins'))
    message.error('Could not get list of admin')
  }
}

function* watchGetAdmin() {
  yield takeLatest(FETCH_ADMIN, getAdmin)
}

//create Admin
const generateAdminAsync = async (data: CreateAdminParams) =>
  await makeAdmin(data)

function* generateAdmin(params: any) {
  const { payload } = params
  try {
    const Admins: AxiosResponse = yield call(generateAdminAsync, payload)
    if (Admins) {
      yield put(createAdminSuccess(Admins.data))
      message.success('Admin created successfully')
    } else {
      const messages = 'Could not create Admin'
      yield put(createAdminFailure(messages))
      message.error(messages)
    }
    yield delay(1000)
    yield window.location.reload()
  } catch (error:any) {    
    const messages = error.response.data.message || 'Network Error while creating Admin'
    yield put(createAdminFailure(messages))
    message.error(messages)
  }
}

function* watchGenerateAdmin() {
  yield takeLatest(CREATE_ADMIN, generateAdmin)
}

//update Admin
const amendAdminAsync = async (data: CreateAdminParams) =>
  await modifyAdmin(data)

function* amendAdmin(params: any) {
  const { payload } = params
  try {
    const Admins: AxiosResponse = yield call(amendAdminAsync, payload)
    if (Admins) {
      yield put(updateAdminSuccess(Admins.data))
      message.success('Admin updated successfully')
    } else {
      const messages = 'Could not Update Admin'
      yield put(updateAdminFailure(messages))
      message.error(messages)
    }
    yield delay(1000)
    yield window.location.reload()
  } catch (error) {
    const messages = 'Network Error while updating Admin'
    yield put(updateAdminFailure(messages))
    message.error(messages)
  }
}

function* watchAmendAdmin() {
  yield takeLatest(UPDATE_ADMIN, amendAdmin)
}

//Delete Admin
const removeAdminAsync = async (data: AdminDetailsParams) =>
  await cutAdmin(data)

function* removeAdmin(params: any) {
  const { payload } = params
  try {
    const Admins: AxiosResponse = yield call(removeAdminAsync, payload)
    if (Admins) {
      yield put(deleteAdminSuccess(Admins.data))
      message.success('Admin Deleted successfully')
    } else {
      const messages = 'Could not delete Admin'
      yield put(deleteAdminFailure(messages))
      message.error(messages)
    }
  } catch (error) {
    const messages = 'Network Error while deleting Admin'
    yield put(deleteAdminFailure(messages))
    message.error(messages)
  }
}

function* watchRemoveAdmin() {
  yield takeLatest(REMOVE_ADMIN, removeAdmin)
}

export default function* AdminSagas() {
  yield all([
    fork(watchGetAdmins),
    fork(watchGetAdmin),
    fork(watchGenerateAdmin),
    fork(watchAmendAdmin),
    fork(watchRemoveAdmin),
  ])
}
