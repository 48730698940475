export const saveFile = (file: BlobPart, type: string, fileName: string) => {
  const blob = new Blob([file], { type: type });
  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName); // Set the desired file name

  // Simulate a click on the link to trigger the download
  document.body.appendChild(link);
  link.click();

  // Cleanup: Remove the link and revoke the Blob URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
