import { Reducer } from 'react';

import {
  ARCHIVE_CUSTOMER,
  ARCHIVE_CUSTOMER_FAILURE,
  ARCHIVE_CUSTOMER_SUCCESS,
  CREATE_DP_CUSTOMER,
  CREATE_DP_CUSTOMER_FAILURE,
  CREATE_DP_CUSTOMER_SUCCESS,
  CUSTOMER_ADD_REFERENCE,
  CUSTOMER_ADD_REFERENCE_FAILURE,
  CUSTOMER_ADD_REFERENCE_SUCCESS,
  DOWNLOAD_CUSTOMER_INFO,
  DOWNLOAD_CUSTOMER_INFO_FAILURE,
  DOWNLOAD_CUSTOMER_INFO_SUCCESS,
  DOWNLOAD_CUSTOMER_TRANSACTION_REPORT,
  DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_FAILURE,
  DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_DP_CUSTOMER_REPORT,
  DOWNLOAD_DP_CUSTOMER_REPORT_FAILURE,
  DOWNLOAD_DP_CUSTOMER_REPORT_SUCCESS,
  EDIT_CUSTOMERS_DATA,
  EDIT_CUSTOMERS_DATA_FAILURE,
  EDIT_CUSTOMERS_DATA_SUCCESS,
  FETCH_DP_CUSTOMER,
  FETCH_DP_CUSTOMER_FAILURE,
  FETCH_DP_CUSTOMER_SUCCESS,
  FETCH_DP_SINGLE_CUSTOMER,
  FETCH_DP_SINGLE_CUSTOMER_FAILURE,
  FETCH_DP_SINGLE_CUSTOMER_SUCCESS,
  FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS,
  FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_FAILURE,
  FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_SUCCESS,
  RESET_STATUS_VALUE,
  REUPLOAD_FILE,
  UPLOAD_DPA_FILE,
  UPLOAD_DPA_FILE_FAILURE,
  UPLOAD_DPA_FILE_INCREMENTAL,
  UPLOAD_DPA_FILE_SUCCESS,
} from './DpTypes';

const INIT_STATE = {
  list: [],
  error: "",
  loading: false,
  loaded: false,
  creating: false,
  created: false,
  uploadDpaLoading: false,
  uploadDpaError: false,
  uploadDpaSuccess: false,
  uploadDpaProgress: 0,
  uploadDpaData: {},
  totalRecords: 8,
  downloadingPdfCsv: false,
  downloadedPdfCsv: false,
  downloadedPdfCsvError: false,
  customerDetails: {},
  editingCustomers: false,
  editedCustomer: false,
  wrongBoidData: [],
  wrongBoidCount: 0,
  correctBoidCount: 0,
  correctBoidList: [],
  archivingCustomer: false,
  archivedCustomer: false,
  downloadingCustomerInfo: false,
  addingCustomerReference: false,
  addedCustomerReference: false,
  customerTransactionLoading: false,
  customerTransactionList: [],
  customerTransactionTotalRecords: 0,
  downloadingCustomerPdfCsv: false,
  downloadedCustomerPdfCsv: false,
  downloadedCustomerPdfCsvError: false,

};

export const dpCustomer: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    //fetch customer

    case FETCH_DP_CUSTOMER:
    case FETCH_DP_SINGLE_CUSTOMER:
      return {
        ...state,
        loaded: false,
        loading: true,
        archivedCustomer: false,
      };
    case FETCH_DP_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.results,
        totalRecords: action.payload.count,
      };
    case FETCH_DP_CUSTOMER_FAILURE:
    case FETCH_DP_SINGLE_CUSTOMER_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FETCH_DP_SINGLE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        customerDetails: action.payload,
      };

    //create customer
    case CREATE_DP_CUSTOMER:
    case EDIT_CUSTOMERS_DATA:
      return { ...state, created: false, creating: true, error: false };
    case CREATE_DP_CUSTOMER_SUCCESS:
    case EDIT_CUSTOMERS_DATA_SUCCESS:
      return { ...state, creating: false, created: true };
    case CREATE_DP_CUSTOMER_FAILURE:
    case EDIT_CUSTOMERS_DATA_FAILURE:
      return {
        ...state,
        creating: false,
        error: "some thing went wrong",
        created: false,
      };

    //upload dpa file

    case UPLOAD_DPA_FILE:
      return {
        ...state,
        uploadDpaLoading: true,
        uploadDpaError: false,
        uploadDpaSuccess: false,
      };

    case UPLOAD_DPA_FILE_SUCCESS:
      return {
        ...state,
        uploadDpaLoading: false,
        uploadDpaError: false,
        uploadDpaSuccess: true,
        uploadDpaData: action.payload,
        isIncremental: false,
      };

    case UPLOAD_DPA_FILE_INCREMENTAL:
      const getFilterData = (status: string) => {
        return action.payload?.users_boid.filter((item: any) => status in item);
      };
      return {
        ...state,
        uploadDpaLoading: false,
        uploadDpaError: false,
        uploadDpaSuccess: false,
        isIncremental: true,
        wrongBoidData:
          action.payload?.users_boid.length > 0
            ? getFilterData("wrong_boid")
            : [],
        wrongBoidCount:
          action.payload?.users_boid.length > 0
            ? getFilterData("wrong_boid").length
            : 0,
        correctBoidCount:
          action.payload?.users_boid.length > 0
            ? getFilterData("correct_boid").length
            : 0,
        correctBoidList:
          action.payload?.users_boid.length > 0
            ? getFilterData("correct_boid")
            : [],
      };

    case UPLOAD_DPA_FILE_FAILURE:
      return {
        ...state,
        uploadDpaLoading: false,
        uploadDpaError: true,
        uploadDpaSuccess: false,
      };

    case REUPLOAD_FILE:
      return {
        ...state,
        uploadDpaError: false,
        editedCustomer: false,
      };

    case DOWNLOAD_DP_CUSTOMER_REPORT:
      return {
        ...state,
        downloadingPdfCsv: true,
        downloadedPdfCsv: false,
        downloadedPdfCsvError: false,
      };

    case DOWNLOAD_DP_CUSTOMER_REPORT_SUCCESS:
      return {
        ...state,
        downloadingPdfCsv: false,
        downloadedPdfCsv: true,
        downloadedPdfCsvError: false,
      };
    case DOWNLOAD_DP_CUSTOMER_REPORT_FAILURE:
      return {
        ...state,
        downloadingPdfCsv: false,
        downloadedPdfCsv: false,
        downloadedPdfCsvError: true,
      };

    case RESET_STATUS_VALUE:
      return {
        ...state,
        uploadDpaError: false,
        uploadDpaSuccess: false,
        isIncremental: false,
      };

    case ARCHIVE_CUSTOMER:
      return {
        ...state,
        archivingCustomer: true,
      };
    case ARCHIVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        archivingCustomer: false,
        archivedCustomer: true,
      };

    case ARCHIVE_CUSTOMER_FAILURE:
      return {
        ...state,
        archivingCustomer: false,
        archivedCustomer: false,
      };

    case DOWNLOAD_CUSTOMER_INFO:
      return {
        ...state,
        downloadingCustomerInfo: true,
      };

    case DOWNLOAD_CUSTOMER_INFO_SUCCESS:
    case DOWNLOAD_CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        downloadingCustomerInfo: false,
      };

    case CUSTOMER_ADD_REFERENCE:
      return {
        ...state,
        addingCustomerReference: true,
        addedCustomerReference: false,
      };

    case CUSTOMER_ADD_REFERENCE_SUCCESS:
      return {
        ...state,
        addingCustomerReference: false,
        addedCustomerReference: true,
      };

    case CUSTOMER_ADD_REFERENCE_FAILURE:
      return {
        ...state,
        addingCustomerReference: false,
        addedCustomerReference: false,
      };

    case FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS:
      return {
        ...state,
        customerTransactionLoading: true,
        customerTransactionList: [],
        customerTransactionTotalRecords: 0
      }

    case FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        customerTransactionLoading: false,
        customerTransactionList: action.payload.results,
        customerTransactionTotalRecords: action.payload.count
      }

    case FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        customerTransactionLoading: false,
        customerTransactionList: [],
        customerTransactionTotalRecords: 0
      }

    case DOWNLOAD_CUSTOMER_TRANSACTION_REPORT:
      return {
        ...state,
        downloadingCustomerPdfCsv: true,
        downloadedCustomerPdfCsv: false,
        downloadedCustomerPdfCsvError: false,
      }

    case DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_SUCCESS:
      return {
        ...state,
        downloadingCustomerPdfCsv: false,
        downloadedCustomerPdfCsv: true,
        downloadedCustomerPdfCsvError: false,
      }

    case DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_FAILURE:
      return {
        ...state,
        downloadingCustomerPdfCsv: false,
        downloadedCustomerPdfCsv: false,
        downloadedCustomerPdfCsvError: true,
      }

    default:
      return { ...state };
  }
};
