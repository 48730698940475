import './FormViewRenderer.scss';

import {
  useEffect,
  useState,
} from 'react';

import {
  AutoComplete,
  Col,
  Divider,
  Form,
  FormProps,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import {
  AntForm,
  AntFormItem,
} from 'Components/AntForm/AntForm';
import { DatePicker } from 'Components/DatePicker';
import { useSelector } from 'react-redux';
import {
  FormItemTypes,
  OptionTypes,
} from 'Types/Types';

export interface FormViewRendererTypes extends FormProps {
  formItems: FormItemTypes[]
  gutter?: Gutter
  wrapperKey?: string
}

export const FormViewRenderer: React.FC<FormViewRendererTypes> = ({
  children,
  formItems,
  wrapperKey,
  layout,
  ...rest
}) => {
  const {
    districtList,
    bankList,
    organizationList,
    bankWithBranch,
    brokerList,
  } = useSelector((state: any) => state.OptionReducer)

  const [branchName, setBranchName] = useState([])
  const [brokerOption, setBrokerOption] = useState([])

  useEffect(() => {
    setBrokerOption(
      brokerList?.map((item: any) => {
        return { value: item?.name, label: item?.name }
      })
    )
  }, [brokerList])

  const handelSelectChange = (selectType: string, selectedData: any) => {
    if (selectType === 'bank') {
      const selectedBranch = bankWithBranch.find(
        (e: any) => e.name === selectedData
      )
      const data = selectedBranch?.branches.map((branch: string) => {
        return { value: branch, label: branch }
      })
      setBranchName(data)
    }
    if (selectType === 'broker') {
      const selectedBroker = brokerList.find(
        (e: any) => e.name === selectedData
      )
      const data = {
        buss_sec_brok_num: selectedBroker?.number,
        buss_sec_brok_name: selectedData,
      }
      rest?.form?.setFieldsValue({
        transaction_related: data,
      })
    }
  }
  useEffect(() => {
    if (wrapperKey && rest?.form?.getFieldsValue()?.[wrapperKey]) {
      const data = rest?.form?.getFieldsValue()?.[wrapperKey]?.bank_name

      handelSelectChange('bank', data)
    }
  }, [formItems])

  const getField = (
    type: any,
    options?: OptionTypes[],
    isLoading: boolean = false,
    selectSearchable: boolean = false,
    disabled: boolean = false,
    remoteRoute?: string
  ) => {
    switch (type) {
      case 'text':
        return <Input disabled={disabled} />
      case 'select': {
        if (isLoading) return <Spin />
        if (remoteRoute) {
          let selectOpt = []
          remoteRoute === 'bank'
            ? (selectOpt = bankList)
            : remoteRoute === 'address'
            ? (selectOpt = districtList)
            : remoteRoute === 'branch'
            ? (selectOpt = branchName)
            : remoteRoute === 'broker'
            ? (selectOpt = brokerOption)
            : (selectOpt = organizationList)
          return (
            <Select
              style={{ width: '100%' }}
              showSearch={selectSearchable}
              onChange={(e) => handelSelectChange(remoteRoute, e)}
            >
              {selectOpt?.map((option: any, index: any) => (
                <Select.Option value={option?.value} key={index}>
                  {option?.label}
                </Select.Option>
              ))}
            </Select>
          )
        } else {
          return (
            <Select style={{ width: '100%' }} showSearch={selectSearchable}>
              {options?.map((option, index) => (
                // @ts-ignore:next-line
                <Select.Option value={option?.value} key={index}>
                  {option?.label}
                </Select.Option>
              ))}
            </Select>
          )
        }
      }
      case 'autocomplete': {
        // let tempOptions = options;
        // const onSearch = (searchedString: string) => {
        //   tempOptions = options?.filter((item: any) =>
        //     item.value.toLowerCase().includes(searchedString.toLowerCase())
        //   );
        // };
        if (isLoading) return <Spin />
        return (
          <AutoComplete
            style={{ width: '100%' }}
            // @ts-ignore:next-line
            options={organizationList}
            filterOption={true}
            showSearch={true}
          />
        )
      }
      case 'number':
        return <Input type='number' />
      case 'date':
        return <DatePicker
          style={{ width: '100%' }}
        />
      case 'radio':
        return (
          <Radio.Group style={{ marginTop: '30px', marginLeft: '10px' }}>
            {options?.map((option, index) => (
              <Radio value={option?.value} key={index}>
                {option?.label}
              </Radio>
            ))}
          </Radio.Group>
        )
      default:
        return <Input />
    }
  }
  return (
    <AntForm layout={layout || 'vertical'} {...rest}>
      {formItems?.length > 0 && (
        <Form.Item name={wrapperKey || 'test'}>
          <Input.Group>
            <Row gutter={[20, 0]}>
              {formItems?.map((item: any, index) => (
                <Col span={item.span || 24}>
                  {item?.section && (
                    <>
                      <Typography.Title
                        style={{
                          marginTop: index === 0 ? 0 : 30,
                          marginBottom: 0,
                        }}
                        level={item.level || 5}
                      >
                        {item.title}
                      </Typography.Title>
                      <Divider style={{ margin: '15px 0px' }} />
                    </>
                  )}
                  {!item.section && item.type !== 'date' && (
                    <AntFormItem
                      label={item.label}
                      name={[wrapperKey || 'test', item.name]}
                      rules={[
                        {
                          required: item.required,
                          message: `${item.label} is required`,
                        },
                        ...(item.pattern
                          ? [
                              {
                                pattern: item?.pattern,
                                message: item?.patternError,
                              },
                            ]
                          : []),
                      ]}
                    >
                      {getField(
                        item.type,
                        item?.options,
                        item?.remoteLoading,
                        item?.selectSearchable,
                        item?.disabled,
                        item?.remoteRoute
                      )}
                    </AntFormItem>
                  )}
                  {item.type === 'date' && (
                    <AntFormItem
                      label={item.label}
                      name={[wrapperKey || 'test', item.name]}
                      rules={[
                        {
                          required: item.required,
                          message: `${item.label} is required`,
                        },
                      ]}
                    >
                      {getField(item.type, item?.options)}
                    </AntFormItem>
                  )}
                </Col>
              ))}
              <Col lg={24}>{children}</Col>
            </Row>
          </Input.Group>
        </Form.Item>
      )}
    </AntForm>
  )
}

export default FormViewRenderer
