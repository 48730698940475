import React, {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import {
  Col,
  Row,
  Table,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';

import { TransactionListFilterProps } from '../Transaction';
import { VoucherDetails } from './VoucherDetails';

interface FilterComponentProps {
  setFilterData: Dispatch<SetStateAction<TransactionListFilterProps>>;
  filterData: TransactionListFilterProps;
}

export const TableComponent = ({
  filterData,
  setFilterData,
}: FilterComponentProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const { voucherList, voucherListLoading, totalRecords } = useSelector(
    (state: any) => state.dpTransactionReducer
  );

  const [showDetails, setShowDetails] = useState({
    visible: false,
    id: ""
  })

  const onSelectChange = (selKeys: any) => {
    setSelectedRowKeys(selKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const transactionColumns = [
    {
      title: "User ID",
      dataIndex: "boid_number",
      key: "boid_number",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Receipt No.",
      dataIndex: "voucher_number",
      key: "voucher_number",
      render: (text: any, row: any, index: number) => (
        <Typography.Text onClick={() =>
          setShowDetails({
            visible: true,
            id: row.id
          })
        }>{text}</Typography.Text>
      ),
    },
    {
      title: "Fiscal Year",
      dataIndex: "fiscal_year",
      key: "fiscal_year",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Amount Paid",
      dataIndex: "amount",
      key: "amount",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Mode",
      dataIndex: "payment_mode",
      key: "payment_mode",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
  ];

  return (
    <>
      <Row
        style={{
          marginTop: 20,
        }}
      >
        <Col lg={24}>
          <Table
            rowKey="id"
            columns={transactionColumns}
            dataSource={voucherList}
            loading={voucherListLoading}
            rowSelection={rowSelection}
            pagination={{
              current: Number(filterData.page),
              total: parseInt(totalRecords),
              onChange: (page: number) => {
                setFilterData((prevState) => ({
                  ...prevState,
                  page: page,
                }));
              },
              pageSize: filterData.limit,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <VoucherDetails showDetails={showDetails} setShowDetails={setShowDetails} />
    </>
  );
};
