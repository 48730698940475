import {
  ACCEPT_REJECT_FAILURE,
  ACCEPT_REJECT_SUCCESS,
  ACCEPT_REJECT_VIDEO_KYC,
  AcceptRejectVideoKyc,
  CREATE_KYC,
  CREATE_KYC_FAILURE,
  CREATE_KYC_SUCCESS,
  CreateKycParams,
  FETCH_KYC,
  FETCH_KYC_FAILURE,
  FETCH_KYC_REQUESTS,
  FETCH_KYC_REQUESTS_FAILURE,
  FETCH_KYC_REQUESTS_SUCCESS,
  FETCH_KYC_SUCCESS,
  FETCH_KYCS,
  FETCH_KYCS_FAILURE,
  FETCH_KYCS_SUCCESS,
  FETCH_VIDEO_KYC,
  FETCH_VIDEO_KYC_FAILURE,
  FETCH_VIDEO_KYC_SUCCESS,
  GENERATE_PDF,
  GENERATE_PDF_FAILURE,
  GENERATE_PDF_SUCCESS,
  KycDetailsParams,
  KycFetchParams,
  REMOVE_KYC,
  REMOVE_KYC_FAILURE,
  REMOVE_KYC_SUCCESS,
  RESCHEDULE_FAILURE,
  RESCHEDULE_SUCCESS,
  RESCHEDULE_VIDEO_KYC,
  RescheduleKyc,
  SCHEDULE_FAILURE,
  SCHEDULE_SUCCESS,
  SCHEDULE_VIDEO_KYC,
  ScheduleKyc,
  UPDATE_KYC,
  UPDATE_KYC_FAILURE,
  UPDATE_KYC_SUCCESS,
  USER_LIST_SCHEDULE_VIDEO_KYC,
  USER_LIST_SCHEDULE_VIDEO_KYC_FAILURE,
  USER_LIST_SCHEDULE_VIDEO_KYC_SUCCESS,
  VERIFY_VIDEO_KYC,
} from './KycTypes';

//fetch Kyc List
export const fetchKycs = (params: KycFetchParams) => ({
  type: FETCH_KYCS,
  payload: params,
});

export const fetchKycsSuccess = (response: any) => ({
  type: FETCH_KYCS_SUCCESS,
  payload: response,
});

export const fetchKycsFailure = (error: any) => ({
  type: FETCH_KYCS_FAILURE,
  payload: error,
});

//fetch Kyc Requests List
export const fetchKycRequests = (params: KycFetchParams) => ({
  type: FETCH_KYC_REQUESTS,
  payload: params,
});

export const fetchKycRequestsSuccess = (response: any) => ({
  type: FETCH_KYC_REQUESTS_SUCCESS,
  payload: response,
});

export const fetchKycRequestsFailure = (error: any) => ({
  type: FETCH_KYC_REQUESTS_FAILURE,
  payload: error,
});

//fetch Video Kyc
export const fetchVideoKyc = (params: string) => ({
  type: FETCH_VIDEO_KYC,
  payload: params,
});

export const fetchVideoKycSuccess = (response: any) => {
  return {
    type: FETCH_VIDEO_KYC_SUCCESS,
    payload: response,
  };
};

export const fetchVideoKycFailure = (error: any) => ({
  type: FETCH_VIDEO_KYC_FAILURE,
  payload: error,
});

//fetch Kyc Details
export const fetchKyc = (params: KycDetailsParams) => ({
  type: FETCH_KYC,
  payload: params,
});

export const fetchKycSuccess = (response: any) => {
  return { type: FETCH_KYC_SUCCESS, payload: response };
};

export const fetchKycFailure = (error: any) => ({
  type: FETCH_KYC_FAILURE,
  payload: error,
});

//create Kyc
export const createKyc = (params: CreateKycParams) => ({
  type: CREATE_KYC,
  payload: params,
});

export const createKycSuccess = (response: CreateKycParams) => ({
  type: CREATE_KYC_SUCCESS,
  payload: response,
});
export const createKycFailure = (error: any) => ({
  type: CREATE_KYC_FAILURE,
  payload: error,
});

//update Kyc
export const updateKyc = (params: CreateKycParams) => ({
  type: UPDATE_KYC,
  payload: params,
});

export const updateKycSuccess = (response: CreateKycParams) => ({
  type: UPDATE_KYC_SUCCESS,
  payload: response,
});

export const updateKycFailure = (error: any) => ({
  type: UPDATE_KYC_FAILURE,
  payload: error,
});

//delete Kyc
export const deleteKyc = (params: KycDetailsParams) => ({
  type: REMOVE_KYC,
  payload: params,
});
export const deleteKycSuccess = (response: KycDetailsParams) => ({
  type: REMOVE_KYC_SUCCESS,
  payload: response,
});
export const deleteKycFailure = (error: any) => ({
  type: REMOVE_KYC_FAILURE,
  payload: error,
});

export const rescheduleVideoKyc = (params: RescheduleKyc) => ({
  type: RESCHEDULE_VIDEO_KYC,
  payload: params,
});
export const rescheduleSuccess = (response: Object) => ({
  type: RESCHEDULE_SUCCESS,
  payload: response,
});
export const rescheduleFailure = (error: any) => ({
  type: RESCHEDULE_FAILURE,
  payload: error,
});

export const acceptRejectVideoKyc = (params: AcceptRejectVideoKyc) => ({
  type: ACCEPT_REJECT_VIDEO_KYC,
  payload: params,
});
export const acceptRejectSuccess = (response: Object) => ({
  type: ACCEPT_REJECT_SUCCESS,
  payload: response,
});
export const acceptRejectFailure = (error: any) => ({
  type: ACCEPT_REJECT_FAILURE,
  payload: error,
});

export const verifyVideoKyc = (payload: string) => ({
  type: VERIFY_VIDEO_KYC,
  payload,
});

export const scheduleVideoKyc = (params: ScheduleKyc) => ({
  type: SCHEDULE_VIDEO_KYC,
  payload: params,
});
export const scheduleSuccess = (response: Object) => ({
  type: SCHEDULE_SUCCESS,
  payload: response,
});
export const scheduleFailure = (error: any) => ({
  type: SCHEDULE_FAILURE,
  payload: error,
});

export const fetchUserListVideoKyc = (data:any) => ({
  type: USER_LIST_SCHEDULE_VIDEO_KYC,
  payload: data
}) 

export const fetchUserListVideoKycSuccess = (data:any) => ({
  type: USER_LIST_SCHEDULE_VIDEO_KYC_SUCCESS,
  payload: data
}) 

export const fetchUserListVideoKycFailure = () => ({
  type: USER_LIST_SCHEDULE_VIDEO_KYC_FAILURE,
})

export const generatePdf = (data:any) => ({
  type: GENERATE_PDF,
  payload: data
})

export const generatePdfSuccess = () => ({
  type: GENERATE_PDF_SUCCESS,
})

export const generatePdfFailure = () => ({
  type: GENERATE_PDF_FAILURE
})