import { useParams } from 'react-router';
import UserDetails from 'Views/Details/Details/UserDetails';
import UserForm from 'Views/Forms/Forms/UserForm';

export const SingleUser = (props: any) => {
    const { id, type } = useParams<any>();
    if (type === 'edit') {
        return <UserForm update />
    }
    else if (id === 'add') {
        return <UserForm />
    }
    else {
        return <UserDetails />
    }

}

export default SingleUser;