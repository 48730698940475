import { message, notification } from "antd";
import {
  all,
  call,
  fork,
  put,
  StrictEffect,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  getAccountStatsFailure,
  getAccountStatsRequest,
  getAccountStatsSuccess,
  getCompletedStatsGraphRequest,
  getPaymentStatsFailure,
  getPaymentStatsRequest,
  getPaymentStatsSuccess,
  gettCompletedStatsGraphFailure,
  gettCompletedStatsGraphSuccess,
} from "./DashboardActions";
import {
  GET_ACCOUNTS_STATS_REQUEST,
  GET_COMPLETED_STATS_GRAPH_REQUEST,
  GET_PAYMENT_STATS_REQUEST,
} from "./DashboardTypes";
import { AxiosResponse } from "axios";
import {
  getAccountStatsApi,
  getCompletedStatsGraphApi,
  getPaymentStatsApi,
} from "./DashboardApi";

function* handleGetPaymentStatsRequest({
  payload,
}: ReturnType<typeof getPaymentStatsRequest>) {
  try {
    const result: AxiosResponse = yield call(() => getPaymentStatsApi(payload));
    yield put(getPaymentStatsSuccess(result?.data));
  } catch (err: any) {
    message.error("Couldn't get payment stats at this time.");
    yield put(getPaymentStatsFailure());
  }
}

function* watchGetPaymentStatsRequest() {
  yield takeLatest(GET_PAYMENT_STATS_REQUEST, handleGetPaymentStatsRequest);
}

function* handleGetAccountStatsRequest({
  payload,
}: ReturnType<typeof getAccountStatsRequest>) {
  try {
    const result: AxiosResponse = yield call(() => getAccountStatsApi(payload));
    yield put(getAccountStatsSuccess(result?.data));
  } catch (err) {
    message.error("Couldn't get account stats at this time.");
    yield put(getAccountStatsFailure());
  }
}

function* watchGetAccountStatsRequest() {
  yield takeLatest(GET_ACCOUNTS_STATS_REQUEST, handleGetAccountStatsRequest);
}

function* handleGetCompletedStatsGraphRequest({
  payload: { accountTypeMedium, startDate, endDate },
}: ReturnType<typeof getCompletedStatsGraphRequest>) {
  try {
    const result: AxiosResponse = yield call(() =>
      getCompletedStatsGraphApi(accountTypeMedium, startDate, endDate)
    );
    yield put(gettCompletedStatsGraphSuccess(result.data));
  } catch (err) {
    message.error("Couldn't get graph stats at this time.");
    yield put(gettCompletedStatsGraphFailure());
  }
}

function* watchGetCompletedStatsGraphRequest() {
  yield takeLatest(
    GET_COMPLETED_STATS_GRAPH_REQUEST,
    handleGetCompletedStatsGraphRequest
  );
}

export default function* DashboardSaga(): Generator<StrictEffect> {
  yield all([fork(watchGetPaymentStatsRequest)]);
  yield all([fork(watchGetAccountStatsRequest)]);
  yield all([fork(watchGetCompletedStatsGraphRequest)]);
}
