import { Popover, Image, Spin, Typography } from 'antd'
import { Storage } from 'aws-amplify'
import AttachIcon from 'Icons/AttachIcon/AttachIcon'
import { useEffect, useState } from 'react'

type Props = {
  image1: string
  image2: string
}

interface getImageProps {
  uri: string
  index: number
}

const imageStyle = {
  width: '100%',
  borderRadius: 15,
}

const container = {
  display: 'flex',
  width: '850px',
}
const PopoverContent = ({ image1, image2 }: Props) => {
  const [{ loading1, loading2 }, setLoading] = useState({
    loading1: false,
    loading2: false,
  })
  const [{ imageData1, imageData2 }, setImageData] = useState({
    imageData1: '',
    imageData2: '',
  })
  const getImageAsync = async ({ uri, index }: getImageProps) => {
    await Storage.get(uri, {
      customPrefix: {
        public: '',
      },
    }).then((data) => {
      if (index) {
        setImageData((prev) => ({ ...prev, imageData2: data }))
      } else {
        setImageData((prev) => ({ ...prev, imageData1: data }))
      }
    })
    if (index) {
      setLoading((prev) => ({ ...prev, loading2: false }))
    } else {
      setLoading((prev) => ({ ...prev, loading1: false }))
    }
  }

  useEffect(() => {
    if (image1 !== '') {
      setLoading((prev) => ({ ...prev, loading1: true }))
      getImageAsync({ uri: image1, index: 0 })
    }
    if (image2 !== '') {
      setLoading((prev) => ({ ...prev, loading2: true }))
      getImageAsync({ uri: image2, index: 1 })
    }
  }, [image1, image2])
  return (
    <div style={container}>
      <Spin spinning={loading1}>
        <div style={imageStyle}>
          <Image
            src={imageData1}
            style={{ objectFit: 'cover', height: '100%' }}
            placeholder={<Spin />}
          />
        </div>
      </Spin>
      <Spin spinning={loading2}>
        {imageData2 && (
          <div style={imageStyle}>
            <Image
              src={imageData2}
              style={{ objectFit: 'cover', height: '100%' }}
              placeholder={<Spin />}
            />
          </div>
        )}
      </Spin>
    </div>
  )
}

const ImagePopover = ({ image1, image2 }: Props) => {
  return (
    <Popover
      placement='left'
      content={<PopoverContent {...{ image1 }} {...{ image2 }} />}
      trigger='click'
    >
      <div
        style={{
          display: 'flex',
          gap: 15,
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <div
          className='neumorphic'
          style={{
            width: 40,
            height: 40,
            borderRadius: 15,
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <AttachIcon />
        </div>
        <Typography.Text>View Kyc Documents</Typography.Text>
      </div>
    </Popover>
  )
}

export default ImagePopover
