import { DpAccountsFilterDataProps } from "Types/Types";

import {
  AccountsListProps,
  CREATE_CUSTOMER_ACCOUNTS,
  CREATE_CUSTOMER_ACCOUNTS_FAILURE,
  CREATE_CUSTOMER_ACCOUNTS_SUCCESS,
  CreateCustomerAccountProps,
  DELETE_CUSTOMER_ACCOUNTS,
  DELETE_CUSTOMER_ACCOUNTS_FAILURE,
  DELETE_CUSTOMER_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNT_LIST,
  FETCH_ACCOUNT_LIST_FAILURE,
  FETCH_ACCOUNT_LIST_SUCCESS,
  FETCH_ACCOUNT_TYPE_LIST,
  FETCH_ACCOUNT_TYPE_LIST_FAILURE,
  FETCH_ACCOUNT_TYPE_LIST_SUCCESS,
  UPDATE_CUSTOMER_ACCOUNTS,
  UPDATE_CUSTOMER_ACCOUNTS_FAILURE,
  UPDATE_CUSTOMER_ACCOUNTS_SUCCESS,
  UpdateCustomerAccountProps,
} from "./AccountTypes";

export const fetchAccountList = (data: DpAccountsFilterDataProps) => ({
  type: FETCH_ACCOUNT_LIST,
  payload: data,
});

export const fetchAccountListSuccess = (data: AccountsListProps) => ({
  type: FETCH_ACCOUNT_LIST_SUCCESS,
  payload: data,
});
export const fetchAccountListFailure = () => ({
  type: FETCH_ACCOUNT_LIST_FAILURE,
});

export const fetchAccountTypeList = () => ({
  type: FETCH_ACCOUNT_TYPE_LIST,
  // payload: data,
});

export const fetchAccountTypeListSuccess = (data: any) => ({
  type: FETCH_ACCOUNT_TYPE_LIST_SUCCESS,
  payload: data,
});
export const fetchAccountTypeListFailure = () => ({
  type: FETCH_ACCOUNT_TYPE_LIST_FAILURE,
});

export const createCustomerAccount = (data: CreateCustomerAccountProps) => ({
  type: CREATE_CUSTOMER_ACCOUNTS,
  payload: data,
});

export const createCustomerAccountSuccess = () => ({
  type: CREATE_CUSTOMER_ACCOUNTS_SUCCESS,
});
export const createCustomerAccountFailure = () => ({
  type: CREATE_CUSTOMER_ACCOUNTS_FAILURE,
});

export const updateCustomerAccount = (data: UpdateCustomerAccountProps) => ({
  type: UPDATE_CUSTOMER_ACCOUNTS,
  payload: data,
});

export const updateCustomerAccountSuccess = () => ({
  type: UPDATE_CUSTOMER_ACCOUNTS_SUCCESS,
});
export const updateCustomerAccountFailure = () => ({
  type: UPDATE_CUSTOMER_ACCOUNTS_FAILURE,
});

export const deleteCustomerAccount = (id: string) => ({
  type: DELETE_CUSTOMER_ACCOUNTS,
  payload: id,
});

export const deleteCustomerAccountSuccess = () => ({
  type: DELETE_CUSTOMER_ACCOUNTS_SUCCESS,
});
export const deleteCustomerAccountFailure = () => ({
  type: DELETE_CUSTOMER_ACCOUNTS_FAILURE,
});
