import { Reducer } from "react";
import {
  CLEAR_KYC_FILE_STATE,
  UPDATE_KYC_FILE_FAILURE,
  UPDATE_KYC_FILE_REQUEST,
  UPDATE_KYC_FILE_SUCCESS,
} from "./UploadKycTypes";

const INIT_STATE = {
  isSubmittingKycFile: false,
  message: "",
  uploadedResponse: {},
};

export const UploadKycReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {
    case UPDATE_KYC_FILE_REQUEST:
      return { ...state, isSubmittingKycFile: true, uploadedResponse: {} };
    case UPDATE_KYC_FILE_SUCCESS:
      return {
        ...state,
        isSubmittingKycFile: false,
        uploadedResponse: action.payload,
      };
    case UPDATE_KYC_FILE_FAILURE:
      return { ...state, isSubmittingKycFile: false };

    case CLEAR_KYC_FILE_STATE:
      return { ...INIT_STATE };

    default:
      return { ...state };
  }
};
