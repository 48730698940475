import { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd'
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon'
import './dashboard.scss'
import Auth from '@aws-amplify/auth'
import PaymentStatsTab from './PaymentStatstab'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAccountStatsRequest,
  getPaymentStatsRequest,
} from 'Redux/DashboardRedux/DashboardActions'
import {
  AccountStatsMedium,
  PaymentMedium,
} from 'Redux/DashboardRedux/DashboardApi'
import DashboardGraph from './DashboardGraph'
import { DatePicker } from 'Components/DatePicker'
import { DownloadIconDark } from 'Icons/DownloadIcon/DownloadIcon'
import { generatePaymentFile } from 'Redux/BatchFileRedux(downlodFiles)/BatchFileActions'
import dayjs, { Dayjs } from 'dayjs'
import { useHistory } from 'react-router'
const accountType = ['Demat', 'Meroshare', 'Trading']
const paymentType = ['Esewa', 'Khalti']

export const BOTTOM_CARDS_HEIGHT = 465

const Dashboard = () => {
  const dispatch = useDispatch()
  const [isAdmin, setIsAdmin] = useState(true)
  const [selectedAccountType, setSelectedAccountType] =
    useState<AccountStatsMedium>('Demat')
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<PaymentMedium>('Esewa')
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false)
  const [{ selectedStartDate, selectedEndDate }, setSelectedDates] = useState({
    selectedStartDate: dayjs().subtract(1, 'month'),
    selectedEndDate: dayjs(),
  })

  const {
    paymentStatsDetails,
    paymentStatsLoading,
    accountStatsDetails,
    accountStatsLoading,
  } = useSelector((state: any) => state?.DashboardReducer)

  const history = useHistory()
  useEffect(() => {
    Auth.currentUserInfo()
      .then((user) => {
        if (user?.attributes?.[`custom:role`] !== 'Super Admin') {
          // message.error('You are not authorized to view this page')
          setIsAdmin(false)
          // history.push('/user')
        }
      })
      .then((data) => {})
      .catch((err) => {})
  }, [])

  useEffect(() => {
    // Auth.currentUserInfo().then((user) => {
    // if (user?.attributes?.[`custom:role`] == 'Super Admin') {
    dispatch(getPaymentStatsRequest(selectedPaymentType))
    // }
    // })
  }, [selectedPaymentType])

  useEffect(() => {
    Auth.currentUserInfo().then((user) => {
      if (user?.attributes?.[`custom:role`] == 'Super Admin') {
        dispatch(getAccountStatsRequest(selectedAccountType))
      }
    })
  }, [selectedAccountType])

  const menu = (isPayment: boolean) => {
    let optionsArr = []
    if (isPayment) optionsArr = paymentType
    else optionsArr = accountType
    return (
      <Menu
        onClick={(e) => {
          if (isPayment) {
            setSelectedPaymentType(e.key as PaymentMedium)
          } else {
            setSelectedAccountType(e.key as AccountStatsMedium)
          }
        }}
      >
        {optionsArr?.map((opt) => {
          return (
            <Menu.Item style={{ textTransform: 'capitalize' }} key={opt}>
              {opt}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }
  const onDatePickerChange = (
    datesArr: any,
    dateStringArr: [string, string]
  ) => {
    setSelectedDates({
      selectedStartDate: datesArr?.[0],
      selectedEndDate: datesArr?.[1],
    })
  }

  const paymentDownloadHandler = () => {
    if (selectedStartDate === null || selectedEndDate === null) {
      message.error('Please select a date range')
    } else {
      dispatch(
        generatePaymentFile({
          startDate: selectedStartDate.format('YYYY-MM-DD'),
          endDate: selectedEndDate.format('YYYY-MM-DD'),
          medium: selectedPaymentType,
        })
      )
      setShowPaymentModal(false)
    }
  }

  const paymentModal = () => {
    return (
      <Modal
        centered
        footer={null}
        closable={false}
        visible={showPaymentModal}
        width={'40vw'}
        onCancel={() => setShowPaymentModal(false)}
        destroyOnClose
      >
        <Row justify='center'>
          <Typography.Title level={4}>Select Date Range</Typography.Title>
        </Row>
        <Space
          align='center'
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <DatePicker.RangePicker
            value={[selectedStartDate, selectedEndDate]}
            onChange={onDatePickerChange}
          />
          <Col>
            <Button
              htmlType='submit'
              type='primary'
              className='primary--alt'
              onClick={paymentDownloadHandler}
            >
              Download
            </Button>
          </Col>
        </Space>
      </Modal>
    )
  }

  const PaymentStatsContainer = useCallback(() => {
    return (
      <Card style={{ marginTop: -40 }}>
        <Spin spinning={paymentStatsLoading}>
          <Row align='middle'>
            <Typography.Title level={3} style={{ fontWeight: 800 }}>
              Total Payments
            </Typography.Title>
            <div style={{ marginLeft: 20 }} />

            <Dropdown trigger={['click']} overlay={menu.bind(this, true)}>
              <Row align='middle' justify='space-between'>
                <Typography.Text
                  style={{ textTransform: 'capitalize', marginRight: 10 }}
                >
                  {selectedPaymentType
                    ? selectedPaymentType
                    : 'Choose account Type'}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
            <Space style={{ marginInlineStart: 'auto' }}>
              <Button type='default' onClick={() => setShowPaymentModal(true)}>
                <DownloadIconDark style={{ marginRight: 0 }} />
              </Button>
              <Typography.Text type='secondary' strong>
                Download
              </Typography.Text>
            </Space>
          </Row>
          <div className='dashboard__payment__tabs__container'>
            <PaymentStatsTab
              numberValue={paymentStatsDetails?.daily ?? 0}
              title='Today'
            />
            <PaymentStatsTab
              numberValue={paymentStatsDetails?.weekly ?? 0}
              title='Weekly'
            />
            <PaymentStatsTab
              numberValue={paymentStatsDetails?.monthly ?? 0}
              title='Montly'
            />
            <PaymentStatsTab
              numberValue={paymentStatsDetails?.total ?? 0}
              title='Total'
              isTotal
            />
          </div>
        </Spin>
      </Card>
    )
  }, [paymentStatsDetails, paymentStatsLoading])

  const AccountStatsContainer = useCallback(() => {
    return (
      <Card style={{ height: BOTTOM_CARDS_HEIGHT }}>
        <Spin spinning={accountStatsLoading}>
          <Row align='middle' justify='space-between'>
            <Typography.Title style={{ fontWeight: 800, fontSize: 18 }}>
              Account Status
            </Typography.Title>
            <div style={{ marginLeft: 20 }} />
            <Dropdown trigger={['click']} overlay={menu.bind(this, false)}>
              <Row align='middle' justify='space-between'>
                <Typography.Text
                  style={{ textTransform: 'capitalize', marginRight: 10 }}
                >
                  {selectedAccountType
                    ? selectedAccountType
                    : 'Choose account Type'}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </Row>
          <PaymentStatsTab
            numberValue={accountStatsDetails?.pending ?? 0}
            title='Pending'
          />
          <PaymentStatsTab
            numberValue={accountStatsDetails?.accepted ?? 0}
            title='Accepted'
          />
          <PaymentStatsTab
            numberValue={accountStatsDetails?.rejected ?? 0}
            title='Rejected'
            isRejected
          />
          <PaymentStatsTab
            numberValue={accountStatsDetails?.completed ?? 0}
            title='Completed'
            isCompleted
          />
        </Spin>
      </Card>
    )
  }, [accountStatsDetails, accountStatsLoading])

  return (
    <>
      {paymentModal()}
      <PaymentStatsContainer />
      {isAdmin && (
        <Row
          justify='space-between'
          gutter={[20, 20]}
          style={{ marginTop: 25 }}
        >
          <Col lg={16} md={24}>
            <DashboardGraph />
          </Col>
          <Col lg={8} md={24}>
            <AccountStatsContainer />
          </Col>
        </Row>
      )}
    </>
  )
}

export default Dashboard
