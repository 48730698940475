import './style.scss';

import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ColumnsType } from 'antd/lib/table';
import AllFormStatusWithLinks
  from 'Components/AllFormStatus/AllFormStatusWithLinks';
import { AntFormItem } from 'Components/AntForm/AntForm';
import { CustomLabel } from 'Components/CustomLabel/CustomLabel';
import Status from 'Components/Status/Status';
import TableActions from 'Components/TableAction/TableAction';
import dayjs from 'dayjs';
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon';
import { AccountTargets } from 'interface';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import {
  fetchKycs,
  updateKyc,
} from 'Redux/KycRedux/KycActions';
import { FilterType } from 'Types/Types';
import { useDebounce } from 'use-debounce/lib';
import { onDownload } from 'Utils/downloadFile';

import { CloseCircleOutlined } from '@ant-design/icons';

export const UserList = (props: any) => {
  const kycUsers = useSelector((state: any) => state.KycReducer);
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [filter, setFilter] = useState<FilterType>({
    page: 1,
    limit: 10,
    branch: selectedLocation,
  });
  const [accType, setAccType] = useState<any>("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filterText, setFilterText] = useState("");
  const [debounceFilter] = useDebounce(filterText, 500);
  const dispatch = useDispatch();

  const history: any = useHistory();

  const { loading, list, updating, totalUserRecords, pageNumber } = kycUsers;

  const [downloadingPdf, setDownloadingPdf] = useState(false);



  useEffect(() => {
    setFilter({ ...filter });
  }, [debounceFilter]);

  //Fetch data with filter
  const fetchWithFilter = (data: any) => {
    dispatch(fetchKycs({ ...data }));
  };

  //edit button click handler
  const onEditHandler = (data: any) => {
    history.push(`user/${data?.id}/edit`);
  };

  const onArchieveHandler = (data: any) => {
    dispatch(
      updateKyc({ oid: data?.id, account: data?.account, archieve: true })
    );
  };

  //clear filter

  const handleClearFilter = () => {
    setFilter({
      page: filter.page,
      limit: filter.limit,
      branch: "all"
    })
    setSelectedLocation("all")
    setAccType("All")
    setSelectedStatus("All")
    form.setFieldsValue({
      account: "",
      status: "",
      date: "",
      username: ""
    });
  }



  useEffect(() => {
    if (kycUsers?.updated) {
      fetchWithFilter({ ...filter });
    }
  }, [kycUsers?.updated]);



  const userColumns: ColumnsType<any[]> = [
    {
      title: "#",
      align: "center",
      render: (row: any, text: any, index: number) => {
        if (filter.page && filter.limit) {
          return (
            <Typography.Text>
              {(filter?.page - 1) * filter.limit + index + 1}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: "USERNAME",
      dataIndex: "username",
      key: "username",
      align: "center",
      // sorter: true,
      render: (text: any, row: any, index: number) => {
        let link = ""

        if (row.form.demat_status !== "N/A") {
          link = "demat"
        } else if (row.form.meroshare_status !== "N/A") {
          link = "meroshare"
        } else if (row.form.trading_status !== "N?A") {
          link = "trading"
        }

        return (
          <Typography.Link
            onClick={() =>
              history.push({
                pathname: `/user/${row?.id}/${row.account ? row.account.toLowerCase() : link}`,
                state: {
                  filter,
                  watchlist: row?.watchlist,
                },
              })
            }
          >
            {text}
          </Typography.Link>
        )
      },
      // render: (row: any, text: any, index: number) => <Typography.Text>{dayjs(row)?.format('LLL')}</Typography.Text>
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      align: "center",

      render: (text: any, row: any, index: number) => (
        <Typography.Link href={`mailto:${text}`}>{text}</Typography.Link>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      align: "center",

      render: (text: any, row: any, index: number) => (
        <Status status={text} rejectMessage={list[index]?.reject_reason} />
      ),
    },
    {
      title: "A/C TYPE",
      dataIndex: "account",
      key: "account",
      align: "center",

      render: (text: any, row: any, index: number) => {
        const displayText = `${text}(${row?.minor === true
          ? "minor"
          : row?.minor === false
            ? "non-minor"
            : "N/A"
          })`;
        return <Status status={displayText} />;
      },
    },
    {
      title: "DATE",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",

      render: (text: any, row: any) => (
        <Typography.Text>{dayjs(text).format("MM/DD/YYYY")}</Typography.Text>
      ),
    },
    {
      title: "OTHERS",
      dataIndex: "form",
      key: "form",
      align: "center",

      render: (text: any, row: any) => {
        return (
          <AllFormStatusWithLinks
            data={text}
            handleClick={(type: string) => {
              history.push({
                pathname: `/user/${row?.id}/${type}`,
                state: {
                  filter,
                },
              });
            }}
          />
        )
      },
    },

    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "center",

      render: (text: any, row: any, index: number) => (
        <TableActions
          noReport
          showDeleteBtn={false}
          onEdit={() => onEditHandler(row)}
          download={true}
          onArchieve={() => onArchieveHandler(row)}
          downloadLoader={downloadingPdf}
          archieved={
            row?.archived_demat ||
            row?.archived_meroshare ||
            row?.archived_trading
          }
          onDownload={() => {
            setDownloadingPdf(true);
            onDownload({
              id: row?.id,
              target: filter?.account as AccountTargets,
              loaderCB: () => setDownloadingPdf(false),
            });
          }}
          onViewDetails={() =>
            history.push({
              pathname: `/user/${row?.id}/${row?.account?.toLowerCase()}`,
              state: {
                filter,
              },
            })
          }
        />
      ),
    },
  ];

  const [form] = useForm();
  const accountType = ["All", "demat", "meroshare", "trading",];

  const menu = (
    <Menu
      onClick={(e) => {
        setAccType(e.key as AccountTargets);
        form.setFieldsValue({ account: e.key });
      }}
    >
      {accountType?.map((acc) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={acc}>
            {acc}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const menuStatus = (
    <Menu
      onClick={(e) => {
        setSelectedStatus(e.key);
        form.setFieldsValue({ status: e.key });
      }}
    >
      {["All", "Pending", "Accepted", "Rejected", "Completed"]?.map((acc) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={acc}>
            {acc}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  useEffect(() => {
    if (history.location.state?.filter) {
      setFilter({ ...filter, ...history.location.state?.filter });
    }
    if (history.location.state?.filter?.account) {
      setAccType(history.location.state?.filter?.account);
      form.setFieldsValue({
        account: history.location.state?.filter?.account,
      });
    }
    if (history.location.state?.filter?.status) {
      setSelectedStatus(history.location.state?.filter?.status);
      form.setFieldsValue({
        status: history.location.state?.filter?.status,
      });
    }

    history.location.state?.filter?.username &&
      form.setFieldsValue({
        username: history.location.state?.filter?.username,
      });
  }, [history]);

  useEffect(() => {
    if (filter) {
      fetchWithFilter({ ...filter });
    }
  }, [filter]);

  const onFilterSubmit = (value: any) => {
    const finalFilter = { ...filter };
    if (value?.date) {
      finalFilter.start = dayjs(value.date[0]).format("YYYY-MM-DD");
      finalFilter.end = dayjs(value.date[1]).format("YYYY-MM-DD");
    } else {
      delete finalFilter.start;
      delete finalFilter.end;
    }
    if (!value?.account || value?.account === "All") {
      delete finalFilter.account;
    } else {
      finalFilter.account = value?.account;
    }
    if (value?.username) {
      finalFilter.username = value?.username;
    } else {
      delete finalFilter.username;
    }
    if (!value?.status || value?.status === "All") {
      delete finalFilter.status
    } else {

      finalFilter.status = value?.status;
    }

    setFilter({ ...finalFilter, page: 1, limit: finalFilter.limit, branch: selectedLocation });
  };

  const location = [
    {
      name: "All",
      value: "all",
    },
    {
      name: "Kathmandu",
      value: "kathmandu",
    },
    {
      name: "Dharan",
      value: "dharan",
    },
  ];


  return (
    <Card>
      <Row gutter={[16, 16]} align={"middle"} justify={"space-between"}>
        <Col>
          <Typography.Title level={3} style={{ fontWeight: 800 }}>
            User Listing
          </Typography.Title>
        </Col>
        <Col>

          <Button icon={<CloseCircleOutlined />} danger onClick={handleClearFilter} >Clear Filter</Button>
        </Col>
      </Row>
      <Form
        onFinish={onFilterSubmit}
        initialValues={{ username: "" }}
        form={form}
        className='user-list-filter'
      >
        <Row gutter={[20, 20]}>
          <Col lg={4}>
            <CustomLabel label='Search' />
            <AntFormItem name="username">
              <Input.Search />
            </AntFormItem>
          </Col>

          <Col lg={4}>
            <Form.Item hidden name="account" label="Account">
              <Input />
            </Form.Item>

            <CustomLabel label='Account' />
            <Dropdown trigger={["click"]} overlay={menu}>
              <Row align="middle" justify="space-between">
                <Typography.Text
                  style={{ textTransform: "capitalize" }}
                  strong={!!accType}
                >
                  {accType ? accType : "Choose Account Type"}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </Col>
          <Col lg={4}>
            <CustomLabel label='Status' />
            <Form.Item hidden name="status">
              <Input />
            </Form.Item>

            <Dropdown trigger={["click"]} overlay={menuStatus}>
              <Row align="middle" justify="space-between">
                <Typography.Text
                  style={{ textTransform: "capitalize" }}
                  strong={!!selectedStatus}
                >
                  {selectedStatus ? selectedStatus : "Choose Status"}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </Col>

          <Col lg={4} className="select-btn">
            <CustomLabel label='Branch' />
            <Select
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e)}
              style={{
                width: "100%",
              }}
            >
              {location.map((item) => (
                <Select.Option key={item?.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col lg={4}>
            <CustomLabel label='Date' />
            <AntFormItem name="date">
              <DatePicker.RangePicker style={{ width: "100%" }} />
            </AntFormItem>
          </Col>
          <Col lg={4}>
            <Button htmlType="submit" type="primary" className="primary--alt search-btn">
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col lg={24}>
          <Table
            rowKey="id"
            // rowSelection={rowSelection}
            columns={userColumns}
            dataSource={list}
            loading={loading || updating}
            pagination={{
              current: parseInt(pageNumber),
              total: parseInt(totalUserRecords),
              onChange: (page: number) => {
                setFilter((prev) => ({
                  ...prev,
                  page: page
                }))
              },
              showSizeChanger: true,
              onShowSizeChange: (current, size) => {
                setFilter((prev) => ({
                  ...prev,
                  limit: size
                }))

              },

            }}
            onRow={(record, rowIndex) => {
              return {};
            }}


          />
        </Col>
      </Row>
    </Card>
  );
};

export default UserList;


