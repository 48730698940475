import './ListHeader.scss'

import { PlusCircleFilled } from '@ant-design/icons'
import { Col, Collapse, Divider, Input, Row, Space, Typography } from 'antd'
// import { ReactComponent as ExternalIcon } from 'Assets/icons/external.svg';
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

const { Panel } = Collapse

interface ListHeaderProps {
  searchable?: boolean
  navigation?: boolean
  onSearch?: (e: any) => void
  module?: string
  title?: string
  noAddFeature?: boolean
  extra?: any
  inlineExtra?: boolean
  prefix?: string
  centerFilter?: React.ReactNode
}
export const ListHeader = (props: ListHeaderProps) => {
  const {
    searchable,
    navigation,
    extra,
    module,
    prefix,
    centerFilter,
    onSearch,
    title,
    noAddFeature,
    inlineExtra,
  } = props
  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <Row
      justify='space-between'
      gutter={[20, 0]}
      align='middle'
      style={{ marginBottom: 0 }}
    >
      <Col>
        <Space align='center'>
          <Typography.Title style={{ marginBottom: 0 }} level={5}>
            {title}
          </Typography.Title>
          {!noAddFeature && (
            <PlusCircleFilled
              // onClick={() => dispatch(setLayout({ form: module, update: false, data: false }))}
              onClick={() => {
                history.push(`${prefix || ''}/${module}/add`)
              }}
            />
          )}
        </Space>
      </Col>
      <Col>{centerFilter}</Col>
      <Col>
        {/* <Avatar style={{ background: 'transparent', border: '2px solid #DEDEDE', display: 'flex', alignItems: 'center' }}>
                    <SearchOutlined style={{ color: 'black', verticalAlign: 'middle' }} />
                </Avatar> */}
        <Space>
          {navigation && (
            <Space align='center'>
              <Typography.Link onClick={() => history.push(`/${module}`)}>
                All
              </Typography.Link>
              {/* <ExternalIcon onClick={() => history.push('/meetings')} style={{ verticalAlign: 'middle', marginBottom: 3 }} /> */}
            </Space>
          )}
          {searchable && (
            <Input.Search
              // onChange={(e: any) => onSearch(`%${e?.currentTarget?.value}%`)}
              size='middle'
              placeholder='Search . . .'
              // className="rounded__search"
              style={{ marginBottom: 0 }}
            />
          )}
        </Space>
      </Col>
      <Divider style={{ margin: 14 }} />
      {/* <Col lg={extra && inlineExtra ? 8 : 24} >
                {searchable &&
                    <Input.Search
                        onChange={(e: any) => onSearch(`%${e?.currentTarget?.value}%`)}
                        size="large"
                        placeholder="Search . . ."
                        // className="rounded__search"
                        style={{ marginBottom: 20 }}
                    />}

            </Col> */}
      <Col lg={inlineExtra ? 16 : 24} hidden={!extra}>
        {inlineExtra ? (
          <>{extra}</>
        ) : (
          <Collapse bordered={false}>
            <Panel
              header={<Typography.Link strong>Advance Filter</Typography.Link>}
              key='1'
            >
              {extra}
            </Panel>
          </Collapse>
        )}
      </Col>
    </Row>
  )
}

export default ListHeader
