import React from 'react';

import {
  Route,
  Switch,
} from 'react-router';
import { Accounts } from 'Views/Dp/ChartAccount/Accounts';
import { Customer } from 'Views/Dp/Customer/Customer';
import {
  CustomerSingle,
} from 'Views/Dp/Customer/CustomerSingle/CustomerSingle';
import { Dashboard } from 'Views/Dp/Dashboard/Dashboard';
import { Reporting } from 'Views/Dp/Reporting/Reporting';
import { Transaction } from 'Views/Dp/Transaction/Transaction';

export const DpRoutes = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match?.path}`} exact component={Dashboard} />
      <Route
        path={`${props.match?.path}/customer`}
        exact
        component={Customer}
      />
      <Route
        path={`${props.match?.path}/customer/:id`}
        exact
        component={CustomerSingle}
      />
      <Route
        path={`${props.match?.path}/accounts`}
        exact
        component={Accounts}
      />
      <Route
        path={`${props.match?.path}/reporting`}
        exact
        component={Reporting}
      />
      <Route
        path={`${props.match?.path}/transaction`}
        exact
        component={Transaction}
      />
    </Switch>
  );
};
