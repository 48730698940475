import AuthLayout from 'Layouts/AuthLayout'
import React from 'react'
import { Route, RouteChildrenProps, Switch } from 'react-router'
import Login from 'Views/Auth/Login'
import OtpVerification from 'Views/Auth/OtpVerification'
import ResetPassword from 'Views/Auth/ResetPassword'
import ChangePassword from 'Views/Auth/ChangePassword'
import ForgotPasswordSubmit from 'Views/Auth/ForgotPasswordSubmit'
export const AuthRoute = (props: RouteChildrenProps) => {
  return (
    <AuthLayout>
      <Switch>
        <Route path={`${props.match?.path}`} exact component={Login} />
        <Route
          path={`${props.match?.path}/reset-password`}
          component={ResetPassword}
        />
        <Route
          path={`${props.match?.path}/change-password`}
          component={ChangePassword}
        />

        <Route
          path={`${props.match?.path}/forgot-password-submit`}
          component={ForgotPasswordSubmit}
        />
        <Route
          path={`${props.match?.path}/otp-verification`}
          component={OtpVerification}
        />
        <Route component={Login} />
      </Switch>
    </AuthLayout>
  )
}

export default AuthRoute
