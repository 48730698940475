import Typography from "antd/lib/typography";
import { commaSeperator } from "Utils/commaSeperator";

interface Props {
  numberValue: number;
  title: string;
  isTotal?: boolean;
  isRejected?: boolean;
  isCompleted?: boolean;
}

const PaymentStatsTab = ({
  numberValue,
  title,
  isTotal,
  isCompleted,
  isRejected,
}: Props) => {
  return (
    <div
      className={`dashboard__payment__stats__tabs neumorphic ${
        isTotal && "stats__green__background"
      }`}
      style={{
        marginTop: 20,
      }}
    >
      <Typography.Text
        strong
        style={{ fontSize: 12 }}
        type={
          isTotal || isCompleted
            ? "success"
            : isRejected
            ? "danger"
            : "secondary"
        }
      >
        {title}
      </Typography.Text>
      <div />
      <Typography.Text
        className={`stats__number ${isTotal && "stats_number_green"}`}
      >
        {commaSeperator(numberValue) ?? "-"}
      </Typography.Text>
    </div>
  );
};
export default PaymentStatsTab;
