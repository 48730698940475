import React, {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import {
  FormInstance,
  Table,
  Typography,
} from 'antd';
import TableActions from 'Components/TableAction/TableAction';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { FilterType } from 'Types/Types';

import { AddEntryModalProps } from '../Customer';

interface TableComponentsProps {
  setFilterData: Dispatch<SetStateAction<FilterType>>;
  filterData: FilterType;
  showAddEntryModal: AddEntryModalProps;
  setShowAddEntryModal: Dispatch<SetStateAction<AddEntryModalProps>>;
  entryForm: FormInstance;
}

export const TableComponents = ({
  filterData,
  setFilterData,
  showAddEntryModal,
  setShowAddEntryModal,
  entryForm,
}: TableComponentsProps) => {
  const dpCustomerReducer = useSelector(
    (state: any) => state.dpCustomerReducer
  );

  const history = useHistory();

  const { list, loading, creating, totalRecords } = dpCustomerReducer;

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const onSelectChange = (selKeys: any) => {
    setSelectedRowKeys(selKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const customerColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "USERNAME",
      dataIndex: "account_holder_name",
      key: "account_holder_name",
      sorter: true,
      render: (text: any, row: any, index: number) => (
        <Link to={`/dp/customer/${row?.id}`}>
          <Typography.Text>{text}</Typography.Text>
        </Link>
      ),
    },
    {
      title: "BOID",
      dataIndex: "boid_number",
      key: "boid_number",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text ?? "N/A"}</Typography.Text>
      ),
    },
    {
      title: "Fathers Name",
      dataIndex: "fathers_name",
      key: "fathers_name",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text ?? "N/A"}</Typography.Text>
      ),
    },
    {
      title: "Amount Due",
      dataIndex: "amount_due",
      key: "amount_due",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text ?? "N/A"}</Typography.Text>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any, index: number) => {
        return (
          <TableActions
            noReport
            showDeleteBtn={false}
            download={false}
            archieved={true}
            onViewDetails={() => history.push(`/dp/customer/${row?.id}`)}
            adminEdit={true}
            onEdit={() => {
              entryForm.setFieldsValue({
                account_holder_name: row.account_holder_name,
                boid_number: row.boid_number,
              });
              setShowAddEntryModal({
                userId: row.id,
                visible: true,
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="customer-table">
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        columns={customerColumns}
        dataSource={list}
        loading={loading || creating}
        pagination={{
          current: Number(filterData.page),
          total: parseInt(totalRecords),
          onChange: (page: number) => {
            setFilterData((prevState: FilterType) => ({
              ...prevState,
              page: page,
            }));
          },
          showSizeChanger: false,
        }}
        onRow={(record, rowIndex) => {
          return {};
        }}
        onChange={(pagination, filter, sorter: any) => {
          setFilterData((prevState: FilterType) => ({
            ...prevState,
            order:
              sorter.order === "descend"
                ? `-${sorter.field}`
                : `${sorter.field}`,
          }));
        }}
      />
    </div>
  );
};
