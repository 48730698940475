import {
  FetchCustomerTransactionListProps,
} from 'Redux/DpRedux/Customer/DpTypes';
import {
  FetchVoucherListProps,
} from 'Redux/DpRedux/Transaction/TransactionTypes';
import { DpCustomerFilterDataProps } from 'Types/Types';

export const dpFilterParams = (filterData: any) => {
  const offset = (filterData.page - 1) * filterData.limit;
  let params: DpCustomerFilterDataProps & FetchCustomerTransactionListProps & FetchVoucherListProps = {};
  if (filterData.search) {
    if(/^\d+$/.test(filterData.search)) {
      params.boid_number = filterData.search
    } else {

      params.search_by_name = filterData.search;
    }
  }
  if(filterData.limit) {
    params.limit = filterData.limit
  }
  if (offset) {
    params.offset = offset;
  }
  if (filterData.date_filter_min && filterData.date_filter_max) {
    params.date_filter_min = filterData.date_filter_min;
    params.date_filter_max = filterData.date_filter_max;
  }
  if (filterData.account_status) {
    params.account_status = filterData.account_status.toUpperCase();
  }
  if (filterData.order) {
    params.ordering = filterData.order;
  }

  if (filterData.payment_mode) {
    params.payment_mode = filterData.payment_mode;
  }
  if (filterData.username) {
    params.username = filterData.username;
  }

  if (
    filterData.transaction_date_after &&
    filterData.transaction_date_before
  ) {
    params.transaction_date_after = filterData.transaction_date_after;
    params.transaction_date_before = filterData.transaction_date_before;
  }
  if (filterData.dateRange) {
    params.date_range = filterData.dateRange;
  }

  return params;
};
