export const dummyRequest = ({
    file,
    onSuccess,
  }: {
    file: any;
    onSuccess?: any;
  }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };