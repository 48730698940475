import { Dispatch } from 'react';

import { updateKyc } from 'Redux/KycRedux/KycActions';
import { getFormSubmitDataReady } from 'Utils/getFormDataReady';

interface Props {
  values: any
  oid: string
  dispatch: Dispatch<any>
}
export default function UserDetailsSubmitHandler({
  values,
  oid,
  dispatch,
}: Props) {
  if (
    values[Object.keys(values)[0]]?.hasOwnProperty('reference_number') ||
    values[Object.keys(values)[0]]?.hasOwnProperty('username')
  ) {
    //handling reference number case and username case
    //bring outside wrapper key to main object and then dispatch
    const formattedData = getFormSubmitDataReady(values)
    let finalSubmitData: any = {
      [Object.keys(formattedData)[0]]: {
        ...formattedData[Object.keys(formattedData)[0]],
        username: undefined,
        reference_number: undefined,
      },
    }
    if (values[Object.keys(values)[0]]?.hasOwnProperty('reference_number')) {
      const reference_number = values[Object.keys(values)[0]]?.reference_number
      finalSubmitData = { ...finalSubmitData, reference_number }
    }
    if (values[Object.keys(values)[0]]?.hasOwnProperty('username')) {
      const username = values[Object.keys(values)[0]]?.username
      finalSubmitData = { ...finalSubmitData, username }
    }
    if(values?.pan_number) {
      finalSubmitData = { ...finalSubmitData, pan_number: Number(values.pan_number) }

    }
    dispatch(updateKyc({ ...finalSubmitData, oid }))
  } else if (values?.family_details?.Spouse) {
    let keyArr = Object.entries(values.family_details).map((item) => {
      return {
        name: item[1],
        relation: item[0],
      }
    })
    let newobj = {
      family_details: {
        in_laws: keyArr,
      },
    }
    dispatch(updateKyc({ ...newobj, oid }))
  }
  // checking if nominee is there
  else if (values?.nominee_details && 'nominee' in values?.nominee_details) {
    let nomineeObj = {
      nominee: values.nominee_details.nominee,
    }
    dispatch(updateKyc({ ...nomineeObj, oid }))
  } else if (values?.rent_details && 'full_name' in values?.rent_details) {
    let rentObj = {
      trading: {
        rent_details: {
          full_name: values.rent_details.full_name,
          mobile: `+977${values.rent_details.mobile
            .replace('+977', '')
            .replace('-', '')}`,
        },
      },
    }
    dispatch(updateKyc({ ...rentObj, oid }))
  } else if (values?.rent_details && 'rent' in values?.rent_details) {
    let rentObj = {
      trading: {
        rent: values?.rent_details?.rent,
      },
    }
    dispatch(updateKyc({ ...rentObj, oid }))
  } else if (values?.politics_person) {
    let polPersonObj = {
      trading: {
        engaged_details: {
          full_name: values?.politics_person?.full_name,
          relation: values?.politics_person?.relation,
        },
      },
    }
    dispatch(updateKyc({ ...polPersonObj, oid }))
  } else if (values?.benificiary_details) {
    let benePersonObj = {
      trading: {
        benificiary_details: {
          full_name: values?.benificiary_details?.full_name,
          relation: values?.benificiary_details?.relation,
        },
      },
    }
    dispatch(updateKyc({ ...benePersonObj, oid }))
  } else if (values?.politics_related) {
    let politicsObj = {
      trading: {
        poli_high_pos: values?.politics_related?.poli_high_pos,
        eng_poli_high_pos: values?.politics_related?.eng_poli_high_pos,
        benificiary: values?.politics_related?.benificiary,
        guilt_case: values?.politics_related?.guilt_case,
        samp_sudhi_2064: values?.politics_related?.samp_sudhi_2064,
      },
    }
    dispatch(updateKyc({ ...politicsObj, oid }))
  } else {
    dispatch(updateKyc({ ...getFormSubmitDataReady(values), oid }))
  }
}
