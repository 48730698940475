import { memo, useEffect, useMemo, useState } from 'react'
import { Area, AreaConfig } from '@ant-design/charts'
import { Card, Dropdown, Menu, message, Row, Spin, Typography } from 'antd'
import { AntFormItem } from 'Components/AntForm/AntForm'
import { DatePicker } from 'Components/DatePicker'
import dayjs from 'dayjs'
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon'
import { useDispatch, useSelector } from 'react-redux'
import { getCompletedStatsGraphRequest } from 'Redux/DashboardRedux/DashboardActions'
import { AccountStatsMedium } from 'Redux/DashboardRedux/DashboardApi'
import { BOTTOM_CARDS_HEIGHT } from './Dashboard'
import { placeHolderData } from './DummyGraphData'
import { Auth } from 'aws-amplify'

const accountType = ['Demat', 'Meroshare', 'Trading']

const DashboardGraph = () => {
  const dispatch = useDispatch()
  const { completedGraphStatsLoading, completedGraphStatsDetails } =
    useSelector((state: any) => state?.DashboardReducer)
  const [selectedGraphAccountType, setSelectedGraphAccountType] =
    useState<AccountStatsMedium>('Demat')

  const [{ selectedStartDate, selectedEndDate }, setSelectedDates] = useState({
    selectedStartDate: dayjs().subtract(1, 'year'),
    selectedEndDate: dayjs(),
  })

  const DashboardData: AreaConfig = useMemo(() => {
    return {
      data:
        completedGraphStatsDetails.length > 0
          ? completedGraphStatsDetails
          : placeHolderData,
      autoFit: true,
      height: 300,
      xField: 'date',
      yField: 'count',
      xAxis: {
        range: [0, 1],
        tickCount: 5,
      },
      line: {
        color: '#1CC67B',
      },
      smooth: true,

      areaStyle: () => {
        return {
          fill: 'l(270) 0:#ffffff 0.5:#1CC67B 1:#1890ff',
        }
      },
    }
  }, [completedGraphStatsDetails])

  useEffect(() => {
    Auth.currentUserInfo().then((user) => {
      if (user?.attributes?.[`custom:role`] == 'Super Admin') {
        if (selectedStartDate && selectedEndDate) {
          dispatch(
            getCompletedStatsGraphRequest({
              accountTypeMedium: selectedGraphAccountType,
              startDate: selectedStartDate.format('YYYY-MM-DD'),
              endDate: selectedEndDate.format('YYYY-MM-DD'),
            })
          )
        } else {
          message.error('Please select a date range')
        }
      }
    })
  }, [selectedStartDate, selectedEndDate, selectedGraphAccountType])

  const onDatePickerChange = (
    datesArr: any,
    dateStringArr: [string, string]
  ) => {
    setSelectedDates({
      selectedStartDate: datesArr?.[0],
      selectedEndDate: datesArr?.[1],
    })
  }

  const menu = () => {
    return (
      <Menu
        onClick={(e) => {
          setSelectedGraphAccountType(e.key as AccountStatsMedium)
        }}
      >
        {accountType?.map((opt) => {
          return (
            <Menu.Item style={{ textTransform: 'capitalize' }} key={opt}>
              {opt}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  return (
    <Card
      style={{
        height: BOTTOM_CARDS_HEIGHT,
      }}
    >
      <Spin spinning={completedGraphStatsLoading}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            justifyContent: 'space-between',
          }}
        >
          <Typography.Title style={{ fontWeight: 800, fontSize: 18 }}>
            Total Customers Created
          </Typography.Title>

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: 20,
            }}
          >
            <AntFormItem name='date'>
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                defaultValue={[selectedStartDate, selectedEndDate]}
                onChange={onDatePickerChange}
              />
            </AntFormItem>
            <Dropdown trigger={['click']} overlay={menu}>
              <Row
                align='middle'
                justify='space-between'
                style={{ width: 130 }}
              >
                <Typography.Text
                  style={{ textTransform: 'capitalize', marginRight: 10 }}
                >
                  {selectedGraphAccountType
                    ? selectedGraphAccountType
                    : 'Choose account Type'}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </div>
        </div>
        <div style={{ marginTop: 25 }}>
          <Area {...DashboardData} />
        </div>
      </Spin>
    </Card>
  )
}

export default memo(DashboardGraph)
