import { FilePdfOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Space, Spin, Upload } from "antd";
import { StoragePut } from "Components/StoragePut/StoragePut";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateKyc } from "Redux/KycRedux/KycActions";
import { downlaodPdf } from "Utils/downloadPdf";
import { dummyRequest } from "Utils/DummyCall";

type Props = {
  pdfUrl: string;
};

const BoidPdf = ({ pdfUrl }: Props) => {
  const dispatch = useDispatch();
  const details = useSelector((state: any) => state?.KycReducer?.details);
  const [uploading, setUploading] = useState(false);
  const beforeUpload = async (file: any) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload pdf file");
    } else {
      //submit here
      let extension = file?.type.split("/")[1];
      setUploading(true);
      StoragePut({
        directoryPath: `boid/user/${details?.userId}/other/${
          details?.id
        }/${dayjs().format("YYMMDD-HHmmss")}.${extension}`,
        fileObj: file,
        failureCB: () => {
          message.error("Error uploading PDF");
          setUploading(false);
        },
        successCB: (key) => {
          const payload = {
            trading: {
              boid_pdf: key,
            },
          };
          dispatch(updateKyc({ oid: details?.id, ...payload }));
          setUploading(false);
        },
      });
    }
  };

  return (
    <Space>
      {pdfUrl && pdfUrl !== "noPdf" && (
        <Button
          type="primary"
          icon={<FilePdfOutlined style={{ fontSize: 24 }} />}
          onClick={() => {
            if (pdfUrl && pdfUrl !== "noPdf") {
              downlaodPdf(pdfUrl);
            }
          }}
        >
          View PDF
        </Button>
      )}

      <Spin spinning={uploading}>
        <Upload
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          accept=".pdf"
          showUploadList={false}
        >
          <Button
            type="default"
            icon={<UploadOutlined style={{ fontSize: 24 }} />}
          >
            {pdfUrl && pdfUrl === "noPdf" ? "Upload PDF" : "Change PDF"}
          </Button>
        </Upload>
      </Spin>
    </Space>
  );
};

export default BoidPdf;
