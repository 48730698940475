import {
  RequestLoginParams,
  REQUEST_LOGIN,
  REQUEST_LOGIN_SUCCESS,
  REQUEST_LOGIN_FAILURE,
} from './AuthTypes'

//login request
export const requestLogin = (params: RequestLoginParams) => ({
  type: REQUEST_LOGIN,
  payload: params,
})

export const requestLoginSuccess = (response: any) => ({
  type: REQUEST_LOGIN_SUCCESS,
  payload: response,
})

export const requestLoginFailure = (error: any) => ({
  type: REQUEST_LOGIN_FAILURE,
  payload: error,
})
