import {
  Button,
  Card,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd'
import { RcFile } from 'antd/lib/upload/interface'
import { ReactComponent as CSVIcon } from 'Assets/svg/csvlogo.svg'
import { ReactComponent as CrossIcon } from 'Assets/svg/cross.svg'
import { useCallback, useEffect, useState } from 'react'
import './UploadKycs.scss'
import { dummyRequest } from 'Utils/DummyCall'
import { StoragePut } from 'Components/StoragePut/StoragePut'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearKycFileState,
  requestKycFileUpdate,
} from 'Redux/UploadKycsFileRedux/UploadKycActions'
import Loading from 'Components/Loading/Loading'

interface resultTab {
  resultType: 'total' | 'success' | 'warning'
  numberValue: number | string
  indexNumber: 0 | 1 | 2
  title: string
}
interface resultText {
  text1: string
  text2: string
  textColor: string
}

interface UploadFileArr {
  label: string
  value: accFormType
  fileAcceptType: string
}

type accFormType = '' | 'demat' | 'meroshare' | 'trading'

const UploadFileArray: UploadFileArr[] = [
  { label: 'DEMAT', value: 'demat', fileAcceptType: '.txt' },
  { label: 'MEROSHARE', value: 'meroshare', fileAcceptType: '.csv' },
  {
    label: 'TRADING',
    value: 'trading',
    fileAcceptType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  },
]

const ResultText = ({ text1 = '', text2 = '', textColor }: resultText) => (
  <div>
    <Typography.Text
      style={{
        color: textColor,
      }}
    >
      {text1}
    </Typography.Text>{' '}
    <div />
    <Typography.Text
      style={{
        color: textColor,
      }}
    >
      {text2}
    </Typography.Text>
    <br />
    <br />
  </div>
)

const UploadKycs = () => {
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState<RcFile | null>(null)
  const [selectedAccType, setSelectedAccType] = useState<accFormType>('')
  const [currentIndex, setCurrentIndex] = useState<0 | 1 | 2>(0)
  const [showAccSelectModal, setShowAccSelectModal] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploadSuccessfull, setIsUploadSuccessFull] = useState(false)

  const { isSubmittingKycFile, uploadedResponse } = useSelector(
    (state: any) => state.UploadKycReducer
  )

  const failureCB = () => setIsUploading(false)
  const successCB = (data: string) => {
    setIsUploading(false)
    setIsUploadSuccessFull(true)
    dispatch(requestKycFileUpdate(data, selectedAccType))
  }

  useEffect(() => {
    return () => {
      dispatch(clearKycFileState())
    }
  }, [])

  const getFilteredResponse = useCallback(
    (incomingResponse: any) => {
      console.log(incomingResponse, 'res incoming')
      if (incomingResponse && Object.keys(incomingResponse).length > 0) {
        const createdResponse = incomingResponse?.accounts?.filter(
          (item: any) => item?.status?.toLowerCase() === 'created'
        )
        const failedResponse = incomingResponse?.accounts?.filter(
          (item: any) => item?.status?.toLowerCase() === 'failed'
        )
        const totalResponse = incomingResponse?.accounts?.filter(
          (item: any) => item?.status?.toLowerCase() !== 'jpaetei'
        )

        switch (currentIndex) {
          case 0:
            return totalResponse.map((totalItem: any) => (
              <ResultText
                text1={`${totalItem?.boid}, ${totalItem?.username ?? ''}, ${
                  totalItem?.phone ?? ''
                }, ${totalItem?.email ?? ''}.........${totalItem?.status}`}
                text2={''}
                textColor='#4C5064'
              />
            ))

          case 1:
            return createdResponse.map((createdItem: any) => (
              <ResultText
                text1={`${createdItem?.boid} ${createdItem?.username ?? ''} ${
                  createdItem?.phone ?? ''
                } ${createdItem?.email ?? ''}`}
                text2={createdItem?.message}
                textColor='#4F9B6D'
              />
            ))
          case 2:
            return failedResponse.map((totalItem: any) => (
              <ResultText
                text1={totalItem.boid}
                text2={totalItem.message}
                textColor='#C96565'
              />
            ))
        }
      }
    },
    [uploadedResponse, currentIndex]
  )

  const onFileChangeHandler = (
    fileInfo: RcFile,
    accInfo: accFormType
  ): void => {
    setSelectedFile(fileInfo)
    setSelectedAccType(accInfo)
    setShowAccSelectModal(false)
  }

  const submitHandler = () => {
    if (!selectedFile) {
      message.error('No file selected')
      return
    }
    let directoryPath = ''
    const fileName = dayjs().format('YYMMDD-HHmmss')
    switch (selectedAccType) {
      case 'demat':
        directoryPath = `cdsc/response/${fileName}`
        break
      case 'meroshare':
        directoryPath = `meroshare/response/${fileName}`
        break
      case 'trading':
        directoryPath = `tms/response/${fileName}`
        break
      default:
        break
    }
    setIsUploading(true)
    StoragePut({
      directoryPath,
      fileObj: selectedFile,
      failureCB,
      successCB,
    })
  }

  const ResultTabs = ({
    resultType,
    numberValue,
    indexNumber,
    title,
  }: resultTab) => {
    return (
      <div
        className={`csv__result__tabs ${
          indexNumber === currentIndex ? 'neumorphic-active' : 'neumorphic'
        }`}
        onClick={() => setCurrentIndex(indexNumber)}
      >
        <Typography.Text
          type={resultType === 'total' ? 'success' : resultType}
          strong
          style={{ fontSize: 12 }}
          disabled={resultType === 'total'}
        >
          {title}
        </Typography.Text>
        <div />
        <Typography.Text style={{ fontSize: 18, fontWeight: 800 }}>
          {numberValue}
        </Typography.Text>
      </div>
    )
  }

  const filePickModal = () => {
    return (
      <Modal
        centered
        footer={null}
        closable={false}
        visible={showAccSelectModal}
        width={'40vw'}
        onCancel={() => setShowAccSelectModal(false)}
        destroyOnClose
      >
        <Row justify='center'>
          <Space>
            <Typography.Title level={4}>
              Select Type of account
            </Typography.Title>
          </Space>
          <Space
            style={{
              width: '100%',
              justifyContent: 'space-around',
            }}
          >
            {UploadFileArray.map((item) => (
              <Upload
                multiple={false}
                beforeUpload={(file) => onFileChangeHandler(file, item.value)}
                showUploadList={false}
                customRequest={dummyRequest}
                accept={item.fileAcceptType}
              >
                <Button className='neumorphic'>
                  <Space>
                    <CSVIcon /> {item.label}
                  </Space>
                </Button>
              </Upload>
            ))}
          </Space>
        </Row>
      </Modal>
    )
  }

  return (
    <>
      <Card>
        {filePickModal()}
        <Typography.Title level={3} style={{ fontWeight: 800 }}>
          Upload Your Document
        </Typography.Title>

        {!selectedFile && (
          <Space
            style={{
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Space
              direction='vertical'
              className='neumorphic-active upload__container'
            >
              <Button
                className='neumorphic'
                onClick={() => setShowAccSelectModal(true)}
              >
                <Space>
                  <CSVIcon /> Upload File
                </Space>
              </Button>
            </Space>
          </Space>
        )}
        {selectedFile && (
          <div className='uploaded__csv_container'>
            <Space>
              <Typography.Text>Selected File:</Typography.Text>
              <Button className='neumorphic'>
                <Space>
                  <CSVIcon /> {selectedFile?.name ?? ''}{' '}
                  <CrossIcon
                    onClick={() => {
                      setSelectedFile(null)
                      setSelectedAccType('')
                      setIsUploadSuccessFull(false)
                      dispatch(clearKycFileState())
                    }}
                  />
                </Space>
              </Button>
              {!isUploadSuccessfull && (
                <Button
                  type='primary'
                  className='primary--alt upload__csv__btn'
                  onClick={submitHandler}
                  loading={isUploading || isSubmittingKycFile}
                >
                  Upload File
                </Button>
              )}
            </Space>
          </div>
        )}
        {isSubmittingKycFile && <Loading />}
        {uploadedResponse && Object.keys(uploadedResponse).length > 0 && (
          <div className='csv__result__container neumorphic-active'>
            <div className='csv__result__container__top'>
              {getFilteredResponse(uploadedResponse)}
            </div>
            <div className='csv__result__container__bottom'>
              <ResultTabs
                title='Total Accounts'
                resultType='total'
                numberValue={uploadedResponse?.total ?? 0}
                indexNumber={0}
              />
              <ResultTabs
                title='Created Accounts'
                resultType='success'
                numberValue={uploadedResponse?.created ?? 0}
                indexNumber={1}
              />
              <ResultTabs
                title='Failed Accounts'
                resultType='warning'
                numberValue={uploadedResponse?.failed ?? 0}
                indexNumber={2}
              />
            </div>
          </div>
        )}
      </Card>
    </>
  )
}

export default UploadKycs
