import { Button, Col, Form, Input, notification, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { Auth as AmplifyAuth } from '@aws-amplify/auth'
import { useHistory } from 'react-router'

export const ResetPassword = () => {
  const history = useHistory()

  const onFinish = async (values: any) => {
    console.log('Success:', values)
    await AmplifyAuth.forgotPassword(values.username)
    notification.success({
      message: 'Password Reset Succeess',
      description: 'Please check your email',
    })
    history.push('/auth/forgot-password-submit')
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
    notification.error({
      message: 'Password Reset Failed',
      description: 'Could not reset  password',
    })
  }
  return (
    <Row justify='center' align='middle' style={{ minHeight: '100%' }}>
      <Col lg={12}>
        <Typography.Title className='shadow-text section-title'>
          FORGOT PASSWORD
        </Typography.Title>
        <Form
          layout='vertical'
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label='Enter Mobile Number/Email'
            name='username'
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' block>
              RESET PASSWORD
            </Button>
          </Form.Item>

          <Row style={{ marginBottom: 20, marginTop: 5 }} justify='start'>
            <Link to='/auth' className='native-link'>
              Back to LOG IN
            </Link>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default ResetPassword
