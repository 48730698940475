import './StorageImage.scss';

import {
  CSSProperties,
  useEffect,
  useState,
} from 'react';

import {
  Image,
  Spin,
  Upload,
} from 'antd';
import {
  API,
  Storage,
} from 'aws-amplify';
import dayjs from 'dayjs';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import { updateKyc } from 'Redux/KycRedux/KycActions';
import useCheckFormPage from 'Utils/checkFormPage';
import { dummyRequest } from 'Utils/DummyCall';
import { getUpdateImageKey } from 'Utils/GetUpdateImageKey';

import { EditOutlined } from '@ant-design/icons';

import { StoragePut } from '../StoragePut/StoragePut';

interface StorageImageProps {
  uri: string
  style?: CSSProperties
  preview?: boolean
  editable?: boolean
  isProfile?: boolean
  dataKey?: string
}
export const StorageImage = ({
  uri,
  style,
  preview,
  editable = false,
  isProfile = false,
  dataKey,
}: StorageImageProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { id } = useParams<any>()
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const formType = useCheckFormPage()
  const user = useSelector(
    (state: any) => state.KycReducer?.details
  )

 const { userId, minor,  } = user


  const getImageAsync = async (uri: string) => {
    setLoading(true)
    await Storage.get(uri, {
      customPrefix: {
        public: '',
      },
    }).then((data) => {
      setUrl(data)
    })
    setLoading(false)
  }

  const removeBG = async ({ url, transparent }: any) => {
    try {
      return await API.post('dockerApi', '/admin/image/signature', {
        body: { url, transparent },
      })
    } catch (err) {
      console.log('error creating todo:', err)
    }
  }

  const uploadImage = (file: any) => {
    let splittedUrl = ''
    console.log({ dataKey })
    if (uri.includes('uploadImage')) {
      const newUri = uri.substr(12)

      newUri.includes('front/citizenship')
        ? (splittedUrl = `${newUri.substr(6)}/${userId}/other/${id}/front`)
        : newUri.includes('back/citizenship')
        ? (splittedUrl = `${newUri.substr(5)}/${userId}/other/${id}/back`)
        : (splittedUrl = `${newUri}/${userId}/other/${id}`)
    } else {
      splittedUrl = uri.substring(0, uri.lastIndexOf('/'))
    }
    if (isProfile) {
      splittedUrl = `profile/user/${userId}/other/${id}`
    }

    // if (dataKey === 'Citizenship Front') {
    //   splittedUrl = `citizenship/user/${userId}/other/${id}/front`
    // } else if (dataKey === `Citizenship Back`) {
    //   splittedUrl = `citizenship/user/${userId}/other/${id}/back`
    // } else if (dataKey === `thumbprint`) {
    //   splittedUrl = `finger-print/user/${userId}/other/${id}`
    // } else if (dataKey === `picture`) {
    //   splittedUrl = `profile/user/${userId}/other/${id}`
    // } else if (dataKey === `signature`) {
    //   splittedUrl = `signature/user/${userId}/other/${id}`
    // } else if (dataKey === `cheque`) {
    //   splittedUrl = `cheque/user/${userId}/other/${id}`
    // } else if (dataKey === `Birth Certificate`) {
    //   splittedUrl = `birth-certificate/user/${userId}/other/${id}`
    // } else if (dataKey === `profile`) {
    //   splittedUrl = `profile/guardian/user/${userId}/other/${id}`
    // } else if (dataKey === `citizenship_front`) {
    //   splittedUrl = `citizenship/guardian/user/${userId}/other/${id}/front`
    // } else if (dataKey === `citizenship_back`) {
    //   splittedUrl = `citizenship/guardian/user/${userId}/other/${id}/back`
    // } else {
    //   splittedUrl = `${userId}/other/${id}`
    // }

    // if (isProfile) {
    //   splittedUrl = `profile/user/${userId}/other/${id}`
    // }

    const fileName = dayjs().format('YYMMDD-HHmmss')
    const type = file.type.split('/').pop()
    var path = `${splittedUrl}/${fileName}.${type}`

    setLoading(true)
    StoragePut({
      directoryPath: path,
      fileObj: file,
      successCB: async (key) => {
        setLoading(false)
        const updatedKey: any = getUpdateImageKey({
          responseKey: key,
          minor,
          formType,
        })

        console.log({ formType })
        console.log({ updatedKey })

        if (key.includes('signature')) {
          const objKeyS = Object.keys(updatedKey)

          const objKey = objKeyS[0]

          try {
            const res = await removeBG({ url: key, transparent: false })
            if (objKeyS.includes('signature')) {
              let newKey = { ...updatedKey, updated_signature: res.data }
              dispatch(updateKyc({ oid: id, ...newKey }))
            } else {
              let newKey = {
                [objKey]: (updatedKey[objKey] = {
                  ...updatedKey[objKey],
                  updated_signature: res.data,
                }),
              }
              dispatch(updateKyc({ oid: id, ...newKey }))
            }
          } catch (err) {
            console.log(err)
          }
        } else if(key.includes("citizenship/guardian")){
          let citizenship_images = {

          }
          if(user.guardian_details?.citizenship_images?.front) {
            citizenship_images = {
              ...citizenship_images,
              front:user.guardian_details?.citizenship_images?.front 
            }
          }
          if(user.guardian_details?.citizenship_images?.back) {
            citizenship_images = {
              ...citizenship_images,
              back:user.guardian_details?.citizenship_images?.back 
            }
          }
          const postData = {
            guardian_details: {
              citizenship_images: {
                ...citizenship_images,
                ...updatedKey.guardian_details.citizenship_images
              }
             
            }
          }
          dispatch(updateKyc({ oid: id, ...postData }))
        } else {
          dispatch(updateKyc({ oid: id, ...updatedKey }))

        }
      },
      failureCB: () => {
        setLoading(false)
      },
    })
  }

  useEffect(() => {
    if (uri !== '' && !uri.includes('uploadImage')) {
      getImageAsync(uri)
    }
  }, [uri])
  if (loading) {
    return <Spin />
  }

  if (url === '') {
    return (
      <Upload
        multiple={false}
        accept='image/*'
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={uploadImage}
      >
        {' '}
        <p
          style={{
            background: '#efefef',
            borderColor: '#0AB290',
            width: '200px',
            height: '200px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          Upload
        </p>
      </Upload>
    )
  }

  return (
    <Spin spinning={loading}>
      <Image
        preview={preview}
        height={200}
        style={style}
        loading='lazy'
        src={url}
        placeholder={<Spin />}
      />

      {editable && (
        <Upload
          multiple={false}
          accept='image/*'
          showUploadList={false}
          customRequest={dummyRequest}
          beforeUpload={uploadImage}
          className='EditableUpload'
        >
          {' '}
          <EditOutlined
            style={{
              fontSize: '10px',
              borderRadius: '100%',
              background: '#fff',
              padding: '8px',
              cursor: 'pointer',
            }}
          />
        </Upload>
      )}
    </Spin>
  )
}

export default StorageImage
