import { message } from "antd";
import { AxiosResponse } from "axios";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  generateBatchFile,
  generateBatchFileFailure,
  generateBatchFileSuccess,
  generatePaymentFile,
  generatePaymentFileSuccess,
} from "./BatchFileActions";
import { generateBatchFileApi, generatePaymentFileApi } from "./BatchFileApis";
import {
  GENERATE_BOFILE_START,
  GENERATE_PAYMENT_FILE_REQUEST,
} from "./BatchFileTypes";

function* handleGenerateBatchFileStart({
  payload,
  accountType,
}: ReturnType<typeof generateBatchFile>): any {
  try {
    const response: AxiosResponse = yield call(() =>
      generateBatchFileApi(payload, accountType)
    );
    yield put(generateBatchFileSuccess(response?.data?.s3_url ?? ""));
  } catch (err) {
    console.log(err);
    message.error(`Error zipping ${accountType} forms`);
    yield put(generateBatchFileFailure());
  }
}

function* watchGenerateBatchFileStart() {
  yield takeLatest(GENERATE_BOFILE_START, handleGenerateBatchFileStart);
}

function* handleGeneratePaymentFileStart({
  payload,
  type,
}: ReturnType<typeof generatePaymentFile>): any {
  const { medium, startDate, endDate } = payload;
  try {
    const response: AxiosResponse = yield call(() =>
      generatePaymentFileApi({ startDate, endDate, medium })
    );
    yield put(generatePaymentFileSuccess(response?.data?.s3_url ?? ""));
  } catch (err) {
    message.error(`Error generating ${medium} payments.`);
    yield put(generateBatchFileFailure());
  }
}

function* watchGeneratePaymentFileStart() {
  yield takeLatest(
    GENERATE_PAYMENT_FILE_REQUEST,
    handleGeneratePaymentFileStart
  );
}

export default function* BatchFileSagas() {
  yield all([fork(watchGenerateBatchFileStart)]);
  yield all([fork(watchGeneratePaymentFileStart)]);
}
