export const lightTheme: ThemeVariables = {
	body: '#E2E2E2',
	text: '#363537',
	toggleBorder: '#FFF',
	gradient: 'linear-gradient(#39598A, #79D7ED)',
	primary: '#10519F',
	primaryTwo: '#10509f3d',
	primaryThree: '#5652ff',
	accent: '#EF5DA8',
	lightBackground: '#f1f7fa',
	lightSecondary: '#b7eb8f8a'
};

export const darkTheme: ThemeVariables = {
	body: '#363537',
	text: '#FAFAFA',
	toggleBorder: '#6B8096',
	gradient: 'linear-gradient(#091236, #1E215D)'
};

export interface ThemeVariables {
	body?: string;
	text?: string;
	toggleBorder?: string;
	gradient?: string;
	primary?: string;
	lowPrimary?: string;
	defaultRadius?: number;
	lowerPrimary?: string;
	black?: string;
	lowGray?: string;
	lowGreen?: string;
	primaryTwo?: string;
	primaryThree?: string;
	accent?: string;
	lightBackground?: string;
	lightSecondary?: string;
}
