import { Reducer } from 'react';
import {
	CreateUserParams,
	CREATE_USER,
	CREATE_USER_FAILURE,
	CREATE_USER_SUCCESS,
	FETCH_USERS,
	FETCH_USERS_FAILURE,
	FETCH_USERS_SUCCESS,
	FETCH_USER,
	FETCH_USER_FAILURE,
	FETCH_USER_SUCCESS,
	REMOVE_USER,
	REMOVE_USER_FAILURE,
	REMOVE_USER_SUCCESS,
	UPDATE_USER,
	UPDATE_USER_FAILURE,
	UPDATE_USER_SUCCESS,
	UserDetailsParams
} from './UserTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	pageNumber: 0,
	pageSize: 0,
	totalPages: 1,
	totalRecords: 8
};

export const UserReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Users
		case FETCH_USERS:
			return { ...state, loaded: false, loading: true };
		case FETCH_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				list: action.payload.data,
				pageNumber: action.payload.page,
				pageSize: action.payload.count,
				totalPages: action.payload.totalPages,
				totalRecords: action.payload.total
			};
		case FETCH_USERS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch User Details
		case FETCH_USER:
			return { ...state, detailed: false, detailing: true };
		case FETCH_USER_SUCCESS:
			return { ...state, detailing: false, detailed: false, details: action.payload.data || false };
		case FETCH_USER_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create User
		case CREATE_USER:
			return { ...state, created: false, creating: true, error: false };
		case CREATE_USER_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false, created: true };
		case CREATE_USER_FAILURE:
			return { ...state, creating: false, error: action.payload.error };

		//update User
		case UPDATE_USER:
			return { ...state, updating: true, updated: false, error: false };
		case UPDATE_USER_SUCCESS:
			const tempList = state.list.filter((d: CreateUserParams) => d.id !== action.payload.id);
			tempList.push(action.payload);
			return { ...state, updated: true, updating: false, list: tempList };
		case UPDATE_USER_FAILURE:
			return { ...state, updating: false, error: action.payload.error };

		//remove User
		case REMOVE_USER:
			return { ...state, deleted: false, deleting: true };
		case REMOVE_USER_SUCCESS:
			return {
				...state,
				deleting: false,
				deleted: true,
				list: state.list.filter((d: UserDetailsParams) => d.id !== action.payload.id)
			};
		case REMOVE_USER_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
