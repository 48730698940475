import React, {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Typography,
} from 'antd';
import { AntFormItem } from 'Components/AntForm/AntForm';
import { DatePicker } from 'Components/DatePicker';
import dayjs from 'dayjs';
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon';
import { DownloadIconDark } from 'Icons/DownloadIcon/DownloadIcon';

import { TransactionListFilterProps } from '../Transaction';
import { ExportComponent } from './ExportComponent';
import { ImportComponent } from './ImportComponent';

const modeType = [
  "Cash",
  "Credit Card",
  "Bank Transfer",
  "CHEQUE",
  "ESEWA",
  "KHALTI",
  "NCHL",
  "IMEPAY",
];

const dateType = ["yesterday", "today", "week", "month", "year"];

interface FilterComponentProps {
  setFilterData: Dispatch<SetStateAction<TransactionListFilterProps>>;
  filterData: TransactionListFilterProps;
}

export const FilterComponent = ({
  filterData,
  setFilterData,
}: FilterComponentProps) => {
  const [formFilter] = Form.useForm();

  const [selectedMode, setSelectedMode] = useState("");
  const [selectedDateRange, setSelecteDDateRange] = useState("");

  const modeMenu = (
    <Menu
      onClick={(e) => {
        setSelectedMode(e.key);
        setFilterData((prevState) => ({
          ...prevState,
          payment_mode: e.key,
          page: 1,
        }));
      }}
    >
      {modeType?.map((status) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={status}>
            <Typography.Text type="secondary">{status}</Typography.Text>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const dateTypeMenu = (
    <Menu
      onClick={(e) => {
        setSelecteDDateRange(e.key);
        setFilterData((prevState) => ({
          ...prevState,
          dateRange: e.key,
          page: 1,
        }));
      }}
    >
      {dateType.map((date) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={date}>
            <Typography.Text type="secondary">{date}</Typography.Text>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const onFilterSubmit = (data: any) => {
    setFilterData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const downloadSample = () => {
    // const file = SampleFile
    fetch("/cdsc_sample.csv").then((res) => res.blob()).then((blob) => {

      const a = document.createElement('a');

      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = 'sample.xlsx';

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    })
  }

  return (
    <div>
      <Form onFinish={onFilterSubmit} form={formFilter}>
        <Row gutter={[20, 20]} align="middle">
          <Col lg={6}>
            <AntFormItem name="username" style={{ marginBottom: 0 }}>
              <Input.Search placeholder='Search username or id' />
            </AntFormItem>
          </Col>
          <Col lg={6}>
            <Button htmlType="submit" type="primary" className="primary--alt">
              Search
            </Button>
          </Col>
          <Col lg={4} offset={2}>
            <Button
              icon={<DownloadIconDark />}
              style={{
                fontWeight: 400,
              }}
              onClick={downloadSample}
            >
              <Typography.Text>
                {"Sample Import"}
              </Typography.Text>
            </Button>
          </Col>
          <Col lg={3}>
            <ExportComponent filterData={filterData} />
          </Col>
          <Col lg={3}>
            <ImportComponent filterData={filterData} />
          </Col>
        </Row>
        <Row gutter={[20, 20]} align="middle" style={{ marginTop: 18 }}>
          <Col lg={4} offset={12}>
            <Dropdown trigger={["click"]} overlay={modeMenu}>
              <Row align="middle" justify="space-between">
                <Typography.Text style={{ textTransform: "capitalize" }}>
                  {selectedMode ? selectedMode : "Mode"}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </Col>
          <Col lg={4}>
            <Dropdown trigger={["click"]} overlay={dateTypeMenu}>
              <Row align="middle" justify="space-between">
                <Typography.Text style={{ textTransform: "capitalize" }}>
                  {selectedDateRange ? selectedDateRange : "Date Filter"}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </Col>
          <Col lg={4}>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              onChange={(dates) => {
                setFilterData((prevState) => ({
                  ...prevState,
                  transaction_date_after: dates
                    ? dayjs(dates?.[0]?.toString()).format("YYYY-MM-DD")
                    : "",
                  transaction_date_before: dates
                    ? dayjs(dates?.[1]?.toString()).format("YYYY-MM-DD")
                    : "",
                  page: 1,
                }));
              }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
