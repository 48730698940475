import { AntForm, AntFormItem } from "Components/AntForm/AntForm";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { updateKyc } from "Redux/KycRedux/KycActions";
import { AddressFormValidation } from "./AddressFormValidation";
type Props = {
  title: "Permanent Address" | "Temporary Address";
  selectedAddressData: any;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddressForm = ({ title, selectedAddressData, setIsEditing }: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { addressDetails } = useSelector((state: any) => state.OptionReducer);
  const { id } = useParams<any>();

  const updateHandler = (values: any) => {
    const wrapperKey = title?.toLowerCase().split(" ").join("_");
    const finalData = {
      [wrapperKey]: {
        ...values,
        ward: Number(values?.ward),
        fax: values?.fax ? Number(values.fax) : undefined,
        block: values?.fax ? Number(values?.block) : undefined,
      },
    };
    dispatch(updateKyc({ ...finalData, oid: id }));
    setIsEditing(false);
  };

  const onSubChange = (value: any) => {
    let parsedData = JSON.parse(value);
    form.setFieldsValue({
      sub: parsedData.name,
      country: parsedData.country,
      province: parsedData.province,
      district: parsedData.district,
      address_code: parsedData.address_code,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      country: selectedAddressData.country,
      province: selectedAddressData.province,
      district: selectedAddressData.district,
      sub: selectedAddressData.sub,
      tole: selectedAddressData.tole,
      ward: selectedAddressData.ward,
      nearest_landmark: selectedAddressData.nearest_landmark,
      fax: selectedAddressData.fax,
      block: selectedAddressData.block,
      address_code: selectedAddressData.address_code,
    });
  }, [selectedAddressData]);

  return (
    <AntForm layout={"vertical"} onFinish={updateHandler} form={form}>
      <Row gutter={[20, 0]}>
        <Col span={8}>
          <AntFormItem
            label={"Municipality"}
            name="sub"
            rules={AddressFormValidation.sub}
          >
            <Select
              style={{ width: "100%" }}
              showSearch={true}
              onChange={onSubChange}
            >
              {addressDetails?.length &&
                addressDetails?.sort().map((option: any, index: any) => (
                  <Select.Option value={JSON.stringify(option)} key={index}>
                    {option?.name}, {option?.district}
                  </Select.Option>
                ))}
            </Select>
          </AntFormItem>
        </Col>
        <Col span={24} />
        <Col span={8}>
          <AntFormItem
            label={"First Form"}
            name="country"
            rules={AddressFormValidation.country}
          >
            <Input disabled />
          </AntFormItem>
        </Col>

        <Col span={8}>
          <AntFormItem
            label={"District"}
            name="district"
            rules={AddressFormValidation.district}
          >
            <Input disabled />
          </AntFormItem>
        </Col>
        <Col span={8}>
          <AntFormItem
            label={"Province"}
            name="province"
            rules={AddressFormValidation.province}
          >
            <Input disabled />
          </AntFormItem>
        </Col>

        <Col span={8}>
          <AntFormItem
            label={"Tole"}
            name="tole"
            rules={AddressFormValidation.tole}
          >
            <Input />
          </AntFormItem>
        </Col>
        <Col span={8}>
          <AntFormItem
            label={"Ward No"}
            name="ward"
            rules={AddressFormValidation.ward}
          >
            <Input type="number" />
          </AntFormItem>
        </Col>
        <Col span={8}>
          <AntFormItem
            label={"Nearest Landmark"}
            name="nearest_landmark"
            rules={AddressFormValidation.nearest_landmark}
          >
            <Input />
          </AntFormItem>
        </Col>
        <Col span={8}>
          <AntFormItem label={"Fax"} name="fax">
            <Input type="number" />
          </AntFormItem>
        </Col>
        <Col span={8}>
          <AntFormItem label={"Block"} name="block">
            <Input type="number" />
          </AntFormItem>
        </Col>
        <Col span={8} hidden>
          <AntFormItem label={"Block"} name="address_code">
            <Input />
          </AntFormItem>
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <Button
            type="default"
            onClick={() => setIsEditing(false)}
            htmlType="submit"
          >
            Cancel
          </Button>
          <Button loading={false} type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form.Item>
    </AntForm>
  );
};

export default AddressForm;
