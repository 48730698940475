import { message } from "antd";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  failureKycFileUpdate,
  requestKycFileUpdate,
  successKycFileUpdate,
} from "./UploadKycActions";
import { UPDATE_KYC_FILE_REQUEST } from "./UploadKycTypes";
import { AxiosResponse } from "axios";
import { accountsUpdateApi } from "./UploadKycApi";

function* handleRequestUploadKycFile({
  payload,
  accountType,
}: ReturnType<typeof requestKycFileUpdate>) {
  try {
    const response: AxiosResponse = yield call(() =>
      accountsUpdateApi(payload, accountType)
    );
    yield put(successKycFileUpdate(response.data));
  } catch (err) {
    console.log(err);
    yield put(failureKycFileUpdate());
    message.error("ERROR UPDATING KYC FILES");
  }
}

function* watchRequestUploadKycFile() {
  yield takeLatest(UPDATE_KYC_FILE_REQUEST, handleRequestUploadKycFile);
}

export default function* UploadKycSaga() {
  yield all([fork(watchRequestUploadKycFile)]);
}
