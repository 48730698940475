export const keys = [
  {
    key: "dpid",
    type: "number",
  },
  {
    key: "clientId",
    type: "number",
  },
  {
    key: "full_name",
    type: "text",
  },
  {
    key: "email",
    type: "email",
  },
  {
    key: "country",
    type: "text",
  },
  {
    key: "province",
    type: "text",
  },
  {
    key: "district",
    type: "text",
  },
  {
    key: "sub",
    type: "text",
  },
  {
    key: "tole",
    type: "text",
  },
  {
    key: "ward",
    type: "number",
  },
  {
    key: "block",
    type: "number",
  },
  {
    key: "fax",
    type: "number",
  },
  {
    key: "nearest_landmark",
    type: "text",
  },
  {
    key: "phone",
    type: "phoneNumber",
  },
  {
    key: "signature",
    type: "image",
  },
  {
    key: "updated_signature",
    type: "image",
  },
  {
    key: "boid",
    type: "text",
  },
  {
    key: "boid_pdf",
    type: "ignored_text",
  },
  {
    key: "identity",
    type: "ignored_text",
  },
  {
    key: "created_date",
    type: "ignored_text",
  },
  {
    key: "income_source",
    type: "text",
  },
  {
    key: "buss_sec_brok_com",
    type: "boolean",
  },
  {
    key: "karja_kalosuchi",
    type: "boolean",
  },
  {
    key: "inv_comp_sec_trad",
    type: "boolean",
  },
  {
    key: "company",
    type: "text",
  },
  {
    key: "designation",
    type: "text",
  },
  {
    key: "rent",
    type: "boolean",
  },
  {
    key: "relation",
    type: "text",
  },
  {
    key: "mobile",
    type: "text",
  },
  {
    key: "photo",
    type: "image",
  },
  {
    key: "poli_high_pos",
    type: "boolean",
  },
  {
    key: "eng_poli_high_pos",
    type: "boolean",
  },
  {
    key: "beneficiary",
    type: "boolean",
  },
  {
    key: "guilt_case",
    type: "boolean",
  },
  {
    key: "samp_sudhi_2064",
    type: "text",
  },
  {
    key: "latitude",
    type: "text",
  },
  {
    key: "longitude",
    type: "text",
  },
  {
    key: "occupation_id",
    type: "text",
  },
  {
    key: "pho_pers_hold_citi",
    type: "text",
  },
  {
    key: "terms_conditions",
    type: "text",
  },
  {
    key: "terms_conditions_status",
    type: "boolean",
  },
  {
    key: "referral_id",
    type: "number",
  },
  {
    key: "username",
    type: "text",
  },
  {
    key: "status",
    type: "text",
  },
  {
    key: "minor",
    type: "boolean",
  },
  {
    key: "pan_number",
    type: "number",
  },
  {
    key: "demat",
    type: "boolean",
  },
  {
    key: "demat_status",
    type: "text",
  },
  {
    key: "meroshare",
    type: "boolean",
  },
  {
    key: "meroshare_status",
    type: "text",
  },
  {
    key: "trading",
    type: "boolean",
  },
  {
    key: "trading_status",
    type: "text",
  },
  {
    key: "passport_number",
    type: "number",
  },
  {
    key: "place_of_issue",
    type: "text",
  },
  {
    key: "issue_date", //passport
    type: "date",
  },
  {
    key: "back", //citizenship images
    type: "image",
  },
  {
    key: "front",
    type: "image",
  },
  {
    key: "citizenship_back", //citizenship images
    type: "image",
  },
  {
    key: "citizenship_front",
    type: "image",
  },

  {
    key: "dob",
    type: "date",
  },
  {
    key: "dob_np",
    type: "text",
  },
  {
    key: "nationality",
    type: "text",
  },
  {
    key: "gender",
    type: "text",
  },
  {
    key: "citizenship_id",
    type: "text",
  },
  {
    key: "issued_date",
    type: "date",
  },
  {
    key: "issued_np",
    type: "text",
  },
  {
    key: "issued_district",
    type: "text",
  },
  {
    key: "reference_number",
    type: "phoneNumber",
  },
  {
    key: "profile",
    type: "image",
  },
  {
    key: "name",
    type: "text",
  },
  {
    key: "username",
    type: "text",
  },
  {
    key: "branch",
    type: "text",
  },
  {
    key: "account_type",
    type: "text",
  },
  {
    key: "father_name",
    type: "text",
  },
  {
    key: "mother_name",
    type: "text",
  },
  {
    key: "grandfather_name",
    type: "text",
  },
  {
    key: "married",
    type: "boolean",
  },
  {
    key: "spouse_name",
    type: "text",
  },
  {
    key: "in_laws",
    type: "", // array of {relation:,name:}
  },
  {
    key: "children",
    type: "", // array of {relation:,name:}
  },
  {
    key: "same_as_permanent",
    type: "boolean",
  },
  {
    key: "occupation_type",
    type: "text",
  },
  {
    key: "business_type",
    type: "text",
  },
  {
    key: "organization",
    type: "text",
  },
  {
    key: "income",
    type: "number",
  },
  {
    key: "statement",
    type: "text",
  },
  {
    key: "applicant",
    type: "text",
  },
  {
    key: "income_range",
    type: "text",
  },
  {
    key: "cheque",
    type: "image",
  },
  {
    key: "bank_name",
    type: "text",
  },
  {
    key: "nominee",
    type: "boolean",
  },
  {
    key: "relationship",
    type: "text",
  },
  {
    key: "age",
    type: "number",
  },
  {
    key: "email_mobile",
    type: "text",
  },
  {
    key: "thumbprint",
    type: "image",
  },
  {
    key: "stan_auto_trans",
    type: "boolean",
  },
  {
    key: "birth_certificate_image",
    type: "image",
  },
  {
    key: "birth_certificate_number",
    type: "text",
  },
  {
    key: "block_full_name",
    type: "text",
  },
  {
    key: "relation_with_applicant",
    type: "text",
  },
  {
    key: "individual",
    type: "boolean",
  },
  {
    key: "demat_pdf",
    type: "text",
  },
  {
    key: "archived",
    type: "boolean",
  },
];

export const getKeyType = (key: string) => {
  const selected = keys?.filter((keyType) => keyType.key === key);
  if (selected?.length > 0) {
    return selected[0].type;
  } else {
    return false;
  }
};
