import { Button, Col, Form, Input, notification, Row, Typography } from 'antd'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Auth as AmplifyAuth } from '@aws-amplify/auth'

export const ForgotPasswordSubmit = () => {
  const history = useHistory()

  const onFinishFailed = (errorInfo: any) => {
    notification.error({
      message: 'Password changed Failed',
      description: 'Please try again',
    })
  }

  const onFinish = async (values: any) => {
    console.log('Success:', values)
    await AmplifyAuth.forgotPasswordSubmit(
      values.username,
      values.code,
      values.password
    )
    notification.success({
      message: 'Password Change Succeess',
      description: 'Please login in to continue',
    })
    history.push('/')
  }

  return (
    <Row justify='center' align='middle' style={{ minHeight: '100%' }}>
      <Col lg={12}>
        <Typography.Title className='shadow-text section-title'>
          NEW PASSWORD
        </Typography.Title>
        <Form
          layout='vertical'
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label='Email'
            name='username'
            rules={[
              {
                required: true,
                message: 'Please input your email!',
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Code'
            name='code'
            rules={[{ required: true, message: 'Please input your code!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Enter New Password'
            name='password'
            rules={[
              { required: true, message: 'Please input your Password!' },
              {
                min: 8,
                message: 'Password must be at least 8 characters ',
              },

              {
                pattern:
                  /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                message:
                  'Password should contain one upper/lowercase with atleast one special character and must be alphanumeric',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='rePassword'
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  )
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' block>
              CHANGE PASSWORD
            </Button>
          </Form.Item>

          <Row style={{ marginBottom: 20, marginTop: 5 }} justify='start'>
            <Link to='/auth' className='native-link'>
              Back to LOG IN
            </Link>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default ForgotPasswordSubmit
