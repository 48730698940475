import { API } from 'aws-amplify';

import { ApiHeaders } from './Api';

export type searchType = 'address' | 'bank' | 'organization' | 'rate' | 'broker'

export const searchApis = (searchKey: searchType) => {
  let url = `/admin/search/${searchKey}`
  return  API.get('kycApi', url, {
    headers: ApiHeaders,
  })
}
