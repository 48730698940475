import { useState } from 'react';

import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Typography,
} from 'antd';
import config from 'Config/config';
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { PoweroffOutlined } from '@ant-design/icons';
import Auth from '@aws-amplify/auth';

import cmsIcon from '../../Assets/images/cms.png';
import crmIcon from '../../Assets/images/crm.png';

export const CommonHeader = () => {
  const [theme, setTheme] = useState('light')
  const admin = useSelector((state: any) => state.UserReducer)
  const history = useHistory()

  const signOut = async () => {
    await Auth.signOut()
    history.push('/auth')
  }

  const { details } = admin

  const menu = (
    <Menu>
      <Menu.Item icon={<PoweroffOutlined />} key='0'>
        <div onClick={() => history.push(`/admin/${details.id}/edit`)}>
          My Profile
        </div>
      </Menu.Item>
      <Menu.Item icon={<PoweroffOutlined />} key='1'>
        <a onClick={signOut}>Sign Out</a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Row justify='space-between' align='middle'>
      <Col>
        <Typography.Title level={2} style={{ fontWeight: 800 }}>
          {/* User Listing */}
        </Typography.Title>
      </Col>
      <Col>
        <Space align='center' size={40}>
          {/* <Space>
            <Typography.Text
              strong
              style={{ color: theme === 'light' ? '#000000' : '#767997' }}
            >
              Light
            </Typography.Text>
            <Switch
              defaultChecked={theme === 'dark'}
              onChange={(e) => (e ? setTheme('dark') : setTheme('light'))}
            />
            <Typography.Text
              strong
              style={{ color: theme === 'dark' ? '#000000' : '#767997' }}
            >
              Dark
            </Typography.Text>
          </Space> */}
          <div>
            <Button style={{
              marginRight: 18,
              height: "auto"
            }}
           
              onClick={() => window.open(config.crmLink, "_blank")}
            >
              <img src={crmIcon} alt='crm' style={{
                height: 38,
                width: 38,
              }} />
              <Typography style={{
                fontSize: 10
              }}>CRM</Typography>
            </Button>
            <Button style={{
              height: "auto"

            }}
              onClick={() => window.open(config.cmsLink, "_blank")}
            >
              <img src={cmsIcon} alt='crm' style={{
                height: 38,
                width: 38,

              }} />
              <Typography style={{
                fontSize: 10
              }}>CMS</Typography>
            </Button>
          </div>
          <Dropdown overlay={menu}>
            <Space align='center'>
              <Avatar src='https://cdn.dribbble.com/users/1577045/screenshots/4914645/media/028d394ffb00cb7a4b2ef9915a384fd9.png?compress=1&resize=400x300' />
              <Typography.Title
                style={{ marginBottom: 0, marginRight: 20 }}
                level={4}
              >
                {details?.full_name}
              </Typography.Title>
              <ArrowDownIcon />
            </Space>
          </Dropdown>
        </Space>
      </Col>
    </Row>
  )
}

export default CommonHeader
