import { Reducer } from 'react';

import {
  FETCH_DASHBOARD_REPORT,
  FETCH_DASHBOARD_REPORT_FAILURE,
  FETCH_DASHBOARD_REPORT_SUCCESS,
} from './DashboardType';

const INIT_STATE = {
  loading: false,
  data: {},
};

export const dpDashboard: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_REPORT:
      return {
        ...state,
        loading: true,
      };

    case FETCH_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case FETCH_DASHBOARD_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        data: {},
      };

    default:
      return { ...state };
  }
};
