import {
  MouseEvent,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  dematDetailsFields,
  tradingDetailsFields,
} from '__abc__lib/fields/UserDetails/tradingFields';
import FormViewRenderer
  from '__abc__lib/FormViewRenderer/FormViewRenderer/FormViewRenderer';
import UserDetailsSubmitHandler
  from '__abc__lib/submitHandler/UserDetails/UserDetailsSubmitHandler';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
  Upload,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Content } from 'antd/lib/layout/layout';
import { RcFile } from 'antd/lib/upload/interface';
import { ApiHeaders } from 'Api/Api';
import { API } from 'aws-amplify';
import DisplayAddress from 'Components/AddressForm/DisplayAddress';
import DisplayChildren from 'Components/ChildrenForm/DisplayChildren';
import MyContent from 'Components/Content/Content';
import { DatePicker } from 'Components/DatePicker';
import DescriptionCard from 'Components/DescriptionCard/DescriptionCard';
import ImagePopover from 'Components/ImagePopover/ImagePopover';
import Loading from 'Components/Loading/Loading';
import PageHolder from 'Components/PageHolder/PageHolder';
import PageLoading from 'Components/PageLoading/PageLoading';
import Status from 'Components/Status/Status';
import StorageImage from 'Components/StorageImage/StorageImage';
import { StoragePut } from 'Components/StoragePut/StoragePut';
import VideoPlayer from 'Components/VideoPlayer/VideoPlayer';
import dayjs from 'dayjs';
import ArchieveIcon from 'Icons/ArchieveIcon/ArchieveIcon';
import BackIcon from 'Icons/BackIcon/BackIcon';
import DownloadIcon from 'Icons/DownloadIcon/DownloadIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  acceptRejectVideoKyc,
  fetchKyc,
  fetchKycSuccess,
  fetchVideoKyc,
  generatePdf,
  rescheduleVideoKyc,
  updateKyc,
  verifyVideoKyc,
} from 'Redux/KycRedux/KycActions';
import { fetchUser } from 'Redux/UserRedux/UserActions';
import useCheckFormPage from 'Utils/checkFormPage';
import {
  scheduleKycDateValidation,
  scheduleKycTimeValidation,
} from 'Utils/dateValidations';
import { onDownload } from 'Utils/downloadFile';
import { dummyRequest } from 'Utils/DummyCall';
import { getFormDataReady } from 'Utils/getFormDataReady';
import { getUsersData } from 'Utils/getUsersData';
import AcceptRejectModal from 'Views/Modal/AcceptRejectModal';

import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  InboxOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import { KycLogs } from './components/KycLogs';
import UserWatchList from './components/UserWatchList';

const { TabPane } = Tabs;
const { Dragger } = Upload;

export const UserDetails = (props: any) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.KycReducer);
  const loggedInUser = useSelector((state: any) => state.UserReducer);
  const [accept, setAccept] = useState(false);
  const {
    detailing,
    details,
    updating,
    updated,
    rescheduling,
    videoKyc,
    acceptingOrRejecting,
    rescheduleSuccess,
    acceptingOrRejectingSuccess,
    generatingPdf,
    generatedPdf
  } = user;
  const [reject, setReject] = useState(false);
  const { id } = useParams<any>();
  const { type, childId } = useParams<any>();
  const [completeRequest, setCompletedrequest] = useState(
    details?.form?.[`${type}_status`] === "Completed"
  );
  const [scheduledDate, setScheduledDate] = useState(null);
  const history: any = useHistory();

  const { rateList } = useSelector((state: any) => state.OptionReducer);



  const historyFilter = history.location.state?.filter;
  const historyWatchlist = history.location.state?.watchlist;
  const historyDefaultSelectedTab = history.location.state?.defaultSelectedTab;

  const [downloadingPdf, setDownloadingPdf] = useState(false);

  const [form] = useForm();
  const [formTrading] = useForm();
  const [acceptForm] = useForm();
  const [displayReschedule, setDisplayReschedule] = useState(false);
  const [displayAccept, setDisplayAccept] = useState(false);
  const [reasonToReschedule, setReason] = useState("");
  const [reasonToAcceptReject, setAcceptReason] = useState("");
  const [trackAccept, setAcceptReject] = useState(true);
  const [verifyKyc, setVerifyKyc] = useState(false);
  const [rejectKyc, setRejectKyc] = useState(false);
  const [reasonToVerifyReject, setVerifyReject] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [videoFileList, setVideoFileList] = useState<RcFile>();
  const [videoUploadLoading, setVideUploadLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const { TextArea } = Input;

  const backHandler = () => {
    history.push({
      pathname:
        historyDefaultSelectedTab && historyDefaultSelectedTab === "video-kyc"
          ? "/videoKycRequestList"
          : "/user",
      state: { filter: historyFilter },
    });
  };

  useEffect(() => {
    const handlePopEvent = () => {
      backHandler();
    };
    window.addEventListener("popstate", handlePopEvent);
    return () => {
      window.removeEventListener("popstate", handlePopEvent);
    };
  }, []);

  useEffect(() => {
    setCompletedrequest(details?.form?.[`${type}_status`] === "Completed");
  }, [details]);

  useEffect(() => {
    if (details?.team?.id) {
      dispatch(fetchUser({ id: details?.team?._id }));
    }
  }, [details]);

  useEffect(() => {
    if (id) {
      dispatch(fetchKyc({ id: id, type: type }));
      dispatch(fetchVideoKyc(id));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(fetchKycSuccess(false));
    };
  }, []);

  const onArchieveHandler = (data: any) => {
    dispatch(updateKyc({ oid: data?.id, account: type, archieve: true }));
  };

  useEffect(() => {
    if ((rescheduleSuccess || acceptingOrRejectingSuccess) && id) {
      dispatch(fetchVideoKyc(id));
      setAccept(false);
      setReject(false);
    }
  }, [rescheduleSuccess, dispatch, id, acceptingOrRejectingSuccess]);


  // const getDetailsData = () => {
  //   const allData = getUsersData(details, videoKyc, rateList);

  //   switch (type) {
  //     case "demat":
  //       return allData?.[0];
  //     case "meroshare":
  //       return allData?.[1];
  //     case "trading":
  //       return allData?.[2];
  //     default:
  //       return allData?.[0];
  //   }
  // };

  const getDetailsData = useCallback(() => {
    const allData = getUsersData(details, rateList, videoKyc,);

    switch (type) {
      case "demat":
        return allData?.[0];
      case "meroshare":
        return allData?.[1];
      case "trading":
        return allData?.[2];
      default:
        return allData?.[0];
    }

  }, [details, videoKyc, rateList, type])

  useEffect(() => {
    if (updated) {
      dispatch(fetchKyc({ id: id, type: type }));
    }
  }, [updated]);

  const onRespond = (value: any) => {
    if (value === "accepted") {
      //regeerate a new pdf for that user if accepting the form
      API.get("dockerApi", `/admin/generate-pdf/${id}/${type}`, {
        headers: ApiHeaders,
      });
    }
    acceptForm?.validateFields()?.then((data) => {
      dispatch(
        updateKyc({
          oid: details.id,
          AcceptRejectKYC: {
            ...data,
            status: value,
          },
        })
      );
      setAccept(false);
      setReject(false);
    });
  };

  const onVerifyReject = async () => {
    setVerifying(true);
    let body: {
      status: string;
      id: number;
      reason?: string;
      admin_name: string;
      time: string;
    } = {
      status: verifyKyc ? "verified" : "rejected",
      id: loggedInUser.details.id,
      admin_name: loggedInUser.details.full_name,
      time: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      reason: reasonToVerifyReject ? reasonToVerifyReject : "",
    };
    if (!verifyKyc) body["reason"] = reasonToVerifyReject;
    let data = await API.patch(
      "kycApi",
      `/admin/verify/reject/video_kyc/${id}`,
      {
        headers: ApiHeaders,
        body,
      }
    );

    if (data.success) {
      message.success("Operation Successful.");
      dispatch(verifyVideoKyc(data.data.video_kyc_status));
      dispatch(fetchVideoKyc(id));
    } else {
      message.error("Operation failure.");
    }

    setVerifying(false);
    setVerifyKyc(false);
    setRejectKyc(false);
    setVerifyReject("");
  };

  const detailsUpdateHandler = (values: any) => {


    let type = details?.minor ? "guardian_details" : "general_details"
    

    let pan_number = values?.[type]?.pan_number

    if(values[type]?.pan_number) {

      delete values[type].pan_number
    }

    const postData = {
      ...values,
      pan_number
    }

    
    UserDetailsSubmitHandler({
      values: postData,
      oid: id,
      dispatch,
    });

    const location = history.location.pathname?.split("/");
    location.pop();
    history.push(location.join("/"));
  };

  const tradingUpdateHandler = (values: any) => {
    dispatch(
      updateKyc({
        ...{
          [type]:
            type === "trading"
              ? { client_code: values[type].client_code }
              : { clientId: parseInt(values[type].clientId) },
        },
        oid: id,
      })
    );
    const location = history.location.pathname?.split("/");
    location.pop();
    history.push(location.join("/"));
  };

  useEffect(() => {
    if (updated) {
      const location = history.location.pathname?.split("/");
      if (location.includes("update")) {
        location.pop();
        history.push(location.join("/"));
      }
    }
  }, [updated]);

  const formType = useCheckFormPage();

  const tradingUserData: any = {
    title: type,
    key: type,
    formFields: type === "trading" ? tradingDetailsFields : dematDetailsFields,
    data:
      type === "trading"
        ? {
          client_code: details?.[type]?.client_code,
          email: details?.email,
        }
        : {
          clientId: details?.[type]?.clientId,
          email: details?.email,
        },
  };

  const userBasicData: any = {
    email: details?.email,
    phone: details?.phone,
    account_type: `${type?.toUpperCase()}  ${details?.minor === true ? "(Minor)" : "(Non-Minor)"
      }`,
    joined_date: dayjs(details?.created_at).format("LL"),
    status: (
      <Status
        status={details?.form?.[`${type}_status`]}
        rejectMessage={
          type === "demat"
            ? details?.demat?.demat_reject_reason
            : type === "trading"
              ? details?.trading?.trading_reject_reason
              : details?.meroshare?.meroshare_reject_reason
        }
      />
    ),
    client_code: details?.[type]?.client_code,
    clientId: details?.[type]?.clientId,
    applied_branch: details?.depository_participant?.branch ?? "",
  };

  if (type === "meroshare") {
    delete userBasicData.client_code;
    delete userBasicData.clientId;
    delete userBasicData.applied_branch;
  }

  if (type === "demat") {
    delete userBasicData.client_code;
  }

  if (type === "trading") {
    delete userBasicData.clientId;
  }

  const EditBthHandler = () => {
    if (type === "meroshare") {
      return true;
    } else {
      if (
        type === "trading" &&
        user?.details?.form?.[`${type}_status`] === "Pending"
      ) {
        return true;
      } else if (
        type === "demat" &&
        user?.details?.form?.[`${type}_status`] === "Pending"
      ) {
        return true;
      }
    }
    return false;
  };

  let client_identifier = "";
  if (type === "trading") {
    client_identifier = "client_code";
  } else {
    client_identifier = "clientId";
  }

  const handleDateChange = (date: any, dateString: string) => {
    form.setFieldsValue({ scheduledDate: date, date });
    setScheduledDate(date);
  };

  const handleUploadVideo = (e: MouseEvent<HTMLButtonElement>) => {
    // console.log("show e", e);
    e.preventDefault();

    let extension = videoFileList?.type.split("/")[1];
    setVideUploadLoading(true);
    if (videoFileList) {
      const path = `admin/videoKyc/${details?.userId}/${dayjs().format(
        "YYMMDD-HHmmss"
      )}.${extension}`;
      StoragePut({
        directoryPath: path,
        fileObj: videoFileList,
        failureCB: () => {
          message.error("Error uploading video");

          setVideUploadLoading(false);
        },
        successCB: (key) => {
          message.success("Video uploaded");

          dispatch(
            acceptRejectVideoKyc({
              oid: id,
              approved_datetime: dayjs(new Date()).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              status: "store_recording",
              s3_path: path,
            })
          );
          setVideUploadLoading(false);
          setVideoFileList(undefined);
          setFileList([]);
        },
      });
    }
  };

  useEffect(() => {
    if (user.rescheduleSuccess) {
      setDisplayReschedule(false);
      setReason("")
      setScheduledDate(null)
    }
  }, [user]);

  const defaultSelectedTab = historyDefaultSelectedTab || null;


  return (
    <MyContent>
      <Suspense fallback={<Loading fullScreen />}>
        <PageLoading loading={!details && detailing}>
          <Content>
            <Row gutter={[20, 20]} align="middle" justify="space-between">
              <Col>
                <Row gutter={[20, 20]} align="middle">
                  <Col>
                    <Button
                      onClick={backHandler}
                      icon={
                        <BackIcon style={{ marginRight: 10, paddingTop: 3 }} />
                      }
                      type="default"
                    >
                      Back
                    </Button>
                  </Col>
                  <Col>
                    <Typography.Title level={4} style={{ marginBottom: 0 }}>
                      {details?.username}
                    </Typography.Title>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row gutter={[20, 20]} align="middle">
                  <Col hidden={completeRequest}>
                    {user?.details?.form?.[`${formType}_status`] !==
                      "Completed" && (
                        <>
                          {user?.details?.form?.[`${formType}_status`] ===
                            "Pending" ||
                            user?.details?.form?.[`${formType}_status`] ===
                            "Accepted" ? (
                            <Button
                              onClick={() => setReject(true)}
                              type="primary"
                              danger
                              icon={<CloseOutlined />}
                            >
                              Reject
                            </Button>
                          ) : (
                            <Button
                              onClick={() => setReject(true)}
                              type="primary"
                              danger
                              disabled
                              icon={<CloseOutlined />}
                            >
                              Rejected
                            </Button>
                          )}
                        </>
                      )}
                  </Col>
                  <Col hidden={completeRequest}>
                    {user?.details?.form?.[`${formType}_status`] !==
                      "Completed" && (
                        <>
                          {user?.details?.form?.[`${formType}_status`] ===
                            "Pending" ||
                            user?.details?.form?.[`${formType}_status`] ===
                            "Rejected" ? (
                            <Button
                              onClick={() => setAccept(true)}
                              className="success"
                              icon={<CheckOutlined />}
                            >
                              Accept User Request
                            </Button>
                          ) : (
                            <>
                              {formType === "meroshare" &&
                                user?.details?.form?.[`${formType}_status`] ===
                                "Accepted" ? (
                                <Button
                                  onClick={() => setAccept(true)}
                                  className="primary"
                                  icon={<CheckOutlined />}
                                >
                                  Complete User Form
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => setAccept(true)}
                                  className="primary"
                                  icon={<CheckOutlined />}
                                  disabled
                                >
                                  Accepted
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}
                  </Col>
                  <Col>
                    <Button
                      loading={downloadingPdf}
                      onClick={() => {
                        setDownloadingPdf(true);
                        onDownload({
                          id,
                          target: type,
                          loaderCB: () => setDownloadingPdf(false),
                        });
                      }}
                      type="default"
                      icon={<DownloadIcon style={{ fill: "gray" }} />}
                    >
                      Download User Info
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      loading={generatingPdf}
                      onClick={() => {
                        dispatch(generatePdf({
                          oid: id,
                          target: type
                        }))
                      }}
                      type="default"
                      // icon={<DownloadIcon style={{ fill: "gray" }} />}
                      icon={<ReloadOutlined />}
                    >
                      Regenerate PDF
                    </Button>
                  </Col>
                  <Col hidden={completeRequest}>
                    <Button
                      disabled={details?.[`archived_${type}`]}
                      type="default"
                      onClick={() => onArchieveHandler(details)}
                      icon={<ArchieveIcon style={{ fill: "gray" }} />}
                    >
                      Archive
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <PageHolder
              loading={!details && detailing}
              empty={!details}
              style={{ marginTop: 40 }}
            >
              <Row gutter={[60, 40]}>
                <Col lg={24}>
                  <Card>
                    <Row
                      align="bottom"
                      justify="space-between"
                      style={{ marginBottom: "30px" }}
                    >
                      <Col>
                        <span
                          style={{
                            width: "120px",
                            height: "120px",
                            display: "block",
                            overflow: "hidden",
                            borderRadius: "100%",
                            border: "3px solid white",
                            background: "#f8f8f8",
                            boxShadow:
                              "-3px -3px 6px #ffffff, 3px 3px 6px #d3d3d3",
                          }}
                        >
                          <StorageImage
                            isProfile={true}
                            preview={true}
                            style={{
                              height: "120px",
                              objectFit: "contain",
                              minWidth: "120px",
                            }}
                            editable={true}
                            uri={
                              details?.minor
                                ? details?.minor_details?.profile
                                : details?.profile ?? ""
                            }
                          />
                        </span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography.Title level={3} style={{ marginTop: 20 }}>
                            {details?.username}
                          </Typography.Title>
                        </div>
                      </Col>

                      <Col>
                        <Button
                          hidden={EditBthHandler()}
                          type="default"
                          onClick={() => {
                            history.push(
                              `${history.location.pathname}/update-clientcode`
                            );
                            formTrading?.resetFields();
                            let updateObj: any = {
                              email: details?.email,
                              phone: details?.phone,
                              account_type: type,
                              joined_date: dayjs(details?.created_at).format(
                                "LL"
                              ),
                              status: details?.form?.[`${type}_status`],
                            };
                            if (type === "trading")
                              updateObj = {
                                ...updateObj,
                                client_code: details?.[type]?.client_code,
                              };
                            if (type === "demat")
                              updateObj = {
                                ...updateObj,
                                clientId: details?.[type]?.clientId,
                              };
                            formTrading?.setFieldsValue({
                              [type]: updateObj,
                            });
                          }}
                          icon={<EditOutlined style={{ fill: "gray" }} />}
                        >
                          Edit {type} Details
                        </Button>
                      </Col>
                    </Row>
                    {childId === "update-clientcode" ? (
                      <FormViewRenderer
                        layout="vertical"
                        form={formTrading}
                        name="basic"
                        onFinish={tradingUpdateHandler}
                        className="style--kbbs"
                        wrapperKey={tradingUserData.key}
                        formItems={tradingUserData.formFields}
                      >
                        <Form.Item>
                          <Space>
                            <Button
                              type="default"
                              onClick={() => {
                                const location =
                                  history.location.pathname?.split("/");
                                location.pop();
                                history.push(location.join("/"));
                              }}
                              htmlType="submit"
                              disabled={updating}
                            >
                              Cancel
                            </Button>
                            <Button
                              loading={updating}
                              type="primary"
                              htmlType="submit"
                            >
                              Submit
                            </Button>
                          </Space>
                        </Form.Item>
                      </FormViewRenderer>
                    ) : (
                      <>
                        <DescriptionCard data={userBasicData} columns={4} />
                      </>
                    )}
                  </Card>
                </Col>

                <Col lg={24}>
                  <Spin spinning={updating}>
                    <Card>
                      {type?.toLowerCase() !== "meroshare" && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: 15,
                          }}
                        >
                          <ImagePopover
                            image1={
                              details?.minor
                                ? details?.minor_details
                                  ?.birth_certificate_image
                                : details?.citizenship_images?.front
                            }
                            image2={
                              details?.minor
                                ? ""
                                : details?.citizenship_images?.back
                            }
                          />
                        </div>
                      )}

                      <Tabs
                        onChange={() => {
                          const location =
                            history.location.pathname?.split("/");
                          if (location.includes("update")) {
                            location.pop();
                            history.push(location.join("/"));
                          }
                        }}
                        className="abc__theme__vertical"
                        destroyInactiveTabPane
                        tabPosition="left"
                        style={{ overflow: "visible" }}
                        defaultActiveKey={defaultSelectedTab}
                      >
                        {getDetailsData()?.children?.map((user, idx) => {
                          return (
                            <TabPane
                              style={{ overflow: "visible" }}
                              tab={user.title}
                              // key={`${user.key}idx${idx}`}
                              key={`${idx}`}
                              destroyInactiveTabPane
                            >
                              {Array.isArray(user?.data) ? (
                                <Card>
                                  <Tabs
                                    onChange={() => {
                                      const location =
                                        history.location.pathname?.split("/");
                                      if (location.includes("update")) {
                                        location.pop();
                                        history.push(location.join("/"));
                                      }
                                    }}
                                    className="abc__theme__horizontal"
                                    destroyInactiveTabPane
                                  >
                                    {user?.data?.map((subUser: any, i) => {
                                      return (
                                        <TabPane
                                          tab={subUser.title}
                                          key={`${subUser.key}i${i}`}
                                        >
                                          {childId === "update" &&
                                            subUser?.formFields ? (
                                            <FormViewRenderer
                                              layout="vertical"
                                              form={form}
                                              name="basic"
                                              onFinish={detailsUpdateHandler}
                                              className="style--kbbs"
                                              wrapperKey={subUser.key}
                                              formItems={subUser.formFields}
                                            >
                                              <Form.Item>
                                                <Space>
                                                  <Button
                                                    type="default"
                                                    onClick={() => {
                                                      const location =
                                                        history.location.pathname?.split(
                                                          "/"
                                                        );
                                                      location.pop();
                                                      history.push(
                                                        location.join("/")
                                                      );
                                                    }}
                                                    htmlType="submit"
                                                    disabled={updating}
                                                  >
                                                    Cancel
                                                  </Button>
                                                  <Button
                                                    loading={updating}
                                                    type="primary"
                                                    htmlType="submit"
                                                  >
                                                    Submit
                                                  </Button>
                                                </Space>
                                              </Form.Item>
                                            </FormViewRenderer>
                                          ) : subUser?.title
                                            .toLowerCase()
                                            .includes("address") ? (
                                            <DisplayAddress
                                              title={subUser.title}
                                            />
                                          ) : subUser?.title
                                            .toLowerCase()
                                            .includes("children") ? (
                                            <DisplayChildren />
                                          ) : (
                                            <>
                                              <Row
                                                hidden={!subUser?.formFields}
                                                style={{ marginBottom: 50 }}
                                              >
                                                <Col>
                                                  <Button
                                                    onClick={() => {
                                                      history.push(
                                                        `${history.location.pathname}/update`
                                                      );
                                                      form.resetFields();
                                                      form.setFieldsValue({
                                                        [subUser.key]:
                                                          getFormDataReady(
                                                            subUser.data
                                                          ),
                                                      });
                                                    }}
                                                    type="default"
                                                    icon={
                                                      <EditOutlined
                                                        style={{ fill: "gray" }}
                                                      />
                                                    }
                                                  >
                                                    Edit {subUser.title}
                                                  </Button>
                                                </Col>
                                              </Row>
                                              <DescriptionCard
                                                title={subUser.title}
                                                data={subUser.data}
                                              />
                                            </>
                                          )}
                                        </TabPane>
                                      );
                                    })}
                                  </Tabs>
                                </Card>
                              ) : (
                                <Card>
                                  {childId === "update" && user?.formFields ? (
                                    <FormViewRenderer
                                      layout="vertical"
                                      form={form}
                                      name="basic"
                                      onFinish={detailsUpdateHandler}
                                      className="style--kbbs"
                                      wrapperKey={user.key}
                                      formItems={user.formFields}
                                    >
                                      <Form.Item>
                                        <Space>
                                          <Button
                                            type="default"
                                            onClick={() => {
                                              const location =
                                                history.location.pathname?.split(
                                                  "/"
                                                );
                                              location.pop();
                                              history.push(location.join("/"));
                                            }}
                                            htmlType="submit"
                                            disabled={updating}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            loading={updating}
                                            type="primary"
                                            htmlType="submit"
                                          >
                                            Submit
                                          </Button>
                                        </Space>
                                      </Form.Item>
                                    </FormViewRenderer>
                                  ) : (
                                    <>
                                      <Row
                                        hidden={!user?.formFields}
                                        style={{ marginBottom: 50 }}
                                      >
                                        <Col>
                                          <Button
                                            onClick={() => {
                                              form.resetFields();

                                              history.push(
                                                `${history.location.pathname}/update`
                                              );
                                              form.setFieldsValue({
                                                [user.key]: getFormDataReady(
                                                  user.data
                                                ),
                                              });
                                            }}
                                            type="default"
                                            icon={
                                              <EditOutlined
                                                style={{ fill: "gray" }}
                                              />
                                            }
                                          >
                                            Edit {user.title}
                                          </Button>
                                        </Col>
                                      </Row>
                                      <Row
                                        hidden={user?.key !== "video-kyc"}
                                        style={{ marginBottom: 50 }}
                                      >
                                        <Col lg={4}>
                                          <Button
                                            onClick={() => {
                                              setDisplayReschedule(true);
                                              setDisplayAccept(false);
                                            }}
                                            type="default"
                                            style={{ width: "90%" }}
                                          >
                                            Reschedule
                                          </Button>
                                        </Col>
                                        <Col lg={4}>
                                          <Button
                                            onClick={() => {
                                              setDisplayAccept(true);
                                              setDisplayReschedule(false);
                                              setAcceptReject(true);
                                              setAcceptReason("");
                                            }}
                                            type="default"
                                            style={{ width: "90%" }}
                                          >
                                            Accept
                                          </Button>
                                        </Col>
                                        <Col lg={4}>
                                          <Button
                                            onClick={() => {
                                              setDisplayAccept(true);
                                              setDisplayReschedule(false);
                                              setAcceptReject(false);
                                              setAcceptReason("");
                                            }}
                                            type="default"
                                            style={{ width: "90%" }}
                                          >
                                            Reject
                                          </Button>
                                        </Col>
                                        <Button
                                          onClick={() => setVerifyKyc(true)}
                                          className="success"
                                          icon={<CheckOutlined />}
                                        >
                                          Verify KYC
                                        </Button>

                                        <Button
                                          onClick={() => setRejectKyc(true)}
                                          type="primary"
                                          danger
                                          icon={<CloseOutlined />}
                                          style={{ marginLeft: 20 }}
                                        >
                                          Reject KYC
                                        </Button>

                                        {displayReschedule && (
                                          <>
                                            <Col
                                              lg={12}
                                              style={{ marginTop: 20 }}
                                            >
                                              <DatePicker
                                                showTime
                                                format={"YYYY-MM-DD HH:mm:ss"}
                                                style={{ width: "90%" }}
                                                value={scheduledDate}
                                                onChange={(
                                                  date: any,
                                                  dateString: string
                                                ) =>
                                                  handleDateChange(
                                                    date,
                                                    dateString
                                                  )
                                                }
                                                // disabledDate={
                                                //   disabledDateValidation
                                                // }
                                                disabledDate={scheduleKycDateValidation}
                                                disabledTime={scheduleKycTimeValidation}
                                              />
                                            </Col>
                                            <Col lg={15}>
                                              <TextArea
                                                value={reasonToReschedule}
                                                onChange={(e) =>
                                                  setReason(e.target.value)
                                                }
                                                style={{ marginTop: 20 }}
                                                rows={4}
                                                placeholder="Please enter your reason to reschedule"
                                              />
                                            </Col>
                                            <Col lg={4}>
                                              <Button
                                                onClick={() => {
                                                  const formattedDate = dayjs(
                                                    scheduledDate
                                                  ).format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  );
                                                  dispatch(
                                                    rescheduleVideoKyc({
                                                      formId: id,
                                                      dateTime: formattedDate,
                                                      reason:
                                                        reasonToReschedule,
                                                      video_kyc_link: "",
                                                      id: loggedInUser.details
                                                        .id,
                                                      email:
                                                        loggedInUser.details
                                                          .email,
                                                      admin_name:
                                                        loggedInUser.details
                                                          .full_name,
                                                      time: dayjs(
                                                        new Date()
                                                      ).format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      ),
                                                    }),
                                                  );

                                                }}
                                                type="default"
                                                disabled={
                                                  scheduledDate == null ||
                                                  reasonToReschedule.length <= 0
                                                }
                                                loading={rescheduling}
                                                style={{
                                                  marginTop: 20,
                                                  marginLeft: 20,
                                                }}
                                              >
                                                Confirm
                                              </Button>
                                            </Col>
                                          </>
                                        )}
                                        {displayAccept && (
                                          <>
                                            <Col lg={15}>
                                              <TextArea
                                                value={reasonToAcceptReject}
                                                onChange={(e) =>
                                                  setAcceptReason(
                                                    e.target.value
                                                  )
                                                }
                                                style={{ marginTop: 20 }}
                                                rows={4}
                                                placeholder={`Please enter your reason to ${trackAccept
                                                  ? "accept"
                                                  : "reject"
                                                  }`}
                                              />
                                            </Col>
                                            <Col lg={4}>
                                              <Button
                                                onClick={() => {
                                                  dispatch(
                                                    acceptRejectVideoKyc({
                                                      oid: id,
                                                      approved_datetime: dayjs(
                                                        new Date(
                                                          videoKyc?.approved_datetime ||
                                                          videoKyc?.preferred_date_time
                                                        )
                                                      ).format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      ),
                                                      status: trackAccept
                                                        ? "accepted"
                                                        : "rejected",
                                                      reason:
                                                        reasonToAcceptReject,
                                                      video_kyc_link: "",
                                                      accept: trackAccept,
                                                      email:
                                                        loggedInUser.details
                                                          .email,
                                                      time: dayjs(
                                                        new Date()
                                                      ).format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      ),
                                                      admin_name:
                                                        loggedInUser.details
                                                          .full_name,
                                                    })
                                                  );
                                                  setAcceptReason("");
                                                }}
                                                type="default"
                                                disabled={
                                                  reasonToAcceptReject.length <=
                                                  0
                                                }
                                                loading={acceptingOrRejecting}
                                                style={{
                                                  marginTop: 20,
                                                  marginLeft: 20,
                                                }}
                                              >
                                                Confirm
                                              </Button>
                                            </Col>
                                          </>
                                        )}
                                      </Row>

                                      {user?.key === "video-kyc" && (
                                        <>
                                          <Typography.Title
                                            style={{ fontSize: "20px" }}
                                          >
                                            Video KYC
                                          </Typography.Title>

                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              flexWrap: "nowrap",
                                              overflowY: "scroll",
                                            }}
                                          >
                                            {videoKyc?.pre_signed?.map(
                                              (item: string) => {
                                                return (
                                                  <div>
                                                    <VideoPlayer src={item} />
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </>
                                      )}

                                      <DescriptionCard
                                        // title={user.title}
                                        data={user.data}
                                        videoKyc={videoKyc}
                                        userDetails={details}
                                      />
                                      {user.key === "video-kyc" && (
                                        <Row>
                                          <Col lg={24}>
                                            <Dragger
                                              customRequest={dummyRequest}
                                              name="file"
                                              multiple={false}
                                              beforeUpload={(file) =>
                                                setVideoFileList(file)
                                              }
                                              disabled={
                                                videoFileList ? true : false
                                              }
                                              onChange={(info: any) =>
                                                setFileList(info.fileList)
                                              }
                                              fileList={fileList}
                                            >
                                              <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                              </p>
                                              <p className="ant-upload-text">
                                                Click or drag file to this area
                                                to upload
                                              </p>
                                              <p className="ant-upload-hint">
                                                Support for a single or bulk
                                                upload. Strictly prohibited from
                                                uploading company data or other
                                                banned files.
                                              </p>
                                            </Dragger>
                                          </Col>
                                          <Col lg={24}>
                                            <Button
                                              style={{
                                                marginTop: videoFileList
                                                  ? 30
                                                  : 20,
                                              }}
                                              onClick={handleUploadVideo}
                                              disabled={!videoFileList}
                                              loading={videoUploadLoading}
                                            >
                                              Upload recordings
                                            </Button>
                                          </Col>
                                          <Col lg={24}>
                                            <div
                                              style={{
                                                marginTop: 15,
                                              }}
                                            >
                                              <Tabs
                                                defaultActiveKey="1"
                                                className="abc__theme__horizontal"
                                                destroyInactiveTabPane
                                              >
                                                <Tabs.TabPane
                                                  tab="Reschedule Logs"
                                                  key="1"
                                                  destroyInactiveTabPane
                                                >
                                                  <KycLogs
                                                    logs={
                                                      videoKyc &&
                                                      videoKyc?.rescheduled_video_kycs
                                                    }
                                                    tab='reschedule'
                                                  />
                                                </Tabs.TabPane>
                                                <Tabs.TabPane
                                                  tab="Accepted logs"
                                                  key={"2"}
                                                  destroyInactiveTabPane
                                                >
                                                  <KycLogs
                                                    logs={
                                                      videoKyc &&
                                                      videoKyc?.acceptedLogs
                                                    }
                                                  />
                                                </Tabs.TabPane>
                                                <Tabs.TabPane
                                                  tab="Reject"
                                                  key="3"
                                                  destroyInactiveTabPane
                                                >
                                                  <KycLogs
                                                    logs={
                                                      videoKyc &&
                                                      videoKyc?.rejectedLogs
                                                    }
                                                  />
                                                </Tabs.TabPane>
                                              </Tabs>
                                            </div>
                                          </Col>
                                        </Row>
                                      )}
                                    </>
                                  )}
                                  {user?.key === "watchlist" && (
                                    <UserWatchList
                                      userId={details?.id}
                                      watchlist={historyWatchlist}
                                    />
                                  )}
                                </Card>
                              )}
                            </TabPane>
                          );
                        })}
                      </Tabs>
                      {getDetailsData()?.children?.length < 1 ||
                        (!getDetailsData()?.children && <Empty />)}
                    </Card>
                  </Spin>
                </Col>
              </Row>
            </PageHolder>
          </Content>
        </PageLoading>
        <Modal
          destroyOnClose
          footer={false}
          onCancel={() => {
            setAccept(false);
            setReject(false);
          }}
          visible={accept || reject}
        >
          <Typography.Title level={4}>
            {user?.details?.form?.[`${formType}_status`] === "Accepted"
              ? "Do you want to complete this form?"
              : "Do you want to accept this form?"}
          </Typography.Title>
          <Form
            style={{ marginTop: 20 }}
            layout="vertical"
            onFinish={onRespond}
            onReset={onRespond}
            form={acceptForm}
          >
            {reject && (
              <Form.Item
                name="reason"
                label="Reason"
                rules={[{ required: true, message: "Reason must be provided" }]}
              >
                <Input.TextArea
                  style={{ minHeight: 100, padding: "10px 15px" }}
                  placeholder="Please enter reason"
                />
              </Form.Item>
            )}
            <Form.Item
              hidden
              name="account"
              label="Account"
              initialValue={type}
            >
              <Input />
            </Form.Item>
            <Row justify={accept ? "center" : "end"} gutter={[20, 20]}>
              <Col>
                <Button
                  htmlType="button"
                  onClick={() => {
                    setReject(false);
                    setAccept(false);
                  }}
                  type={reject ? "default" : "primary"}
                  danger={accept}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="button"
                  onClick={() =>
                    onRespond(
                      accept
                        ? user?.details?.form?.[`${formType}_status`] ===
                          "Accepted"
                          ? "completed"
                          : "accepted"
                        : "rejected"
                    )
                  }
                  className={reject ? "" : "success"}
                  type={reject ? "default" : "primary"}
                  danger={reject}
                >
                  {accept
                    ? user?.details?.form?.[`${formType}_status`] === "Accepted"
                      ? "Complete"
                      : "Accept"
                    : "Reject"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
        <AcceptRejectModal
          setVerifyKyc={setVerifyKyc}
          setRejectKyc={setRejectKyc}
          displayAcceptModal={verifyKyc}
          displayRejectModal={rejectKyc}
          kycRejectReason={reasonToVerifyReject}
          setKycRejectReason={setVerifyReject}
          acceptForm={acceptForm}
          loading={verifying}
          onSubmit={onVerifyReject}
        />
      </Suspense>
    </MyContent>
  );
};

export default UserDetails;
