import { FormItemTypes } from 'Types/Types';

import {
  accountOptions,
  accountStatementOptions,
  businessTypeOptions,
  designationOptions,
  genderOptions,
  maritialOptions,
  nomineeOptions,
  occupationOptions,
  relationshipOptions,
  yesNoOptions,
} from './selectDefaultOptions';

//DEMAT KO MATRA ETA

export const GeneralDetailsFields: FormItemTypes[] = [
  // {
  //   label: "Full Name",
  //   type: "text",
  //   name: "full_name",
  //   required: true,
  //   span: 12,
  // },
  {
    label: "User's Name",
    type: 'text',
    name: 'username',
    required: true,
    span: 12,
  },
  {
    label: 'Date of Birth',
    type: 'date',
    name: 'dob',
    required: true,
    span: 12,
  },

  {
    label: 'NATIONALITY',
    type: 'text',
    name: 'nationality',
    required: true,
    span: 12,
  },
  {
    label: 'GENDER',
    type: 'radio',
    name: 'gender',
    required: true,
    span: 24,
    options: genderOptions,
  },
  {
    label: 'Citizenship Number',
    type: 'text',
    name: 'citizenship_id',
    required: true,
    span: 8,
  },
  {
    label: 'Issued District',
    type: 'select', //select maybe?
    name: 'issued_district',
    required: true,
    span: 8,
    remoteRoute: 'address',
    selectSearchable: true,
  },
  {
    label: 'ISSUED DATE',
    type: 'date',
    name: 'issued_date',
    required: true,
    span: 8,
  },
  {
    label: 'Reference Number',
    type: 'text',
    name: 'reference_number',
    required: true,
    span: 12,
  },
  {
    label: 'Pan number',
    type: 'text',
    name: 'pan_number',
    required: false,
    span: 12,
  },
]

export const guardianDetailsFields: FormItemTypes[] = [
  {
    label: 'NAME',
    type: 'text',
    name: 'full_name',
    required: true,
    span: 12,
  },
  {
    label: 'CITIZENSHIP ID',
    type: 'text',
    name: 'citizenship_id',
    required: true,
    span: 12,
  },
  {
    label: 'PHONE',
    type: 'text',
    name: 'phone',
    required: true,
    span: 12,
  },
  {
    label: 'RELATIONSHIP WITH APPLICANT',
    type: 'select',
    name: 'relation_with_applicant',
    required: true,
    span: 12,
    options: relationshipOptions,
  },
  {
    label: 'GENDER',
    type: 'select',
    name: 'gender',
    required: true,
    span: 12,
    options: genderOptions,
  },
  {
    label: 'Reference Number',
    type: 'text',
    name: 'reference_number',
    required: true,
    span: 12,
  },
  {
    label: 'Pan number',
    type: 'text',
    name: 'pan_number',
    required: false,
    span: 12,
  },
]

export const minorGeneralDetailsFields: FormItemTypes[] = [
  // {
  //   label: "Name",
  //   type: "text",
  //   name: "name",
  //   required: true,
  //   span: 12,
  // },
  {
    label: "User's Name",
    type: 'text',
    name: 'username',
    required: true,
    span: 12,
  },
  {
    label: 'Date of Birth',
    type: 'date',
    name: 'dob',
    required: true,
    span: 12,
  },

  {
    label: 'GENDER',
    type: 'radio',
    name: 'gender',
    required: true,
    span: 24,
    options: genderOptions,
  },
  {
    label: 'Birth Certificate Number',
    type: 'text',
    name: 'birth_certificate_number',
    required: true,
    span: 8,
  },
  {
    label: 'Issued District',
    type: 'select', //select maybe?
    name: 'issued_district',
    required: true,
    span: 8,
    remoteRoute: 'address',
    selectSearchable: true,
  },
  {
    label: 'ISSUED DATE',
    type: 'date',
    name: 'issued_date',
    required: true,
    span: 8,
  },
]

export const dematFamilyDetails: FormItemTypes[] = [
  {
    label: "FATHER'S NAME",
    type: 'text',
    name: 'father_name',
    required: true,
    span: 12,
  },
  {
    label: "MOTHER'S NAME",
    type: 'text',
    name: 'mother_name',
    required: true,
    span: 12,
  },
  {
    label: "GRANDFATHER'S NAME",
    type: 'text',
    name: 'grandfather_name',
    required: true,
    span: 12,
  },
  {
    label: 'MARITAL STATUS',
    type: 'radio',
    name: 'married',
    required: true,
    span: 24,
    options: maritialOptions,
  },

  {
    label: 'SPOUSE',
    type: 'text',
    name: 'spouse_name',
    required: true,
    span: 12,
  },
]

export const DematOccupationDetails: FormItemTypes[] = [
  {
    label: 'Occupation Type',
    type: 'select',
    name: 'occupation_type',
    required: true,
    span: 12,
    options: occupationOptions,
  },
  {
    label: 'Business Type',
    type: 'select',
    name: 'business_type',
    required: false,
    span: 12,
    options: businessTypeOptions,
  },
  {
    label: 'Organization',
    type: 'autocomplete',
    name: 'organization', //remote bata aucha
    required: false,
    span: 12,
    remoteRoute: 'organization',
    selectSearchable: true,
  },
  {
    label: 'Designation',
    type: 'select',
    name: 'designation',
    required: false,
    span: 12,
    options: designationOptions,
  },
]

export const dematFinanceDetails: FormItemTypes[] = [
  {
    label: 'Finance details',
    type: 'number',
    name: 'income',
    required: true,
    span: 12,
    options: designationOptions,
  },
  {
    label: 'Standing Instructions for Automatic Transactions',
    type: 'radio',
    name: 'stan_auto_trans',
    required: false,
    options: yesNoOptions,
    span: 12,
  },
  {
    label: 'Statement',
    type: 'select',
    name: 'statement',
    required: true,
    span: 12,
    options: accountStatementOptions,
  },
]

export const DematBankDetail = () => {
  const myArray: FormItemTypes[] = [
    {
      label: 'BANK NAME',
      type: 'select', //remote from db
      name: 'bank_name',
      required: true,
      span: 12,
      remoteRoute: 'bank',
      selectSearchable: true,
    },
    {
      label: 'BRANCH NAME',
      type: 'select',
      name: 'branch', //remote after filtering according to bank name
      required: true,
      span: 12,
      remoteRoute: 'branch',
      selectSearchable: true,
    },
    {
      label: 'ACCOUNT NUMBER',
      type: 'text',
      name: 'account_number',
      required: true,
      span: 12,
    },
    {
      label: 'ACCOUNT TYPE',
      type: 'select',
      name: 'account_type',
      required: true,
      span: 12,
      options: accountOptions,
    },
  ]
  return myArray
}

export const dematNomineeStatus: FormItemTypes[] = [
  {
    label: 'Nominee Status',
    type: 'radio',
    name: 'nominee',
    required: true,
    span: 12,
    options: nomineeOptions,
  },
]

export const dematNomineeDetails: FormItemTypes[] = [
  {
    label: 'NAME OF NOMINEE',
    type: 'text',
    name: 'name',
    required: false,
    span: 12,
  },
  {
    label: 'RELATIONSHIP',
    type: 'select',
    options: relationshipOptions,
    name: 'relationship',
    required: false,
    span: 12,
  },
  {
    label: 'AGE',
    type: 'number',
    name: 'age',
    required: false,
    span: 12,
  },
  {
    label: 'EMAIL_MOBILE',
    type: 'text',
    name: 'email_mobile',
    required: false,
    span: 12,
  },
]
