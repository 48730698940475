import { Components } from 'Layouts/Components'
import { Route, Switch } from 'react-router'
import AuthRoute from './AuthRoute'
import DashboardRoute from './DashboardRoute'
import ProtectedRoute from './ProtectedRoute'

export const MainRoute = () => {
  return (
    <Switch>
      <Route path='/auth' component={AuthRoute} />
      <Route path='/components' component={Components} />
      <Route path='/' component={ProtectedRoute} />
    </Switch>
  )
}
export default MainRoute
