import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space } from "antd";
import { AntForm, AntFormItem } from "Components/AntForm/AntForm";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { updateKyc } from "Redux/KycRedux/KycActions";
import { childrenOptions } from "__abc__lib/fields/UserDetails/selectDefaultOptions";

type Props = {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  childrenData: any[];
};

const ChildrenForm = ({ setIsEditing, childrenData }: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams<any>();
  const updateHandler = (values: any) => {
    const family_details = {
      ...values,
    };
    dispatch(updateKyc({ ...{ family_details }, oid: id }));
    setIsEditing(false);
  };
  useEffect(() => {
    form.setFieldsValue({
      children: childrenData,
    });
  }, [childrenData]);
  return (
    <AntForm layout={"vertical"} onFinish={updateHandler} form={form}>
      <Form.List name="children">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="center" style={{ width: "100%" }}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.children !== curValues.children
                  }
                >
                  {() => (
                    <AntFormItem
                      {...field}
                      label="Relation"
                      name={[field.name, "relation"]}
                      rules={[
                        { required: true, message: "Select a relationship" },
                      ]}
                    >
                      <Select
                        style={{
                          width: 130,
                        }}
                      >
                        {childrenOptions.map((item) => (
                          <Select.Option key={item.label} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </AntFormItem>
                  )}
                </Form.Item>
                <AntFormItem
                  {...field}
                  label="Name"
                  name={[field.name, "name"]}
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input />
                </AntFormItem>

                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                style={{ width: 355 }}
              >
                Add Relation
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Space>
          <Button
            type="default"
            onClick={() => setIsEditing(false)}
            htmlType="submit"
          >
            Cancel
          </Button>
          <Button loading={false} type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form.Item>
    </AntForm>
  );
};

export default ChildrenForm;
