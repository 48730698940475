import { message } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
	createUserFailure,
	createUserSuccess,
	deleteUserFailure,
	deleteUserSuccess,
	fetchUserFailure,
	fetchUsersFailure,
	fetchUsersSuccess,
	fetchUserSuccess,
	updateUserFailure,
	updateUserSuccess
} from './UserActions';
import { cutUser, makeUser, modifyUser, takeUser, takeUsers } from './UserApis';
import {
	CreateUserParams,
	CREATE_USER,
	REMOVE_USER,
	FETCH_USERS,
	FETCH_USER,
	UPDATE_USER,
	UserDetailsParams,
	UserFetchParams
} from './UserTypes';

//get user
const getUsersAsync = async (data: UserFetchParams) => await takeUsers(data);

function* getUsers(params: any) {
	const { payload } = params;
	try {
		const Users: AxiosResponse = yield call(getUsersAsync, payload);
		if (Users) {
			yield put(fetchUsersSuccess(Users.data));
		} else {
			yield put(fetchUsersFailure('Could not get list of user'));
			message.error('Could not get list of user');
		}
	} catch (error) {
		yield put(fetchUsersFailure('Network Error while getting Users'));
		message.error('Could not get list of user');
	}
}

function* watchGetUsers() {
	yield takeLatest(FETCH_USERS, getUsers);
}

//get user details
const getUserAsync = async (data: UserDetailsParams) => await takeUser(data);

function* getUser(params: any) {
	const { payload } = params;
	try {
		const Users: AxiosResponse = yield call(getUserAsync, payload);
		if (Users) {
			yield put(fetchUserSuccess(Users.data));
		} else {
			yield put(fetchUserFailure('Could not get list of user'));
			message.error('Could not get list of user');
		}
	} catch (error) {
		yield put(fetchUserFailure('Network Error while getting Users'));
		message.error('Could not get list of user');
	}
}

function* watchGetUser() {
	yield takeLatest(FETCH_USER, getUser);
}

//create User
const generateUserAsync = async (data: CreateUserParams) => await makeUser(data);

function* generateUser(params: any) {
	const { payload } = params;
	try {
		const Users: AxiosResponse = yield call(generateUserAsync, payload);
		if (Users) {
			yield put(createUserSuccess(Users.data));
			message.success('User created successfully');
		} else {
			const messages = 'Could not create User';
			yield put(createUserFailure(messages));
			message.error(messages);
		}
	} catch (error) {
		const messages = 'Network Error while creating User';
		yield put(createUserFailure(messages));
		message.success(messages);
	}
}

function* watchGenerateUser() {
	yield takeLatest(CREATE_USER, generateUser);
}

//update User
const amendUserAsync = async (data: CreateUserParams) => await modifyUser(data);

function* amendUser(params: any) {
	const { payload } = params;
	try {
		const Users: AxiosResponse = yield call(amendUserAsync, payload);
		if (Users) {
			yield put(updateUserSuccess(Users.data));
			message.success('User updated successfully');
		} else {
			const messages = 'Could not Update User';
			yield put(updateUserFailure(messages));
			message.error(messages);
		}
	} catch (error) {
		const messages = 'Network Error while updating User';
		yield put(updateUserFailure(messages));
		message.error(messages);
	}
}

function* watchAmendUser() {
	yield takeLatest(UPDATE_USER, amendUser);
}

//Delete User
const removeUserAsync = async (data: UserDetailsParams) => await cutUser(data);

function* removeUser(params: any) {
	const { payload } = params;
	try {
		const Users: AxiosResponse = yield call(removeUserAsync, payload);
		if (Users) {
			yield put(deleteUserSuccess(Users.data));
			message.success('User Deleted successfully');
		} else {
			const messages = 'Could not delete User';
			yield put(deleteUserFailure(messages));
			message.error(messages);
		}
	} catch (error) {
		const messages = 'Network Error while deleting User';
		yield put(deleteUserFailure(messages));
		message.error(messages);
	}
}

function* watchRemoveUser() {
	yield takeLatest(REMOVE_USER, removeUser);
}

export default function* UserSagas() {
	yield all([
		fork(watchGetUsers),
		fork(watchGetUser),
		fork(watchGenerateUser),
		fork(watchAmendUser),
		fork(watchRemoveUser)
	]);
}
