import React, {
  MouseEvent,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { DatePicker } from 'Components/DatePicker';
import Modal from 'Components/Modal';
import dayjs from 'dayjs';
import { CsvLogoIcon } from 'Icons/CsvIcon/CsvIcon';
import { DownloadIconDark } from 'Icons/DownloadIcon/DownloadIcon';
import { PdfLogoIcon } from 'Icons/PdfIcon/PdfIcon';
import { ReportIcon } from 'Icons/ReportIcon/ReportIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { fetchAccountList } from 'Redux/DpRedux/Accounts/AccountActions';
import {
  generatePdfExcel,
  generateReport,
} from 'Redux/DpRedux/Reports/ReportsAction';

export const GenerateReport = () => {
  const dispatch = useDispatch();

  const [userAccount, setUserAccount] = useState("");

  const [filterSearch, setFilterSearch] = useState("");

  const [selectedDate, setSelectedDate] = useState({
    start_date: "",
    end_date: "",
  });

  const [showExportModal, setShowExportModal] = useState(false);

  const { list } = useSelector(
    (state: any) => state.accountReducer
  );


  const dpReportsReducer = useSelector((state: any) => state.dpReportsReducer);

  const { generatingPdfExcel, generatedPdfExcel } = dpReportsReducer;

  useEffect(() => {
    let filterData = {}
    if (/^\d+$/.test(filterSearch)) {
      filterData = {
        boid_number: filterSearch
      }
    } else {
      filterData = {
        account_holder_name: filterSearch,
      }
    }
    dispatch(
      fetchAccountList(filterData)
    );
  }, [dispatch, filterSearch]);

  useEffect(() => {
    if (generatedPdfExcel) {
      setShowExportModal(false);
    }
  }, [generatedPdfExcel]);

  const handleGenerateReport = () => {
    let data = {
      boid_number: userAccount,
      ...selectedDate,
    };
    dispatch(generateReport(data));
  };

  const handleFileDownload = (
    e: MouseEvent<HTMLElement>,
    type: "pdf" | "excel"
  ) => {
    e.preventDefault();
    let data = {
      type: type,
      boid_number: userAccount,
      ...selectedDate,
    };

    dispatch(generatePdfExcel(data));
  };



  return (
    <>
      <Card className="generate">

        <div className='card-items'>

          <div className='generate-field'>
            <Row gutter={[16, 16]}>
              <Col lg={8}>
                <Select
                  filterOption={false}
                  showSearch
                  searchValue={filterSearch}
                  placeholder="Search by name or boid"
                  onSearch={(value: string) => {

                    setFilterSearch(value);
                  }}
                  onChange={(val) => {

                    setUserAccount(val)
                  }}
                  className='add-account'
                >
                  {
                    list.map((data: any) => {
                      return <Select.Option value={data.account_code} title={data.account_name} > {data.account_name}

                        <span style={{
                          fontSize: 12
                        }}> ({data.boid_number}) </span>


                      </Select.Option>
                    })
                  }

                </Select>
              </Col>
              <Col lg={6}>
                <DatePicker.RangePicker
                  onChange={(dates) => {
                    if (dates?.[0] && dates?.[1]) {
                      setSelectedDate({
                        start_date: dayjs(dates?.[0]?.toString()).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: dayjs(dates?.[1]?.toString()).format(
                          "YYYY-MM-DD"
                        ),
                      });
                    } else {
                      setSelectedDate({
                        start_date: "",
                        end_date: "",
                      });
                    }
                  }}
                  className='date-range'
                />
              </Col>
              <Col lg={4}>
                <Button
                  htmlType="submit"
                  className="success generate-btn"
                  icon={<ReportIcon />}
                  onClick={handleGenerateReport}
                  disabled={userAccount && selectedDate.start_date ? false : true}
                >
                  Generate Report
                </Button>
              </Col>
              <Col lg={3} offset={3}>
                <Button
                  icon={<DownloadIconDark />}
                 className='export-btn'
                  onClick={() => setShowExportModal(true)}
                  disabled={userAccount && selectedDate.start_date ? false : true}
                >
                  <Typography.Text>{"Export"}</Typography.Text>
                </Button>
              </Col>
            </Row>




          </div>

        </div>
      </Card>
      <Modal
        visible={showExportModal}
        width={"30vw"}
        className="exportModal"
        onCancel={() => {
          setShowExportModal(false);
        }}
      >
        <Space
          direction="vertical"
          align="center"
          size={"large"}
          className="content"
        >
          <Spin spinning={generatingPdfExcel}>
            <Typography.Title level={4}> Choose File Format</Typography.Title>
            <Row>
              <Button
                className="pdf-btn"
                onClick={(e) => handleFileDownload(e, "pdf")}
              >
                <Space>
                  <PdfLogoIcon />
                  <Typography.Text>PDF</Typography.Text>
                </Space>
              </Button>
              <Button
                className="csv-btn"
                onClick={(e) => handleFileDownload(e, "excel")}
              >
                <Space>
                  <CsvLogoIcon />
                  <Typography.Text>CSV</Typography.Text>
                </Space>
              </Button>
            </Row>
          </Spin>
        </Space>
      </Modal>
    </>
  );
};
