import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import MyContent from 'Components/Content/Content';
import Loading from 'Components/Loading/Loading';
import PageHolder from 'Components/PageHolder/PageHolder';
import { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { fetchKyc } from 'Redux/KycRedux/KycActions';

export const KycDetails = (props: any) => {
	const kyc = useSelector((state: any) => state.KycReducer);
	const { id } = useParams<any>();
	const { detailing, details } = kyc;
	const dispatch = useDispatch();

	const history = useHistory();
	useEffect(() => {
		if (details?.team?.id) {
			dispatch(fetchKyc({ id: details?.team?._id }))
		}
	}, [details])

	useEffect(
		() => {
			if (id) {
				dispatch(fetchKyc({ id: id }));
			}
		},
		[id]
	);


	return (
		<MyContent>
			<Suspense fallback={<Loading fullScreen />}>
				<Content>
					<Space align="center" size="large">

						<Typography.Title level={5} >{details?.title}</Typography.Title>
						<Button onClick={() => history.push(`${details?.id}/edit`)} shape="circle" size="small" icon={<EditOutlined />} />

					</Space>
					<Divider />
					<PageHolder loading={detailing} empty={!details}>
						<Row gutter={[60, 20]}>
							<Col lg={24}>
								this is kyc details

							</Col>
						</Row>
					</PageHolder>
				</Content>
			</Suspense>
		</MyContent>

	);
};

export default KycDetails;
