import { Button, Divider, Input, Space, Row, Col, Card, Menu, Layout, Select } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { EditOutlined, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { AntForm, AntFormItem } from 'Components/AntForm/AntForm';
import { useHistory } from 'react-router';
import Sider from 'antd/lib/layout/Sider';
import DashboardIcon from 'Icons/DashboardIcon/DashboardIcon';
import CompanyLogo from 'Icons/CompanyLogo/CompanyLogo';

export const Components = () => {
    const [form] = useForm();
    const [collapsed, setCollapsed] = useState(false);

    const history = useHistory();


    // const menu = (
    //     <Menu>
    //         <Menu.Item>
    //             <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
    //                 1st menu item
    //             </a>
    //         </Menu.Item>
    //         <Menu.Item icon={<DownOutlined />} disabled>
    //             <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
    //                 2nd menu item (disabled)
    //             </a>
    //         </Menu.Item>
    //         <Menu.Item disabled>
    //             <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
    //                 3rd menu item (disabled)
    //             </a>
    //         </Menu.Item>
    //         <Menu.Item danger>a danger item</Menu.Item>
    //     </Menu>
    // );
    return (
        <Content>
            <Divider />
            <Space>
                <Button type="default" icon={<EditOutlined />}>
                    Text
                </Button>
                <Button type="primary" icon={<EditOutlined />}>
                    Text
                </Button>
                <Button type="primary">Text</Button>
                <Button type="primary" danger>
                    Text
                </Button>
                <Button type="primary" className="success">
                    Text
                </Button>
            </Space>
            <Divider />
            <Divider />
            <Space>
                <Input />
                <Input />
            </Space>

            <Row>
                <Col lg={6}>
                    <Card>
                        <AntForm
                            onFinish={(valuse: any) => console.log(valuse)}
                            layout="vertical"
                            form={form}
                        >
                            <AntFormItem
                                name="name"
                                label="Name">
                                <Input placeholder="Prakash G." />
                            </AntFormItem>
                            <AntFormItem rules={[{ required: true, message: 'Name is Required' }]} name="name" label="Name">
                                <Input.Password type="email" placeholder="Prakash G." />
                            </AntFormItem>
                            <Button htmlType="submit" type="default">
                                Submit
                            </Button>
                        </AntForm>
                    </Card>
                </Col>
            </Row>
            <Divider />

            <Row justify="center" style={{ padding: '40px' }}>
                <Col lg={12}>
                    <Layout>
                        <Sider
                            collapsible
                            collapsed={collapsed}
                            collapsedWidth={115}
                            theme="light"
                            style={{ maxHeight: '100vh' }}
                            width={300}
                            onCollapse={(e: any) => setCollapsed(e)}
                        >
                            <Row justify="space-between">
                                <Col>
                                    <div className={`logo ${collapsed ? 'collapsed' : ''}`}>
                                        <CompanyLogo />
                                    </div>

                                </Col>
                                <Col>
                                    <div className={`collapse--switch ${collapsed ? 'position--fixed' : 'position--absolute'}`} onClick={() => setCollapsed(!collapsed)}>
                                        <div className="collapse--switch--wrapper">
                                            {collapsed ? (
                                                <RightCircleFilled style={{ fontSize: 28, color: '#4C5064' }} />

                                            ) : (
                                                <LeftCircleFilled style={{ fontSize: 28, color: '#4C5064' }} />

                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {/* <div style={{ width: 200, height: 40, background: '#cccccc', margin: '00px 0px 20px 0px' }} /> */}
                            <Menu
                                theme="light"
                                mode="inline"
                                style={{ marginTop: 40 }}
                                defaultSelectedKeys={['1']}
                                onClick={(e: any) => history.push(e.key)}
                            >
                                <Menu.Item key="1" title="Text" icon={<DashboardIcon />}>Dashboard</Menu.Item>
                                <Menu.Item key="7" icon={<DashboardIcon />}>User Listing</Menu.Item>
                                <Menu.Item key="3" icon={<DashboardIcon />}>Admin Management</Menu.Item>
                                <Menu.Item key="4" icon={<DashboardIcon />}>Batch File</Menu.Item>


                            </Menu>
                        </Sider>
                    </Layout>
                </Col>
                <Col lg={8} offset={2}>
                    <Card>
                        <Space>
                            <Select style={{ minWidth: 200 }}>
                                <Select.Option value="test">Test</Select.Option>
                                <Select.Option value="test1">Test</Select.Option>
                                <Select.Option value="test2">Test</Select.Option>
                                <Select.Option value="test3">Test</Select.Option>
                                <Select.Option value="test4">Test</Select.Option>
                            </Select>
                            <FloatLabel label="Search">
                                <Select showSearch style={{ minWidth: 200 }}>
                                    <Select.Option value="test">Test</Select.Option>
                                    <Select.Option value="test1">Test</Select.Option>
                                    <Select.Option value="test2">Test</Select.Option>
                                    <Select.Option value="test3">Test</Select.Option>
                                    <Select.Option value="test4">Test</Select.Option>
                                </Select>
                            </FloatLabel>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};
