import {
  CreateAdminParams,
  AdminDetailsParams,
  AdminFetchParams,
} from './AdminTypes'
import { API } from 'aws-amplify'
import { ApiHeaders, API_NAME } from 'Api/Api'
//get Admin
export const takeAdmins = (data: AdminFetchParams) => {
  return API.get('adminApi', '/users/list', {
    headers: ApiHeaders,
    queryStringParameters: data,
  })
}

//get Admin Details
export const takeAdmin = (data: AdminDetailsParams) =>
  API.get('adminApi', `/users/${data?.id}`, { headers: ApiHeaders, data: data })

//create Admin Details
export const makeAdmin = (data: CreateAdminParams) =>
  API.post('adminApi', '/users', { headers: ApiHeaders, body: data })

//modify Admin
export const modifyAdmin = async (data: any) => {
  const url = data?.logined_user ? `update-profile` : `users/${data?.id}`
  await delete data?.logined_user
  return await API.patch('adminApi', `/${url}`, {
    headers: ApiHeaders,
    body: data,
  })
}

//delete Admin
export const cutAdmin = (data: AdminDetailsParams) =>
  API.del('adminApi', `/users/${data?.id}`, { headers: ApiHeaders, data: data })
