export type Address_Data = { label: string; value: any };

export const getAddressData = (data: any): Address_Data[] => {
  const payload: Address_Data[] = [
    { label: "Municipality", value: data?.sub },
    { label: "Country", value: data?.country },
    { label: "Province", value: data?.province },
    { label: "District", value: data?.district },
    { label: "Tole", value: data?.tole },
    { label: "Ward No", value: data?.ward },
    { label: "Nearest Landmark", value: data?.nearest_landmark },
    { label: "Fax", value: data?.fax },
    { label: "Block", value: data?.block },
    { label: "Address Code", value: data?.address_code },
  ];
  return payload;
};
