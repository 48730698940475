import { Route, Switch } from "react-router";
import VideoKycRequests from "Views/VerifyKycRequests";

export const VerifyKycRequestRoute = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match?.path}`} exact component={VideoKycRequests} />
    </Switch>
  );
};

export default VerifyKycRequestRoute;
