import { useEffect } from 'react'

const RouteGuard = (props: any) => {
  if (props.authState === 'signIn') {
    window.location.replace('/auth')
  }

  return <>{props.authState === 'signedIn' && props.children}</>
}

export default RouteGuard
