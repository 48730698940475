import { Form, FormItemProps, FormProps } from 'antd'
import FloatLabel from 'Components/FloatLabel/FloatLabel'

let initialState: any = {}

export const AntForm: React.FC<FormProps> = ({ children, ...rest }) => {
  return (
    <Form
      {...rest}
      onChange={() => (initialState = rest.form?.getFieldsValue())}
    >
      {children}
    </Form>
  )
}

export const AntFormItem: React.FC<FormItemProps> = ({ children, ...rest }) => {
  const name: any = rest.name || ''
  return (
    <FloatLabel
      label={rest?.label}
      name={rest?.name}
      value={initialState[name]}
    >
      <Form.Item {...rest} label={false}>
        {children}
      </Form.Item>
    </FloatLabel>
  )
}
