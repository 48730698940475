import React from 'react';

import {
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';

interface KycLogsProps {
  logs: { reason: string; time: string; admin_name: string }[];
  tab?: string;
}

export const KycLogs = ({ logs, tab }: KycLogsProps) => {
  const columns = [
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",

      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Admin",
      dataIndex: "admin_name",
      key: "admin_name",

      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Date",
      dataIndex: tab === "reschedule" ? "time" : "scheduled_time",
      key: tab === "reschedule" ? "time" : "scheduled_time",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>
          {" "}
          {dayjs(text).format("ddd, MMM D, YYYY h:mm A")}{" "}
        </Typography.Text>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={logs} pagination={false} scroll={{y: 320}}/>
    </div>
  );
};
