import { FormItemTypes } from 'Types/Types'

export const meroShareFormFields: FormItemTypes[] = [
  {
    label: 'DP ID',
    type: 'number',
    name: 'dpid',
    required: true,
    span: 8,
    rules: [{ len: 8, message: 'BOID must be 8 characters.' }],
  },
  {
    label: 'CLIENT ID',
    type: 'number',
    name: 'clientId',
    required: false,
    span: 8,
    rules: [{ len: 8, message: 'BOID must be 8 characters.' }],
  },
  {
    label: "User's Name",
    type: 'text',
    name: 'username',
    required: true,
    span: 8,
  },
  // {
  // 	label: 'FULL NAME',
  // 	type: 'text',
  // 	name: 'full_name',
  // 	required: true,
  // 	span: 8
  // }
]
