import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { useForm } from 'antd/lib/form/Form'
import { Auth, Storage } from 'aws-amplify'
import MyContent from 'Components/Content/Content'
import PageLoading from 'Components/PageLoading/PageLoading'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import {
  createAdmin,
  fetchAdmin,
  updateAdmin,
} from 'Redux/AdminRedux/AdminActions'

export const AdminForm = (props: any) => {
  const dispatch = useDispatch()
  const admin = useSelector((state: any) => state.AdminReducer)
  const user = useSelector((state: any) => state.UserReducer)
  const { creating, updating, details, detailing } = admin
  const userRole = user?.details?.role
  const [selectedRole, setSelectedRole] = useState('User')
  const [currentUser, setCurrentUser] = useState('')
  const { update } = props

  const { id } = useParams<any>()

  const [form] = useForm()

  const history = useHistory()

  const { Option } = Select

  const [profileImage, setProfileImage] = useState([])
  const [imageUrl, setImageUrl] = useState()
  const [url, setUrl] = useState('')

  const onBackHandler = () => {
    if (id !== 'add') {
      history.push(`/admin`)
    } else {
      history.push(`/admin`)
    }
  }

  const initialValues = {
    full_name: '',
    role: 'User',
    phone_number: '',
    email: '',
  }

  const getData = async () => {
    const user = await Auth.currentAuthenticatedUser()
    setCurrentUser(user?.attributes?.[`custom:id`])
  }

  useEffect(() => {
    getData()
  }, [])
  const onFinish = async (values: any) => {
    const name = await Auth.currentAuthenticatedUser()

    if (update) {
      if (values.profile?.file?.name) {
        const filename = values.profile.file.name
        const { key }: any = await Storage.put(
          `admin/profile/${id}/${filename}`,
          values.profile.file.originFileObj,
          {
            contentType: values.profile.file.type,
            customPrefix: {
              public: '',
            },
          }
        )

        dispatch(
          updateAdmin({
            ...values,
            id: details?.id,
            logined_user: name?.attributes['custom:id'] === details?.id,
            profile: key,
            phone_number: `+977${values.phone_number
              .replace('+977', '')
              .replace('-', '')}`,
          })
        )
      } else {
        dispatch(
          updateAdmin({
            ...values,
            id: details?.id,
            logined_user: name?.attributes['custom:id'] === details?.id,
            phone_number: `+977${values.phone_number
              .replace('+977', '')
              .replace('-', '')}`,
          })
        )
      }
    } else {
      dispatch(
        createAdmin({
          ...values,
          status: 'pending',
          phone_number: `+977${values.phone_number
            .replace('+977', '')
            .replace('-', '')}`,
        })
      )
    }
  }

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const handleChangeProfileImage = (info: any) => {
    getBase64(info.file.originFileObj, (imageUrl: any) => {
      setImageUrl(imageUrl)
    })
    setProfileImage(info.fileList)
  }

  const ProfileImage = () => (
    <Avatar
      src={imageUrl}
      size='large'
      style={{ width: '100px', height: '100px' }}
    />
  )

  const ExistingProfileImage = () => (
    <Avatar
      src={url}
      size='large'
      style={{ width: '100px', height: '100px' }}
    />
  )

  const getImageAsync = async (uri: string) => {
    await Storage.get(uri, {
      customPrefix: {
        public: '',
      },
    }).then((data) => {
      setUrl(data)
    })
  }

  const uploadButtonProfile = (
    <div>
      <div
        style={{
          borderRadius: 10,
          color: '#6A5FF6',
          fontWeight: 600,
          fontSize: '12px',
        }}
      >
        {profileImage.length > 0 ? (
          <ProfileImage />
        ) : details?.profile ? (
          <ExistingProfileImage />
        ) : (
          'Upload Image'
        )}
      </div>
    </div>
  )

  useEffect(() => {
    form.setFieldsValue({
      ...details,
    })
    setSelectedRole(details?.role)
    getImageAsync(details?.profile)
  }, [details])

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
    })
  }, [])

  useEffect(() => {
    if (!admin?.detailed && id && id !== 'add') {
      dispatch(fetchAdmin({ id: id }))
    }
  }, [id])

  const roleOption = [
    {
      value: 'demat',
      title: 'Demat',
    },
    {
      value: 'meroshare',
      title: 'Mero Share',
    },
    {
      value: 'trading',
      title: 'Trading',
    },
  ]

  return (
    <Card>
      <PageLoading loading={detailing}>
        <MyContent>
          <Space align='center' size='large'>
            <Button
              onClick={() => onBackHandler()}
              shape='circle'
              size='small'
              icon={<ArrowLeftOutlined />}
            />

            <Typography.Title level={5}>
              {!update
                ? 'ADD ADMIN'
                : details?.id !== currentUser
                ? 'Admin Management'
                : 'Update Profile'}
            </Typography.Title>
          </Space>
          <Divider />
          <Form
            layout='vertical'
            form={form}
            name='basic'
            onFinish={onFinish}
            className='style--kbbs'
            initialValues={initialValues}
          >
            <Row gutter={[20, 0]}>
              <Col lg={18}>
                <Form.Item
                  label='Admin Role'
                  name='role'
                  rules={[
                    { required: true, message: 'Admin Role is required!' },
                  ]}
                >
                  <Select
                    disabled={details?.id === currentUser}
                    className='custom-ant-select'
                    onChange={(e: any) => setSelectedRole(e)}
                  >
                    {userRole === 'Super Admin' && (
                      <>
                        <Option value='Super Admin'>Super Admin</Option>
                        <Option value='Admin'>Admin</Option>
                      </>
                    )}

                    <Option value='User'>User</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={18}>
                <Form.Item
                  label='Full Name'
                  name='full_name'
                  rules={[
                    { required: true, message: 'Full Name is required!' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              {update && (
                <Col lg={18}>
                  <Form.Item label='Profile Picture' name='profile' rules={[]}>
                    <Upload
                      name='avatar'
                      listType='picture-card'
                      className='avatar-uploader'
                      showUploadList={false}
                      onChange={handleChangeProfileImage}
                      customRequest={() => false}
                      fileList={profileImage}
                      maxCount={1}
                      accept='image/*'
                    >
                      {uploadButtonProfile}
                    </Upload>
                  </Form.Item>
                </Col>
              )}

              <Col lg={18}>
                <Form.Item
                  label='Phone Number'
                  name='phone_number'
                  rules={[
                    { required: true, message: 'Phone Number is required!' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              {update && details?.role !== 'Super Admin' && (
                <Col lg={18}>
                  <Form.Item
                    label='Status'
                    name='status'
                    rules={[{ required: true, message: 'status is required!' }]}
                  >
                    <Radio.Group>
                      <Radio value={'Active'}>Active</Radio>
                      <Radio value={'Inactive'}>Inactive</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}

              <Col lg={18}>
                <Form.Item
                  label='Email'
                  name='email'
                  rules={[
                    { required: true, message: 'Email is required!' },
                    { type: 'email' },
                  ]}
                >
                  <Input disabled={update} />
                </Form.Item>
              </Col>

              {selectedRole === 'User' && details?.id !== currentUser && (
                <Col
                  lg={18}
                  className='select-btn'
                  style={{ marginBottom: 20 }}
                >
                  <Form.Item
                    label='Role Type'
                    name='role_type'
                    rules={[
                      { required: true, message: 'Role Type is required!' },
                    ]}
                  >
                    <Select
                      mode='multiple'
                      style={{
                        width: '100%',
                      }}
                    >
                      {roleOption.map((item) => (
                        <Select.Option key={item?.value} value={item.value}>
                          {item.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col lg={24}>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      loading={creating || updating}
                      type='primary'
                      size='large'
                      shape='round'
                      htmlType='submit'
                    >
                      {update ? 'UPDATE' : 'ADD'}
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </MyContent>
      </PageLoading>
    </Card>
  )
}

export default AdminForm
