import './DescriptionCard.scss';

import { useState } from 'react';

import {
  Button,
  Descriptions,
  Empty,
  Upload,
} from 'antd';
import BoidPdf from 'Components/BoidPdf/BoidPdf';
import CustomMap from 'Components/Map/CustomMap';
import Modal from 'Components/Modal';
import StorageImage from 'Components/StorageImage/StorageImage';
import Jitsi from 'react-jitsi';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ThemeVariables } from 'Themes/Theme';

export interface DescriptionCard {
  data: any;
  columns?: number;
  title?: string;
  videoKyc?: any;
  userDetails?: {
    username: string;
  };
}

export const DescriptionCard = ({
  data,
  title,
  columns,
  videoKyc,
  userDetails,
}: DescriptionCard) => {
  const { Dragger } = Upload;
  const theme: ThemeVariables = useTheme();
  const images: any = [];

  const [api, setAPI] = useState(null) as any;
  const [showCall, setShowCall] = useState(false);

  const loggedInUser = useSelector((state: any) => state.UserReducer);

  const handle = (JitsiMeetAPI: any) => {
    setAPI(JitsiMeetAPI);

    JitsiMeetAPI.addListener("participantJoined", (event: any) => {
      // alert(`${event.displayName} has joined the meeting.`)

      JitsiMeetAPI.executeCommand("toggleTileView");

      JitsiMeetAPI.executeCommand("overwriteNames", [
        {
          id: event.id,
          name: userDetails?.username,
        },
      ]);

      JitsiMeetAPI.executeCommand("startRecording", {
        mode: `local`, //recording mode, either `local`, `file` or `stream`.
      });
    });

    JitsiMeetAPI.addListener("participantLeft", (event: any) => {
      alert(` Someone has left the meeting.`);
    });

    JitsiMeetAPI.addListener("knockingParticipant", (event: any) => {
      alert(`${event.participant.name} is knocking!`);
    });

    JitsiMeetAPI.addListener("incomingMessage", (event: any) => {
      alert(`${event.nick} says ${event.message}`);
    });

    // JitsiMeetAPI.addListener("displayNameChange", (event: any) => {
    //   alert(`${event.id} is now ${event.displayname}`);
    // });

    JitsiMeetAPI.addListener("videoConferenceLeft", async (event: any) => {
      // alert(`application closed`);

      setShowCall(false);
    });

    // JitsiMeetAPI.executeCommand('password', password)
    // JitsiMeetAPI.executeCommand('displayName', displayName)
    // JitsiMeetAPI.executeCommand('subject', roomName)
  };

  let roomName = videoKyc?.meeting_link?.split("/") || "";

  const options = {
    roomName: roomName[roomName.length - 1],
    userInfo: {
      displayName: loggedInUser.details.full_name, // User's display name
      email: loggedInUser.details.email, // User's email
      moderator: loggedInUser.details.email, // Indicate moderator status
    },
    configOverwrite: {
      startWithAudioMuted: false,
      startWithVideoMuted: false,
    },
  };

  const handleCancelMeeting = async () => {
    // setShowCall(false);
    if (api) {
      await api.executeCommand("hangup");
      await api.executeCommand("stopRecording", {
        mode: "local",
      });
      setShowCall(false);
    }
  };

  return (
    <>
      <Descriptions
        column={columns}
        title={title}
        labelStyle={{
          textTransform: "uppercase",
          color: "#767997",
          fontWeight: 600,
        }}
        contentStyle={{
          color: "#191D36",
          fontWeight: 600,
          fontSize: 14,
        }}
        layout="vertical"
        className="style--kbbs"
      >
        {data ? (
          <>
            {Array.isArray(data)
              ? data?.map((item) => (
                <Descriptions.Item span={3}>
                  <DescriptionCard data={item?.data} title={item?.title} />
                </Descriptions.Item>
              ))
              : Object.keys(data)?.map((key, index) => {
                return (
                  <Descriptions.Item
                    // label={key?.split(/(?=[A-Z])/)?.join(' ')}
                    label={
                      key?.split("_").length > 0
                        ? key?.split("_")?.join(" ")
                        : key
                    }
                  >
                    {Array.isArray(data[key]) ? (
                      data[key]?.data?.map((d: any) => (
                        <DescriptionCard columns={1} data={d} />
                      ))
                    ) : typeof data[key] === "string" ? (
                      data[key]?.toLowerCase().includes(".png") ||
                        data[key]?.toLowerCase().includes(".jpg") ||
                        data[key]?.toLowerCase().includes(".jpeg") ||
                        data[key]?.includes("uploadImage") ? (
                        <>
                          <StorageImage
                            uri={data[key]}
                            dataKey={key}
                            editable={true}
                          />
                        </>
                      ) : key === "boid_pdf" ? (
                        <>
                          <BoidPdf pdfUrl={data[key] ?? ""} />
                        </>
                      ) : key === "map_location" ? (
                        <>
                          <CustomMap map_data_string={data[key]} />
                        </>
                      ) : key === "link" && data[key] !== "n/a" ? (
                        videoKyc.meeting_link &&
                          videoKyc.meeting_link.length > 0 ? (
                          <Button
                            onClick={() => {
                              setShowCall(true);
                            }}
                            className="success"
                            // icon={<CheckOutlined />}
                            style={{
                              color: "white",
                              background: "#1cc67b",
                              boxShadow: "-3px -3px 6px #ffffff, 3px 3px 6px #d3d3d3;"
                            }}
                          >
                            Join Video KYC
                          </Button>
                        ) : (
                          <span className="ant-descriptions-item-content">
                            {" "}
                            N/A{" "}
                          </span>
                        )
                      ) : (
                        data[key]
                      )
                    ) : typeof data[key] === "number" ? (
                      data[key]
                    ) : typeof data[key] === "boolean" ? (
                      `${key === "married"
                        ? data[key] === true
                          ? "Married"
                          : "Unmarried"
                        : data[key]
                      }`
                    ) : (
                      data[key]
                    )}
                    {/* {getKeyType(key) === 'image' && <StorageImage uri={data[key]}/>} */}
                  </Descriptions.Item>
                );
              })}
          </>
        ) : (
          <Descriptions.Item
            span={3}
            contentStyle={{ display: "flex", justifyContent: "center" }}
          >
            <Empty />
          </Descriptions.Item>
        )}
        {/* <Descriptions.Item
                span={2}
                label="Images"
            >
                <Descriptions layout="vertical">
                    {images?.map((img: any) => {
                        return <Descriptions.Item label={img.label}>
                            <StorageImage uri={img.value} />
                        </Descriptions.Item>
                    })}
                </Descriptions>
            </Descriptions.Item> */}
      </Descriptions>

      <Modal
        visible={showCall}
        destroyOnClose
        footer={false}
        onCancel={() => {
          // setShowCall(false);
          handleCancelMeeting();
        }}
        width={"100vw"}
        closable={false}
        maskClosable={false}
        bodyStyle={{
          padding: 0,
          overflow: "hidden",
          borderRadius: 0,
          maxWidth: "100vw",
          zIndex: 99999999999
        }}
      >
        {/* <div>Jesti video</div> */}
        <Jitsi
          {...options}
          // loadingComponent={<div>..loading</div>}
          // domain='video-bridge.thebuildingdecision.com'
          domain="jitsi.dev.abcsecurities.com.np"
          onAPILoad={(JitsiMeetAPI) => {
            handle(JitsiMeetAPI);
          }}
          containerStyle={{
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
          }}
          displayName={loggedInUser.details.full_name}
        />
      </Modal>
    </>
  );
};

export default DescriptionCard;
