import { API } from "aws-amplify";

export const accountsUpdateApi = (s3Url: string, accountType: string) => {
  let url = "";
  switch (accountType) {
    case "demat":
      url = "/update/demat";
      break;
    case "meroshare":
      url = "/update/meroshare";
      break;
    case "trading":
      //todo
      url = "/update/trading";
      break;

    default:
      break;
  }
  if (accountType === "meroshare") {
    return API.post("dockerApi", url, {
      body: {
        url: s3Url,
      },
    });
  } else {
    return API.patch("dockerApi", url, {
      body: {
        url: s3Url,
      },
    });
  }
};
