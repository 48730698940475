import './scss/video-react.scss'; // or import scss

import {
  BigPlayButton,
  Player,
} from 'video-react';

export interface VideoPlayerProps {
  src: string;
}

const VideoPlayer = ({ src }: VideoPlayerProps) => {
  return (
    <div
      style={{
        minWidth: "500px",
        marginBottom: "12px",
        borderRadius: "10px",
        overflow: "hidden",
        marginRight: 12,
      }}
    >
      <Player
        // poster="https://www.freecodecamp.org/news/content/images/2022/07/FaceIO-react--1-.jpg"
        src={src}
      >
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};

export default VideoPlayer;
