export const LABEL_STYLE: React.CSSProperties = {
    textTransform: "uppercase",
    color: "#767997",
    fontWeight: 600,
  };
  
  export const CONTENT_STYLE: React.CSSProperties = {
    color: "#191D36",
    fontWeight: 600,
    fontSize: 14,
  };
  
  export const EDIT_BUTTON: React.CSSProperties = {
    marginBottom: 50,
  };
  