import { AccountStatsMedium, PaymentMedium } from "./DashboardApi";
import {
  GET_ACCOUNTS_STATS_FAILURE,
  GET_ACCOUNTS_STATS_REQUEST,
  GET_ACCOUNTS_STATS_SUCCESS,
  GET_COMPLETED_STATS_GRAPH_FAILURE,
  GET_COMPLETED_STATS_GRAPH_REQUEST,
  GET_COMPLETED_STATS_GRAPH_SUCCESS,
  GET_PAYMENT_STATS_FAILURE,
  GET_PAYMENT_STATS_REQUEST,
  GET_PAYMENT_STATS_SUCCESS,
} from "./DashboardTypes";

type Payload =
  | PaymentMedium
  | AccountStatsMedium
  | { [key: string]: string | number }
  | any;

export interface DashboardActions {
  type:
    | typeof GET_PAYMENT_STATS_REQUEST
    | typeof GET_PAYMENT_STATS_SUCCESS
    | typeof GET_PAYMENT_STATS_FAILURE
    | typeof GET_ACCOUNTS_STATS_REQUEST
    | typeof GET_ACCOUNTS_STATS_SUCCESS
    | typeof GET_ACCOUNTS_STATS_FAILURE
    | typeof GET_COMPLETED_STATS_GRAPH_REQUEST
    | typeof GET_COMPLETED_STATS_GRAPH_SUCCESS
    | typeof GET_COMPLETED_STATS_GRAPH_FAILURE;

  payload?: Payload;
}

export const getPaymentStatsRequest = (
  payMedium: PaymentMedium
): DashboardActions => ({
  type: GET_PAYMENT_STATS_REQUEST,
  payload: payMedium,
});

export const getPaymentStatsSuccess = (response: any): DashboardActions => ({
  type: GET_PAYMENT_STATS_SUCCESS,
  payload: response,
});
export const getPaymentStatsFailure = (): DashboardActions => ({
  type: GET_PAYMENT_STATS_FAILURE,
});

export const getAccountStatsRequest = (
  accountTypeMedium: AccountStatsMedium
): DashboardActions => ({
  type: GET_ACCOUNTS_STATS_REQUEST,
  payload: accountTypeMedium,
});

export const getAccountStatsSuccess = (response: any): DashboardActions => ({
  type: GET_ACCOUNTS_STATS_SUCCESS,
  payload: response,
});
export const getAccountStatsFailure = (): DashboardActions => ({
  type: GET_PAYMENT_STATS_FAILURE,
});

export const getCompletedStatsGraphRequest = ({
  accountTypeMedium,
  startDate,
  endDate,
}: {
  accountTypeMedium: AccountStatsMedium;
  startDate: string;
  endDate: string;
}) => ({
  type: GET_COMPLETED_STATS_GRAPH_REQUEST,
  payload: { accountTypeMedium, startDate, endDate },
});

export const gettCompletedStatsGraphSuccess = (
  response: any
): DashboardActions => ({
  type: GET_COMPLETED_STATS_GRAPH_SUCCESS,
  payload: response,
});
export const gettCompletedStatsGraphFailure = (): DashboardActions => ({
  type: GET_COMPLETED_STATS_GRAPH_FAILURE,
});
