import React from "react";

import { Authenticator } from "aws-amplify-react";
import ProtectedLayout from "Layouts/ProtectedLayout";
import { Route, RouteChildrenProps, Switch } from "react-router";
import Dashboard from "Views/Dashboard/Dashboard";

import AdminRoute from "./AdminRoute";
import BatchRoute from "./BatchRoute";
import { DpRoutes } from "./DpRoutes";
import KycRoute from "./KycRoute";
import RouteGuard from "./RouteGuard";
import StaticContentsRoute from "./StaticContentRoutes";
import UserRoute from "./UserRoute";
import VerifyKycRequestRoute from "./VerifyKycRequestRoute";
import VerifyKycRoute from "./VerifyKycRoute";

export const ProtectedRoute = (props: RouteChildrenProps) => {
  return (
    <Authenticator hideDefault={true}>
      <RouteGuard {...props}>
        <ProtectedLayout>
          <Switch>
            <Route
              path={`${props.match?.path}static-contents`}
              component={StaticContentsRoute}
            />
            <Route path={`${props.match?.path}`} exact component={Dashboard} />
            <Route path={`${props.match?.path}user`} component={UserRoute} />
            <Route path={`${props.match?.path}kyc`} component={KycRoute} />
            <Route path={`${props.match?.path}admin`} component={AdminRoute} />
            <Route path={`${props.match?.path}batch`} component={BatchRoute} />
            <Route
              path={`${props.match?.path}verifyKyc`}
              component={VerifyKycRoute}
            />
            <Route
              path={`${props.match?.path}videoKycRequestList`}
              component={VerifyKycRequestRoute}
            />
            <Route path={`${props.match?.path}dp`} component={DpRoutes} />
          </Switch>
        </ProtectedLayout>
      </RouteGuard>
    </Authenticator>
  );
};

export default ProtectedRoute;
