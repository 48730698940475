export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY || "";

export const MAP_STYLES = [
  {
    featureType: "administrative.country",
    elementType: "geometry",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        hue: "#ff0000",
      },
    ],
  },
];

export const mapContainerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: 16,
};

export const googleMapOptions = {
  styles: MAP_STYLES,
  disableDefaultUI: true,
};
export const circleOptions = {
  strokeColor: "#00dda2",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#00dda2",
  fillOpacity: 0.15,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};
