import React, { useState } from 'react'
import { useHistory } from 'react-router'

function useCheckFormPage() {
  const [formPage, setFromPage] = useState<string>('demat')

  const history = useHistory()
  React.useEffect(() => {
    if (history.location.pathname.includes('demat')) {
      setFromPage('demat')
    } else if (history.location.pathname.includes('trading')) {
      setFromPage('trading')
    } else {
      setFromPage('meroshare')
    }
  }, [history])

  return formPage
}

export default useCheckFormPage
