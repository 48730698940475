import {
  CLEAR_S3URLTODOWNLOAD,
  GENERATE_BOFILE_START,
  GENERATE_BOFILE_SUCCESS,
  GENERATE_PAYMENT_FILE_FAILURE,
  GENERATE_PAYMENT_FILE_REQUEST,
  GENERATE_PAYMENT_FILE_SUCCESS,
  SET_BOFILE_POPUP_MESSAGE,
  TOGGLE_BOFILE_POPUP,
} from "./BatchFileTypes";

export const generateBatchFile = (
  payload: Array<string>,
  accountType: string,
  fileName: string
) => ({
  type: GENERATE_BOFILE_START,
  payload,
  accountType,
  fileName,
});

export const generateBatchFileSuccess = (s3Url: string) => ({
  type: GENERATE_BOFILE_SUCCESS,
  payload: s3Url,
});
export const generateBatchFileFailure = () => ({
  type: GENERATE_BOFILE_SUCCESS,
});

export const clears3UrlBoFile = () => ({
  type: CLEAR_S3URLTODOWNLOAD,
});

export const toggleBofilePopup = (payload: boolean) => ({
  type: TOGGLE_BOFILE_POPUP,
  payload,
});

export const setBofileMessage = (payload: string) => ({
  type: SET_BOFILE_POPUP_MESSAGE,
  payload,
});

export const generatePaymentFile = ({
  medium,
  startDate,
  endDate,
}: {
  medium: "Esewa" | "Khalti";
  startDate: string;
  endDate: string;
}) => ({
  type: GENERATE_PAYMENT_FILE_REQUEST,
  payload: { medium, startDate, endDate },
});

export const generatePaymentFileSuccess = (s3Url: string) => ({
  type: GENERATE_PAYMENT_FILE_SUCCESS,
  payload: s3Url,
});

export const generatePaymentFileFailure = () => ({
  type: GENERATE_PAYMENT_FILE_FAILURE,
});
