import { FormItemTypes } from 'Types/Types';

export const merosharePersonalDetailsFields: FormItemTypes[] = [
	{
		label: 'EMAIL ID',
		type: 'text',
		name: 'email',
		required: true,
		span: 8
	},
	{
		label: 'PHONE NUMBER',
		type: 'text',
		name: 'phone',
		required: true,
		span: 8
	},
	// {
	// 	label: 'SIGNATURE',
	// 	type: 'text',
	// 	name: 'updated_signature',
	// 	required: true,
	// 	span: 8
	// }
	// {
	// 	label: 'Organization Type',
	// 	type: 'select',
	// 	name: 'organizationType',
	// 	required: true,
	// 	options: [
	// 		{
	// 			label: 'Public',
	// 			value: 'public'
	// 		},
	// 		{
	// 			label: 'Private',
	// 			value: 'private'
	// 		},
	// 		{
	// 			label: 'Single',
	// 			value: 'single'
	// 		}
	// 	],
	// 	span: 9
	// }
];
