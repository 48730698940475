export const UPLOAD_CDS_FILE = "UPLOAD_CDS_FILE";
export const UPLOAD_CDS_FILE_SUCCESS = "UPLOAD_CDS_FILE_SUCCESS";
export const UPLOAD_CDS_FILE_FAILURE = "UPLOAD_CDS_FILE_FAILURE";

export const RESET_UPLOAD_CDSC_FILE_STATE = "RESET_UPLOAD_CDSC_FILE_STATE"

export const FETCH_VOUCHER_LIST = "FETCH_VOUCHER_LIST"
export const FETCH_VOUCHER_LIST_SUCCESS = "FETCH_VOUCHER_LIST_SUCCESS"
export const FETCH_VOUCHER_LIST_FAILURE = "FETCH_VOUCHER_LIST_FAILURE"

export const DOWNLOAD_TRANSACTION_REPORT = "DOWNLOAD_TRANSACTION_REPORT"
export const DOWNLOAD_TRANSACTION_REPORT_SUCCESS = "DOWNLOAD_TRANSACTION_REPORT_SUCCESS"
export const DOWNLOAD_TRANSACTION_REPORT_FAILURE = "DOWNLOAD_TRANSACTION_REPORT_FAILURE"

export const FETCH_VOUCHER_DETAILS = "FETCH_VOUCHER_DETAILS"
export const FETCH_VOUCHER_DETAILS_SUCCESS = "FETCH_VOUCHER_DETAILS_SUCCESS"
export const FETCH_VOUCHER_DETAILS_FAILURE = "FETCH_VOUCHER_DETAILS_FAILURE"


export interface FetchVoucherListProps {
    limit?: number;
    offset?: number;
    username?: string
    payment_mode?: string
    transaction_date_after?: string
    transaction_date_before?: string
    date_range?: string
}

export interface FetchVoucherDetailsProps {
    limit?: number;
    offset?: number;
    voucherId?: string
}
