import { getCustomerWatchList, getTickerDetailList } from "Api/WatchlistApi";
import { CONTENT_STYLE, LABEL_STYLE } from "Components/DescriptionsStyle";
import { TickerDetail } from "Types/watchList";
import { Card, Col, Descriptions, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";

const HighSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="17"
    fill="none"
    style={{
      color: "#5CAD00",
      rotate: "180deg",
      transform: "translate(-10px, -2px)",
    }}
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-width="3"
      d="M14.5 14.992 2.936 2h23.128L14.5 14.992Z"
    ></path>
  </svg>
);

const LowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="17"
    fill="none"
    style={{
      color: "#FF452C",
      transform: "translate(10px, 2px)",
    }}
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-width="3"
      d="M14.5 14.992 2.936 2h23.128L14.5 14.992Z"
    ></path>
  </svg>
);

const UserWatchList = ({
  userId,
  watchlist,
}: {
  userId: string;
  watchlist: string[];
}) => {
  //   todo : fetch slug list from api
  const [userWatchlistSlug, setUserWatchistSlug] = useState<string[]>([
    // "BNT",
    // "API",
    // "BARUN",
    // "AKPL",
    // "ALICL",
  ]);

  const [userWatchlistDetailList, setUserWatchistDetailList] = useState<
    TickerDetail[]
  >([]);

  useEffect(() => {
    getTickerDetailList(userWatchlistSlug).then((data) =>
      setUserWatchistDetailList(data)
    );
  }, [userWatchlistSlug]);

  useEffect(() => {
    setUserWatchistSlug(watchlist);
  }, []);

  // useEffect(() => {
  //   getCustomerWatchList(userId).then((list) => setUserWatchistSlug(list));
  // }, [userId]);

  const handleClick = (ticker: string) => {
    window.open(
      `http://dev.abcsecurities.com.np/ticker-profile/${ticker}`,
      "_blank"
    );
  };

  return (
    <div>
      <Typography.Title style={{ fontSize: "20px" }}>
        User's Watch List
      </Typography.Title>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {userWatchlistDetailList?.map((item) => {
          const isNegative = item?.point_change < 0;
          return (
            <Col
              className="gutter-row"
              span={8}
              style={{ marginTop: "20px", cursor: "pointer" }}
              key={item?.ticker}
              onClick={() => {
                handleClick(item?.ticker);
              }}
            >
              <Card
              // style={{ background: isNegative ? "#f44e5110" : "#e2f3ec" }}
              >
                {/* <div style={{}}>{JSON.stringify(item)}</div> */}

                <Descriptions
                  //   column={9}
                  labelStyle={LABEL_STYLE}
                  contentStyle={CONTENT_STYLE}
                  layout="horizontal"
                  title={
                    <>
                      {item?.ticker_name}{" "}
                      {isNegative ? <LowSvg /> : <HighSvg />}
                    </>
                  }
                >
                  <Descriptions.Item span={3} label={`Symbol`}>
                    {item?.ticker}
                  </Descriptions.Item>

                  <Descriptions.Item span={3} label={`Latest Price`}>
                    {item?.latest_price}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={`Point Change`}>
                    {item?.point_change}
                  </Descriptions.Item>
                  {/* <Descriptions.Item span={3} label={`Previous Close`}>
                    {item?.latest_price}
                  </Descriptions.Item> */}
                  <Descriptions.Item span={3} label={`Open`}>
                    {item?.open}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={`High`}>
                    {item?.high}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={`Low`}>
                    {item?.low}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={`Volume`}>
                    {item?.volume}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default UserWatchList;
