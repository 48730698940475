import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Spin,
  Typography,
} from 'antd';
import { StatsCard } from 'Components/StatsCard/StatsCard';
import dayjs from 'dayjs';
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { fetchDashboardReport } from 'Redux/DpRedux/Dashboard/DashboardActions';

export const Dashboard = () => {
  const dispatch = useDispatch();

  const { loading, data } = useSelector(
    (state: any) => state.dpDashboardReportReducer
  );

  const [dateSelect, setDateSelect] = useState("last_7_days");
  const [customDate, setCustomDate] = useState({
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    let params = {};

    if (dateSelect === "last_30_days") {
      params = {
        last_30_days: true,
      };
    }

    if (dateSelect === "last_7_days") {
      params = {
        last_7_days: true,
      };
    }

    if (dateSelect === "custom_date") {
      params = {
        custom_date: true,
        start_date: customDate.start_date,
        end_date: customDate.end_date,
      };
    }

    dispatch(fetchDashboardReport(params));
  }, [dispatch, dateSelect, customDate]);

  const statsValue = (stats: string | number | null) => {
    if (typeof stats === "number" && stats === 0) {
      return stats;
    }
    if (!stats) {
      return `N/A`;
    }
    return stats;
  };

  return (
    <Spin spinning={loading}>
      <Card className="dp-dashboard">
        <div className="filter-section">
          <Button
            disabled={dateSelect === "last_7_days"}
            onClick={() => setDateSelect("last_7_days")}
          >
            {" "}
            Last 7 Days{" "}
          </Button>
          <Button
            disabled={dateSelect === "last_30_days"}
            onClick={() => setDateSelect("last_30_days")}
          >
            {" "}
            Last one month{" "}
          </Button>
          <DatePicker.RangePicker
            className="date-range"
            onChange={(dates) => {
              if (dates) {
                setDateSelect("custom_date");
                setCustomDate({
                  start_date: dayjs(dates?.[0]?.toString()).format(
                    "YYYY-MM-DD"
                  ),
                  end_date: dayjs(dates?.[1]?.toString()).format("YYYY-MM-DD"),
                });
              } else {
                setDateSelect("last_7_days");
                setCustomDate({
                  start_date: "",
                  end_date: "",
                });
              }
            }}
          />
        </div>
        <div className="content">
          <div className="divide-section">
            <Typography.Title
              style={{
                fontSize: "1rem",
                marginBottom: 0,
              }}
            >
              Meroshare
            </Typography.Title>
            <div className="divider-line" />

            <Button>
              <ArrowDownIcon />
            </Button>
          </div>

          <Row
            align="middle"
            justify="space-between"
            style={{
              marginTop: 24,
            }}
            gutter={[16, 16]}
          >
            <Col lg={6}>
              <StatsCard
                label={statsValue(data.total_meroshare_accounts)}
                text="Total No of Meroshare Account"
                textType={
                  statsValue(data.total_meroshare_accounts) === "N/A" ||
                  statsValue(data.total_meroshare_accounts) === 0
                    ? undefined
                    : "success"
                }
              />
            </Col>
            <Col lg={6}>
              <StatsCard
                label={statsValue(data.total_meroshare_accounts_created)}
                text="Accounts Created"
                textType={
                  statsValue(data.total_meroshare_accounts_created) === "N/A" ||
                  statsValue(data.total_meroshare_accounts_created) === 0
                    ? undefined
                    : "success"
                }
              />
            </Col>
            {/* <Col lg={6}> //can be needed in future
              <StatsCard label="52" text="Accounts Updated" />
            </Col> */}
            <Col lg={6}>
              <StatsCard
                label={statsValue(data.total_meroshare_accounts_closed)}
                text="Accounts Deleted"
                textType={
                  statsValue(data.total_meroshare_accounts_closed) === "N/A" ||
                  statsValue(data.total_meroshare_accounts_closed) === 0
                    ? undefined
                    : "danger"
                }
              />
            </Col>
          </Row>
          <Row
            align="middle"
            justify="space-between"
            gutter={[16, 16]}
            style={{
              marginTop: 24,
            }}
          >
            <Col lg={8}>
              <StatsCard
                label={statsValue(data.total_meroshare_transactions)}
                text="Meroshare Transactions"
              />
            </Col>
            <Col lg={8}>
              <StatsCard
                label={statsValue(data.total_meroshare_paid)}
                text="Amount Paid"
              />
            </Col>
            <Col lg={8}>
              <StatsCard
                label={statsValue(data.total_meroshare_expired_accounts)}
                text="Expired Accounts"
              />
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="divide-section">
            <Typography.Title
              style={{
                fontSize: "1rem",
                marginBottom: 0,
              }}
            >
              Demat
            </Typography.Title>
            <div className="divider-line" />

            <Button>
              <ArrowDownIcon />
            </Button>
          </div>
          <Row
            align="middle"
            justify="space-between"
            style={{
              marginTop: 24,
            }}
            gutter={[16, 16]}
          >
            <Col lg={6}>
              <StatsCard
                label={statsValue(data.total_demat_accounts)}
                text="Total No of Demat Account"
                textType={
                  statsValue(data.total_demat_accounts) === "N/A" ||
                  statsValue(data.total_demat_accounts) === 0
                    ? undefined
                    : "success"
                }
              />
            </Col>
            <Col lg={6}>
              <StatsCard
                label={statsValue(data.total_demat_accounts_created)}
                text="Accounts Created"
                textType={
                  statsValue(data.total_demat_accounts_created) === "N/A" ||
                  statsValue(data.total_demat_accounts_created) === 0
                    ? undefined
                    : "success"
                }
              />
            </Col>
            {/* <Col lg={6}> //can be needed in future
              <StatsCard label="52" text="Accounts Updated" />
            </Col> */}
            <Col lg={6}>
              <StatsCard
                label={statsValue(data.total_demat_accounts_closed)}
                text="Accounts Deleted"
                textType={
                  statsValue(data.total_demat_accounts_closed) === "N/A" ||
                  statsValue(data.total_demat_accounts_closed) === 0
                    ? undefined
                    : "danger"
                }
              />
            </Col>
          </Row>
          <Row
            align="middle"
            justify="space-between"
            gutter={[16, 16]}
            style={{
              marginTop: 24,
            }}
          >
            <Col lg={8}>
              <StatsCard
                label={statsValue(data.total_demat_transactions)}
                text="Demat Transactions"
              />
            </Col>
            <Col lg={8}>
              <StatsCard
                label={statsValue(data.total_demat_paid)}
                text="Amount Paid"
              />
            </Col>
            <Col lg={8}>
              <StatsCard
                label={statsValue(data.total_demat_due)}
                text="Amount Due"
              />
            </Col>
          </Row>
        </div>
      </Card>
    </Spin>
  );
};
