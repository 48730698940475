import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { AdminReducer } from 'Redux/AdminRedux/AdminReducer';
import {
  BatchFileReducer,
} from 'Redux/BatchFileRedux(downlodFiles)/BatchFileReducer';
import { DashboardReducer } from 'Redux/DashboardRedux/DashboardReducer';
import { accountReducer } from 'Redux/DpRedux/Accounts/AccountReducer';
import { dpCustomer } from 'Redux/DpRedux/Customer/DpReducer';
import { dpDashboard } from 'Redux/DpRedux/Dashboard/DashboardReducer';
import { dpReport } from 'Redux/DpRedux/Reports/ReportReducer';
import { dpTransaction } from 'Redux/DpRedux/Transaction/TransactionReducer';
import { OptionReducer } from 'Redux/OptionsRedux/OptionReducer';
import { UploadKycReducer } from 'Redux/UploadKycsFileRedux/UploadKycReducer';
import { UserReducer } from 'Redux/UserRedux/UserReducer';

import { AuthReducer } from '../AuthRedux/AuthReducer';
import { KycReducer } from '../KycRedux/KycReducer';

const reducers = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    AuthReducer: AuthReducer,
    UserReducer: UserReducer,
    KycReducer: KycReducer,
    AdminReducer: AdminReducer,
    BatchFileReducer: BatchFileReducer,
    UploadKycReducer,
    OptionReducer,
    DashboardReducer,
    dpCustomerReducer: dpCustomer,
    accountReducer: accountReducer,
    dpReportsReducer: dpReport,
    dpDashboardReportReducer: dpDashboard,
    dpTransactionReducer: dpTransaction
  });

export default reducers;
