import { message } from "antd";
import { ApiHeaders } from "Api/Api";
import { API, Storage } from "aws-amplify";
interface generateDownload {
  id: string;
  target: "demat" | "meroshare" | "trading";
  loaderCB: () => void;
}

export const onDownload = async ({
  id,
  target = "demat",
  loaderCB,
}: generateDownload) => {
  try {
    const result = await API.get(
      "dockerApi",
      `/admin/generate-pdf/${id}/${target}`,
      {
        headers: ApiHeaders,
      }
    );

    const data = await Storage.get(result.data, {
      customPrefix: {
        public: "",
      },
    });
    const link = document.createElement("a");
    link.href = data;
    link.setAttribute("download", `FileName.pdf`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
  } catch (err) {
    message.error("Error generating pdf");
  } finally {
    loaderCB();
  }
};
