import {
  ARCHIVE_CUSTOMER,
  ARCHIVE_CUSTOMER_FAILURE,
  ARCHIVE_CUSTOMER_SUCCESS,
  CREATE_DP_CUSTOMER,
  CREATE_DP_CUSTOMER_FAILURE,
  CREATE_DP_CUSTOMER_SUCCESS,
  CreateDpCustomerParams,
  CUSTOMER_ADD_REFERENCE,
  CUSTOMER_ADD_REFERENCE_FAILURE,
  CUSTOMER_ADD_REFERENCE_SUCCESS,
  CustomerAddReferenceProps,
  DOWNLOAD_CUSTOMER_INFO,
  DOWNLOAD_CUSTOMER_INFO_FAILURE,
  DOWNLOAD_CUSTOMER_INFO_SUCCESS,
  DOWNLOAD_CUSTOMER_TRANSACTION_REPORT,
  DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_FAILURE,
  DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_DP_CUSTOMER_REPORT,
  DOWNLOAD_DP_CUSTOMER_REPORT_FAILURE,
  DOWNLOAD_DP_CUSTOMER_REPORT_SUCCESS,
  EDIT_CUSTOMERS_DATA,
  EDIT_CUSTOMERS_DATA_FAILURE,
  EDIT_CUSTOMERS_DATA_SUCCESS,
  FETCH_DP_CUSTOMER,
  FETCH_DP_CUSTOMER_FAILURE,
  FETCH_DP_CUSTOMER_SUCCESS,
  FETCH_DP_SINGLE_CUSTOMER,
  FETCH_DP_SINGLE_CUSTOMER_FAILURE,
  FETCH_DP_SINGLE_CUSTOMER_SUCCESS,
  FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS,
  FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_FAILURE,
  FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_SUCCESS,
  FetchCustomerParams,
  FetchCustomerTransactionListProps,
  RESET_STATUS_VALUE,
  REUPLOAD_FILE,
  UPLOAD_DPA_FILE,
  UPLOAD_DPA_FILE_FAILURE,
  UPLOAD_DPA_FILE_INCREMENTAL,
  UPLOAD_DPA_FILE_SUCCESS,
  UploadDpaFile,
} from './DpTypes';

//fetch dp customer List
export const fetchDPCustomer = (params?: FetchCustomerParams) => ({
  type: FETCH_DP_CUSTOMER,
  payload: params,
});

export const fetchDpCustomerSuccess = (response: any) => ({
  type: FETCH_DP_CUSTOMER_SUCCESS,
  payload: response,
});

export const fetchDpCustomerFailure = (error: any) => ({
  type: FETCH_DP_CUSTOMER_FAILURE,
  payload: error,
});

//fetch dp single customer
export const fetchDPSingleCustomer = (id: string) => ({
  type: FETCH_DP_SINGLE_CUSTOMER,
  payload: id,
});

export const fetchDpSingleCustomerSuccess = (response: any) => ({
  type: FETCH_DP_SINGLE_CUSTOMER_SUCCESS,
  payload: response,
});

export const fetchDpSingleCustomerFailure = () => ({
  type: FETCH_DP_SINGLE_CUSTOMER_FAILURE,
});

//create Admin
export const createDpCustomer = (params: CreateDpCustomerParams) => ({
  type: CREATE_DP_CUSTOMER,
  payload: params,
});

export const createDpCustomerSuccess = (response: CreateDpCustomerParams) => ({
  type: CREATE_DP_CUSTOMER_SUCCESS,
  payload: response,
});
export const createDpCustomerFailure = (error: any) => ({
  type: CREATE_DP_CUSTOMER_FAILURE,
  payload: error,
});

//upload dpa file

export const uploadDpaFile = (data: any) => ({
  type: UPLOAD_DPA_FILE,
  payload: data,
});

export const uploadDpaFileSuccess = (data: UploadDpaFile) => ({
  type: UPLOAD_DPA_FILE_SUCCESS,
  payload: data,
});

export const uploadDpaFileIncremental = (data: UploadDpaFile) => ({
  type: UPLOAD_DPA_FILE_INCREMENTAL,
  payload: data,
});

export const uploadDpaFileFailure = (error: string) => ({
  type: UPLOAD_DPA_FILE_FAILURE,
  payload: error,
});

export const reuploadDpaFile = () => ({
  type: REUPLOAD_FILE,
});

export const getDpCustomerReport = (
  data: FetchCustomerParams,
  type: string
) => ({
  type: DOWNLOAD_DP_CUSTOMER_REPORT,
  payload: data,
  fileType: type,
});

export const getDpCustomerReportSuccess = (data: any) => ({
  type: DOWNLOAD_DP_CUSTOMER_REPORT_SUCCESS,
  payload: data,
});

export const getDpCustomerReportFailure = () => ({
  type: DOWNLOAD_DP_CUSTOMER_REPORT_FAILURE,
});

export const editCustomersData = (data: any) => ({
  type: EDIT_CUSTOMERS_DATA,
  payload: data,
});

export const editCustomersDataSuccess = (data: any) => ({
  type: EDIT_CUSTOMERS_DATA_SUCCESS,
  payload: data,
});

export const editCustomersDataFailure = () => ({
  type: EDIT_CUSTOMERS_DATA_FAILURE,
});

export const resetStatusValue = () => ({
  type: RESET_STATUS_VALUE,
});

export const archiveCustomer = (data: string) => ({
  type: ARCHIVE_CUSTOMER,
  payload: data,
});

export const archiveCustomerSuccess = () => ({
  type: ARCHIVE_CUSTOMER_SUCCESS,
});

export const archiveCustomerFailure = () => ({
  type: ARCHIVE_CUSTOMER_FAILURE,
});

export const downloadCustomerInfo = (data: string) => ({
  type: DOWNLOAD_CUSTOMER_INFO,
  payload: data,
});
export const downloadCustomerInfoSuccess = () => ({
  type: DOWNLOAD_CUSTOMER_INFO_SUCCESS,
});
export const downloadCustomerInfoFailure = () => ({
  type: DOWNLOAD_CUSTOMER_INFO_FAILURE,
});

export const customerAddReference = (data: CustomerAddReferenceProps) => ({
  type: CUSTOMER_ADD_REFERENCE,
  payload: data,
});

export const customerAddReferenceSuccess = () => ({
  type: CUSTOMER_ADD_REFERENCE_SUCCESS,
});

export const customerAddReferenceFailure = () => ({
  type: CUSTOMER_ADD_REFERENCE_FAILURE,
});


export const fetchCustomerTransaction = (userId: string, data: FetchCustomerTransactionListProps,) => {
  return ({
    type: FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS,
    payload: data,
    userId: userId
  })
}

export const fetchCustomerTransactionSuccess = (data: any) => ({
  type: FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_SUCCESS,
  payload: data
})

export const fetchCustomerTransactionFailure = () => ({
  type: FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_FAILURE
})

export const downloadCustomerTransReport = (userId: string, data: FetchCustomerTransactionListProps, fileType: string) => ({
  type: DOWNLOAD_CUSTOMER_TRANSACTION_REPORT,
  payload: data,
  userId: userId,
  fileType: fileType
})

export const downloadCustomerTransReportSuccess = () => ({
  type: DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_SUCCESS
})

export const downloadCustomerTransReportFailure = () => ({
  type: DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_FAILURE
})