import AuthBackground from 'Assets/Company/login-background.jpg'
import { Col, Image, Row } from 'antd'
import { ReactComponent as LightLogo } from 'Assets/Company/logo-light.svg'
import { Content } from 'antd/lib/layout/layout'
export const AuthLayout: React.FC = ({ children }) => {
  return (
    <Content className='site-layout-background'>
      <Row
        style={{
          margin: 0,
          minHeight: '100vh',
          maxHeight: '100vh',
          overflow: 'hidden',
        }}
      >
        <Col lg={12} className='p-0 overflow-hidden'>
          <Image src={AuthBackground} className='' />
          <div className='overflow--black' style={{ padding: 20 }}>
            <LightLogo />
          </div>
        </Col>
        <Col lg={12} style={{ height: '100vh' }}>
          {children}
        </Col>
      </Row>
    </Content>
  )
}

export default AuthLayout
