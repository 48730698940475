export const FETCH_DASHBOARD_REPORT = "FETCH_DASHBOARD_REPORT";
export const FETCH_DASHBOARD_REPORT_SUCCESS = "FETCH_DASHBOARD_REPORT_SUCCESS";
export const FETCH_DASHBOARD_REPORT_FAILURE = "FETCH_DASHBOARD_REPORT_FAILURE";

export interface DpDashboardReportParamsProps {
  last_7_days?: boolean;
  last_30_days?: boolean;
  custom_date?: boolean;
  start_date?: string;
  end_date?: string;
}
