import { message } from 'antd'
import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import AdminList from './AdminList'

export const Admins = (props: any) => {
  const history = useHistory()
  useEffect(() => {
    Auth.currentUserInfo()
      .then((user) => {
        if (user?.attributes?.[`custom:role`] !== 'Super Admin') {
          message.error(
            `You are not authorized to view this page ${
              user?.attributes?.[`custom:role`]
            }`
          )
          history.push('/user')
        }
      })
      .then((data) => {})
      .catch((err) => {})
  }, [0])
  return <AdminList />
}

export default Admins
