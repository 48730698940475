import React, { useState } from 'react';

import {
  Button,
  Dropdown,
  Menu,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd';
import Modal from 'Components/Modal';
import ArrowDownBoldIcon from 'Icons/ArrowDownBoldIcon/ArrowDownBoldIcon';
import { DownloadIconDark } from 'Icons/DownloadIcon/DownloadIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  fetchVoucherList,
  resetUploadCdscFileStateValue,
  uploadCdscFile,
} from 'Redux/DpRedux/Transaction/TransactionAction';
import { dpFilterParams } from 'Utils/dpFilterParams';
import { dummyRequest } from 'Utils/DummyCall';

import { TransactionListFilterProps } from '../Transaction';

const importType = [
  {
    key: "transaction",
    type: "Import Transaction",
  },
];

interface ImportComponentProps {
  filterData: TransactionListFilterProps;
}

export const ImportComponent = ({ filterData }: ImportComponentProps) => {
  const dispatch = useDispatch();

  const [showImportModal, setShowImportModal] = useState({
    visible: false,
    key: "",
  });

  const {
    uploadingCdscFile,
    uploadingCdscFileError,
    uploadingCdscFileSuccess,
  } = useSelector((state: any) => state.dpTransactionReducer);

  const menuImport = (
    <Menu
      onClick={(e) => {
        setShowImportModal({
          visible: true,
          key: e.key,
        });
      }}
    >
      {importType.map((item: { key: string; type: string }) => {
        return (
          <Menu.Item key={item.key}>
            <Typography.Text type="secondary">{item.type}</Typography.Text>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const renderModalComp = () => {
    switch (true) {
      case uploadingCdscFileError:
        return (
          <>
            <Typography.Title type="danger" level={4}>
              Import Failed!
            </Typography.Title>
            <Row>
              <Button
                style={{
                  marginRight: 20,
                }}
                onClick={() => {
                  dispatch(resetUploadCdscFileStateValue());
                  setShowImportModal({
                    visible: false,
                    key: "",
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => dispatch(resetUploadCdscFileStateValue())}
              >
                Reupload File
              </Button>
            </Row>
          </>
        );

      case uploadingCdscFileSuccess:
        return (
          <>
            <Typography.Title type="success" level={4}>
              successfully imported
            </Typography.Title>
            <Button
              type="primary"
              onClick={() => {
                dispatch(resetUploadCdscFileStateValue());
                setShowImportModal({
                  visible: false,
                  key: "",
                });

                const params = dpFilterParams(filterData);

                dispatch(fetchVoucherList(params));
              }}
            >
              See new Data
            </Button>
          </>
        );

      default:
        return (
          <Spin spinning={uploadingCdscFile}>
            <Upload
              customRequest={dummyRequest}
              beforeUpload={(file: any) => {
                const formData = new FormData();

                formData.append("file", file);
                dispatch(uploadCdscFile(formData));
              }}
              accept=".xlsx, application/vnd.ms-excel"
            >
              <Space direction="vertical" align="center" size={"large"}>
                <DownloadIconDark />
                <Typography.Text>Upload .xls or .xlsx file</Typography.Text>
              </Space>
            </Upload>
          </Spin>
        );
    }
  };

  return (
    <>
      <Dropdown trigger={["click"]} overlay={menuImport}>
        <Row align="middle" justify="space-between">
          <Typography.Text style={{ textTransform: "capitalize" }}>
            {"Import"}
          </Typography.Text>
          <ArrowDownBoldIcon />
        </Row>
      </Dropdown>
      <Modal
        visible={showImportModal.visible}
        width={"30vw"}
        onCancel={() => {
          dispatch(resetUploadCdscFileStateValue());
          setShowImportModal({
            visible: false,
            key: "",
          });
        }}
      >
        <Space
          direction="vertical"
          align="center"
          size={"large"}
          style={{
            width: "100%",
          }}
        >
          {renderModalComp()}
        </Space>
      </Modal>
    </>
  );
};
