export const GENERATE_BOFILE_START = "GENERATE_BOFILE_START";
export const GENERATE_BOFILE_SUCCESS = "GENERATE_BOFILE_SUCCESS";
export const GENERATE_BOFILE_FAILURE = "GENERATE_BOFILE_FAILURE";

export const CLEAR_S3URLTODOWNLOAD = "CLEAR_S3URLTODOWNLOAD";

export const TOGGLE_BOFILE_POPUP = "TOGGLE_BOFILE_POPUP";

export const SET_BOFILE_POPUP_MESSAGE = "SET_BOFILE_POPUP_MESSAGE";



export const GENERATE_PAYMENT_FILE_REQUEST = "GENERATE_PAYMENT_FILE_REQUEST";
export const GENERATE_PAYMENT_FILE_SUCCESS = "GENERATE_PAYMENT_FILE_SUCCESS";
export const GENERATE_PAYMENT_FILE_FAILURE = "GENERATE_PAYMENT_FILE_FAILURE";