import { Reducer } from 'react';

import {
  ADDRESS_OPTION_LIST_FAILURE,
  ADDRESS_OPTION_LIST_SUCCESS,
  BANK_OPTION_LIST_FAILURE,
  BANK_OPTION_LIST_SUCCESS,
  BANK_OPTION_WITH_BRANCH_LIST_SUCCESS,
  BROKER_LIST,
  ORGANIZATION_OPTION_LIST_FAILURE,
  ORGANIZATION_OPTION_LIST_SUCCESS,
  RATE_OPTION_LIST_FAILURE,
  RATE_OPTION_LIST_SUCCESS,
} from './OptionTypes';

const INIT_STATE = {
  districtList: [{ label: 'label', value: 'value' }], // for district select only
  addressLoading: true,
  addressError: '',
  addressDetails: [], //used for autofill adddress in addressform (contains all data of address)
  organizationList: [{ label: 'label', value: 'value' }],
  organizationLoading: true,
  organizationError: '',
  bankList: [{ label: 'label', value: 'value' }],
  bankLoading: true,
  bankError: '',
  bankWithBranch: [],
  brokerList: [],
  rateList: []
}

export const OptionReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {
    //fetch Users
    case ADDRESS_OPTION_LIST_SUCCESS:
      return {
        ...state,
        addressLoading: false,
        districtList: action.payload,
        addressDetails: action.payload2,
      }
    case ADDRESS_OPTION_LIST_FAILURE:
      return { ...state, addressLoading: false, error: action.payload.error }

    case ORGANIZATION_OPTION_LIST_SUCCESS:
      return {
        ...state,
        organizationLoading: false,
        organizationList: action.payload,
      }
    case BROKER_LIST:
      return {
        ...state,
        brokerList: action.payload?.data,
      }
    case ORGANIZATION_OPTION_LIST_FAILURE:
      return {
        ...state,
        organizationLoading: false,
        error: action.payload.error,
      }

    case BANK_OPTION_LIST_SUCCESS:
      return {
        ...state,
        bankLoading: false,
        bankList: action.payload,
      }
    case BANK_OPTION_WITH_BRANCH_LIST_SUCCESS:
      return {
        ...state,
        bankLoading: false,
        bankWithBranch: action.payload,
      }
    case BANK_OPTION_LIST_FAILURE:
      return { ...state, bankLoading: false, error: action.payload.error }

    case RATE_OPTION_LIST_SUCCESS:
      return {
        ...state,
        rateList: action.payload
      }

    case RATE_OPTION_LIST_FAILURE:
      return {
        ...state,
        rateList: []
      }

    default:
      return { ...state }
  }
}
