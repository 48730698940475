import { AreaConfig } from "@ant-design/charts";

export const DummyGraphData = [
  { date: "1991", customers: 3 },
  { date: "1992", customers: 4 },
  { date: "1993", customers: 3.5 },
  { date: "1994", customers: 5 },
  { date: "1995", customers: 4.9 },
  { date: "1996", customers: 6 },
  { date: "1997", customers: 7 },
  { date: "1998", customers: 9 },
  { date: "1999", customers: 13 },
];
export const placeHolderData = [{ date: "", count: 0 }];

export const graphConfig = {
  data: DummyGraphData,
  xField: "date",
  yField: "customers",
  autoFit: true,
  height: 300,
  lineStyle: {
    stroke: "#1CC67B",
    shadowColor: "#1CC67B",
    shadowBlur: 15,
    shadowOffsetX: 5,
    shadowOffsetY: 13,
  },
  areaStyle: () => {
    return {
      fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
    };
  },
};

export const areaGraphConfig = (dataArr: any[]): AreaConfig => {
  return {
    data: dataArr.length > 0 ? dataArr : placeHolderData,
    autoFit: true,
    height: 300,
    xField: "date",
    yField: "count",
    xAxis: {
      range: [0, 1],
      tickCount: 5,
    },
    line: {
      color: "#1CC67B",
    },

    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#1CC67B 1:#1890ff",
      };
    },
  };
};
