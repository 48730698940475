import { Content } from 'antd/lib/layout/layout';
import React, { CSSProperties } from 'react';
import './Content.scss';

interface MyContentTypes {
	shadow?: boolean;
	style?: CSSProperties;
}
export const MyContent: React.FC<MyContentTypes> = ({ shadow, children, ...rest }) => {
	return (
		<Content className="primary__container" {...rest}>
			{children}
		</Content>
	);
};

export default MyContent;
