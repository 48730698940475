import { ApiHeaders } from "Api/Api";
import { API } from "aws-amplify";

export const generateBatchFileApi = (
  payload: Array<string>,
  accountType: string
) => {
  let url = "";
  switch (accountType) {
    case "demat":
      url = "/bo/bo_generator";
      break;
    case "meroshare":
      url = "/meroshare/download-csv";
      break;
    case "bot-trading":
      //todo
      url = "/tms/bot/zip";
      break;
    case "non-bot-trading":
      //todo
      url = "/tms/nonbot/zip";
      break;

    default:
      break;
  }
  return API.post("dockerApi", url, {
    body: {
      others_id: payload,
    },
  });
};

export const generatePaymentFileApi = ({
  medium,
  startDate,
  endDate,
}: {
  medium: "Esewa" | "Khalti";
  startDate: string;
  endDate: string;
}) => {
  const url = `/admin/payment/excel?start_date=${startDate}&end_date=${endDate}&medium=${medium}`;
  return API.get("kycApi", url, {
    headers: ApiHeaders,
  });
};
