import './style.scss';

import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import AllFormStatusWithLinks
  from 'Components/AllFormStatus/AllFormStatusWithLinks';
import { AntFormItem } from 'Components/AntForm/AntForm';
import { DatePicker as ScheduleKycDatePicker } from 'Components/DatePicker';
import Modal from 'Components/Modal';
import Status from 'Components/Status/Status';
import dayjs from 'dayjs';
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import {
  fetchKycRequests,
  fetchUserListVideoKyc,
  scheduleVideoKyc,
} from 'Redux/KycRedux/KycActions';
import { FilterType } from 'Types/Types';
import { useDebounce } from 'use-debounce/lib';
import {
  scheduleKycDateValidation,
  scheduleKycTimeValidation,
} from 'Utils/dateValidations';

import {
  CloseCircleOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';

const statuses = ["All", "Pending", "Verified", "Rejected"];

const customDate = [
  {
    title: "All",
    date: ""
  },
  {
    title: "Today",
    date: dayjs().format("YYYY-MM-DD")
  },

  {
    title: "Tomorrow",
    date: dayjs().add(1, "day").format("YYYY-MM-DD")
  },

  {
    title: "Yesterday",
    date: dayjs().subtract(1, "day").format("YYYY-MM-DD")
  },

]

export const UserList = (props: any) => {
  const kycUsers = useSelector((state: any) => state.KycReducer);
  const [filter, setFilter] = useState<FilterType>({
    page: 1,
    limit: 10,
  });
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filterText, setFilterText] = useState("");
  const [debounceFilter] = useDebounce(filterText, 500);

  // const []
  const dispatch = useDispatch();

  const history: any = useHistory();

  const {
    loading,
    videoKycRequestList: list,
    updating,
    totalRecords,
    pageNumber,
    list: kycsUserList,
    scheduling,
    scheduleSuccess,
    userListVideoKyc
  } = kycUsers;

  const [showModal, setShowModal] = useState(false);

  const [scheduledDate, setScheduledDate] = useState("" as any);

  const [userId, setUserId] = useState("");

  const [selectSearch, setSelectSearch] = useState("");

  const [selectCustomDate, setSelectCustomDate] = useState("All")






  useEffect(() => {
    setFilter({ ...filter });
  }, [debounceFilter]);

  //Fetch data with filter
  const fetchWithFilter = (data: any) => {
    dispatch(fetchKycRequests({ ...data }));
  };


  useEffect(() => {
    if (kycUsers?.updated) {
      fetchWithFilter({ ...filter });
    }
  }, [kycUsers?.updated]);

  useEffect(() => {
    if (scheduleSuccess) {
      setShowModal(false);
      dispatch(fetchKycRequests({ ...filter }));
    }
  }, [scheduleSuccess]);

  const handleSelectSearch = (val: string) => {
    setSelectSearch(val);
  };

  useEffect(() => {
    let filter = {};

    if (selectSearch) {
      filter = {
        username: selectSearch,
      };
    }

    dispatch(fetchUserListVideoKyc(filter));
  }, [selectSearch, dispatch]);

  const userColumns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (row: any, text: any, index: number) => {
        if (filter.page && filter.limit) {
          return (
            <Typography.Text>
              {(filter?.page - 1) * filter.limit + index + 1}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: "USERNAME",
      dataIndex: "username",
      key: "username",
      // sorter: true,
      render: (text: any, row: any, index: number) => (
        <Typography.Link>{text}</Typography.Link>
      ),
      // render: (row: any, text: any, index: number) => <Typography.Text>{dayjs(row)?.format('LLL')}</Typography.Text>
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text: any, row: any, index: number) => (
        <Status status={text} rejectMessage={list[index]?.reject_reason} />
      ),
    },
    {
      title: "PREFERRED DATE",
      // dataIndex: "created_at",
      // key: "created_at",
      render: (text: any, row: any) => (
        <Typography.Text>
          {row.video_kyc.preferred_date_time
            ? dayjs(row.video_kyc.preferred_date_time).format("MM-DD-YYYY HH:mm")
            : "N/A"}
        </Typography.Text>
      ),
    },
    {
      title: "APPROVED DATE",
      // dataIndex: "created_at",
      // key: "created_at",
      render: (text: any, row: any) => (
        <Typography.Text>
          {row.video_kyc.approved_datetime
            ? dayjs(row.video_kyc.approved_datetime).format("MM-DD-YYYY HH:mm")
            : "N/A"}
        </Typography.Text>
      ),
    },
    {
      title: "OTHERS",
      dataIndex: "form",
      key: "form",
      render: (text: any, row: any) => (
        <AllFormStatusWithLinks
          data={text}
          handleClick={(type: string) => {
            history.push({
              pathname: `/user/${row?.id}/${type}`,
              state: {
                filter,
                defaultSelectedTab: "video-kyc",
              },
            });
          }}
        />
      ),
    },
  ];

  const [form] = useForm();


  const menuStatus = (
    <Menu
      onClick={(e) => {
        setSelectedStatus(e.key);
        form.setFieldsValue({ status: e.key });
      }}
    >
      {statuses?.map((acc) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={acc}>
            {acc}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const dateMenu = (
    <Menu
      onClick={(e) => {
        setSelectCustomDate(e.key);
        form.setFieldsValue({ custom_date: e.key });
      }}
    >
      {customDate?.map((item) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={item.title}>
            {item.title}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  useEffect(() => {
    if (history.location.state?.filter) {
      setFilter({ ...filter, ...history.location.state?.filter });
    }

    if (history.location.state?.filter?.status) {
      setSelectedStatus(history.location.state?.filter?.status);
      form.setFieldsValue({
        status: history.location.state?.filter?.status,
      });
    }

    history.location.state?.filter?.username &&
      form.setFieldsValue({
        username: history.location.state?.filter?.username,
      });
  }, [history]);



  useEffect(() => {
    if (filter) {
      fetchWithFilter({ ...filter });
    }
  }, [filter]);

  const onFilterSubmit = (value: any) => {

    const finalFilter = { ...filter };
    if (value?.date) {
      finalFilter.start = dayjs(value.date[0]).format("YYYY-MM-DD");
      finalFilter.end = dayjs(value.date[1]).format("YYYY-MM-DD");
    } else {
      delete finalFilter.start;
      delete finalFilter.end;
    }
    if (value?.username) {
      finalFilter.searchQuery = value?.username;
    } else {
      delete finalFilter.searchQuery;
    }
    if (!value?.status || value?.status === "All") {
      delete finalFilter.status
    } else {
      finalFilter.status = value?.status;

    }
    if (!value?.custom_date || value?.custom_date === "All") {
      delete finalFilter.custom_date
    } else {
      finalFilter.custom_date = customDate.find((item) => item.title === value.custom_date)?.date
    }


    setFilter({ ...finalFilter, page: 1, limit: finalFilter.limit, });
  };

  const handleDateChange = (date: any, dateString: string) => {
    setScheduledDate(date);
  };

  const handleClearFilter = () => {
    setSelectedStatus("All")
    setSelectCustomDate("All")
    setFilter({
      page: filter.page,
      limit: filter.limit
    })
    form.setFieldsValue({
      username: "",
      status: "",
      date: ""
    });
  }

  return (
    <>
      <Card>
        <Row gutter={[16, 16]} align={"middle"} justify={"space-between"}>
          <Col>

            <Typography.Title level={3} style={{ fontWeight: 800 }}>
              Video KYC Requests Listing
            </Typography.Title>
          </Col>
          <Col>

            <Button icon={<CloseCircleOutlined />} danger onClick={handleClearFilter} >Clear Filter</Button>

          </Col>
        </Row>
        <Form
          onFinish={onFilterSubmit}
          initialValues={{ username: "" }}
          form={form}
          className='kyc-list-filter'
        >
          <Row gutter={[20, 20]} align={"middle"}>
            <Col lg={4}>
              <AntFormItem name="username" className='search-filter'>
                <Input.Search />
              </AntFormItem>
            </Col>
            <Col lg={4}>
              <Form.Item hidden name="status">
                <Input />
              </Form.Item>

              <Dropdown trigger={["click"]} overlay={menuStatus}>
                <Row align="middle" justify="space-between">
                  <Typography.Text
                    style={{ textTransform: "capitalize" }}
                    strong={!!selectedStatus}
                  >
                    {selectedStatus ? selectedStatus : "Choose Status"}
                  </Typography.Text>
                  <ArrowDownIcon />
                </Row>
              </Dropdown>
            </Col>

            <Col lg={4}>
              <Form.Item hidden name="custom_date">
                <Input />
              </Form.Item>

              <Dropdown trigger={["click"]} overlay={dateMenu}>
                <Row align="middle" justify="space-between">
                  <Typography.Text
                    style={{ textTransform: "capitalize" }}
                    strong={!!selectCustomDate}
                  >
                    {selectCustomDate ? selectCustomDate : "Choose Date"}
                  </Typography.Text>
                  <ArrowDownIcon />
                </Row>
              </Dropdown>
            </Col>


            <Col lg={4}>
              <AntFormItem name="date" className='date-range-filter'>
                <DatePicker.RangePicker style={{ width: "100%" }} />
              </AntFormItem>
            </Col>
            <Col lg={4}>
              <Button htmlType="submit" type="primary" className="primary--alt">
                Search
              </Button>
            </Col>
            <Col lg={4}>
              <Space
                align="end"
                onClick={() => setShowModal(true)}
                style={{ cursor: "pointer" }}
              >
                <Typography.Title style={{ marginBottom: 0 }} level={5}>
                  {" "}
                  Schedule Kyc{" "}
                </Typography.Title>
                <PlusCircleFilled />
              </Space>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col lg={24}>
            <Table
              rowKey="id"
              columns={userColumns}
              dataSource={list}
              loading={loading || updating}
              pagination={{
                current: parseInt(pageNumber),
                total: parseInt(totalRecords),
                onChange: (page: number) => {
                  setFilter((prev) => ({
                    ...prev,
                    page: page
                  }))
                },
                showSizeChanger: true,
                onShowSizeChange: (current, size) => {
                  setFilter((prev) => ({
                    ...prev,
                    limit: size
                  }))

                },
              }}
            />
          </Col>
        </Row>
      </Card>
      <Modal
        visible={showModal}
        destroyOnClose
        footer={false}
        onCancel={() => setShowModal(false)}
      >
        <Typography.Title style={{ fontSize: "18px" }}>
          Schedule Kyc
        </Typography.Title>
        <Select
          filterOption={false}
          style={{ width: "100%" }}
          onChange={(val) => setUserId(val)}
          showSearch={true}
          onSearch={handleSelectSearch}
          searchValue={selectSearch}
          placeholder="Select user"
          loading={loading}
          defaultActiveFirstOption={false}
        >
          {userListVideoKyc.length > 0 &&
            userListVideoKyc.map((item: { username: string; id: string, email: string }) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  <Typography> {item.username} <span style={{ fontSize: 10, fontWeight: 600 }}> ({item.email})  </span> </Typography>
                </Select.Option>
              );
            })}
        </Select>

        <ScheduleKycDatePicker
          showTime
          format={"YYYY-MM-DD HH:mm:ss"}
          style={{ width: "100%", marginTop: "12px" }}
          value={scheduledDate}
          onChange={(date: any, dateString: string) =>
            handleDateChange(date, dateString)
          }
          disabledDate={scheduleKycDateValidation}
          disabledTime={scheduleKycTimeValidation}
        />

        <div>
          <Button
            style={{ marginTop: "12px" }}
            loading={scheduling}
            onClick={() => {
              dispatch(
                scheduleVideoKyc({
                  formId: userId,
                  preferred_datetime: scheduledDate
                    ? dayjs(scheduledDate).format("YYYY-MM-DD HH:mm:ss")
                    : "",
                })
              );
            }}
            disabled={!userId || !scheduledDate}
          >
            Submit{" "}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UserList;
