import { Reducer } from "react";
import { DashboardActions } from "./DashboardActions";
import {
  GET_ACCOUNTS_STATS_FAILURE,
  GET_ACCOUNTS_STATS_REQUEST,
  GET_ACCOUNTS_STATS_SUCCESS,
  GET_COMPLETED_STATS_GRAPH_FAILURE,
  GET_COMPLETED_STATS_GRAPH_REQUEST,
  GET_COMPLETED_STATS_GRAPH_SUCCESS,
  GET_PAYMENT_STATS_FAILURE,
  GET_PAYMENT_STATS_REQUEST,
  GET_PAYMENT_STATS_SUCCESS,
} from "./DashboardTypes";

interface InitialState {
  paymentStatsLoading: boolean;
  paymentStatsDetails: any;

  accountStatsLoading: boolean;
  accountStatsDetails: any;

  completedGraphStatsLoading: boolean;
  completedGraphStatsDetails: any;
}

const INIT_STATE: InitialState = {
  paymentStatsLoading: false,
  paymentStatsDetails: {},
  accountStatsLoading: false,
  accountStatsDetails: {},
  completedGraphStatsLoading: false,
  completedGraphStatsDetails: [],
};
export const DashboardReducer: Reducer<InitialState, DashboardActions> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {
    case GET_PAYMENT_STATS_REQUEST: {
      return {
        ...state,
        paymentStatsLoading: true,
      };
    }
    case GET_PAYMENT_STATS_SUCCESS: {
      return {
        ...state,
        paymentStatsLoading: false,
        paymentStatsDetails: action.payload,
      };
    }
    case GET_PAYMENT_STATS_FAILURE: {
      return {
        ...state,
        paymentStatsLoading: false,
      };
    }
    case GET_ACCOUNTS_STATS_REQUEST: {
      return {
        ...state,
        accountStatsLoading: true,
      };
    }
    case GET_ACCOUNTS_STATS_SUCCESS: {
      return {
        ...state,
        accountStatsDetails: action.payload,
        accountStatsLoading: false,
      };
    }
    case GET_ACCOUNTS_STATS_FAILURE: {
      return {
        ...state,
        accountStatsLoading: false,
      };
    }

    case GET_COMPLETED_STATS_GRAPH_REQUEST: {
      return {
        ...state,
        completedGraphStatsLoading: true,
      };
    }

    case GET_COMPLETED_STATS_GRAPH_SUCCESS: {
      return {
        ...state,
        completedGraphStatsLoading: false,
        completedGraphStatsDetails: action.payload,
      };
    }

    case GET_COMPLETED_STATS_GRAPH_FAILURE: {
      return {
        ...state,
        completedGraphStatsLoading: false,
      };
    }

    default:
      return { ...state };
  }
};
