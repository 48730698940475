import { CheckCircleFilled } from '@ant-design/icons';
import { Table, Typography } from 'antd';
import MyContent from 'Components/Content/Content';
import ListHeader from 'Components/ListHeader/ListHeader';
import Status from 'Components/Status/Status';
import TableActions from 'Components/TableAction/TableAction';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteKyc, fetchKycs } from 'Redux/KycRedux/KycActions';
import { FilterType } from 'Types/Types';
import { useDebounce } from 'use-debounce/lib';
import { AccessControl } from 'Utils/AccessControl';

export const KycList = (props: any) => {
	const kycs = useSelector((state: any) => state.KycReducer);
	const [filter, setFilter] = useState<FilterType>({})
	const [filterText, setFilterText] = useState('');
	const [debounceFilter] = useDebounce(filterText, 500);
	const dispatch = useDispatch();

	const history = useHistory();

	const { loading, list, totalRecords } = kycs;

	useEffect(() => {
		// setFilter({ ...filter })
	}, [debounceFilter])

	//Fetch data with filter
	const fetchWithFilter = (data: any) => {
		dispatch(fetchKycs({ ...data, ...filter }))
	}


	//edit button click handler
	const onEditHandler = (data: any) => {
		history.push(`kyc/${data?.id}/edit`)
	}

	const onDeleteHandler = (id: number) => {
		dispatch(deleteKyc({ id: id }))
	}

	const kycColumns = [

		{
			dataIndex: 'isCompleted',
			key: 'isCompleted',
			width: '30px',
			render: (row: any, text: any, index: number) => row ? <CheckCircleFilled /> : ''
		},
		{
			title: '#',
			render: (row: any, text: any, index: number) => <Typography.Text>{index + 1}</Typography.Text>,
		},
		{
			title: 'Kyc Number',
			dataIndex: 'kycNumber',
			key: 'kycNumber'
		},
		{
			title: 'Kyc Date',
			dataIndex: 'startTime',
			key: 'startTime',
			render: (row: any, text: any, index: number) => <Typography.Text>{dayjs(row)?.format('LLL')}</Typography.Text>
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			sorter: (a: any, b: any) => a.title.localeCompare(b.title),
			render: (row: any, text: any, index: number) => <Typography.Link strong onClick={(e: any) => {
				// dispatch(setLayout({ form: 'kyc', update: false, readOnly: true, data: text }))
				history.push(`/kyc/${text?.id}`)
			}}>{row}</Typography.Link>
		},


		{
			title: 'Agenda',
			dataIndex: 'agendas',
			key: 'agendas',
			render: (row: any, text: any, index: number) => <Typography.Text strong>{text?.isCompleted ? text?.agenda_history?.length : text?.agenda?.length || 0}</Typography.Text>
		},
		{
			title: 'Status',
			dataIndex: 'isCompleted',
			key: 'isCompleted',
			render: (row: any, text: any, index: number) => <Status id={row ? 'completed' : text?.status} />
		},
		{
			title: 'Comittee',
			dataIndex: 'team',
			key: 'type',
			render: (text: any, row: any) => <Typography.Text>{row?.team?.title}</Typography.Text>
		},
		{
			title: '',
			dataIndex: 'id',
			key: 'id',
			render: (text: any, row: any, index: number) => <TableActions
				noReport
				onDelete={() => onDeleteHandler(row.id)}
				onEdit={() => onEditHandler(row)}
			/>
		}
	];

	useEffect(() => {
		if (filter) {
			fetchWithFilter({ ...filter })
		}
	}, [filter])

	return (
		<>
			<MyContent shadow>
				<div >
					<ListHeader
						title="Kycs"
						noAddFeature={!AccessControl('kyc')?.create}
						module="kyc"
						onSearch={(e: any) => setFilterText(e)}
						searchable
					/>
				</div>
				<Table
					className="style--kbbs"
					columns={kycColumns}
					dataSource={list}

					loading={loading}
					onRow={(record, rowIndex) => {
						return {

						};
					}}
				/>
			</MyContent>
		</>
	);
};

export default KycList;
