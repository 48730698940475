import './Loading.scss';

import { Col, Row, Spin } from 'antd';

export const Loading = (props: any) => {
	const { fullScreen, title } = props;
	return (
		<Row className={fullScreen ? 'full--screen' : ''} justify="center" style={{ margin: '40px 0px', minHeight: '30vh', alignItems: 'center' }}>
			<Col>
				<Spin tip={title || 'Getting Data'} />
			</Col>
		</Row>
	);
};

export default Loading;
