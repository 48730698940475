import { Auth } from '@aws-amplify/auth'
import { notification } from 'antd'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { requestLoginFailure, requestLoginSuccess } from './AuthActions'
import { RequestLoginParams, REQUEST_LOGIN } from './AuthTypes'

//get auths
const tryLoginAsync = async (data: RequestLoginParams) => {
  const res = await Auth.signIn(data.username, data.password)
  return res
}

function* tryLogin(params: any) {
  const { payload } = params

  try {
    const auths: AxiosResponse = yield call(tryLoginAsync, payload)

    if (auths) {
      yield put(requestLoginSuccess(auths))
    } else {
      yield put(requestLoginFailure('Could not get list of auths'))
      notification.error({
        message: 'Login Failed',
        description: ' error?.message',
      })
    }
  } catch (error: any) {
    yield put(requestLoginFailure(error))
    notification.error({
      message: 'Login Failed',
      description: error?.message,
    })
  }
}

function* watchTryLogin() {
  yield takeLatest(REQUEST_LOGIN, tryLogin)
}

export default function* AuthSagas() {
  yield all([fork(watchTryLogin)])
}
