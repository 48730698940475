import { Button, Col, Input, Modal, Row, Typography, Form } from "antd";
import { FormInstance } from "antd/es/form";

const AcceptReject = (props: {
  setVerifyKyc: (accept: boolean) => void
  setRejectKyc: (reject: boolean) => void
  displayAcceptModal: boolean
  displayRejectModal: boolean
  kycRejectReason: string
  setKycRejectReason: (value: string) => void
  acceptForm: FormInstance<any>
  loading: boolean
  onSubmit: () => void
}) => {
  return (
    <Modal
      destroyOnClose
      footer={false}
      onCancel={() => {
        props.setVerifyKyc(false);
        props.setRejectKyc(false);
      }}
      visible={props.displayAcceptModal || props.displayRejectModal}
    >
      <Typography.Title level={4}>
        {props.displayAcceptModal
          ? "Do you want to verify KYC?"
          : "Do you want to reject KYC?"}
      </Typography.Title>
      <Form
        style={{ marginTop: 20 }}
        layout="vertical"
        onFinish={() => {}}
        onReset={() => {}}
        form={props.acceptForm}
      >
        {props.displayRejectModal && (
          <Form.Item
            name="reason"
            label="Reason"
            rules={[{ required: true, message: "Reason must be provided" }]}
          >
            <Input.TextArea
              style={{ minHeight: 100, padding: "10px 15px" }}
              placeholder="Please enter reason"
              onChange={e => props.setKycRejectReason(e.target.value)}
            />
          </Form.Item>
        )}
        <Form.Item hidden name="account" label="Account">
          <Input />
        </Form.Item>
        <Row justify={props.displayAcceptModal ? "center" : "end"} gutter={[20, 20]}>
          <Col>
            <Button
              htmlType="button"
              onClick={() => {
                props.setRejectKyc(false);
                props.setVerifyKyc(false);
              }}
              type={props.displayRejectModal ? "default" : "primary"}
              danger={props.displayAcceptModal}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="button"
              onClick={props.onSubmit}
              disabled={props.displayRejectModal && props.kycRejectReason.length < 1 || false}
              loading={props.loading}
            >
              {props.displayAcceptModal? 'Accept': 'Reject'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AcceptReject;
