import {
	CreateUserParams,
	CREATE_USER,
	CREATE_USER_FAILURE,
	CREATE_USER_SUCCESS,
	FETCH_USERS,
	FETCH_USERS_FAILURE,
	FETCH_USERS_SUCCESS,
	FETCH_USER,
	FETCH_USER_FAILURE,
	FETCH_USER_SUCCESS,
	REMOVE_USER,
	REMOVE_USER_FAILURE,
	REMOVE_USER_SUCCESS,
	UPDATE_USER,
	UPDATE_USER_FAILURE,
	UPDATE_USER_SUCCESS,
	UserDetailsParams,
	UserFetchParams
} from './UserTypes';

//fetch User List
export const fetchUsers = (params: UserFetchParams) => ({
	type: FETCH_USERS,
	payload: params
});

export const fetchUsersSuccess = (response: any) => ({
	type: FETCH_USERS_SUCCESS,
	payload: response
});

export const fetchUsersFailure = (error: any) => ({
	type: FETCH_USERS_FAILURE,
	payload: error
});

//fetch User Details
export const fetchUser = (params: UserDetailsParams) => ({
	type: FETCH_USER,
	payload: params
});

export const fetchUserSuccess = (response: any) => ({
	type: FETCH_USER_SUCCESS,
	payload: response
});

export const fetchUserFailure = (error: any) => ({
	type: FETCH_USER_FAILURE,
	payload: error
});

//create User
export const createUser = (params: CreateUserParams) => ({
	type: CREATE_USER,
	payload: params
});

export const createUserSuccess = (response: CreateUserParams) => ({
	type: CREATE_USER_SUCCESS,
	payload: response
});
export const createUserFailure = (error: any) => ({
	type: CREATE_USER_FAILURE,
	payload: error
});

//update User
export const updateUser = (params: CreateUserParams) => ({
	type: UPDATE_USER,
	payload: params
});

export const updateUserSuccess = (response: CreateUserParams) => ({
	type: UPDATE_USER_SUCCESS,
	payload: response
});

export const updateUserFailure = (error: any) => ({
	type: UPDATE_USER_FAILURE,
	payload: error
});

//delete User
export const deleteUser = (params: UserDetailsParams) => ({
	type: REMOVE_USER,
	payload: params
});
export const deleteUserSuccess = (response: UserDetailsParams) => ({
	type: REMOVE_USER_SUCCESS,
	payload: response
});
export const deleteUserFailure = (error: any) => ({
	type: REMOVE_USER_FAILURE,
	payload: error
});
