import { Auth } from "aws-amplify";
import axios from "axios";
import config from "Config/config";
import { DpAccountsFilterDataProps } from "Types/Types";

import {
  CreateCustomerAccountProps,
  UpdateCustomerAccountProps,
} from "./AccountTypes";

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const fetchAccountListApi = async (data: DpAccountsFilterDataProps) => {
  return axios.get(`accounts/chart-of-account/list`, {
    params: data,
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const createCustomerAccountApi = async (
  data: CreateCustomerAccountProps
) => {
  return axios.post(`accounts/chart-of-account/create`, data, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const updateCustomerAccountApi = async (
  data: UpdateCustomerAccountProps
) => {
  const { id, body } = data;
  return axios.patch(`accounts/chart-of-account/${id}/update`, body, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const deleteCustomerAccountApi = async (id: string) => {
  return axios.delete(`accounts/chart-of-account/${id}/delete`, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const fetchAccountTypeListApi = async () => {
  return axios.get(`accounts/list`, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};
