import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { saveFile } from 'Utils/saveFile';

import {
  downloadTransactionReportFailure,
  downloadTransactionReportSuccess,
  fetchVoucherDetailsFailure,
  fetchVoucherDetailsSuccess,
  fetchVoucherListFailure,
  fetchVoucherListSuccess,
  uploadCdscFileFailure,
  uploadCdscFileSuccess,
} from './TransactionAction';
import {
  downloadTransactionReportApi,
  fetchVoucherDetailsApi,
  fetchVoucherListApi,
  uploadCdscFileApi,
} from './TransactionApi';
import {
  DOWNLOAD_TRANSACTION_REPORT,
  FETCH_VOUCHER_DETAILS,
  FETCH_VOUCHER_LIST,
  FetchVoucherDetailsProps,
  FetchVoucherListProps,
  UPLOAD_CDS_FILE,
} from './TransactionTypes';

const uploadedCdscFileAsync = async (data: File) => {
    return await uploadCdscFileApi(data);
};


function* uploadCdscFile(data: any) {
    const { payload } = data

    try {
        const response: AxiosResponse = yield uploadedCdscFileAsync(payload);

        if (response) {
            yield put(uploadCdscFileSuccess(response.data));
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.file[0] ?? "Something went wrong";
            yield put(uploadCdscFileFailure());
            message.error(messages);
        }


    }
}

function* watchUploadCdscFile() {
    yield takeLatest(UPLOAD_CDS_FILE, uploadCdscFile);
}


const fetchVoucherListAsync = async (data: FetchVoucherListProps) => {
    return await fetchVoucherListApi(data)
}


function* fetchVoucherList(data: any) {
    const { payload } = data

    try {

        const response: AxiosResponse = yield fetchVoucherListAsync(payload)
        if (response) {
            yield put(fetchVoucherListSuccess(response.data))
        }

    } catch (error) {

        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data.message ?? "Something went wrong";
            yield put(fetchVoucherListFailure());
            message.error(messages);
        }

    }
}

function* watchFetchVoucherList() {
    yield takeLatest(FETCH_VOUCHER_LIST, fetchVoucherList)
}

const downloadTransactionReport = async (data: FetchVoucherListProps, fileType: string) => {
    return await downloadTransactionReportApi(data, fileType);
};

function* downloadReport(params: any) {
    const { payload, fileType } = params;

    try {
        const downloadReport: AxiosResponse = yield call(
            downloadTransactionReport,
            payload,
            fileType
        );
        if (downloadReport) {
            const contentType =
                fileType === "pdf"
                    ? "application/pdf"
                    : fileType === "csv"
                        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        : "";
            const extension =
                fileType === "pdf" ? "pdf" : fileType === "excel" ? "xlsx" : "";
            saveFile(downloadReport.data, contentType, `transaction_report.${extension}`);
            yield put(downloadTransactionReportSuccess());
            message.success("File downloaded successfully");
        }
    } catch (error) {
        const messages = "Network Error while downloading file";
        yield put(downloadTransactionReportFailure());
        message.error(messages);
    }
}

function* watchDownloadFile() {
    yield takeLatest(DOWNLOAD_TRANSACTION_REPORT, downloadReport);
}




const fetchVoucherDetailsAsync = async (data: FetchVoucherDetailsProps) => {
    return await fetchVoucherDetailsApi(data)
}


function* fetchVoucherDetails(data: any) {
    const { payload } = data

    try {

        const response: AxiosResponse = yield fetchVoucherDetailsAsync(payload)
        if (response) {
            yield put(fetchVoucherDetailsSuccess(response.data))
        }

    } catch (error) {

        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data.message ?? "Something went wrong";
            yield put(fetchVoucherDetailsFailure());
            message.error(messages);
        }

    }
}

function* watchFetchVoucherDetails() {
    yield takeLatest(FETCH_VOUCHER_DETAILS, fetchVoucherDetails)
}



export default function* DpTransactionSagas() {
    yield all([fork(watchUploadCdscFile), fork(watchFetchVoucherList), fork(watchDownloadFile), fork(watchFetchVoucherDetails)]);
}