import { FormItemTypes } from "Types/Types";

export const AddressDetailsFields: FormItemTypes[] = [
  {
    label: "COUNTRY",
    type: "text",
    name: "country",
    required: true,
    span: 8,
  },
  {
    label: "MUNICIPALITY",
    type: "text",
    name: "sub",
    required: true,
    span: 8,
  },
  {
    label: "DISTRICT",
    type: "select",
    name: "district",
    required: true,
    span: 8,
    remoteRoute: "address",
    selectSearchable: true,
  },
  {
    label: "PROVINCE",
    type: "text",
    name: "province",
    required: true,
    span: 8,
  },

  {
    label: "TOLE",
    type: "text",
    name: "tole",
    required: true,
    span: 8,
  },
  {
    label: "WARD NO",
    type: "number",
    name: "ward",
    required: true,
    span: 8,
  },
  {
    label: "Nearest Landmark",
    type: "text",
    name: "nearest_landmark",
    required: true,
    span: 8,
  },
  {
    label: "BLOCK NO",
    type: "number",
    name: "block",
    required: false,
    span: 8,
  },
  {
    label: "Fax",
    type: "number",
    name: "fax",
    required: false,
    span: 8,
  },
];
