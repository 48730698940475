import { Reducer } from "react";

import {
  GENERATE_PDF_EXCEL,
  GENERATE_PDF_EXCEL_FAILURE,
  GENERATE_PDF_EXCEL_SUCCESS,
  GENERATE_REPORT,
  GENERATE_REPORT_FAILURE,
  GENERATE_REPORT_SUCCESS,
} from "./ReportsTypes";

const INIT_STATE = {
  generatingReport: false,
  generatedReport: false,
  generatedReportData: {},
  generatingPdfExcel: false,
  generatedPdfExcel: false,
};

export const dpReport: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GENERATE_REPORT:
      return {
        ...state,
        generatingReport: true,
        generatedReport: false,
      };

    case GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        generatingReport: false,
        generatedReport: true,
        generatedReportData: action.payload,
      };

    case GENERATE_REPORT_FAILURE:
      return {
        ...state,
        generatingReport: false,
        generatedReport: false,
        generatedReportData: {},
      };

    case GENERATE_PDF_EXCEL:
      return {
        ...state,
        generatingPdfExcel: true,
        generatedPdfExcel: false,
      };

    case GENERATE_PDF_EXCEL_SUCCESS:
      return {
        ...state,
        generatingPdfExcel: false,
        generatedPdfExcel: true,
      };

    case GENERATE_PDF_EXCEL_FAILURE:
      return {
        ...state,
        generatingPdfExcel: false,
        generatedPdfExcel: false,
      };

    default:
      return {
        ...state,
      };
  }
};
