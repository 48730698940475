// import { getRole } from 'Api/Api';


//module access control
const modulesAccess: any = {
	agenda: {
		create: [ 'staff' ],
		edit: [ 'staff' ],
		delete: [ 'staff' ],
		view: [ 'staff'],
		list: [ 'staff' ]
	},
	meeting: {
		create: [ 'staff_admin' ],
		edit: [ 'staff' ],
		delete: [ 'staff' ],
		view: [ 'staff'],
		list: [ 'staff' ]
	},
	comittee: {
		create: [ 'staff_admin' ],
		edit: [ 'staff' ],
		delete: [ 'staff' ],
		view: [ 'staff'],
		list: [ 'staff' ]
	},
	hierarchy: {
		create: [ 'staff_admin' ],
		edit: [ 'staff' ],
		delete: [ 'staff' ],
		view: [ 'staff'],
		list: [ 'staff' ]
	},
	task: {
		create: [ 'staff_admin' ],
		edit: [ 'staff' ],
		delete: [ 'staff' ],
		view: [ 'staff'],
		list: [ 'staff' ]
	},
	department: {
		create: [ 'staff_admin' ],
		edit: [ 'staff' ],
		delete: [ 'staff' ],
		view: [ 'staff'],
		list: [ 'staff' ]
	},
	loginscreen: {
		create: [ 'staff_admin', 'staff' ],
		edit: [ 'staff' ],
		delete: [ 'staff' ],
		view: [ 'staff'],
		list: [ 'staff' ]
	},
	cctvreporting: {
		create: [ 'staff_admin', 'staff' ],
		edit: [ 'staff' ],
		delete: [ 'staff' ],
		view: [ 'staff'],
		list: [ 'staff' ]
	},
	vendor: {
		create: [ 'staff_admin', 'staff' ],
		edit: [ 'staff' ],
		delete: [ 'staff' ],
		view: [ 'staff'],
		list: [ 'staff' ]
	},
};


export const AccessControl = (module: string) => {
	// const roleData: any = getRole();
	const roleData: any = 'staff_admin';
	let role = roleData;
	return {
		create: modulesAccess[module]?.create?.includes(role),
		delete: modulesAccess[module]?.delete?.includes(role),
		edit: modulesAccess[module]?.edit?.includes(role),
		view: modulesAccess[module]?.view?.includes(role),
		list: modulesAccess[module]?.list?.includes(role)
	};
};

export const RoleDefinition = () => {
	const roleData: any = '';
	
	return {
		isAdmin: roleData.some((role: any) => role?.name === 'admin'),
		isUser: roleData.some((role: any) => role?.name === 'user'),
	};
};

//control for navigation

const navigationAccess: any = {
	user: ['dashboard', 'task', 'meeting', 'agenda', 'cctv'],
	admin: ['dashboard', 'task', 'meeting', 'agenda', 'committee', 'setting', 'gantt', 'cctvreporting', 'cctv', 'vendor'],
	superadmin: ['dashboard', 'task', 'meeting', 'agenda', 'committee', 'setting', 'gantt', 'cctv', 'vendor']
};



export const NavigationAccess = (module: string) => {
	const roleData: any = [];
	if (roleData.length > 0) {
		// console.log(module, roleData?.some((role: any)=> navigationAccess[role?.name]?.includes(module)))
		return roleData?.some((role: any)=> navigationAccess[role?.name]?.includes(module));
	}
	else {
		return false;
	}
};


//self data only control
export const IsSelfOnly: any = () => {
	const roleData: any = '';
	let role = roleData?.type;
	return role === 'staff';
}
