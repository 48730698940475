import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { AntFormItem } from 'Components/AntForm/AntForm';
import Modal from 'Components/Modal';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  createCustomerAccount,
  fetchAccountTypeList,
  updateCustomerAccount,
} from 'Redux/DpRedux/Accounts/AccountActions';
import { DpAccountsFilterDataProps } from 'Types/Types';

import { ShowAddModalProps } from '../Accounts';

interface FilterComponentProps {
  showAddModal: ShowAddModalProps;
  setShowAddModal: Dispatch<SetStateAction<ShowAddModalProps>>;
  addUpdateForm: FormInstance;
  setFilterData: Dispatch<SetStateAction<DpAccountsFilterDataProps>>;
  filterData: DpAccountsFilterDataProps;
}

export const FilterComponent = ({
  showAddModal,
  setShowAddModal,
  addUpdateForm,
  setFilterData,
  filterData,
}: FilterComponentProps) => {
  const dispatch = useDispatch();
  const [formFilter] = Form.useForm();

  const { creating, list, accountTypeList } = useSelector(
    (state: any) => state.accountReducer
  );

  const [accountType, setAccountType] = useState("All")

  useEffect(() => {
    dispatch(fetchAccountTypeList());
  }, [dispatch]);

  const onFilterSubmit = (value: any) => {
    setFilterData((prevState) => ({
      ...prevState,
      search: value.username,
      account_type: accountType,
      page: 1,
    }));
  };

  const handleSubmit = (data: any) => {
    const postData = {
      ...data,
      account_type: Number(data.account_type),
    };

    if (showAddModal.userId) {
      dispatch(
        updateCustomerAccount({
          id: showAddModal.userId,
          body: postData,
        })
      );
    } else {
      dispatch(createCustomerAccount(postData));
    }
    setShowAddModal({
      userId: "",
      visible: false,
    });
    addUpdateForm.resetFields();
  };

  return (
    <div className="filter-component">
      <Form onFinish={onFilterSubmit} form={formFilter}>
        <Row gutter={[20, 20]} align="middle">
          <Col lg={7}>
            <AntFormItem name="username" className="search-box">
              <Input.Search />
            </AntFormItem>
          </Col>
          <Col lg={6}>
            <Select
              onChange={(val) => setAccountType(val)}
              style={{
                width: "100%",
              }}
              value={accountType}
            >
              <Select.Option key={"all"} value={"all"} >All</Select.Option>
              {accountTypeList.map((item: any) => (
                <Select.Option key={item?.id} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>

          </Col>
          <Col lg={4}>
            <Button htmlType="submit" type="primary" className="primary--alt">
              Search
            </Button>
          </Col>{" "}

          {/* <Col lg={4} offset={6}>
            <Space
              align="end"
              onClick={() =>
                setShowAddModal({
                  userId: "",
                  visible: true,
                })
              }
              className="add-account"
            >
              <Typography.Title className="title" level={5}>
                {" "}
                Add Accounts{" "}
              </Typography.Title>
              <PlusCircleFilled />
            </Space>
          </Col>  */}
        </Row>{" "}
      </Form>

      <Modal
        visible={showAddModal.visible}
        onCancel={() => {
          addUpdateForm.resetFields();
          setShowAddModal({
            userId: "",
            visible: false,
          });
        }}
      >
        <div>
          <Typography.Title level={4}>
            {showAddModal.userId ? "Update" : "Add"} Account
          </Typography.Title>
          <Form layout="vertical" form={addUpdateForm} onFinish={handleSubmit}>
            <Row gutter={[20, 20]}>
              <Col lg={12}>
                <Form.Item
                  colon={false}
                  label="Account Code"
                  name="account_code"
                  rules={[
                    { required: true, message: "Account Code is required!" },
                  ]}
                >
                  <Input placeholder="Add Account Code" />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  colon={false}
                  label="Account Name"
                  name="account_name"
                  rules={[
                    { required: true, message: "Account name is required!" },
                  ]}
                >
                  <Input placeholder="Add Account name" />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item
                  colon={false}
                  label="Account Type"
                  name="account_type"
                >
                  <Select
                    filterOption={false}
                    placeholder="Add Account type"
                    options={accountTypeList.map((data: any) => {
                      return {
                        value: data.id,
                        label: data.name,
                      };
                    })}
                  />
                </Form.Item>
              </Col>
              {/**can be need in future  */}
              {/* <Col lg={12}>
                <Form.Item
                  colon={false}
                  label="Parent Account"
                  name="parent_account"
                >
                  <Select
                    filterOption={false}
                    showSearch
                    searchValue={filterData.search}
                    placeholder="Add Parent Account"
                    onSearch={(value: string) => {
                      setFilterData((prevState) => ({
                        ...prevState,
                        search: value,
                        page: 1,
                      }));
                    }}
                    options={list.map((data: any) => {
                      return {
                        value: data.account_code,
                        label: data.account_name,
                      };
                    })}
                  />
                </Form.Item>
              </Col> */}
            </Row>
            <Row>
              <Col lg={4} offset={16}>
                <Button
                  onClick={() =>
                    setShowAddModal({
                      userId: "",
                      visible: false,
                    })
                  }
                >
                  Cancel
                </Button>
              </Col>
              <Col lg={4}>
                <Button type="primary" htmlType="submit" loading={creating}>
                  {showAddModal.userId ? "Update" : "Create"}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
