export const GET_PAYMENT_STATS_REQUEST = "GET_PAYMENT_STATS_REQUEST";
export const GET_PAYMENT_STATS_SUCCESS = "GET_PAYMENT_STATS_SUCCESS";
export const GET_PAYMENT_STATS_FAILURE = "GET_PAYMENT_STATS_FAILURE";

export const GET_ACCOUNTS_STATS_REQUEST = "GET_ACCOUNTS_STATS_REQUEST";
export const GET_ACCOUNTS_STATS_SUCCESS = "GET_ACCOUNTS_STATS_SUCCESS";
export const GET_ACCOUNTS_STATS_FAILURE = "GET_ACCOUNTS_STATS_FAILURE";

export const GET_COMPLETED_STATS_GRAPH_REQUEST =
  "GET_COMPLETED_STATS_GRAPH_REQUEST";
export const GET_COMPLETED_STATS_GRAPH_SUCCESS =
  "GET_COMPLETED_STATS_GRAPH_SUCCESS";
export const GET_COMPLETED_STATS_GRAPH_FAILURE =
  "GET_COMPLETED_STATS_GRAPH_FAILURE";


