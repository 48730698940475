import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, Input, Row, Space, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import MyContent from 'Components/Content/Content'
import PageLoading from 'Components/PageLoading/PageLoading'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { createUser, fetchUser, updateUser } from 'Redux/UserRedux/UserActions'

export const UserForm = (props: any) => {
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.UserReducer)
  const { creating, updating, details, detailing } = user

  const { update } = props

  const { id } = useParams<any>()

  const [form] = useForm()

  const history = useHistory()

  const onBackHandler = () => {
    if (id !== 'add') {
      history.push(`/user/${id}`)
    } else {
      history.push(`/user`)
    }
  }

  const onFinish = (values: any) => {
    if (update) {
      dispatch(updateUser({ ...values, id: details?.id }))
    } else {
      dispatch(createUser({ ...values, status: 'pending' }))
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      ...details,
    })
  }, [details])

  useEffect(() => {
    if (!user?.detailed && id && id !== 'add') {
      dispatch(fetchUser({ id: id }))
    }
  }, [id])

  return (
    <PageLoading loading={detailing}>
      <MyContent>
        <Space align='center' size='large'>
          <Button
            onClick={() => onBackHandler()}
            shape='circle'
            size='small'
            icon={<ArrowLeftOutlined />}
          />

          <Typography.Title level={5}>
            {update ? details?.title : 'ADD TASK'}{' '}
          </Typography.Title>
        </Space>
        <Divider />
        <Form
          layout='vertical'
          form={form}
          name='basic'
          onFinish={onFinish}
          className='style--kbbs'
        >
          <Row gutter={[20, 0]}>
            <Col lg={18}>
              <Form.Item
                label='User Number'
                name='userNo'
                rules={[
                  { required: true, message: 'User Number is required!' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={18}>
              <Form.Item
                label='Title'
                name='title'
                rules={[{ required: true, message: 'Title is required!' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={24}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    loading={creating || updating}
                    type='primary'
                    size='large'
                    shape='round'
                    htmlType='submit'
                  >
                    {update ? 'UPDATE' : 'ADD'}
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </MyContent>
    </PageLoading>
  )
}

export default UserForm
