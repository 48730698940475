interface GetUpdateImageKey {
  responseKey: string
  minor: boolean
  formType: string
}

export const getUpdateImageKey = ({
  responseKey,
  minor,
  formType,
}: GetUpdateImageKey) => {
  if (formType === 'meroshare') {
    if (responseKey.includes('signature')) {
      return {
        meroshare: {
          signature: responseKey,
          updated_signature: responseKey,
        },
      }
    }
  }

  //banks case todo

  if (formType === 'trading') {
    if (responseKey.includes('cheque')) {
      return {
        trading_bank_details: {
          cheque: responseKey,
        },
      }
    }

    // TODO NEED TO HANDLE MINOR AND NON MINOR CASE FOR PROFILE IMAGE'
    if (minor) {
      if (responseKey.includes('profile/guardian')) {
        return {
          guardian_details: {
            profile: responseKey,
          },
        }
      }

      if (responseKey.includes('profile')) {
        return {
          minor_details: {
            profile: responseKey,
          },
        }
      }
    } else {
      if (responseKey.includes('profile')) {
        return {
          profile: responseKey,
        }
      }
    }
  }

  if (formType !== 'trading') {
    if (responseKey.includes('cheque')) {
      return {
        bank_details: {
          cheque: responseKey,
        },
      }
    }
  }

  //nominee first
  if (responseKey.includes('signature/nominee')) {
    return {
      nominee_details: {
        signature: responseKey,
        updated_signature: responseKey,
      },
    }
  }
  if (responseKey.includes('finger-print/nominee')) {
    return {
      nominee_details: {
        thumbprint: responseKey,
      },
    }
  }

  if (responseKey.includes('citizenship/nominee')) {
    let citizenSide = ''
    if (responseKey.includes('front')) {
      citizenSide = 'front'
    } else {
      citizenSide = 'back'
    }
    return {
      nominee_details: {
        citizenship_images: {
          [citizenSide]: responseKey,
        },
      },
    }
  }

  if (minor) {
    //minor case
    if (responseKey.includes('profile/guardian')) {
      return {
        guardian_details: {
          profile: responseKey,
        },
      }
    }
    if (responseKey.includes('profile')) {
      return {
        minor_details: {
          profile: responseKey,
        },
      }
    }
    if (responseKey.includes('birth-certificate')) {
      return {
        minor_details: {
          birth_certificate_image: responseKey,
        },
      }
    }
    if (responseKey.includes('citizenship/guardian')) {
      let citizenSide = ''
      if (responseKey.includes('front')) {
        citizenSide = 'front'
      } else {
        citizenSide = 'back'
      }
      return {
        guardian_details: {
          citizenship_images: {
            [citizenSide]: responseKey,
          },
        },
      }
    }

    if (responseKey.includes('finger-print/guardian')) {
      return {
        guardian_details: {
          thumbprint: responseKey,
        },
      }
    }
    if (responseKey.includes('signature/guardian')) {
      return {
        guardian_details: {
          signature: responseKey,
          updated_signature: responseKey,
        },
      }
    }
  } else {
    //non-minor case
    if (responseKey.includes('profile')) {
      return {
        profile: responseKey,
      }
    }

    if (responseKey.includes('citizenship/user')) {
      let citizenSide = ''
      if (responseKey.includes('front')) {
        citizenSide = 'front'
      } else {
        citizenSide = 'back'
      }
      return {
        citizenship_images: {
          [citizenSide]: responseKey,
        },
      }
    }

    if (responseKey.includes('signature/user')) {
      return {
        signature: responseKey,
        updated_signature: responseKey,
      }
    }
    if (responseKey.includes('finger-print/user')) {
      return {
        thumbprint: responseKey,
      }
    }
  }
}
