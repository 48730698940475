import {
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Menu,
  Row,
  Spin,
  Typography,
} from 'antd';
import Layout, {
  Content,
  Footer,
  Header,
} from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { searchApis } from 'Api/StaticApi';
import {
  Auth,
  Storage,
} from 'aws-amplify';
import CommonHeader from 'Components/CommonHeader/CommonHeader';
import dayjs from 'dayjs';
import BatchIcon from 'Icons/BatchIcon/BatchIcon';
import CompanyLogo from 'Icons/CompanyLogo/CompanyLogo';
import DashboardIcon from 'Icons/DashboardIcon/DashboardIcon';
import DpIcon from 'Icons/DpIcon/DpIcon';
import GroupIcon from 'Icons/GroupIcon/GroupIcon';
import SettingIcon from 'Icons/SettingIcon/SettingIcon';
import VerifyKycIcon from 'Icons/VerifyKycIcon/VerifyKycIcon';
import VideoKycIcon from 'Icons/VideoKycIcon/VideoKycIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useLocation,
} from 'react-router';
import { takeAdmin } from 'Redux/AdminRedux/AdminApis';
import {
  clears3UrlBoFile,
  setBofileMessage,
  toggleBofilePopup,
} from 'Redux/BatchFileRedux(downlodFiles)/BatchFileActions';
import {
  addressOptionListFailure,
  addressOptionListSuccess,
  bankOptionListFailure,
  bankOptionListSuccess,
  bankOptionWithBranchSuccess,
  brokerSuccess,
  organizationOptionListFailure,
  organizationOptionListSuccess,
  rateListFailure,
  rateListSuccess,
} from 'Redux/OptionsRedux/OptionActions';
import { fetchUserSuccess } from 'Redux/UserRedux/UserActions';
import { downloadBlob } from 'Utils/downloadBlob';

import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  LeftCircleFilled,
  RightCircleFilled,
} from '@ant-design/icons';

export const ProtectedLayout: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState<any>();
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();
  const { showDownloadingPopup, message, s3UrlToDownload, downloadFileName } =
    useSelector((state: any) => state.BatchFileReducer);

  const { details } = useSelector((state: any) => state.UserReducer);

  useEffect(() => {
    if (!userInfo) {
      Auth.currentUserInfo()
        .then((user) => {
          setUserInfo(user);
          getUserInformation(user?.attributes?.[`custom:id`]);
        })
        .then((data) => { })
        .catch((err) => { });
    }
  }, [0]);

  const getUserInformation = async (id: any) => {
    await takeAdmin({ id: id }).then((data) => {
      dispatch(fetchUserSuccess({ data: data?.data }));
    });
  };

  const downloadBoFileFn = async (s3UrlToDownload: string) => {
    try {
      dispatch(toggleBofilePopup(true));
      dispatch(setBofileMessage("Downloading File"));
      const result: any = await Storage.get(s3UrlToDownload, {
        download: true,
        customPrefix: {
          public: "",
        },
      });
      downloadBlob(result.Body, `${downloadFileName}`);
      dispatch(setBofileMessage("Downloading Completed"));
    } catch (err) {
      dispatch(setBofileMessage("Error downloading file"));
    } finally {
      setTimeout(() => {
        dispatch(clears3UrlBoFile());
        dispatch(toggleBofilePopup(false));
        dispatch(setBofileMessage(""));
      }, 5000);
    }
  };

  useEffect(() => {
    if (s3UrlToDownload !== "") {
      downloadBoFileFn(s3UrlToDownload);
    }
  }, [s3UrlToDownload]);

  useEffect(() => {
    getAddressOptions();
    getOrganizationOptions();
    getBankOptions();
    getBrokerOptions();
    getRateList()
  }, []);

  const getAddressOptions = async () => {
    try {
      const response = await searchApis("address");
      dispatch(addressOptionListSuccess(response));
    } catch (err) {
      dispatch(addressOptionListFailure({ payload: err }));
    }
  };

  const getOrganizationOptions = async () => {
    try {
      const response = await searchApis("organization");
      dispatch(organizationOptionListSuccess(response));
    } catch (err) {
      dispatch(organizationOptionListFailure({ payload: err }));
    }
  };
  const getBrokerOptions = async () => {
    try {
      const response = await searchApis("broker");
      dispatch(brokerSuccess(response));
    } catch (err) { }
  };

  const getBankOptions = async () => {
    try {
      const response = await searchApis("bank");
      dispatch(bankOptionListSuccess(response));
      dispatch(bankOptionWithBranchSuccess(response));
    } catch (err) {
      dispatch(bankOptionListFailure({ payload: err }));
    }
  };

  const getRateList = async () => {
    try {
      const response = await searchApis("rate");
      
      dispatch(rateListSuccess(response.data));
    } catch (err) {
      dispatch(rateListFailure())
    }
  };

  return (
    <>
      <Layout className="custom-layout-theme" style={{ padding: 20 }}>
        <Sider
          collapsible
          collapsed={collapsed}
          collapsedWidth={120}
          theme="light"
          trigger={null}
          style={{
            maxHeight: "95vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          width={300}
          onCollapse={(e: any) => setCollapsed(e)}
        >
          <Row justify="space-between">
            <Col>
              <div className={`logo ${collapsed ? "collapsed" : ""}`}>
                <CompanyLogo collapsed={collapsed} />
              </div>
            </Col>
            <Col>
              <div
                className={`collapse--switch ${collapsed ? "position--fixed" : "position--absolute"
                  }`}
                onClick={() => setCollapsed(!collapsed)}
              >
                <div className="collapse--switch--wrapper">
                  {collapsed ? (
                    <RightCircleFilled
                      style={{ fontSize: 28, color: "#4C5064" }}
                    />
                  ) : (
                    <LeftCircleFilled
                      style={{ fontSize: 28, color: "#4C5064" }}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Menu
            theme="light"
            mode="inline"
            style={{ marginTop: 40 }}
            defaultSelectedKeys={["1"]}
            selectedKeys={["2"]}
            onClick={(e: any) => {
              if (e.key === "dp" && !location.pathname.includes("dp")) {
                return window.open(`/${e.key}`);
              }
              history.push(`/${e.key}`);
            }}
            inlineIndent={20}
          >
            {location.pathname.includes("dp") ? (
              <>
                <Menu.Item key="dp" title="Dashboard" icon={<DashboardIcon />}>
                  Dashboard
                </Menu.Item>
                <Menu.Item
                  key="dp/transaction"
                  title="Transactions"
                  icon={<BatchIcon />}
                >
                  Transactions
                </Menu.Item>
                <Menu.Item
                  key="dp/customer"
                  title="Customer Account"
                  icon={<GroupIcon style={{
                    marginRight: "-5px"
                  }} />}
                >
                  Customer Account
                </Menu.Item>
                <Menu.Item
                  key="dp/accounts"
                  title="Charts of Account"
                  icon={<VerifyKycIcon />}
                >
                  Charts of Account
                </Menu.Item>
                <Menu.Item
                  key="dp/reporting"
                  title="Reporting"
                  icon={<BatchIcon />}
                >
                  Reporting
                </Menu.Item>
              </>
            ) : (
              <>
                {userInfo &&
                  details &&
                  (details?.role_type?.includes("demat") ||
                    details?.role_type?.includes("meroshare")) && (
                    <Menu.Item
                      key=""
                      title="Dashboard"
                      icon={<DashboardIcon />}
                    >
                      Dashboard
                    </Menu.Item>
                  )}

                <Menu.Item key="user" icon={<GroupIcon style={{
                  marginRight: "-5px"
                }} />} title="Listing">
                  Listing
                </Menu.Item>

                {userInfo &&
                  userInfo?.attributes?.[`custom:role`] === "Super Admin" && (
                    <Menu.Item
                      key="admin"
                      title="Admin Management"
                      icon={<SettingIcon />}
                    >
                      Admin Mgmt
                    </Menu.Item>
                  )}
                <Menu.Item key="batch" title="Batch File" icon={<BatchIcon />}>
                  Batch File
                </Menu.Item>
                <Menu.Item
                  key="verifyKyc"
                  title="Verify Kyc"
                  icon={<VerifyKycIcon />}
                >
                  Verify KYC
                </Menu.Item>
                <Menu.Item
                  key="videoKycRequestList"
                  title="Video Kyc"
                  icon={<VideoKycIcon />}
                >
                  Video KYC
                </Menu.Item>
                <Menu.Item key="dp" title="DP Platform" icon={<DpIcon />}>
                  DP Platform
                </Menu.Item>
              </>
            )}
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ background: "transparent" }}>
            <CommonHeader />
          </Header>
          <Content
            className="site-layout"
            style={{ padding: "0 50px", marginTop: 64, minHeight: "100vh" }}
          >
            {children}
          </Content>

          <Footer style={{ textAlign: "center" }}>
            ©{dayjs().format("YYYY")} Created by Bottle
          </Footer>
        </Layout>
      </Layout>
      {showDownloadingPopup && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            height: 80,
            width: 240,
            background: message?.toLowerCase().includes("error")
              ? "rgb(235, 87, 87)"
              : "white",
            margin: 20,
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            paddingLeft: 20,
            zIndex: "999999",
          }}
        >
          {message?.toLowerCase().includes("error") ? (
            <CloseCircleOutlined style={{ color: "white" }} />
          ) : message?.toLowerCase().includes("completed") ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <Spin />
          )}
          <Typography.Text
            style={{
              marginLeft: 10,
              color: message?.toLowerCase().includes("error")
                ? "white"
                : "#333",
            }}
          >
            {message}
          </Typography.Text>
        </div>
      )}

      {/* <FooterWatchList /> */}
    </>
  );
};

export default ProtectedLayout;
