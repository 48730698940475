import React, {
  Dispatch,
  SetStateAction,
} from 'react';

import {
  Col,
  FormInstance,
  Table,
  Typography,
} from 'antd';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { deleteCustomerAccount } from 'Redux/DpRedux/Accounts/AccountActions';
import { DpAccountsFilterDataProps } from 'Types/Types';

import { ShowAddModalProps } from '../Accounts';

interface TableComponentsProps {
  setFilterData: Dispatch<SetStateAction<DpAccountsFilterDataProps>>;
  filterData: DpAccountsFilterDataProps;
  showAddModal: ShowAddModalProps;
  setShowAddModal: Dispatch<SetStateAction<ShowAddModalProps>>;
  addUpdateForm: FormInstance;
}

export const TableComponent = ({
  setFilterData,
  filterData,
  setShowAddModal,
  addUpdateForm,
}: TableComponentsProps) => {
  const dispatch = useDispatch();
  const { loading, list, totalRecords } = useSelector(
    (state: any) => state.accountReducer
  );

  const handleDelete = (id: string) => {
    dispatch(deleteCustomerAccount(id));
  };

  const accountsColumns = [
    {
      title: "Account ID",
      dataIndex: "account_code",
      key: "account_code",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Final Code",
      dataIndex: "final_code",
      key: "final_code",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },

  ];

  return (
    <div className="account-table">
      <Col lg={24}>
        <Table
          rowKey="id"
          columns={accountsColumns}
          dataSource={list}
          loading={loading}
          pagination={{
            current: Number(filterData.page),
            total: parseInt(totalRecords),
            onChange: (page: number) => {
              setFilterData((prevState: DpAccountsFilterDataProps) => ({
                ...prevState,
                page: page,
              }));
            },
            showSizeChanger: false,
          }}
        />
      </Col>
    </div>
  );
};
