const config = {
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  bucket: process.env.REACT_APP_BUCKET,
  userApi: process.env.REACT_APP_USERAPI,
  kycApi: process.env.REACT_APP_KYCAPI,
  adminApi: process.env.REACT_APP_ADMINAPI,
  dockerApi: process.env.REACT_APP_DOCKERAPI,
  baseApiUrl: process.env.REACT_APP_BASE_API_URL,
  nepseApi: process.env.REACT_APP_NEPSE_API,
  dpApi: process.env.REACT_APP_DP_API,
  crmLink: process.env.REACT_APP_CRM_LINK,
  cmsLink: process.env.REACT_APP_CMS_LINK
};

export default config;
