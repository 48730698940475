import {
  API_NAME,
  ApiHeaders,
} from 'Api/Api';
import { API } from 'aws-amplify';

import {
  AcceptRejectVideoKyc,
  CreateKycParams,
  KycDetailsParams,
  KycFetchParams,
  RescheduleKyc,
  ScheduleKyc,
} from './KycTypes';

//get Kyc
export const takeKycs = (data: KycFetchParams) => {
  const finalData = {
    ...data,
    // order_by: data?.status === "Completed" ? "-created_at" : "+created_at",
  };
  return API.get("kycApi", "/list", {
    headers: ApiHeaders,
    queryStringParameters: finalData,
  });
};

//get Kyc Requests
export const takeKycRequests = (data: KycFetchParams) => {
  
  const finalData = {
    ...data,
    // order_by: data?.status === "Completed" ? "-created_at" : "+created_at",
  };
  return API.get("kycApi", "/admin/video-kyc/list", {
    headers: ApiHeaders,
    queryStringParameters: finalData,
  });
};

//get Kyc Details
export const takeKyc = (data: KycDetailsParams) =>
  API.get("kycApi", `/admin/get/${data?.id}/${data?.type}`, {
    headers: ApiHeaders,
    data: data,
  });

//create Kyc Details
export const makeKyc = (data: CreateKycParams) => {
  if (data?.AcceptRejectKYC) {
    return API.patch(API_NAME, `/other/${data.oid}`, {
      headers: ApiHeaders,
      body: data,
    });
  } else {
    return API.post(API_NAME, `/admin/update/${data.oid}`, {
      headers: ApiHeaders,
      data: data,
    });
  }
};

//archieve Kyc
export const archieveKyc = (data: any) =>
  API.post(API_NAME, "/insuranceKycAdmin", { headers: ApiHeaders, body: data });

//modify Kyc
export const modifyKyc = (data: CreateKycParams) => {
  if (data?.AcceptRejectKYC) {
    return API.patch("kycApi", `/other/${data.oid}`, {
      headers: ApiHeaders,
      body: data?.AcceptRejectKYC,
    });
  } else if (data?.archieve) {
    delete data.archieve;
    return API.patch("kycApi", `/other/${data.oid}/${data?.account}`, {
      headers: ApiHeaders,
    });
  } else {
    return API.patch("kycApi", `/admin/update/${data.oid}`, {
      headers: ApiHeaders,
      body: data,
    });
  }
};

//delete Kyc
export const cutKyc = (data: KycDetailsParams) =>
  API.post(API_NAME, "/insuranceKycAdmin", { headers: ApiHeaders, data: data });

//Reschedule
export const rescheduleVideoKyc = (data: RescheduleKyc) =>
  API.patch("kycApi", `/reschedule-video-kyc/${data.formId}`, {
    headers: ApiHeaders,
    body: {
      time: data.time,
      id: data.id,
      reason: data.reason,
      email: data.email,
      admin_name: data.admin_name,
      scheduled_time: data.dateTime
    },
  });

export const scheduleVideoKyc = (data: ScheduleKyc) =>
  API.post("kycApi", `/schedule-video-kyc-admin/${data.formId}`, {
    headers: ApiHeaders,
    body: {
      preferred_datetime: data.preferred_datetime,
    },
  });

//get Video Kyc Detail
export const getVideoKycDetails = (formId: string) => {
  return API.get("kycApi", `/admin/video-kyc-details/${formId}`, {
    headers: ApiHeaders,
  });
};

export const acceptRejectVideoKyc = (data: AcceptRejectVideoKyc) =>
  API.patch("kycApi", `/admin/accept/reject/scheduled/video_kyc/${data.oid}`, {
    headers: ApiHeaders,
    body: {
      approved_datetime: data.approved_datetime,
      status: `${data.status}`,
      reason: data.reason,
      s3_path: data.s3_path,
      email: data.email,
      time: data.time,
      admin_name: data.admin_name,
    },
  });

//get Video Kyc Detail
export const getUserListVideoKyc = (data: any) => {
  return API.get("kycApi", `/admin/dropdown`, {
    headers: ApiHeaders,
    queryStringParameters: data
  });
};

//generate pdf

export const generatePdfApi = (data: any) => {

  const url = `/admin/generate-pdf/${data.oid}/${data.target}`;
  return API.get("dockerApi", url, {});
}