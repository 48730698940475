import {
  GENERATE_PDF_EXCEL,
  GENERATE_PDF_EXCEL_FAILURE,
  GENERATE_PDF_EXCEL_SUCCESS,
  GENERATE_REPORT,
  GENERATE_REPORT_FAILURE,
  GENERATE_REPORT_SUCCESS,
  generatePdfExcelProps,
  generateReportProps,
} from "./ReportsTypes";

export const generateReport = (data: generateReportProps) => ({
  type: GENERATE_REPORT,
  payload: data,
});

export const generateReportSuccess = (data: any) => ({
  type: GENERATE_REPORT_SUCCESS,
  payload: data,
});

export const generateReportFailure = () => ({
  type: GENERATE_REPORT_FAILURE,
});

export const generatePdfExcel = (data: generatePdfExcelProps) => ({
  type: GENERATE_PDF_EXCEL,
  payload: data,
});

export const generatePdfExcelSuccess = () => ({
  type: GENERATE_PDF_EXCEL_SUCCESS,
});

export const generatePdfExcelFailure = () => ({
  type: GENERATE_PDF_EXCEL_FAILURE,
});
