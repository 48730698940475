import { Storage } from "aws-amplify";
//used for trading pdf generated by meroshare details tab
//reupload hudaina yo
export const downlaodPdf = async (uri: any) => {
  try {
    const url = await Storage.get(uri, {
      customPrefix: {
        public: "",
      },
    });
    var link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "FileName.pdf");
    link.setAttribute("target", "_blank");

    document.body.appendChild(link);

    link.click();

    link?.parentNode?.removeChild(link);
  } catch (e) {
    console.log(e, "Error form getUrl utils");
  }
};
