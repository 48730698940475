import { Reducer } from 'react'
import {
  CreateAdminParams,
  CREATE_ADMIN,
  CREATE_ADMIN_FAILURE,
  CREATE_ADMIN_SUCCESS,
  FETCH_ADMINS,
  FETCH_ADMINS_FAILURE,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMIN,
  FETCH_ADMIN_FAILURE,
  FETCH_ADMIN_SUCCESS,
  REMOVE_ADMIN,
  REMOVE_ADMIN_FAILURE,
  REMOVE_ADMIN_SUCCESS,
  UPDATE_ADMIN,
  UPDATE_ADMIN_FAILURE,
  UPDATE_ADMIN_SUCCESS,
  AdminDetailsParams,
} from './AdminTypes'

const INIT_STATE = {
  list: [],
  error: '',
  detailsError: '',
  details: {},
  loading: false,
  loaded: false,
  detailing: false,
  detailed: false,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  pageNumber: 0,
  pageSize: 0,
  totalPages: 1,
  totalRecords: 8,
}

export const AdminReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    //fetch Admins
    case FETCH_ADMINS:
      return { ...state, loaded: false, loading: true }
    case FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.users,
        pageNumber: action.payload.page,
        pageSize: action.payload.count,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.total,
      }
    case FETCH_ADMINS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    //fetch Admin Details
    case FETCH_ADMIN:
      return { ...state, detailed: false, detailing: true }
    case FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        detailing: false,
        detailed: false,
        details: action.payload || false,
      }
    case FETCH_ADMIN_FAILURE:
      return { ...state, detailing: false, detailsError: action.payload }

    //create Admin
    case CREATE_ADMIN:
      return { ...state, created: false, creating: true, error: false }
    case CREATE_ADMIN_SUCCESS:
      state.list.push(action.payload)
      return { ...state, creating: false, created: true }
    case CREATE_ADMIN_FAILURE:
      return { ...state, creating: false, error: action.payload.error }

    //update Admin
    case UPDATE_ADMIN:
      return { ...state, updating: true, updated: false, error: false }
    case UPDATE_ADMIN_SUCCESS:
      const tempList = state.list.filter(
        (d: CreateAdminParams) => d.id !== action.payload.id
      )
      tempList.push(action.payload)
      return { ...state, updated: true, updating: false, list: tempList }
    case UPDATE_ADMIN_FAILURE:
      return { ...state, updating: false, error: action.payload.error }

    //remove Admin
    case REMOVE_ADMIN:
      return { ...state, deleted: false, deleting: true }
    case REMOVE_ADMIN_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        list: state.list.filter(
          (d: AdminDetailsParams) => d.id !== action.payload.id
        ),
      }
    case REMOVE_ADMIN_FAILURE:
      return { ...state, deleting: false }
    default:
      return { ...state }
  }
}
