import { Storage } from 'aws-amplify'
import { RcFile } from 'antd/lib/upload/interface'
import { message } from 'antd'

interface Props {
  directoryPath: string
  successCB: (data: string) => void
  failureCB: () => void
  fileObj: RcFile
}
export const StoragePut = async (props: Props) => {
  const { directoryPath, successCB, failureCB, fileObj } = props
  await Storage.put(directoryPath, fileObj, {
    contentType: fileObj.type,
    customPrefix: {
      public: '',
    },
  })
    .then((res: any) => {
      const data = res?.key
      console.log({ data })
      successCB(data)
    })
    .catch((err) => {
      console.log({ err })
      message.error('Error Uploading file')
      failureCB()
    })
}
