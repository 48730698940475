export const GENERATE_REPORT = "GENERATE_REPORT";
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS";
export const GENERATE_REPORT_FAILURE = "GENERATE_REPORT_FAILURE";

export const GENERATE_PDF_EXCEL = "GENERATE_PDF_EXCEL";
export const GENERATE_PDF_EXCEL_SUCCESS = "GENERATE_PDF_EXCEL_SUCCESS";
export const GENERATE_PDF_EXCEL_FAILURE = "GENERATE_PDF_EXCEL_FAILURE";

export interface generateReportProps {
  boid_number?: string;
  start_date?: string;
  end_date?: string;
}

export interface generatePdfExcelProps extends generateReportProps {
  type?: "pdf" | "excel";
}
