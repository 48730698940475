import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Space, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import MyContent from 'Components/Content/Content';
import PageLoading from 'Components/PageLoading/PageLoading';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { createKyc, fetchKyc, updateKyc } from 'Redux/KycRedux/KycActions';


export const KycForm = (props: any) => {
	const dispatch = useDispatch();
	const kyc = useSelector((state: any) => state.KycReducer);
	const { creating, updating, details, detailing } = kyc;

	const { update } = props;

	const { id } = useParams<any>();

	const [form] = useForm();

	const history = useHistory();

	const onBackHandler = () => {
		if (id !== 'add') {
			history.push(`/kyc/${id}`);
		}
		else {
			history.push(`/kyc`);

		}
	}

	const onFinish = (values: any) => {

		if (update) {
			dispatch(updateKyc({ ...values, id: details?.id }));
		}
		else {
			dispatch(createKyc({ ...values, status: 'pending' }));
		}
	}


	useEffect(() => {
		form.setFieldsValue({
			...details
		});


	}, [details])


	useEffect(() => {
		if (!kyc?.detailed && id && id !== 'add') {
			dispatch(fetchKyc({ id: id }))
		}
	}, [id])


	return (
		<PageLoading loading={detailing}>
			<MyContent>
				<Space align="center" size="large">
					<Button onClick={() => onBackHandler()} shape="circle" size="small" icon={<ArrowLeftOutlined />} />

					<Typography.Title level={5} >{update ? details?.title : 'ADD TASK'} </Typography.Title>
				</Space>
				<Divider />
				<Form
					layout="vertical"
					form={form}
					name="basic"
					onFinish={onFinish}
					className="style--kbbs"
				>
					<Row gutter={[20, 0]}>
						<Col lg={18}>
							<Form.Item
								label="Kyc Number"
								name="kycNo"
								rules={[{ required: true, message: 'Kyc Number is required!' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col lg={18}>
							<Form.Item
								label="Title"
								name="title"
								rules={[{ required: true, message: 'Title is required!' }]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col lg={24}>
							<Form.Item shouldUpdate>
								{() => <Button loading={creating || updating} type="primary" size="large" shape="round" htmlType="submit">
									{update ? 'UPDATE' : 'ADD'}
								</Button>}
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</MyContent>
		</PageLoading >
	);
};

export default KycForm;
