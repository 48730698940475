import { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Descriptions } from "antd";
import { useSelector } from "react-redux";
import { Address_Data, getAddressData } from "./getAddressData";
import AddressForm from "./AddressForm";
import { CONTENT_STYLE, EDIT_BUTTON, LABEL_STYLE } from "Components/DescriptionsStyle";

type Props = {
  title: "Permanent Address" | "Temporary Address";
};

const DisplayAddress = ({ title }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { details } = useSelector((state: any) => state?.KycReducer);
  const selectedTab = title.toLowerCase().includes("permanent")
    ? "permanent_address"
    : "temporary_address";
  const selectedAddressData = details[selectedTab];
  return (
    <div>
      {!isEditing && (
        <Button
          onClick={() => setIsEditing((curr) => !curr)}
          type="default"
          icon={<EditOutlined style={{ fill: "gray" }} />}
          style={EDIT_BUTTON}
        >
          Edit {title}
        </Button>
      )}

      {isEditing ? (
        <AddressForm
          {...{ title }}
          {...{ setIsEditing }}
          selectedAddressData={selectedAddressData}
        />
      ) : (
        <Descriptions
          column={9}
          labelStyle={LABEL_STYLE}
          contentStyle={CONTENT_STYLE}
          layout="vertical"
        >
          {getAddressData(selectedAddressData).map(
            (item: Address_Data, idx: number) => (
              <Descriptions.Item
                span={3}
                label={item.label}
                key={idx.toString()}
              >
                {item.value}
              </Descriptions.Item>
            )
          )}
        </Descriptions>
      )}
    </div>
  );
};

export default DisplayAddress;
