import {
  CreateAdminParams,
  CREATE_ADMIN,
  CREATE_ADMIN_FAILURE,
  CREATE_ADMIN_SUCCESS,
  FETCH_ADMINS,
  FETCH_ADMINS_FAILURE,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMIN,
  FETCH_ADMIN_FAILURE,
  FETCH_ADMIN_SUCCESS,
  REMOVE_ADMIN,
  REMOVE_ADMIN_FAILURE,
  REMOVE_ADMIN_SUCCESS,
  UPDATE_ADMIN,
  UPDATE_ADMIN_FAILURE,
  UPDATE_ADMIN_SUCCESS,
  AdminDetailsParams,
  AdminFetchParams,
} from './AdminTypes'

//fetch Admin List
export const fetchAdmins = (params: AdminFetchParams) => ({
  type: FETCH_ADMINS,
  payload: params,
})

export const fetchAdminsSuccess = (response: any) => ({
  type: FETCH_ADMINS_SUCCESS,
  payload: response,
})

export const fetchAdminsFailure = (error: any) => ({
  type: FETCH_ADMINS_FAILURE,
  payload: error,
})

//fetch Admin Details
export const fetchAdmin = (params: AdminDetailsParams) => ({
  type: FETCH_ADMIN,
  payload: params,
})

export const fetchAdminSuccess = (response: any) => ({
  type: FETCH_ADMIN_SUCCESS,
  payload: response,
})

export const fetchAdminFailure = (error: any) => ({
  type: FETCH_ADMIN_FAILURE,
  payload: error,
})

//create Admin
export const createAdmin = (params: CreateAdminParams) => ({
  type: CREATE_ADMIN,
  payload: params,
})

export const createAdminSuccess = (response: CreateAdminParams) => ({
  type: CREATE_ADMIN_SUCCESS,
  payload: response,
})
export const createAdminFailure = (error: any) => ({
  type: CREATE_ADMIN_FAILURE,
  payload: error,
})

//update Admin
export const updateAdmin = (params: CreateAdminParams) => ({
  type: UPDATE_ADMIN,
  payload: params,
})

export const updateAdminSuccess = (response: CreateAdminParams) => ({
  type: UPDATE_ADMIN_SUCCESS,
  payload: response,
})

export const updateAdminFailure = (error: any) => ({
  type: UPDATE_ADMIN_FAILURE,
  payload: error,
})

//delete Admin
export const deleteAdmin = (params: AdminDetailsParams) => ({
  type: REMOVE_ADMIN,
  payload: params,
})
export const deleteAdminSuccess = (response: AdminDetailsParams) => ({
  type: REMOVE_ADMIN_SUCCESS,
  payload: response,
})
export const deleteAdminFailure = (error: any) => ({
  type: REMOVE_ADMIN_FAILURE,
  payload: error,
})
