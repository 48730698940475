export const FETCH_KYCS = "FETCH_KYCS";
export const FETCH_KYCS_SUCCESS = "FETCH_KYCS_SUCCESS";
export const FETCH_KYCS_FAILURE = "FETCH_KYCS_FAILURE";

export const FETCH_KYC_REQUESTS = "FETCH_KYC_REQUESTS ";
export const FETCH_KYC_REQUESTS_SUCCESS = "FETCH_KYC_REQUESTS_SUCCESS ";
export const FETCH_KYC_REQUESTS_FAILURE = "FETCH_KYC_REQUESTS_FAILURE ";

export const FETCH_KYC = "FETCH_KYC";
export const FETCH_KYC_SUCCESS = "FETCH_KYC_SUCCESS";
export const FETCH_KYC_FAILURE = "FETCH_KYC_FAILURE";

export const CREATE_KYC = "CREATE_KYC";
export const CREATE_KYC_SUCCESS = "CREATE_KYC_SUCCESS";
export const CREATE_KYC_FAILURE = "CREATE_KYC_FAILURE";

export const UPDATE_KYC = "UPDATE_KYC";
export const UPDATE_KYC_SUCCESS = "UPDATE_KYC_SUCCESS";
export const UPDATE_KYC_FAILURE = "UPDATE_KYC_FAILURE";

export const REMOVE_KYC = "REMOVE_KYC";
export const REMOVE_KYC_SUCCESS = "REMOVE_KYC_SUCCESS";
export const REMOVE_KYC_FAILURE = "REMOVE_KYC_FAILURE";

export const RESCHEDULE_VIDEO_KYC = "RESCHEDULE_VIDEO_KYC";
export const RESCHEDULE_SUCCESS = "RESCHEDULE_SUCCESS";
export const RESCHEDULE_FAILURE = "RESCHEDULE_FAILURE";

export const ACCEPT_REJECT_VIDEO_KYC = "ACCEPT_REJECT_VIDEO_KYC";
export const ACCEPT_REJECT_SUCCESS = "ACCEPT_REJECT_SUCCESS";
export const ACCEPT_REJECT_FAILURE = "ACCEPT_REJECT_FAILURE";

export const FETCH_VIDEO_KYC = "FETCH_VIDEO_KYC";
export const FETCH_VIDEO_KYC_SUCCESS = "FETCH_VIDEO_KYC_SUCCESS";
export const FETCH_VIDEO_KYC_FAILURE = "FETCH_VIDEO_KYC_FAILURE";

export const VERIFY_VIDEO_KYC = "VERIFY_VIDEO_KYC";
export const REJECT_VIDEO_KYC = "REJECT_VIDEO_KYC";


export const USER_LIST_SCHEDULE_VIDEO_KYC = "USER_LIST_SCHEDULE_VIDEO_KYC"
export const USER_LIST_SCHEDULE_VIDEO_KYC_SUCCESS = "USER_LIST_SCHEDULE_VIDEO_KYC_SUCCESS"
export const USER_LIST_SCHEDULE_VIDEO_KYC_FAILURE = "USER_LIST_SCHEDULE_VIDEO_KYC_FAILURE"

export const SCHEDULE_VIDEO_KYC = "SCHEDULE_VIDEO_KYC";
export const SCHEDULE_SUCCESS = "SCHEDULE_SUCCESS";
export const SCHEDULE_FAILURE = "SCHEDULE_FAILURE";

export const GENERATE_PDF = "GENERATE_PDF"
export const GENERATE_PDF_SUCCESS = "GENERATE_PDF_SUCCESS"
export const GENERATE_PDF_FAILURE = "GENERATE_PDF_FAILURE"

export interface KycFetchParams {
  limit?: number;
  page?: number;
  PageSize?: number;
  PageNumber?: number;
  status?: string;
  custom_date?:string
}
export interface KycDetailsParams {
  id?: number;
  type?: string;
}

export interface AcceptRejectKYCType {
  reason?: string;
  account?: "demat" | "meroshare" | "trading";
  status?: "Pending" | "N/A" | "Completed" | "Rejected" | "Accepted";
}
export interface CreateKycParams {
  id?: number;
  name?: string;
  address?: string;
  phone?: string;
  oid?: string;
  AcceptRejectKYC?: AcceptRejectKYCType;
  archieve?: boolean;
  account?: "demat" | "meroshare" | "trading";
}

export interface ArchiveKycParams {
  oid?: string;
  account?: "demat" | "meroshare" | "trading";
}

export interface RescheduleKyc {
  formId?: string;
  dateTime?: string;
  reason: string;
  video_kyc_link: string;
  id: string;
  email?: string;
  admin_name?: string;
  time?: String
}

export interface ScheduleKyc {
  preferred_datetime: string;
  formId?: string;
}

export interface AcceptRejectVideoKyc {
  oid?: string;
  approved_datetime?: string;
  status?: string;
  reason?: string;
  video_kyc_link?: string;
  accept?: boolean;
  s3_path?: string;
  email?: string;
  time?: string;
  admin_name?: string;
}
