import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Card,
  Form,
} from 'antd';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { fetchAccountList } from 'Redux/DpRedux/Accounts/AccountActions';
import { DpAccountsFilterDataProps } from 'Types/Types';

import { FilterComponent } from './Components/FilterComponent';
import { TableComponent } from './Components/TableComponent';

export interface ShowAddModalProps {
  userId: string;
  visible: boolean;
}

export const Accounts = () => {
  const dispatch = useDispatch();

  const [addUpdateForm] = Form.useForm();

  const { deleted, created } = useSelector(
    (state: any) => state.accountReducer
  );

  const [filterData, setFilterData] = useState<DpAccountsFilterDataProps>({
    page: 1,
    limit: 10,
  });

  const [showAddModal, setShowAddModal] = useState<ShowAddModalProps>({
    userId: "",
    visible: false,
  });

  useEffect(() => {
    if (filterData.page && filterData.limit) {
      const offset = (filterData.page - 1) * filterData.limit;

      let filterParams: DpAccountsFilterDataProps = {
        limit: filterData.limit,
      };
      if (offset) {
        filterParams.offset = offset;
      }
      if (filterData.search) {
        if (/^\d+$/.test(filterData.search)) {
          filterParams.boid_number = filterData.search;

        } else {
          filterParams.account_holder_name = filterData.search;

        }
      }
      if (filterData.account_type && filterData.account_type !== "All") {
        
        filterParams.account_type = filterData.account_type
      }
      dispatch(
        fetchAccountList({
          ...filterParams,
        })
      );
    }
  }, [dispatch, filterData, created, deleted]);

  return (
    <Card className="dp-accounts">
      <FilterComponent
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        addUpdateForm={addUpdateForm}
        filterData={filterData}
        setFilterData={setFilterData}
      />
      <TableComponent
        filterData={filterData}
        setFilterData={setFilterData}
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        addUpdateForm={addUpdateForm}
      />
    </Card>
  );
};
