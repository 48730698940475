export const FETCH_DP_CUSTOMER = "FETCH_DP_CUSTOMER";
export const FETCH_DP_CUSTOMER_SUCCESS = "FETCH_DP_CUSTOMER_SUCCESS";
export const FETCH_DP_CUSTOMER_FAILURE = "FETCH_DP_CUSTOMER_FAILURE";

export const FETCH_DP_SINGLE_CUSTOMER = "FETCH_DP_SINGLE_CUSTOMER";
export const FETCH_DP_SINGLE_CUSTOMER_SUCCESS =
  "FETCH_DP_SINGLE_CUSTOMER_SUCCESS";
export const FETCH_DP_SINGLE_CUSTOMER_FAILURE =
  "FETCH_DP_SINGLE_CUSTOMER_FAILURE";

export const CREATE_DP_CUSTOMER = "CREATE_DP_CUSTOMER";
export const CREATE_DP_CUSTOMER_SUCCESS = "CREATE_DP_CUSTOMER_SUCCESS";
export const CREATE_DP_CUSTOMER_FAILURE = "CREATE_DP_CUSTOMER_FAILURE";

export const UPLOAD_DPA_FILE = "UPLOAD_DPA_FILE";
export const UPLOAD_DPA_FILE_SUCCESS = "UPLOAD_DPA_FILE_SUCCESS";
export const UPLOAD_DPA_FILE_FAILURE = "UPLOAD_DPA_FILE_FAILURE";
export const UPLOAD_DPA_FILE_INCREMENTAL = "UPLOAD_DPA_FILE_INCREMENTAL";

export const REUPLOAD_FILE = "REUPLOAD_FILE";

export const DOWNLOAD_DP_CUSTOMER_REPORT = "DOWNLOAD_DP_CUSTOMER_REPORT";
export const DOWNLOAD_DP_CUSTOMER_REPORT_SUCCESS =
  "DOWNLOAD_DP_CUSTOMER_REPORT_SUCCESS";
export const DOWNLOAD_DP_CUSTOMER_REPORT_FAILURE =
  "DOWNLOAD_DP_CUSTOMER_REPORT_FAILURE";

export const EDIT_CUSTOMERS_DATA = "EDIT_CUSTOMERS_DATA";
export const EDIT_CUSTOMERS_DATA_SUCCESS = "EDIT_CUSTOMERS_DATA_SUCCESS";
export const EDIT_CUSTOMERS_DATA_FAILURE = "EDIT_CUSTOMERS_DATA_FAILURE";

export const RESET_STATUS_VALUE = "REST_STATUS_VALUE";

export const ARCHIVE_CUSTOMER = "ARCHIVE_CUSTOMER";
export const ARCHIVE_CUSTOMER_SUCCESS = "ARCHIVE_CUSTOMER_SUCCESS";
export const ARCHIVE_CUSTOMER_FAILURE = "ARCHIVE_CUSTOMER_FAILURE";

export const DOWNLOAD_CUSTOMER_INFO = "DOWNLOAD_CUSTOMER_INFO";
export const DOWNLOAD_CUSTOMER_INFO_SUCCESS = "DOWNLOAD_CUSTOMER_INFO_SUCCESS";
export const DOWNLOAD_CUSTOMER_INFO_FAILURE = "DOWNLOAD_CUSTOMER_INFO_FAILURE";

export const CUSTOMER_ADD_REFERENCE = "CUSTOMER_ADD_REFERENCE";
export const CUSTOMER_ADD_REFERENCE_SUCCESS = "CUSTOMER_ADD_REFERENCE_SUCCESS";
export const CUSTOMER_ADD_REFERENCE_FAILURE = "CUSTOMER_ADD_REFERENCE_FAILURE";

export const FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS = "FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS"
export const FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_SUCCESS = "FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_SUCCESS"
export const FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_FAILURE = "FETCH_DP_SINGLE_CUSTOMER_TRANSACTIONS_FAILURE"

export const DOWNLOAD_CUSTOMER_TRANSACTION_REPORT = "DOWNLOAD_CUSTOMER_TRANSACTION_REPORT"
export const DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_SUCCESS = "DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_SUCCESS"
export const DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_FAILURE = "DOWNLOAD_CUSTOMER_TRANSACTION_REPORT_FAILURE"

export interface FetchCustomerParams {
  limit?: number;
  offset?: number;
  search_by_name?: string;
  type?: string;
  // page?: number;
  // PageSize?: number;
  // PageNumber?: number;
}

export interface CreateDpCustomerParams {
  account_holder_name: string;
  boid_number: string;
}

export interface UploadDpaFile {
  file_type: string;
  file: any;
}

export interface CustomerAddReferenceProps {
  id: string;
  body: {
    reference_name: string;
    reference_number: string;
    reference_address: string;
    remarks: string;
  };
}


export interface FetchCustomerTransactionListProps {
  limit?: number;
  offset?: number;
  transaction_date_after?: string
  transaction_date_before?: string
  date_range?: string
}