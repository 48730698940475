import Auth from "@aws-amplify/auth";
import config from "./config";

const { default: Amplify } = require("aws-amplify");

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolWebClientId,
      mandatorySignIn: true,
      identityPoolRegion: config.region,
      identityPoolId: config.identityPoolId,

      authenticationFlowType: "USER_SRP_AUTH",

      oauth: {
        domain: "",
        redirectSignIn: "",
        redirectSignOut: "",
        scope: [],
        responseType: "",
      },
    },
    Storage: {
      AWSS3: {
        bucket: config.bucket, //REQUIRED -  Amazon S3 bucket name
        region: config.region, //OPTIONAL -  Amazon service region
      },
    },
    API: {
      endpoints: [
        {
          name: "userApi",
          endpoint: config.userApi,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "kycApi",
          endpoint: config.kycApi,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "adminApi",
          endpoint: config.adminApi,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "fakeApi",
          endpoint: "http://localhost:4011",
        },
        {
          name: "dockerApi",
          endpoint: config.dockerApi,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "nepseApi",
          endpoint: config.nepseApi,
        },
        {
          name: "baseApi",
          endpoint: config.baseApiUrl,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    },
  });
  Auth.configure({
    Auth: {
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolWebClientId,
      mandatorySignIn: true,
      identityPoolRegion: config.region,
      identityPoolId: config.identityPoolId,

      authenticationFlowType: "USER_SRP_AUTH",

      oauth: {
        domain: "",
        redirectSignIn: "",
        redirectSignOut: "",
        scope: [],
        responseType: "",
      },
    },
    Storage: {
      AWSS3: {
        bucket: config.bucket, //REQUIRED -  Amazon S3 bucket name
        region: config.region, //OPTIONAL -  Amazon service region
      },
    },
    API: {
      endpoints: [
        {
          name: "userApi",
          endpoint: config.userApi,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "kycApi",
          endpoint: config.kycApi,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "adminApi",
          endpoint: config.adminApi,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "fakeApi",
          endpoint: "http://localhost:4011",
        },
        {
          name: "dockerApi",
          endpoint: config.dockerApi,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "nepseApi",
          endpoint: config.nepseApi,
        },
        {
          name: "baseApi",
          endpoint: config.baseApiUrl,
          region: config.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    },
  })
};
