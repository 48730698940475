import { Avatar, Popover, Space, Tag } from "antd";

export const statuses = ["Pending", "Accepted", "Rejected", "Completed"];

const statusOptions = (id: any) => {
  switch (id) {
    case "Pending":
    case "pending":
      return { name: "PENDING", color: "#FF895B", background: "#FF895B10" };
    case "Completed":
    case "completed":
      return { name: "COMPLETED", color: "#1CC67B", background: "#1CC67B10" };
    case "Accepted":
    case "accepted":
      return { name: "ACCEPTED", color: "#1CC67B", background: "#1CC67B10" };
    case "verified":
    case "Verified":
      return { name: "VERIFIED", color: "#1CC67B", background: "#1CC67B10" };
    case "Rejected":
    case "rejected":
      return {
        name: "REJECTED",
        color: "#F44E51",
        icon: "!",
        background: "#F44E5110",
      };
    case "ACTIVE":
    case "Active": {
      return { name: "ACTIVE", color: "#1CC67B", background: "#1CC67B10" };
    }
    case "CLOSE": {
      return {
        name: "CLOSE",
        color: "#F44E51",
        background: "#F44E5110",
      };
    }
    case "Inactive": {
      return {
        name: "INACTIVE",
        color: "#F44E51",
        background: "#F44E5110",
      };
    }
    case "demat(minor)":
      return {
        name: "Demat (Minor)",
        color: "#00dda2",
        background: "rgb(229, 252, 246)",
      };
    case "demat(non-minor)":
      return {
        name: "Demat (Non-minor)",
        color: "#00dda2",
        background: "rgb(229, 252, 246)",
      };
    case "meroshare(minor)":
      return {
        name: "Meroshare (Minor)",
        color: "rgba(245, 167, 15, 1)",
        background: "rgba(245, 167, 15, 0.1)",
      };
    case "meroshare(non-minor)":
      return {
        name: "Meroshare (Non-Minor)",
        color: "rgba(245, 167, 15, 1)",
        background: "rgba(245, 167, 15, 0.1)",
      };
    case "meroshare(N/A)":
      return {
        name: "Meroshare",
        color: "rgba(245, 167, 15, 1)",
        background: "rgba(245, 167, 15, 0.1)",
      };
    case "trading(minor)":
      return {
        name: "Trading (Minor)",
        color: "rgba(57, 140, 255, 1)",
        background: "rgba(57, 140, 255, 0.1)",
      };
    case "trading(non-minor)":
      return {
        name: "Trading (Non-Minor)",
        color: "rgba(57, 140, 255, 1)",
        background: "rgba(57, 140, 255, 0.1)",
      };
    case "Super Admin": {
      return { name: "SUPER ADMIN", color: "#1CC67B", background: "#1CC67B10" };
    }
    case "Admin": {
      return { name: "ADMIN", color: "#FF895B", background: "#FF895B10" };
    }
    case "User": {
      return {
        name: "USER",
        color: "rgba(57, 140, 255, 1)",
        background: "rgba(57, 140, 255, 0.1)",
      };
    }

    default:
      return { name: "N/A" };
  }
};

export const Status = (props: any) => {
  const details = statusOptions(props.status);
  const { rejectMessage } = props;

  return (
    <Space align="center" size={5}>
      <Tag
        color={details.color}
        style={{
          background: details.background,
          color: details.color,
          marginRight: 0,
        }}
        className="status--tag"
      >
        {details.name}
      </Tag>
      {details?.icon && (
        <Popover
          placement="bottom"
          title={"Rejected"}
          content={rejectMessage || "Due to incompletion of form"}
        >
          <Avatar
            style={{
              background: details.background,
              color: details.color,
              cursor: "pointer",
            }}
            size={25}
          >
            {details?.icon}
          </Avatar>
        </Popover>
      )}
    </Space>
  );
};
export default Status;
