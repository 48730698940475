import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchVoucherList } from 'Redux/DpRedux/Transaction/TransactionAction';
import { dpFilterParams } from 'Utils/dpFilterParams';

import { FilterComponent } from './Components/FilterComponent';
import { TableComponent } from './Components/TableComponent';

export interface TransactionListFilterProps {
  limit: number;
  page: number;
  payment_mode: string;
  username: string;
  transaction_date_after: string;
  transaction_date_before: string;
}

export const Transaction = () => {
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState<TransactionListFilterProps>({
    limit: 10,
    page: 1,
    payment_mode: "",
    username: "",
    transaction_date_after: "",
    transaction_date_before: "",
  });

  useEffect(() => {
    let  params = dpFilterParams(filterData);
    if(params.username) {
      if(/^\d+$/.test(params.username)) {
        params.boid_number = params.username
        delete params.username
      } 
    }
  

    dispatch(fetchVoucherList(params));
  }, [dispatch, filterData]);

  return (
    <Card>
      <FilterComponent filterData={filterData} setFilterData={setFilterData} />
      <TableComponent filterData={filterData} setFilterData={setFilterData} />
    </Card>
  );
};
