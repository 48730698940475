import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'Config/config';

import {
  FetchVoucherDetailsProps,
  FetchVoucherListProps,
} from './TransactionTypes';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const uploadCdscFileApi = async (data: any) => {
    return axios.post(`accounts/upload-cdsc-file`, data, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
};


export const fetchVoucherListApi = async (data: FetchVoucherListProps) => {
    return axios.get(`reports/vouchers/list`, {
        params: data,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
};

export const downloadTransactionReportApi = async (
    data: FetchVoucherListProps,
    fileType: string
) => {
    let url = ""
    if(fileType === "excel") {
        url = "reports/trasaction/export/excel"
    } else {
        url = "reports/transactions/export/pdf"
    }
    return axios.get(url, {
        params: data,
        responseType: "blob",
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
};

export const fetchVoucherDetailsApi = async (data: FetchVoucherDetailsProps) => {
    let params = {
        limit: data.limit,
        offset: data.offset
    }
    return axios.get(`accounts/voucher/${data.voucherId}/transactions/list`, {
        params,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
};