import {
  AddressDetailsFields,
} from '__abc__lib/fields/UserDetails/addressDetailsFields';
import {
  DematBankDetail,
  dematFinanceDetails,
  dematNomineeDetails,
  dematNomineeStatus,
  DematOccupationDetails,
  GeneralDetailsFields,
  guardianDetailsFields,
  minorGeneralDetailsFields,
} from '__abc__lib/fields/UserDetails/generalDetailsFields';
import {
  meroShareFormFields,
} from '__abc__lib/fields/UserDetails/meroShareDetails';
import {
  merosharePersonalDetailsFields,
} from '__abc__lib/fields/UserDetails/merosharePersonalDetailsFields';
import {
  BoidInformation,
  tradingAddress,
  tradingBankCheque,
  tradingBeneficiaryPerson,
  tradingCitizenship,
  TradingFamilyDetails,
  tradingFinancialDetail,
  tradingGeneralDetailsFields,
  TradingInlawsDetails,
  TradingMinorDetails,
  tradingMinorGeneralDetails,
  tradingOrganizationInformation,
  tradingPoliticsInfo,
  tradingPoliticsRelated,
  tradingRentDetails,
  tradingRentField,
  tradingTransactionRelated,
} from '__abc__lib/fields/UserDetails/tradingFields';
import dayjs from 'dayjs';

import {
  brokerageName,
  companyName,
} from '../__abc__lib/fields/UserDetails/tradingFields';
import { addYearsToDate } from './addYearsToDate';

export const getUsersData = (data: any, rate: any, videoKycData?: any,) => {

  return [
    //demat
    {
      title: "DMAT",
      key: "dmat",
      children: [
        ...(data?.minor
          ? [
            {
              title: "Minor Information",
              key: "minor_details",
              data: {
                // name: data?.minor_details?.name,
                username: data?.username,
                dob: data?.minor_details?.dob,
                gender: data?.minor_details?.gender,
                identity: "Birth Certificate", //birth certificate matra magiracham in mobile, no identity field in db
                birth_certificate_number:
                  data?.minor_details?.birth_certificate_number,
                issued_district: data?.minor_details?.issued_district,
                issued_date: data?.minor_details?.issued_date,
                // issued_np: "string",
                // reference_number: "string",
              },
              formFields: minorGeneralDetailsFields,
            },
          ]
          : [
            {
              title: "General Information",
              key: "general_details",
              data: {
                // full_name: data?.general_details?.full_name,
                username: data?.username,
                dob: data?.general_details?.dob,
                pan_number: data?.pan_number,
                nationality: data?.general_details?.nationality,
                gender: data?.general_details?.gender,
                citizenship_id: data?.general_details?.citizenship_id,
                issued_district: data?.general_details?.issued_district,
                issued_date: data?.general_details?.issued_date,
                reference_number: data?.reference_number,
              },
              formFields: GeneralDetailsFields,
            },
          ]),

        {
          title: "Address Information",
          key: "address-information",
          formFields: AddressDetailsFields,
          data: [
            {
              title: "Temporary Address",
              key: "temporary_address",
              data: {
                country: data?.temporary_address?.country,
                sub: data?.temporary_address?.sub,
                district: data?.temporary_address?.district,
                province: data?.temporary_address?.province,
                tole: data?.temporary_address?.tole,
                nearest_landmark: data?.temporary_address?.nearest_landmark,
                ward: data?.temporary_address?.ward,
                fax: data?.temporary_address?.fax,
                block: data?.temporary_address?.block,
              },
              formFields: AddressDetailsFields,
            },
            {
              title: "Permanent Address",
              key: "permanent_address",
              data: {
                country: data?.permanent_address?.country,
                sub: data?.permanent_address?.sub,
                district: data?.permanent_address?.district,
                province: data?.permanent_address?.province,
                tole: data?.permanent_address?.tole,
                nearest_landmark: data?.permanent_address?.nearest_landmark,
                ward: data?.permanent_address?.ward,
                fax: data?.permanent_address?.fax,
                block: data?.permanent_address?.block,
              },
              formFields: AddressDetailsFields,
            },
            {
              title: "Location Map",
              key: "Location Map",
              data: {
                map_location: `${data?.location_map?.longitude}/${data?.location_map?.latitude}`,
              },
            },
          ],
        },
        {
          title: "Family Information",
          key: "family_details",
          data: data?.family_details?.married
            ? [
              {
                title: "Family Information",
                key: "family_details",
                data: {
                  father_name: data?.family_details?.father_name,
                  mother_name: data?.family_details?.mother_name,
                  grandfather_name: data?.family_details?.grandfather_name,
                  grandmother_name: data?.family_details?.grandmother_name,
                  married: data?.family_details?.married,
                },
                formFields: TradingFamilyDetails,
              },

              {
                title: "In Laws Information",
                key: "family_details",
                data: {
                  Spouse: getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "Spouse",
                  }),
                  "Father-in-law": getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "Father-in-law",
                  }),
                  "Mother-in-law": getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "Mother-in-law",
                  }),
                  "Grandfather-in-law": getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "Grandfather-in-law",
                  }),
                  "GrandMother-in-law": getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "GrandMother-in-law",
                  }),
                },
                formFields: TradingInlawsDetails,
              },
              {
                title: "Children Information",
                key: "children",
              },
            ]
            : [
              {
                title: "Family Information",
                key: "family_details",
                data: {
                  father_name: data?.family_details?.father_name,
                  mother_name: data?.family_details?.mother_name,
                  grandfather_name: data?.family_details?.grandfather_name,
                  grandmother_name: data?.family_details?.grandmother_name,
                  married: data?.family_details?.married,
                },
                formFields: TradingFamilyDetails,
              },
            ],
        },
        {
          title: "Occupation Details",
          key: "occupation-details",
          data: [
            {
              title: "Occupation Details",
              key: "occupation",
              data: {
                occupation_type: data?.occupation?.occupation_type,
                organization: data?.occupation?.organization,
                designation: data?.occupation?.designation,
                business_type: data?.occupation?.business_type,
              },
              formFields: DematOccupationDetails,
            },
            {
              title: "Finance Information",
              key: "finance_details",
              data: {
                finance_details: data?.finance_details?.income,
                ["Standing instruction for automatic transaction"]:
                  data?.stan_auto_trans,
                statement: data?.finance_details?.statement,
              },
              formFields: dematFinanceDetails,
            },
          ],
          formFields: DematOccupationDetails,
        },
        ...(data?.minor
          ? [
            {
              title: "Guardian Details",
              key: "guardian_details",
              data: {
                full_name: data?.guardian_details?.full_name,
                citizenship_id: data?.guardian_details?.citizenship_id,
                phone: data?.guardian_details?.phone,
                relation_with_applicant:
                  data?.guardian_details?.relation_with_applicant,
                gender: data?.guardian_details?.gender,
                reference_number: data?.reference_number,
                pan_number: data?.pan_number,
                profile:
                  data?.guardian_details?.profile ? data?.guardian_details?.profile :
                    "uploadImage/profile/guardian/user",
                citizenship_front:
                  data?.guardian_details?.citizenship_images?.front ? data?.guardian_details?.citizenship_images?.front :
                    "uploadImage/front/citizenship/guardian/user",
                citizenship_back:
                  data?.guardian_details?.citizenship_images?.back ? data?.guardian_details?.citizenship_images?.back :
                    "uploadImage/back/citizenship/guardian/user",
                thumbprint:
                  data?.guardian_details?.thumbprint ? data?.guardian_details?.thumbprint :
                    "uploadImage/finger-print/guardian/user",
                signature:
                  data?.guardian_details?.signature ? data?.guardian_details?.signature :
                    "uploadImage/signature/guardian/user",
              },
              formFields: guardianDetailsFields,
            },
          ]
          : []),

        {
          title: "Bank Details",
          key: "bank_details",
          data: {
            bank_name: data?.bank_details?.bank_name,
            account_number: data?.bank_details?.account_number,
            cheque: data?.bank_details?.cheque ? data?.bank_details?.cheque : "uploadImage/cheque/user",
            branch: data?.bank_details?.branch,
            account_type: data?.bank_details?.account_type,
          },
          // formFields: dematBankDetail(),
          formFields: DematBankDetail(),
        },
        {
          title: "Nominee Details",
          key: "nominee_details",
          data: !data?.nominee
            ? [
              {
                title: "Nominee Status",
                key: "nominee_details",
                data: {
                  nominee: data?.nominee,
                },
                formFields: dematNomineeStatus,
              },
            ]
            : [
              {
                title: "Nominee Status",
                key: "nominee_details",
                data: {
                  nominee: data?.nominee,
                },
                formFields: dematNomineeStatus,
              },

              {
                title: "Nominee Details",
                key: "nominee_details",
                data: {
                  name: data?.nominee_details?.name,
                  relationship: data?.nominee_details?.relationship,
                  age: data?.nominee_details?.age,
                  email_mobile: data?.nominee_details?.email_mobile,
                  thumbprint:
                    data?.nominee_details?.thumbprint ? data?.nominee_details?.thumbprint :
                      "uploadImage/finger-print/nominee/user",
                  citizenship_front:
                    data?.nominee_details?.citizenship_images?.front ? data?.nominee_details?.citizenship_images?.front :
                      "uploadImage/front/citizenship/nominee/user",
                  citizenship_back:
                    data?.nominee_details?.citizenship_images?.back ? data?.nominee_details?.citizenship_images?.back :
                      "uploadImage/back/citizenship/nominee/user",
                  signature:
                    data?.nominee_details?.signature ? data?.nominee_details?.signature :
                      "uploadImage/signature/nominee/user",
                },
                formFields: dematNomineeDetails,
              },
            ],
        },
        {
          title: "Documents",
          key: "documents",
          data: {
            picture: data?.minor ? data?.minor_details?.profile ? data?.minor_details?.profile : "uploadImage/profile/user" : data?.profile ? data?.profile : "uploadImage/profile/user",
            ...(data?.minor && {
              "Birth Certificate": data?.minor_details?.birth_certificate_image ? data?.minor_details?.birth_certificate_image : "upload/birth-certificate/user",
            }),
            ...(!data?.minor && {
              "Citizenship Front": data?.citizenship_images?.front ? data?.citizenship_images?.front : "uploadImage/front/citizenship/user",
            }),
            ...(!data?.minor && {
              "Citizenship Back": data?.citizenship_images?.back ? data?.citizenship_images?.back : "uploadImage/back/citizenship/user",
            }),
            thumbprint: data?.minor
              ? data?.guardian_details?.thumbprint ? data?.guardian_details?.thumbprint :
                "uploadImage/finger-print/guardian/user"
              : data?.thumbprint ? data?.thumbprint : "uploadImage/finger-print/user",
            signature: data?.minor
              ? data?.guardian_details?.signature ? data?.guardian_details?.signature :
                "uploadImage/signature/guardian/user"
              : data?.signature ? data?.signature : "uploadImage/signature/user",
          },
        },

        {
          title: "Payments",
          key: "payments",
          data: paymentType({ arr: data?.payments, formType: getFormType(data), rateList: rate }),
        },
        {
          title: "Video Kyc",
          key: "video-kyc",
          data: {
            preferred_Date: videoKycData?.preferred_date_time
              ? dayjs(videoKycData?.preferred_date_time).format("llll")
              : "N/a",
            approved_Date: videoKycData?.approved_datetime
              ? dayjs(videoKycData?.approved_datetime).format("llll")
              : "n/a",
            link:
              videoKycData?.meeting_link &&
                videoKycData?.meeting_link.length > 0
                ? videoKycData?.meeting_link
                : "https://google.com",
            status:
              videoKycData && videoKycData.status
                ? `KYC ${videoKycData?.status}`
                : "n/a",
          },
        },
        {
          title: "Watch List",
          key: "watchlist",
          data: {},
        },
      ],
    },

    //merosharre
    {
      title: "Meroshare Details",
      key: "meroshare-details",
      children: [
        {
          title: "Meroshare Details",
          key: "meroshare",
          data: {
            // full_name: data?.meroshare?.full_name,
            username: data?.username,
            dpid: data?.meroshare?.dpid,
            clientId: data?.meroshare?.clientId,
          },
          formFields: meroShareFormFields,
        },
        {
          title: "Personal Information",
          key: "meroshare",
          data: {
            email: data?.meroshare?.email,
            // address: data?.address,
            phone: data?.meroshare?.phone,
            // signature: data?.meroshare?.signature,
            signature: data?.meroshare?.signature ?  data?.meroshare?.signature : "uploadImage/signature/user",
          },
          formFields: merosharePersonalDetailsFields,
        },
        {
          title: "Address Information",
          key: "permanent_address",
          data: [
            {
              title: "Permanent Address",
              key: "permanent_address",
              data: {
                country: data?.permanent_address?.country,
                sub: data?.permanent_address?.sub,
                district: data?.permanent_address?.district,
                province: data?.permanent_address?.province,
                tole: data?.permanent_address?.tole,
                nearest_landmark: data?.permanent_address?.nearest_landmark,
                ward: data?.permanent_address?.ward,
                fax: data?.permanent_address?.fax,
                block: data?.permanent_address?.block,
              },
            },
          ],
        },

        {
          title: "Payments",
          key: "payments",
          data: paymentType({ arr: data?.payments, formType: getFormType(data), rateList: rate }),
        },
        {
          title: "Video Kyc",
          key: "video-kyc",
          data: {
            preferred_Date: videoKycData?.preferred_date_time
              ? dayjs(videoKycData?.preferred_date_time).format("llll")
              : "N/a",
            approved_Date: videoKycData?.approved_datetime
              ? dayjs(videoKycData?.approved_datetime).format("llll")
              : "n/a",
            link:
              videoKycData?.meeting_link &&
                videoKycData?.meeting_link.length > 0
                ? videoKycData?.meeting_link
                : "https://google.com",
            status:
              videoKycData && videoKycData.status
                ? `KYC ${videoKycData?.status}`
                : "n/a",
          },
        },
      ],
    },
    //trading
    {
      title: "Trading Details",
      key: "trading-details",
      children: [
        {
          title: "Boid Information",
          key: "trading",
          data: {
            boid: data?.trading?.boid,
            created_date: dayjs(data?.created_at).format("LL"),
            boid_pdf: data?.trading?.boid_pdf ?? "noPdf",
          },
          formFields: BoidInformation,
        },
        ...(data?.minor
          ? [
            {
              title: "Minor Information",
              key: "minor_details",
              data: {
                // name: data?.minor_details?.name,
                username: data?.username,
                dob: data?.minor_details?.dob,
                gender: data?.minor_details?.gender,
              },
              formFields: tradingMinorGeneralDetails,
            },
          ]
          : [
            {
              title: "General Information",
              key: "general_details",
              data: {
                // full_name: data?.general_details?.full_name,
                username: data?.username,
                dob: data?.general_details?.dob,
                pan_number: data?.pan_number,
                nationality: data?.general_details?.nationality,
                gender: data?.general_details?.gender,
                citizenship_front: data?.citizenship_images?.front ? data?.citizenship_images?.front : "uploadImage/front/citizenship/user",
                citizenship_back: data?.citizenship_images?.back ? data?.citizenship_images?.back  : "uploadImage/back/citizenship/user",
              },
              formFields: tradingGeneralDetailsFields,
            },
          ]),

        ...(data?.minor
          ? [
            {
              title: "Minor Details",
              key: "minor_details",
              data: {
                birth_certificate_number:
                  data?.minor_details?.birth_certificate_number,
                issued_date: data?.minor_details?.issued_date,
                issued_district: data?.minor_details?.issued_district,
                //minor ko ref number leko hunna, maybe guardian?
              },

              formFields: TradingMinorDetails,
            },
          ]
          : [
            {
              title: "Citizenship Info",
              key: "general_details",
              data: {
                citizenship_id: data?.general_details?.citizenship_id,
                issued_date: data?.general_details?.issued_date,
                issued_district: data?.general_details?.issued_district,
                reference_number: data?.reference_number ?? "",
              },

              formFields: tradingCitizenship,
            },
          ]),
        ...(data?.minor
          ? [
            {
              title: "Guardian Details",
              key: "guardian_details",
              data: {
                full_name: data?.guardian_details?.full_name,
                citizenship_id: data?.guardian_details?.citizenship_id,
                phone: data?.guardian_details?.phone,
                relation_with_applicant:
                  data?.guardian_details?.relation_with_applicant,
                gender: data?.guardian_details?.gender,
                reference_number: data?.reference_number,
                pan_number: data.pan_number,
                profile: data?.guardian_details?.profile ?  data?.guardian_details?.profile : "uploadImage/profile/guardian/user",
                citizenship_front:
                  data?.guardian_details?.citizenship_images?.front ? data?.guardian_details?.citizenship_images?.front : "uploadImage/front/citizenship/guardian/user",
                citizenship_back:
                  data?.guardian_details?.citizenship_images?.back ? data?.guardian_details?.citizenship_images?.back : "uploadImage/back/citizenship/guardian/user",
                thumbprint:
                  data?.guardian_details?.thumbprint ? data?.guardian_details?.thumbprint :
                    "uploadImage/finger-print/guardian/user",
                signature:
                  data?.guardian_details?.signature ? data?.guardian_details?.signature :
                    "uploadImage/signature/guardian/user",
              },
              formFields: guardianDetailsFields,
            },
          ]
          : []),

        {
          title: "Family Information",
          key: "family_details",
          data: data?.family_details?.married
            ? [
              {
                title: "Family Information",
                key: "family_details",
                data: {
                  father_name: data?.family_details?.father_name,
                  mother_name: data?.family_details?.mother_name,
                  grandfather_name: data?.family_details?.grandfather_name,
                  grandmother_name: data?.family_details?.grandmother_name,
                  married: data?.family_details?.married,
                },
                formFields: TradingFamilyDetails,
              },

              {
                title: "In Laws Information",
                key: "family_details",
                data: {
                  Spouse: getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "Spouse",
                  }),
                  "Father-in-law": getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "Father-in-law",
                  }),
                  "Mother-in-law": getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "Mother-in-law",
                  }),
                  "Grandfather-in-law": getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "Grandfather-in-law",
                  }),
                  "GrandMother-in-law": getInlaws({
                    data: data?.family_details?.in_laws,
                    relation: "GrandMother-in-law",
                  }),
                },
                formFields: TradingInlawsDetails,
              },
            ]
            : [
              {
                title: "Family Information",
                key: "family_details",
                data: {
                  father_name: data?.family_details?.father_name,
                  mother_name: data?.family_details?.mother_name,
                  grandfather_name: data?.family_details?.grandfather_name,
                  grandmother_name: data?.family_details?.grandmother_name,
                  married: data?.family_details?.married,
                },
                formFields: TradingFamilyDetails,
              },
            ],
        },
        {
          title: "Finance Information",
          key: "finance_details",
          data: {
            income: data?.finance_details?.income,
            income_range: data?.finance_details?.income_range,
          },
          formFields: tradingFinancialDetail,
        },
        {
          title: "Address Information",
          formFields: tradingAddress,
          key: "address-information",
          data: [
            {
              title: "Permanent Address",
              key: "permanent_address",
              data: {
                country: data?.permanent_address?.country,
                sub: data?.permanent_address?.sub,
                district: data?.permanent_address?.district,
                province: data?.permanent_address?.province,
                tole: data?.permanent_address?.tole,
                nearest_landmark: data?.permanent_address?.nearest_landmark,
                ward: data?.permanent_address?.ward,
                fax: data?.permanent_address?.fax,
                block: data?.permanent_address?.block,
              },
              formFields: AddressDetailsFields,
            },
            {
              title: "Temporary Address",
              key: "temporary_address",
              data: {
                country: data?.temporary_address?.country,
                sub: data?.temporary_address?.sub,
                district: data?.temporary_address?.district,
                province: data?.temporary_address?.province,
                tole: data?.temporary_address?.tole,
                nearest_landmark: data?.temporary_address?.nearest_landmark,
                ward: data?.temporary_address?.ward,
                fax: data?.temporary_address?.fax,
                block: data?.temporary_address?.block,
              },
              formFields: AddressDetailsFields,
            },
            {
              title: "Location Map",
              key: "Location Map",
              data: {
                map_location: `${data?.location_map?.longitude}/${data?.location_map?.latitude}`,
              },
            },
          ],
        },
        {
          title: "Organization Information",
          key: "occupation",
          data: {
            occupation_type: data?.occupation?.occupation_type,
            organization: data?.occupation?.organization,
            designation: data?.occupation?.designation,
            business_type: data?.occupation?.business_type,
          },
          formFields: tradingOrganizationInformation,
        },
        //trading ko lagi trading>bank_details huncha
        {
          title: "Bank Details",
          key: "trading_bank_details",
          data: {
            bank_name: data?.trading_bank_details?.bank_name,
            account_number: data?.trading_bank_details?.account_number,
            cheque:
              data?.trading_bank_details?.cheque ? data?.trading_bank_details?.cheque : "uploadImage/cheque/user",
            branch: data?.trading_bank_details?.branch,
            account_type: data?.trading_bank_details?.account_type,
          },
          formFields: tradingBankCheque,
        },

        {
          title: "Transaction Related",
          key: "trading",
          data:
            !data?.trading?.inv_comp_sec_trad &&
              !data?.trading?.buss_sec_brok_com
              ? [
                {
                  title: "Transaction Related",
                  key: "trading",
                  data: {
                    income_source: data?.trading?.income_source,
                    buss_sec_brok_com: data?.trading?.buss_sec_brok_com,
                    karja_kalosuchi: data?.trading?.karja_kalosuchi,
                    inv_comp_sec_trad: data?.trading?.inv_comp_sec_trad,
                    // company: data?.trading?.company,
                    // designation: data?.trading?.designation,
                  },
                  formFields: tradingTransactionRelated,
                },
              ]
              : !data?.trading?.buss_sec_brok_com
                ? [
                  {
                    title: "Transaction Related",
                    key: "trading",
                    data: {
                      income_source: data?.trading?.income_source,
                      buss_sec_brok_com: data?.trading?.buss_sec_brok_com,
                      karja_kalosuchi: data?.trading?.karja_kalosuchi,
                      inv_comp_sec_trad: data?.trading?.inv_comp_sec_trad,
                    },
                    formFields: tradingTransactionRelated,
                  },
                  {
                    title: "Investment Companies Name  ",
                    key: "trading",
                    data: {
                      company: data?.trading?.company,
                      designation: data?.trading?.designation,
                    },
                    formFields: companyName,
                  },
                ]
                : [
                  {
                    title: "Transaction Related",
                    key: "trading",
                    data: {
                      income_source: data?.trading?.income_source,
                      buss_sec_brok_com: data?.trading?.buss_sec_brok_com,
                      karja_kalosuchi: data?.trading?.karja_kalosuchi,
                      inv_comp_sec_trad: data?.trading?.inv_comp_sec_trad,
                    },
                    formFields: tradingTransactionRelated,
                  },
                  {
                    title: "Investment Companies Name  ",
                    key: "trading",
                    data: {
                      company: data?.trading?.company,
                      designation: data?.trading?.designation,
                    },
                    formFields: companyName,
                    brokerageName,
                  },
                  {
                    title: "Brokerage  Name  ",
                    key: "trading",
                    data: {
                      buss_sec_brok_name: data?.trading?.buss_sec_brok_name,
                      buss_sec_brok_num: data?.trading?.buss_sec_brok_num,
                    },
                    formFields: brokerageName,
                  },
                ],
        },
        {
          title: "Rent Information",
          key: "rent_details",
          data: !data?.trading?.rent
            ? [
              {
                title: "Rent Status",
                key: "rent_details",
                data: {
                  rent: data?.trading?.rent,
                },
                formFields: tradingRentField,
              },
            ]
            : [
              {
                title: "Rent Status",
                key: "rent_details",
                data: {
                  rent: data?.trading?.rent,
                },
                formFields: tradingRentField,
              },
              {
                title: "Rent Information",
                key: "rent_details",
                data: {
                  full_name: data?.trading?.rent_details?.full_name,
                  mobile: data?.trading?.rent_details?.mobile,
                },
                formFields: tradingRentDetails,
              },
            ],
        },
        {
          title: "Politics Information",
          key: "politics-info",
          data:
            data?.trading?.eng_poli_high_pos && data?.trading?.benificiary
              ? [
                {
                  title: "Politics Related",
                  key: "politics_related",
                  data: {
                    poli_high_pos: data?.trading?.poli_high_pos,
                    eng_poli_high_pos: data?.trading?.eng_poli_high_pos,
                    benificiary: data?.trading?.benificiary,
                    guilt_case: data?.trading?.guilt_case,
                    samp_sudhi_2064: data?.trading?.samp_sudhi_2064,
                  },
                  formFields: tradingPoliticsRelated,
                },
                {
                  title: "Politics Person",
                  key: "politics_person",
                  data: {
                    full_name: data?.trading?.engaged_details?.full_name,
                    relation: data?.trading?.engaged_details?.relation,
                  },
                  formFields: tradingPoliticsInfo,
                },
                {
                  title: "Benificiary Details",
                  key: "benificiary_details",
                  data: {
                    full_name: data?.trading?.benificiary_details?.full_name,
                    relation: data?.trading?.benificiary_details?.relation,
                  },
                  formFields: tradingBeneficiaryPerson,
                },
              ]
              : data?.trading?.eng_poli_high_pos && !data?.trading?.benificiary
                ? [
                  {
                    title: "Politics Related",
                    key: "politics_related",
                    data: {
                      poli_high_pos: data?.trading?.poli_high_pos,
                      eng_poli_high_pos: data?.trading?.eng_poli_high_pos,
                      benificiary: data?.trading?.benificiary,
                      guilt_case: data?.trading?.guilt_case,
                      samp_sudhi_2064: data?.trading?.samp_sudhi_2064,
                    },
                    formFields: tradingPoliticsRelated,
                  },
                  {
                    title: "Politics Person",
                    key: "politics_person",
                    data: {
                      full_name: data?.trading?.engaged_details?.full_name,
                      relation: data?.trading?.engaged_details?.relation,
                    },
                    formFields: tradingPoliticsInfo,
                  },
                ]
                : !data?.trading?.eng_poli_high_pos && data?.trading?.benificiary
                  ? [
                    {
                      title: "Politics Related",
                      key: "politics_related",
                      data: {
                        poli_high_pos: data?.trading?.poli_high_pos,
                        eng_poli_high_pos: data?.trading?.eng_poli_high_pos,
                        benificiary: data?.trading?.benificiary,
                        guilt_case: data?.trading?.guilt_case,
                        samp_sudhi_2064: data?.trading?.samp_sudhi_2064,
                      },
                      formFields: tradingPoliticsRelated,
                    },

                    {
                      title: "Benificiary Details",
                      key: "benificiary_details",
                      data: {
                        full_name: data?.trading?.benificiary_details?.full_name,
                        relation: data?.trading?.benificiary_details?.relation,
                      },
                      formFields: tradingBeneficiaryPerson,
                    },
                  ]
                  : [
                    {
                      title: "Politics Related",
                      key: "politics_related",
                      data: {
                        poli_high_pos: data?.trading?.poli_high_pos,
                        eng_poli_high_pos: data?.trading?.eng_poli_high_pos,
                        benificiary: data?.trading?.benificiary,
                        guilt_case: data?.trading?.guilt_case,
                        samp_sudhi_2064: data?.trading?.samp_sudhi_2064,
                      },
                      formFields: tradingPoliticsRelated,
                    },
                  ],
        },

        {
          title: "Documents",
          key: "documents",
          data: {
            //not working here
            picture: data?.minor
              ? data?.minor_details?.profile ? data?.minor_details?.profile : "uploadImage/profile/user"
              : data?.profile ? data?.profile  : "uploadImage/profile/user",
            ...(data?.minor && {
              "Birth Certificate":
                data?.minor_details?.birth_certificate_image ? data?.minor_details?.birth_certificate_image : "uploadImage/birth-certificate/user",
            }),
            ...(!data?.minor && {
              "Citizenship Front": data?.citizenship_images?.front ? data?.citizenship_images?.front :  "uploadImage/front/citizenship/user",
            }),
            ...(!data?.minor && {
              "Citizenship Back": data?.citizenship_images?.back ? data?.citizenship_images?.back :  "uploadImage/back/citizenship/user",
            }),
            thumbprint: data?.minor
              ? data?.guardian_details?.thumbprint ? data?.guardian_details?.thumbprint :
                "uploadImage/finger-print/guardian/user"
              : data?.thumbprint ? data?.thumbprint : "uploadImage/finger-print/user",
            signature: data?.minor
              ? data?.guardian_details?.signature ? data?.guardian_details?.signature :
                "uploadImage/signature/guardian/user"
              : data?.signature ? data?.signature : "uploadImage/signature/guardian/user",
            boid_pdf: data?.trading?.boid_pdf ?? "noPdf",
          },
        },
        {
          title: "Video Kyc",
          key: "video-kyc",
          data: {
            preferred_Date: videoKycData?.preferred_date_time
              ? dayjs(videoKycData?.preferred_date_time).format("llll")
              : "N/a",
            approved_Date: videoKycData?.approved_datetime
              ? dayjs(videoKycData?.approved_datetime).format("llll")
              : "n/a",
            link:
              videoKycData?.meeting_link &&
                videoKycData?.meeting_link.length > 0
                ? videoKycData?.meeting_link
                : "https://google.com",
            status:
              videoKycData && videoKycData.status
                ? `KYC ${videoKycData?.status}`
                : "n/a",
          },
        },
      ],
    },
  ];
};

interface PaymentType {
  arr: any[];
  formType: any;
  rateList: any[]
}

const paymentType = ({ arr, formType, rateList }: PaymentType) => {
  const newArr = arr?.filter((item) => item?.pay_for?.includes(formType)) ?? [];

  let newRate = rateList?.find((item) => item.rate === formType) || {}
  const dematOpeningRate = rateList.find((item) => item.rate === "demat_opening")

  let rate = 0;

  if (formType === "demat_with_meroshare" || formType === "demat") {
    rate = newArr[0]?.years * newRate?.value + dematOpeningRate?.value
  } else if (formType === "meroshare") {
    rate = newArr[0]?.years * newRate?.value

  }

  return {
    Amount: rate,
    Years: newArr[0]?.years,
    Expire_Date: `${addYearsToDate(newArr[0]?.created_at, newArr[0]?.years)}`,
    medium: newArr[0]?.medium ?? "",
  };
};

const getInlaws = ({ data, relation }: { data: any[]; relation: string }) => {
  const filteredData = data?.filter(
    (item) => item?.relation?.toLowerCase() === relation.toLowerCase()
  )[0];
  return filteredData?.name ?? "";
}

const getFormType = (data: any) => {
  const { form } = data
  let formType = ""
  if (form?.demat && form?.meroshare) {
    formType = "demat_with_meroshare"
  }
  if (form?.demat && !form?.meroshare) {
    formType = "demat"
  }
  if (form?.meroshare && !form?.demat) {
    formType = "meroshare"
  }

  return formType


}