import { useParams } from 'react-router';
import AdminDetails from 'Views/Details/Details/AdminDetails';
import AdminForm from 'Views/Forms/Forms/AdminForm';

export const SingleAdmin = (props: any) => {
    const { id, type } = useParams<any>();
    if (type === 'edit') {
        return <AdminForm update />
    }
    else if (id === 'add') {
        return <AdminForm />
    }
    else {
        return <AdminDetails />
    }

}

export default SingleAdmin;