import './styles.scss';

import React from 'react';

import {
  Card,
  Col,
  Row,
  Spin,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';

import { GenerateReport } from './Components/GenerateReport';
import { TableComponent } from './Components/TableComponent';

export const Reporting = () => {
  const dpReportsReducer = useSelector((state: any) => state.dpReportsReducer);

  const { generatedReportData, generatingReport } = dpReportsReducer;

  return (
    <Spin spinning={generatingReport}>
      <div className="reporting">
        <GenerateReport />
        <Card>
          <div className="headers">
            <Typography.Title level={3}>
              ABC Securities Pvt Ltd.
            </Typography.Title>
            <Typography.Text>
              {" "}
              Stock Broker No 17, Indrachowk-24 <br /> Kathmandu, Nepal | 0159700117

            </Typography.Text>
          </div>
          <div className="details">
            <Row>
              <Col lg={10}>
                <Card className="accounts">
                  <Row gutter={[16, 16]}>
                    <Typography.Text>Date:</Typography.Text>
                    <Typography.Text className="value">
                      {generatedReportData?.start_date || "-"} to{" "}
                      {generatedReportData?.end_date || "-"}
                    </Typography.Text>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Typography.Text>Account Name:</Typography.Text>
                    <Typography.Text className="value">
                      {generatedReportData?.customer_name || "-"}
                    </Typography.Text>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Typography.Text>Fiscal Year:</Typography.Text>
                    <Typography.Text className="value"></Typography.Text>
                  </Row>
                </Card>
              </Col>
              <Col lg={10} offset={4}>
                <Card className="accounts">
                  <Row gutter={[16, 16]}>
                    <Typography.Text>Branch:</Typography.Text>
                    <Typography.Text className="value">All</Typography.Text>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Typography.Text>
                      Including Opening Balance::
                    </Typography.Text>
                    <Typography.Text className="value">Yes</Typography.Text>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Typography.Text>Report Type:</Typography.Text>
                    <Typography.Text className="value">All</Typography.Text>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
          <TableComponent />
        </Card>
      </div>
    </Spin>
  );
};
