import { Reducer } from 'redux';

import {
  DOWNLOAD_TRANSACTION_REPORT,
  DOWNLOAD_TRANSACTION_REPORT_FAILURE,
  DOWNLOAD_TRANSACTION_REPORT_SUCCESS,
  FETCH_VOUCHER_DETAILS,
  FETCH_VOUCHER_DETAILS_FAILURE,
  FETCH_VOUCHER_DETAILS_SUCCESS,
  FETCH_VOUCHER_LIST,
  FETCH_VOUCHER_LIST_FAILURE,
  FETCH_VOUCHER_LIST_SUCCESS,
  RESET_UPLOAD_CDSC_FILE_STATE,
  UPLOAD_CDS_FILE,
  UPLOAD_CDS_FILE_FAILURE,
  UPLOAD_CDS_FILE_SUCCESS,
} from './TransactionTypes';

const INIT_STATE = {
    uploadingCdscFile: false,
    uploadingCdscFileError: false,
    uploadingCdscFileSuccess: false,
    uploadedCdscFile: [],
    voucherListLoading: false,
    voucherList: [],
    totalRecords: 0,
    downloadingPdfCsv: false,
    downloadedPdfCsv: false,
    downloadedPdfCsvError: false,
    voucherDetailsList: [],
    voucherDetailsLoading: false,
    voucherDetailsListCount: 0
}

export const dpTransaction: Reducer<any, any> = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPLOAD_CDS_FILE:
            return {
                ...state,
                uploadingCdscFile: true,
                uploadingCdscFileError: false,
                uploadingCdscFileSuccess: false,

            }
        case UPLOAD_CDS_FILE_SUCCESS:
            return {
                ...state,
                uploadingCdscFile: false,
                uploadedCdscFile: action.payload,
                uploadingCdscFileError: false,
                uploadingCdscFileSuccess: true

            }

        case UPLOAD_CDS_FILE_FAILURE:
            return {
                ...state,
                uploadingCdscFile: false,
                uploadedCdscFile: [],
                uploadingCdscFileError: true,
                uploadingCdscFileSuccess: false,

            }

        case RESET_UPLOAD_CDSC_FILE_STATE:
            return {
                ...state,
                uploadingCdscFile: false,
                uploadingCdscFileError: false,
                uploadedCdscFile: [],
                uploadingCdscFileSuccess: false,

            }

        case FETCH_VOUCHER_LIST:
            return {
                ...state,
                voucherListLoading: true,
                voucherList: []
            }

        case FETCH_VOUCHER_LIST_SUCCESS:
            return {
                ...state,
                voucherListLoading: false,
                voucherList: action.payload.results,
                totalRecords: action.payload.count
            }

        case FETCH_VOUCHER_LIST_FAILURE:
            return {
                ...state,
                voucherListLoading: false,
                voucherList: []
            }

        case DOWNLOAD_TRANSACTION_REPORT:
            return {
                ...state,
                downloadingPdfCsv: true,
                downloadedPdfCsv: false,
                downloadedPdfCsvError: false,
            }

        case DOWNLOAD_TRANSACTION_REPORT_SUCCESS:
            return {
                ...state,
                downloadingPdfCsv: false,
                downloadedPdfCsv: true,
                downloadedPdfCsvError: false,
            }

        case DOWNLOAD_TRANSACTION_REPORT_FAILURE:
            return {
                ...state,
                downloadingPdfCsv: false,
                downloadedPdfCsv: false,
                downloadedPdfCsvError: true,
            }

        case FETCH_VOUCHER_DETAILS:
            return {
                ...state,
                voucherDetailsLoading: true
            }


        case FETCH_VOUCHER_DETAILS_SUCCESS:
            return {
                ...state,
                voucherDetailsLoading: false,
                voucherDetailsList: action.payload.results,
                voucherDetailsListCount: action.payload.count

            }

        case FETCH_VOUCHER_DETAILS_FAILURE:
            return {
                ...state,
                voucherDetailsLoading: false,
                voucherDetailsList: [],
                voucherDetailsListCount: 0

            }
        default:
            return {
                ...state
            }
    }
}