import { Route, Switch } from "react-router";
import BatchFileUserList from "Views/BatchFile/BatchFileUserList";

export const BatchRoute = (props: any) => {
  return (
    <Switch>
      <Route
        path={`${props.match?.path}`}
        exact
        component={BatchFileUserList}
      />
    </Switch>
  );
};

export default BatchRoute;
