export const FETCH_ACCOUNT_LIST = "FETCH_ACCOUNT_LIST";
export const FETCH_ACCOUNT_LIST_SUCCESS = "FETCH_ACCOUNT_LIST_SUCCESS";
export const FETCH_ACCOUNT_LIST_FAILURE = "FETCH_ACCOUNT_LIST_FAILURE";

export const FETCH_ACCOUNT_TYPE_LIST = "FETCH_ACCOUNT_TYPE_LIST";
export const FETCH_ACCOUNT_TYPE_LIST_SUCCESS =
  "FETCH_ACCOUNT_TYPE_LIST_SUCCESS";
export const FETCH_ACCOUNT_TYPE_LIST_FAILURE =
  "FETCH_ACCOUNT_TYPE_LIST_FAILURE";

export const CREATE_CUSTOMER_ACCOUNTS = "CREATE_CUSTOMER_ACCOUNTS";
export const CREATE_CUSTOMER_ACCOUNTS_SUCCESS =
  "CREATE_CUSTOMER_ACCOUNTS_SUCCESS";
export const CREATE_CUSTOMER_ACCOUNTS_FAILURE =
  "CREATE_CUSTOMER_ACCOUNTS_FAILURE";

export const UPDATE_CUSTOMER_ACCOUNTS = "UPDATE_CUSTOMER_ACCOUNTS";
export const UPDATE_CUSTOMER_ACCOUNTS_SUCCESS =
  "UPDATE_CUSTOMER_ACCOUNTS_SUCCESS";
export const UPDATE_CUSTOMER_ACCOUNTS_FAILURE =
  "UPDATE_CUSTOMER_ACCOUNTS_FAILURE";

export const DELETE_CUSTOMER_ACCOUNTS = "DELETE_CUSTOMER_ACCOUNTS";
export const DELETE_CUSTOMER_ACCOUNTS_SUCCESS =
  "DELETE_CUSTOMER_ACCOUNTS_SUCCESS";
export const DELETE_CUSTOMER_ACCOUNTS_FAILURE =
  "DELETE_CUSTOMER_ACCOUNTS_FAILURE";

export interface AccountsListProps {
  count: number;
  results: [];
}

export interface CreateCustomerAccountProps {
  account_code: string;
  account_name: string;
  account_type?: string;
  parent_account?: string;
  is_archived?: boolean;
  customer?: string;
  fiscal_year?: string;
}

export interface UpdateCustomerAccountProps {
  id: string;
  body: CreateCustomerAccountProps;
}
