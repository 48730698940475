import {
  DpDashboardReportParamsProps,
  FETCH_DASHBOARD_REPORT,
  FETCH_DASHBOARD_REPORT_FAILURE,
  FETCH_DASHBOARD_REPORT_SUCCESS,
} from './DashboardType';

export const fetchDashboardReport = (data: DpDashboardReportParamsProps) => ({
  type: FETCH_DASHBOARD_REPORT,
  payload: data,
});

export const fetchDashboardReportSuccess = (data: any) => ({
  type: FETCH_DASHBOARD_REPORT_SUCCESS,
  payload: data,
});

export const fetchDashboardReportFailure = () => ({
  type: FETCH_DASHBOARD_REPORT_FAILURE,
});
