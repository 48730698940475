import { Route, Switch } from "react-router";
import UploadKycs from "Views/VerifyKycs/UploadKycs";

export const VerifyKycRoute = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match?.path}`} exact component={UploadKycs} />
    </Switch>
  );
};

export default VerifyKycRoute;
