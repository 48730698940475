export const ADDRESS_OPTION_LIST_SUCCESS = 'ADDRESS_OPTION_LIST_SUCCESS'
export const ADDRESS_OPTION_LIST_FAILURE = 'ADDRESS_OPTION_LIST_FAILURE'
export const ORGANIZATION_OPTION_LIST_SUCCESS =
  'ORGANIZATION_OPTION_LIST_SUCCESS'
export const ORGANIZATION_OPTION_LIST_FAILURE =
  'ORGANIZATION_OPTION_LIST_FAILURE'
export const BANK_OPTION_LIST_SUCCESS = 'BANK_OPTION_LIST_SUCCESS'
export const BANK_OPTION_LIST_FAILURE = 'BANK_OPTION_LIST_FAILURE'
export const BROKER_LIST = 'BROKER_LIST'
export const BANK_OPTION_WITH_BRANCH_LIST_SUCCESS =
  'BANK_OPTION_WITH_BRANCH_LIST_SUCCESS'

  export const RATE_OPTION_LIST_SUCCESS = "RATE_OPTION_LIST_SUCCESS"
  export const RATE_OPTION_LIST_FAILURE = "RATE_OPTION_LIST_FAILURE"
