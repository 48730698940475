import { CreateUserParams, UserDetailsParams, UserFetchParams } from './UserTypes';
import { API } from 'aws-amplify';
import { ApiHeaders, API_NAME } from 'Api/Api';
//get User
export const takeUsers = (data: UserFetchParams) =>
	API.get('userApi', '/users/list', { headers: ApiHeaders, queryStringParameters: data });

//get User Details
export const takeUser = (data: UserDetailsParams) =>
	API.get('userApi', `/admin/get/${data?.id}`, { headers: ApiHeaders, data: data });

//create User Details
export const makeUser = (data: CreateUserParams) =>
	API.post(API_NAME, '/insuranceUserAdmin', { headers: ApiHeaders, data: data });

//modify User
export const modifyUser = (data: any) =>
	API.post(API_NAME, '/insuranceUserAdmin', { headers: ApiHeaders, body: data });

//delete User
export const cutUser = (data: UserDetailsParams) =>
	API.post(API_NAME, '/insuranceUserAdmin', { headers: ApiHeaders, data: data });
