import { ReactComponent as LogoImage } from 'Assets/svg/logo.svg'
import { ReactComponent as DematSvg } from 'Assets/svg/demat.svg'
import { Image } from 'antd'
import logo from 'Assets/Company/logo.png'
import smallLogo from 'Assets/Company/smallLogo.png'

export const CompanyLogo = ({ collapsed = true }: any) => {
  return (
    <img
      style={{ width: collapsed ? '50px' : 'clamp(100px, 7vw, 150px)' }}
      src={collapsed ? smallLogo : logo}
    />
  )
  // return <LogoImage />
}
export default CompanyLogo
