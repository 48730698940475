import { ApiHeaders } from "Api/Api";
import { API } from "aws-amplify";
export type PaymentMedium = "Esewa" | "Khalti";
export type AccountStatsMedium = "Demat" | "Meroshare" | "Trading";
export const getPaymentStatsApi = (medium: PaymentMedium) =>
  API.get("kycApi", `/admin/dashboard/payment/${medium}`, {
    headers: ApiHeaders,
  });

export const getAccountStatsApi = (medium: AccountStatsMedium) =>
  API.get(
    "kycApi",
    `/admin/dashboard/account-counts/${medium?.toLowerCase()}`,
    {
      headers: ApiHeaders,
    }
  );

export const getCompletedStatsGraphApi = (
  medium: AccountStatsMedium,
  startDate: string,
  endDate: string
) => {
  const url = `/admin/dashboard/timely-counts?start=${startDate}&end=${endDate}&account=${medium?.toLowerCase()}`;
  return API.get("kycApi", url, {
    headers: ApiHeaders,
  });
};
