export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';
export const FETCH_USER_LIST_FAILURE = 'FETCH_USER_LIST_FAILURE';

export const SET_AUTH = 'SET_AUTH';

export const SET_LAYOUT = 'SET_LAYOUT';

export const SET_VIEW = 'SET_VIEW';

export const defaultFunction = () => {};
