import "./AllFormStatus.scss";

import { Avatar, Space } from "antd";
import { useHistory } from "react-router";

export const AllFormStatusWithLinks = (props: any) => {
  const { data, handleClick } = props;

  const history: any = useHistory();

  const getStyle = (status: any) => {
    let backgroundColor = "white";
    let fontColor = "black";
    switch (status) {
      case "N/A":
        backgroundColor = "white";
        fontColor = "black";
        break;
      case "Pending":
        backgroundColor = "#FF895B";
        fontColor = "white";
        break;

      case "Accepted":
        backgroundColor = "#1CC67B";
        fontColor = "white";
        break;
      case "Rejected":
        backgroundColor = "#F44E51";
        fontColor = "white";
        break;
      case "Completed":
        backgroundColor = "green";
        fontColor = "white";
        break;

      default:
        backgroundColor = "white";
        fontColor = "black";
        break;
    }
    return {
      color: fontColor,
      backgroundColor,
    };
  };

  return (
    <Space>
      <div
        onClick={() => {
          data?.demat && handleClick("demat");
        }}
      >
        <Avatar
          style={{
            ...getStyle(data?.demat ? data.demat_status : "N/A"),
            fontSize: 12,
            verticalAlign: "middle",
            lineHeight: "27px",
          }}
        >
          D
        </Avatar>
      </div>
      <div
        onClick={() => {
          data?.meroshare && handleClick("meroshare");
        }}
      >
        <Avatar
          style={{
            ...getStyle(data?.meroshare ? data?.meroshare_status : "N/A"),
            fontSize: 12,
            verticalAlign: "middle",
            lineHeight: "27px",
          }}
        >
          M
        </Avatar>
      </div>
      <div
        onClick={() => {
          data?.trading && handleClick("trading");
        }}
      >
        <Avatar
          style={{
            ...getStyle(data?.trading ? data?.trading_status : "N/A"),
            fontSize: 12,
            verticalAlign: "middle",
            lineHeight: "27px",
          }}
        >
          T
        </Avatar>
      </div>
    </Space>
  );
};

export default AllFormStatusWithLinks;
