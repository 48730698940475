import React from 'react'
import MainRoute from 'Routes/MainRoute'
import 'Themes/App.scss'
import 'theme-config/theme/ant.css'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { message } from 'antd'

const App = () => {
  message.config({
    duration: 7,
  })

  dayjs.extend(localizedFormat)
  dayjs.extend(isToday)
  dayjs.extend(isTomorrow)
  dayjs.extend(utc)
  dayjs.extend(duration)
  dayjs.extend(relativeTime)
  console.log(
    process.env.REACT_APP_BUILD_ENV,
    'THIS IS AWS AMPLIFY ENV VARIABLES'
  )
  return <MainRoute />
}

export default App
