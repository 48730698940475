import { Reducer } from 'react';

import {
  ACCEPT_REJECT_FAILURE,
  ACCEPT_REJECT_SUCCESS,
  ACCEPT_REJECT_VIDEO_KYC,
  CREATE_KYC,
  CREATE_KYC_FAILURE,
  CREATE_KYC_SUCCESS,
  CreateKycParams,
  FETCH_KYC,
  FETCH_KYC_FAILURE,
  FETCH_KYC_REQUESTS,
  FETCH_KYC_REQUESTS_FAILURE,
  FETCH_KYC_REQUESTS_SUCCESS,
  FETCH_KYC_SUCCESS,
  FETCH_KYCS,
  FETCH_KYCS_FAILURE,
  FETCH_KYCS_SUCCESS,
  FETCH_VIDEO_KYC,
  FETCH_VIDEO_KYC_FAILURE,
  FETCH_VIDEO_KYC_SUCCESS,
  GENERATE_PDF,
  GENERATE_PDF_FAILURE,
  GENERATE_PDF_SUCCESS,
  KycDetailsParams,
  REMOVE_KYC,
  REMOVE_KYC_FAILURE,
  REMOVE_KYC_SUCCESS,
  RESCHEDULE_FAILURE,
  RESCHEDULE_SUCCESS,
  RESCHEDULE_VIDEO_KYC,
  SCHEDULE_FAILURE,
  SCHEDULE_SUCCESS,
  SCHEDULE_VIDEO_KYC,
  UPDATE_KYC,
  UPDATE_KYC_FAILURE,
  UPDATE_KYC_SUCCESS,
  USER_LIST_SCHEDULE_VIDEO_KYC,
  USER_LIST_SCHEDULE_VIDEO_KYC_FAILURE,
  USER_LIST_SCHEDULE_VIDEO_KYC_SUCCESS,
  VERIFY_VIDEO_KYC,
} from './KycTypes';

const INIT_STATE = {
  list: [],
  error: "",
  detailsError: "",
  details: false,
  loading: false,
  loaded: false,
  detailing: false,
  detailed: false,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  pageNumber: 0,
  pageSize: 0,
  totalPages: 1,
  totalRecords: 8,
  totalUserRecords: 0,
  rescheduling: false,
  rescheduleSuccess: false,
  rescheduleFailure: false,
  scheduling: false,
  scheduleSuccess: false,
  scheduleFailure: false,
  videoKyc: {},
  loadingVideoKyc: false,
  loadedVideoKyc: false,
  acceptingOrRejecting: false,
  acceptingOrRejectingSuccess: false,
  acceptingOrRejectingFailure: false,
  videoKycRequestList: [],
  userListVideoKyc: [],
  userListVideoKycLoading: false,
  generatingPdf: false,
  generatedPdf: false
};

export const KycReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    //fetch Kycs
    case FETCH_KYCS:
      return { ...state, loaded: false, loading: true };
    case FETCH_KYCS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.forms,
        pageNumber: action.payload.page,
        pageSize: action.payload.count,
        totalPages: action.payload.totalPages,
        totalUserRecords: action.payload.total,
      };
    case FETCH_KYCS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    //fetch Kyc requests
    case FETCH_KYC_REQUESTS:
      return { ...state, loaded: false, loading: true };
    case FETCH_KYC_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        videoKycRequestList: action.payload.forms,
        pageNumber: action.payload.page,
        pageSize: action.payload.count,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.total,
      };
    case FETCH_KYC_REQUESTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FETCH_VIDEO_KYC:
      return { ...state, loadedVideoKyc: false, loadingVideoKyc: true };
    case FETCH_VIDEO_KYC_SUCCESS:
      return {
        ...state,
        loadingVideoKyc: false,
        loadedVideoKyc: true,
        videoKyc: action.payload,
      };
    case FETCH_VIDEO_KYC_FAILURE:
      return { ...state, loadingVideoKyc: false, error: action.payload };

    //fetch Kyc Details
    case FETCH_KYC:
      return { ...state, detailed: false, detailing: true };
    case FETCH_KYC_SUCCESS:
      return {
        ...state,
        detailing: false,
        detailed: false,
        details: action.payload || false,
      };
    case FETCH_KYC_FAILURE:
      return { ...state, detailing: false, detailsError: action.payload };

    //create Kyc
    case CREATE_KYC:
      return { ...state, created: false, creating: true, error: false };
    case CREATE_KYC_SUCCESS:
      state.list.push(action.payload);
      return { ...state, creating: false, created: true };
    case CREATE_KYC_FAILURE:
      return { ...state, creating: false, error: action.payload.error };

    //update Kyc
    case UPDATE_KYC:
      return { ...state, updating: true, updated: false, error: false };
    case UPDATE_KYC_SUCCESS:
      const tempList = state.list.filter(
        (d: CreateKycParams) => d.id !== action.payload.id
      );
      tempList.push(action.payload);
      return { ...state, updated: true, updating: false, list: tempList };
    case UPDATE_KYC_FAILURE:
      return { ...state, updating: false, error: action.payload.error };

    //remove Kyc
    case REMOVE_KYC:
      return { ...state, deleted: false, deleting: true };
    case REMOVE_KYC_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        list: state.list.filter(
          (d: KycDetailsParams) => d.id !== action.payload.id
        ),
      };
    case REMOVE_KYC_FAILURE:
      return { ...state, deleting: false };

    //Reschdule
    case RESCHEDULE_VIDEO_KYC:
      return { ...state, rescheduling: true, rescheduleSuccess: false };
    case RESCHEDULE_SUCCESS:
      return {
        ...state,
        videoKyc: {
          ...state.videoKyc,
          meeting_link: action.payload.meeting_link,
        },
        rescheduling: false,
        rescheduleSuccess: true,
      };
    case RESCHEDULE_FAILURE:
      return { ...state, rescheduling: false, rescheduleFailure: true };

    //Schdule
    case SCHEDULE_VIDEO_KYC:
      return { ...state, scheduling: true, scheduleSuccess: false };
    case SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduling: false,
        scheduleSuccess: true,
      };
    case SCHEDULE_FAILURE:
      return { ...state, scheduling: false, scheduleFailure: true };

    //Accepting or Rejecting
    case ACCEPT_REJECT_VIDEO_KYC:
      return {
        ...state,
        acceptingOrRejecting: true,
        acceptingOrRejectingSuccess: false,
      };
    case ACCEPT_REJECT_SUCCESS:
      return {
        ...state,
        acceptingOrRejecting: false,
        acceptingOrRejectingSuccess: true,
        videoKyc: {
          ...state.videoKyc,
          approved_datetime: action.payload.approved_date_time
            ? action.payload.approved_date_time
            : null,
        },
      };
    case ACCEPT_REJECT_FAILURE:
      return {
        ...state,
        acceptingOrRejecting: false,
        acceptingOrRejectingSuccess: false,
      };
    // case RESCHEDULE_FAILURE:
    //   return {
    //     ...state,
    //     acceptingOrRejecting: false,
    //     acceptingOrRejectingSuccess: true,
    //   };

    case VERIFY_VIDEO_KYC:
      return {
        ...state,
        videoKyc: {
          ...state.videoKyc,
          status: action.payload,
        },
      };

    case USER_LIST_SCHEDULE_VIDEO_KYC:
      return {
        ...state,
        userListVideoKyc: [],
        userListVideoKycLoading: true
      }
    case USER_LIST_SCHEDULE_VIDEO_KYC_SUCCESS:
      return {
        ...state,
        userListVideoKyc: action.payload.data,
        userListVideoKycLoading: false
      }

    case USER_LIST_SCHEDULE_VIDEO_KYC_FAILURE:
      return {
        ...state,
        userListVideoKyc: [],
        userListVideoKycLoading: false
      }

    case GENERATE_PDF:
      return {
        ...state,
        generatingPdf: true,
        generatedPdf: false

      }

    case GENERATE_PDF_SUCCESS:
      return {
        ...state,
        generatingPdf: false,
        generatedPdf: true
      }

    case GENERATE_PDF_FAILURE:
      return {
        ...state,
        generatingPdf: false,
        generatedPdf: false
      }
    default:
      return { ...state };
  }
};
