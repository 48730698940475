import {
  ADDRESS_OPTION_LIST_FAILURE,
  ADDRESS_OPTION_LIST_SUCCESS,
  BANK_OPTION_LIST_FAILURE,
  BANK_OPTION_LIST_SUCCESS,
  BANK_OPTION_WITH_BRANCH_LIST_SUCCESS,
  BROKER_LIST,
  ORGANIZATION_OPTION_LIST_FAILURE,
  ORGANIZATION_OPTION_LIST_SUCCESS,
  RATE_OPTION_LIST_FAILURE,
  RATE_OPTION_LIST_SUCCESS,
} from './OptionTypes';

export const addressOptionListSuccess = (response: any) => {
  let newResposne = response.data.map((item: any) => ({
    value: item.district,
    label: item.district,
  }))

  //remove duplicate objects from the array using sets
  const seen = new Set()
  const filteredArr = newResposne.filter((element: any) => {
    const duplicate = seen.has(element.label)
    seen.add(element.label)
    return !duplicate
  })

  return {
    type: ADDRESS_OPTION_LIST_SUCCESS,
    payload: filteredArr,
    payload2: response.data,
  }
}

export const addressOptionListFailure = (error: any) => ({
  type: ADDRESS_OPTION_LIST_FAILURE,
  payload: error,
})

export const organizationOptionListSuccess = (response: any) => {
  const newResposne = response.data.map((item: any) => ({
    value: item.info,
    label: item.info,
  }))
  return {
    type: ORGANIZATION_OPTION_LIST_SUCCESS,
    payload: newResposne,
  }
}
export const brokerSuccess = (response: any) => {
  return {
    type: BROKER_LIST,
    payload: response,
  }
}

export const organizationOptionListFailure = (error: any) => ({
  type: ORGANIZATION_OPTION_LIST_FAILURE,
  payload: error,
})

export const bankOptionListSuccess = (response: any) => {
  const newResposne = response.data.map((item: any) => ({
    value: item.name,
    label: item.name,
  }))
  return {
    type: BANK_OPTION_LIST_SUCCESS,
    payload: newResposne,
  }
}
export const bankOptionWithBranchSuccess = (response: any) => {
  return {
    type: BANK_OPTION_WITH_BRANCH_LIST_SUCCESS,
    payload: response.data,
  }
}

export const bankOptionListFailure = (error: any) => ({
  type: BANK_OPTION_LIST_FAILURE,
  payload: error,
})

export const rateListSuccess = (response: any) => {
  return {
    type: RATE_OPTION_LIST_SUCCESS,
    payload: response
  }
}

export const rateListFailure = () => {
  return {
    type: RATE_OPTION_LIST_FAILURE,
  }
}
