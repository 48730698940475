export const ApiName = "fakeApi";
export const DEV_API_NAME = "devApi";
export const PROD_API_NAME = "devApi";
export const NEPSE_API_NAME = "nepseApi";
export const ADMIN_API_NAME = "adminApi";
export const USER_API_NAME = "userApi";
export const BASE_API_NAME = "baseApi";

export const API_NAME =
  process.env.NODE_ENV === "development" ? DEV_API_NAME : PROD_API_NAME;

export const ApiHeaders = {
  "Content-Type": "application/json",
};
