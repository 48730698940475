import {
  DOWNLOAD_TRANSACTION_REPORT,
  DOWNLOAD_TRANSACTION_REPORT_FAILURE,
  DOWNLOAD_TRANSACTION_REPORT_SUCCESS,
  FETCH_VOUCHER_DETAILS,
  FETCH_VOUCHER_DETAILS_FAILURE,
  FETCH_VOUCHER_DETAILS_SUCCESS,
  FETCH_VOUCHER_LIST,
  FETCH_VOUCHER_LIST_FAILURE,
  FETCH_VOUCHER_LIST_SUCCESS,
  FetchVoucherDetailsProps,
  FetchVoucherListProps,
  RESET_UPLOAD_CDSC_FILE_STATE,
  UPLOAD_CDS_FILE,
  UPLOAD_CDS_FILE_FAILURE,
  UPLOAD_CDS_FILE_SUCCESS,
} from './TransactionTypes';

export const uploadCdscFile = (data: object) => ({
    type: UPLOAD_CDS_FILE,
    payload: data
})

export const uploadCdscFileSuccess = (data: any) => ({
    type: UPLOAD_CDS_FILE_SUCCESS,
    payload: data
})

export const uploadCdscFileFailure = () => ({
    type: UPLOAD_CDS_FILE_FAILURE,

})

export const resetUploadCdscFileStateValue = () => ({
    type: RESET_UPLOAD_CDSC_FILE_STATE
})

export const fetchVoucherList = (data: FetchVoucherListProps) => ({
    type: FETCH_VOUCHER_LIST,
    payload: data
})

export const fetchVoucherListSuccess = (data: any) => ({
    type: FETCH_VOUCHER_LIST_SUCCESS,
    payload: data
})

export const fetchVoucherListFailure = () => ({
    type: FETCH_VOUCHER_LIST_FAILURE
})

export const downloadTransactionReport = (data: FetchVoucherListProps, fileType: string) => ({
    type: DOWNLOAD_TRANSACTION_REPORT,
    payload: data,
    fileType: fileType,

})

export const downloadTransactionReportSuccess = () => ({
    type: DOWNLOAD_TRANSACTION_REPORT_SUCCESS
})

export const downloadTransactionReportFailure = () => ({
    type: DOWNLOAD_TRANSACTION_REPORT_FAILURE
})

export const fetchVoucherDetails = (data:FetchVoucherDetailsProps) => ({
    type: FETCH_VOUCHER_DETAILS,
    payload: data
})

export const fetchVoucherDetailsSuccess = (data:any) => ({
    type: FETCH_VOUCHER_DETAILS_SUCCESS,
    payload: data
})

export const fetchVoucherDetailsFailure = () => ({
    type: FETCH_VOUCHER_DETAILS_FAILURE
})