import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Divider,
  Modal,
  Spin,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  fetchVoucherDetails,
} from 'Redux/DpRedux/Transaction/TransactionAction';

interface VoucherDetailsProps {
    showDetails: {
        visible: boolean,
        id: string
    },
    setShowDetails: Dispatch<SetStateAction<{ visible: boolean, id: string }>>
}

export const VoucherDetails = ({ showDetails, setShowDetails }: VoucherDetailsProps) => {

    const dispatch = useDispatch()

    const { voucherDetailsList, voucherDetailsLoading, voucherDetailsListCount } = useSelector(
        (state: any) => state.dpTransactionReducer
    );

    const [filterData, setFilterData] = useState({
        page: 1,
        limit: 10
    })

    useEffect(() => {
        if (showDetails.id) {
            const offset = (filterData.page - 1) * filterData.limit;
            dispatch(fetchVoucherDetails({
                limit: filterData.limit,
                offset: offset,
                voucherId: showDetails.id
            }))
        }
    }, [showDetails.id])


    const voucherColumn = [
        {
            title: "Account Code",
            dataIndex: "ca_code",
            key: "ca_code",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
        {
            title: "Account Name",
            dataIndex: "account_name",
            key: "account_name",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
        {
            title: "Description",
            dataIndex: "particulars",
            key: "particulars",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
        {
            title: "Branch",
            dataIndex: "branch",
            key: "branch",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{"KTM"}</Typography.Text>
            ),
        },
        // {
        //     title: "Amount",
        //     dataIndex: "amount",
        //     key: "amount",
        //     render: (text: any, row: any, index: number) => (
        //         <Typography.Text>{text}</Typography.Text>
        //     ),
        // },
        {
            title: "Debit",
            dataIndex: "amount",
            key: "amount",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{row.entry_type === "D" ? text : ""}</Typography.Text>
            ),
        },
        {
            title: "Credit",
            dataIndex: "amount",
            key: "amount",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{row.entry_type === "C" ? text : ""}</Typography.Text>
            ),
        },
    ]

    return (
        <Modal
            visible={showDetails.visible}
            width={"65vw"}
            onCancel={() => {
                setShowDetails({
                    visible: false,
                    id: ""
                })
            }}
            footer={false}
            className='voucher-details'
        >
            <Spin spinning={voucherDetailsLoading}>
                <div className="headers">
                    <div className='left'>
                        <Typography.Title level={5}>
                            ABC Securities Pvt Ltd.
                        </Typography.Title>
                        <Typography.Text>
                            {" "}
                            Stock Broker No 17, Indrachowk-24  <br /> Kathmandu, Nepal | 0159700117
                        </Typography.Text>
                    </div>
                    <div className='right'>
                        <Typography.Text>
                            {" "}
                            Voucher Number: {voucherDetailsList[0]?.voucher_number}
                        </Typography.Text>
                        <Typography.Text>
                            {" "}
                            Fiscal Year:
                        </Typography.Text>
                        <Typography.Text>
                            {" "}
                            Date: {dayjs(voucherDetailsList[0]?.voucher_transaction_date).format("YYYY-MM-DD")}
                        </Typography.Text>
                    </div>
                </div>
                <Divider />
                <div>
                    <Table
                        rowKey="ca_code"
                        columns={voucherColumn}
                        dataSource={
                            voucherDetailsList
                        }
                        className='voucher-details-table'
                        // loading={voucherDetailsLoading}
                        pagination={{
                            current: Number(filterData.page),
                            total: parseInt(voucherDetailsListCount),
                            onChange: (page: number) => {
                                setFilterData((prevState) => ({
                                    ...prevState,
                                    page: page,
                                }));
                            },
                            pageSize: filterData.limit,
                            showSizeChanger: false,
                        }}
                        summary={(currentData) => {
                            
                            return (
                                <Table.Summary>
                                    <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3} className='summary-cell'>
                                        Total
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5} className='summary-cell'>
                                        {currentData[0]?.voucher_amount}
                                    </Table.Summary.Cell>

                                    </Table.Summary.Row></Table.Summary>
                            )
                        }}
                    />
                </div>

            </Spin>
        </Modal>
    )
}
