import React from 'react';

import { Typography } from 'antd';

export const CustomLabel = ({label}: {label:string}) => {
  return (
    <Typography style={{
        marginBottom: 12,
        fontSize: 13,
        fontWeight: 600

    }}> {label}</Typography>
    
  )
}
