import { message } from 'antd';
import { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { DpAccountsFilterDataProps } from 'Types/Types';

import {
  createCustomerAccountFailure,
  createCustomerAccountSuccess,
  deleteCustomerAccountFailure,
  deleteCustomerAccountSuccess,
  fetchAccountListFailure,
  fetchAccountListSuccess,
  fetchAccountTypeListFailure,
  fetchAccountTypeListSuccess,
  updateCustomerAccountFailure,
  updateCustomerAccountSuccess,
} from './AccountActions';
import {
  createCustomerAccountApi,
  deleteCustomerAccountApi,
  fetchAccountListApi,
  fetchAccountTypeListApi,
  updateCustomerAccountApi,
} from './AccountApi';
import {
  CREATE_CUSTOMER_ACCOUNTS,
  DELETE_CUSTOMER_ACCOUNTS,
  FETCH_ACCOUNT_LIST,
  FETCH_ACCOUNT_TYPE_LIST,
  UPDATE_CUSTOMER_ACCOUNTS,
  UpdateCustomerAccountProps,
} from './AccountTypes';

const fetchAccountListAsync = async (data: DpAccountsFilterDataProps) => {
  return await fetchAccountListApi(data);
};

function* fetchAccountList(params: any) {
  const { payload } = params;
  try {
    const response: AxiosResponse = yield call(fetchAccountListAsync, payload);

    yield put(fetchAccountListSuccess(response.data));
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(fetchAccountListFailure());
    message.error(messages);
  }
}

function* watchAccountList() {
  yield takeLatest(FETCH_ACCOUNT_LIST, fetchAccountList);
}

const createAccountAsync = async (data: any) =>
  await createCustomerAccountApi(data);

function* createAccount(params: any) {
  const { payload } = params;
  try {
    const response: AxiosResponse = yield call(createAccountAsync, payload);
    if (response) {
      const messages = "Account created successfully";
      yield put(createCustomerAccountSuccess());
      message.success(messages);
    }
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(createCustomerAccountFailure());
    message.error(messages);
  }
}

function* watchCreateAccount() {
  yield takeLatest(CREATE_CUSTOMER_ACCOUNTS, createAccount);
}

const updateAccountAsync = async (data: UpdateCustomerAccountProps) => {
  return await updateCustomerAccountApi(data);
};

function* updateAccount(params: any) {
  const { payload } = params;

  try {
    const response: AxiosResponse = yield updateAccountAsync(payload);
    if (response) {
      const messages = "Account updated successfully";
      yield put(updateCustomerAccountSuccess());
      message.success(messages);
    }
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(updateCustomerAccountFailure());
    message.error(messages);
  }
}

function* watchUpdateAccount() {
  yield takeLatest(UPDATE_CUSTOMER_ACCOUNTS, updateAccount);
}

const deleteAccountAsync = async (id: string) => {
  return await deleteCustomerAccountApi(id);
};

function* deleteCustomerAccount(data: any) {
  const { payload } = data;
  try {
    const response: AxiosResponse = yield deleteAccountAsync(payload);
    if (response) {
      const messages = "Account deleted successfully";
      yield put(deleteCustomerAccountSuccess());
      message.success(messages);
    }
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(deleteCustomerAccountFailure());
    message.error(messages);
  }
}

function* watchDeleteCustomerAccount() {
  yield takeLatest(DELETE_CUSTOMER_ACCOUNTS, deleteCustomerAccount);
}

const fetchAccountTypeListAsync = async () => {
  return await fetchAccountTypeListApi();
};

function* fetchAccountTypeList(data: any) {
  // const { payload } = data;

  try {
    const response: AxiosResponse = yield call(fetchAccountTypeListAsync);
    if (response) {
      yield put(fetchAccountTypeListSuccess(response.data));
    }
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(fetchAccountTypeListFailure());
    message.error(messages);
  }
}

function* watchFetchAccountTypeList() {
  yield takeLatest(FETCH_ACCOUNT_TYPE_LIST, fetchAccountTypeList);
}

export default function* AccountSagas() {
  yield all([
    fork(watchAccountList),
    fork(watchCreateAccount),
    fork(watchUpdateAccount),
    fork(watchDeleteCustomerAccount),
    fork(watchFetchAccountTypeList),
  ]);
}
