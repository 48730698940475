import { useParams } from 'react-router';
import KycDetails from 'Views/Details/Details/KycDetails';
import KycForm from 'Views/Forms/Forms/KycForm';

export const SingleKyc = (props: any) => {
    const { id, type } = useParams<any>();
    if (type === 'edit') {
        return <KycForm update />
    }
    else if (id === 'add') {
        return <KycForm />
    }
    else {
        return <KycDetails />
    }

}

export default SingleKyc;