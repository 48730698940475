import { message } from 'antd';
import { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  fetchDashboardReportFailure,
  fetchDashboardReportSuccess,
} from './DashboardActions';
import { fetchDashboardReportApi } from './DashboardApi';
import {
  DpDashboardReportParamsProps,
  FETCH_DASHBOARD_REPORT,
} from './DashboardType';

const fetchDashboardReportAsync = async (
  data: DpDashboardReportParamsProps
) => {
  return await fetchDashboardReportApi(data);
};

function* fetchDashboardReport(data: any) {
  const { payload } = data;

  try {
    const response: AxiosResponse = yield call(
      fetchDashboardReportAsync,
      payload
    );

    if (response) {
      yield put(fetchDashboardReportSuccess(response.data));
    }
  } catch (error: any) {
    const messages = error.message ?? "Something went wrong";
    yield put(fetchDashboardReportFailure());
    message.error(messages);
  }
}

function* watchFetchDashboardReport() {
  yield takeLatest(FETCH_DASHBOARD_REPORT, fetchDashboardReport);
}

export default function* DpDashboardReportSagas() {
  yield all([fork(watchFetchDashboardReport)]);
}
