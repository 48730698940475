import React from "react";

import { Table, Typography } from "antd";
import { useSelector } from "react-redux";

export const TableComponent = () => {
  const dpReportsReducer = useSelector((state: any) => state.dpReportsReducer);

  const { generatedReportData } = dpReportsReducer;

  const columns = [
    {
      title: "SN",
      dataIndex: "SN",
      key: "SN",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Date AD",
      dataIndex: "date",
      key: "date",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Date BS",
      dataIndex: "date_bs",
      key: "date_bs",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Voucher No.",
      dataIndex: "Voucher_number",
      key: "Voucher_number",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "particulars",
      dataIndex: "particulars",
      key: "particulars",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Reference No.",
      dataIndex: "reference_no",
      key: "date_ad",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
  ];

  return (
    <Table
      className="report-table"
      rowKey="SN"
      columns={columns}
      dataSource={generatedReportData?.transactions}
      pagination={false}
      summary={() => {
        if (generatedReportData?.transactions) {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={6}
                  colSpan={3}
                  className="summaryCell first"
                >
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="summaryCell ">
                  {" "}
                  {generatedReportData?.total_debit || 0}{" "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} className="summaryCell">
                  {" "}
                  {generatedReportData?.total_credit || 0}{" "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} className="summaryCell last">
                  {generatedReportData?.total_balance || 0}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }
      }}
    />
  );
};
