import React from 'react';

import {
  Card,
  Typography,
} from 'antd';

interface StatsCardProps {
  textType?: "secondary" | "success" | "warning" | "danger" | undefined;
  label: string | number;
  text: string;
}

export const StatsCard = ({ textType, label, text }: StatsCardProps) => {
  return (
    <Card>
      <Typography.Title
        level={2}
        type={textType}
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
        }}
      >
        {label}
      </Typography.Title>
      <Typography.Title style={{ textAlign: "center", fontSize: "1rem" }}>
        {" "}
        {text}{" "}
      </Typography.Title>
    </Card>
  );
};
