import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'Config/config';

import {
  generatePdfExcelProps,
  generateReportProps,
} from './ReportsTypes';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const generateReportApi = async (data: generateReportProps) => {
  const { boid_number, start_date, end_date } = data;
  const body = {
    start_date,
    end_date,
  };
  return axios.post(`reports/chart-of-account/${boid_number}/reports`, body, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
};

export const generatePdfExcelApi = async (data: generatePdfExcelProps) => {
  const { boid_number, start_date, end_date, type } = data;
  const body = {
    start_date,
    end_date,
  };

  return axios.post(
    `reports/chart-of-account/${boid_number}/${
      type === "excel" ? "excel-reports" : "pdf"
    }`,
    body,
    {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
      responseType: "blob",
    }
  );
};
