export const FETCH_ADMINS = 'FETCH_ADMINS'
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS'
export const FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE'

export const FETCH_ADMIN = 'FETCH_ADMIN'
export const FETCH_ADMIN_SUCCESS = 'FETCH_ADMIN_SUCCESS'
export const FETCH_ADMIN_FAILURE = 'FETCH_ADMIN_FAILURE'

export const CREATE_ADMIN = 'CREATE_ADMIN'
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS'
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE'

export const UPDATE_ADMIN = 'UPDATE_ADMIN'
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS'
export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE'

export const REMOVE_ADMIN = 'REMOVE_ADMIN'
export const REMOVE_ADMIN_SUCCESS = 'REMOVE_ADMIN_SUCCESS'
export const REMOVE_ADMIN_FAILURE = 'REMOVE_ADMIN_FAILURE'

export interface AdminFetchParams {
  limit?: number
  page?: number
  PageSize?: number
  PageNumber?: number
}
export interface AdminDetailsParams {
  id?: number
}
export interface CreateAdminParams {
  id?: number
  name?: string
  address?: string
  phone?: string
  role?: string
  full_name?: string
  phone_number?: string
  email?: string
  status?: string
}
