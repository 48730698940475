import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { AntFormItem } from "Components/AntForm/AntForm";
import Status from "Components/Status/Status";
import dayjs from "dayjs";
import ArrowDownIcon from "Icons/ArrowDownIcon/ArrowDownIcon";
import { DownloadIconDark } from "Icons/DownloadIcon/DownloadIcon";
import { AccountTargets } from "interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { generateBatchFile } from "Redux/BatchFileRedux(downlodFiles)/BatchFileActions";
import { fetchKycs, updateKyc } from "Redux/KycRedux/KycActions";
import { FilterType } from "Types/Types";
import { useDebounce } from "use-debounce/lib";
import { onDownload } from "Utils/downloadFile";

export const BatchFileUserList = (props: any) => {
  const kycUsers = useSelector((state: any) => state.KycReducer);
  const { submitting } = useSelector((state: any) => state.BatchFileReducer);
  const [filter, setFilter] = useState<FilterType>({
    page: 1,
    limit: 10,
  });
  const [accType, setAccType] = useState("demat");
  const [searchedType, setSearchedType] = useState<AccountTargets>("demat");
  const [filterText, setFilterText] = useState("");
  const [showTradingModal, setShowTradingModal] = useState(false);
  const [debounceFilter] = useDebounce(filterText, 500);
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();

  const { loading, list, totalRecords, updating, pageNumber } = kycUsers;

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const onSelectChange = (selKeys: any) => {
    setSelectedRowKeys(selKeys);
  };
  const onPageChange = (page: number) => {
    setFilter({ ...filter, page });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    setFilter({ ...filter });
  }, [debounceFilter]);

  //Fetch data with filter
  const fetchWithFilter = (data: any) => {
    dispatch(fetchKycs({ ...data, status: "Accepted" }));
  };

  //edit button click handler

  useEffect(() => {
    if (kycUsers?.updated) {
      fetchWithFilter({ ...filter });
    }
  }, [kycUsers?.updated]);

  const downloadHandler = () => {
    if (selectedRowKeys.length < 1) {
      message.error("No forms selected");
    } else {
      if (searchedType === "demat") {
        dispatch(
          generateBatchFile(
            selectedRowKeys,
            "demat",
            `${dayjs().format("YYMMDD-HHmmss")}.zip`
          )
        );
      } else if (searchedType === "trading") {
        // dispatch(generateBatchFile(selectedRowKeys, "trading"));
        setShowTradingModal(true);
      } else {
        dispatch(
          generateBatchFile(
            selectedRowKeys,
            "meroshare",
            `${dayjs().format("YYMMDD-HHmmss")}.csv`
          )
        );

        // message.success("TODO meroshare");
      }
    }
  };

  const tradingDownloadHandler = (csv: boolean) => {
    if (csv) {
      dispatch(generateBatchFile(selectedRowKeys, "bot-trading", "bot.zip"));
    } else {
      dispatch(
        generateBatchFile(
          selectedRowKeys,
          "non-bot-trading",
          `${dayjs().format("YYMMDD-HHmmss")}.zip`
        )
      );
    }
    setShowTradingModal(false);
  };

  const userColumns = [
    {
      title: "USER ID",
      dataIndex: "userNumber",
      key: "userNumber",
      render: (text: any, row: any, index: number) => index + 1,
    },
    {
      title: "USERNAME",
      dataIndex: "username",
      key: "username",
      // render: (row: any, text: any, index: number) => <Typography.Text>{dayjs(row)?.format('LLL')}</Typography.Text>
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      render: (row: any, text: any, index: number) => (
        <Typography.Link href={`mailto:${row}`}>{row}</Typography.Link>
      ),
    },

    {
      title: "A/C TYPE",
      dataIndex: "account",
      key: "account",
      render: (text: any, row: any, index: number) => {
        const displayText = `${text}(${row?.minor ? "minor" : "non-minor"})`;
        return <Status status={displayText} />;
      },
    },
    {
      title: "DATE",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any, row: any) => (
        <Typography.Text>{dayjs(text).format("MM/DD/YYYY")}</Typography.Text>
      ),
    },
    {
      title: () => <>KYC DOCUMENTS {downloadingPdf && <LoadingOutlined />}</>,
      dataIndex: "url",
      key: "url",
      render: (text: any, row: any) => (
        <Typography.Text>
          <span
            onClick={() => {
              setDownloadingPdf(true);
              onDownload({
                id: row?.id,
                target: searchedType,
                loaderCB: () => setDownloadingPdf(false),
              });
            }}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            View Document
          </span>
        </Typography.Text>
      ),
    },

    // {
    //   title: "",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (text: any, row: any, index: number) => (
    //     <TableActions
    //       noReport
    //       onDelete={() => onDeleteHandler(row.id)}
    //       onEdit={() => onEditHandler(row)}
    //       download={row?.url}
    //       onArchieve={() => onArchieveHandler(row)}
    //       archieved={
    //         row?.archived_demat ||
    //         row?.archived_meroshare ||
    //         row?.archived_trading
    //       }
    //       onDownload={() => onDownload(row?.url)}
    //       onViewDetails={() =>
    //         history.push(`/user/${row?.id}/${row?.account?.toLowerCase()}`)
    //       }
    //     />
    //   ),
    // },
  ];

  const [form] = useForm();
  const accountType = ["demat", "meroshare", "trading"];

  const menu = (
    <Menu
      onClick={(e) => {
        setAccType(e.key);
        form.setFieldsValue({ account: e.key });
      }}
    >
      {accountType?.map((acc) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={acc}>
            {acc}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  useEffect(() => {
    if (filter) {
      fetchWithFilter({ ...filter });
      setSearchedType(accType as AccountTargets);
      setSelectedRowKeys([]);
    }
  }, [filter]);

  const onFilterSubmit = (value: any) => {
    const finalFilter = { ...filter };
    if (value?.date) {
      finalFilter.start = dayjs(value.date[0]).format("YYYY-MM-DD");
      finalFilter.end = dayjs(value.date[1]).format("YYYY-MM-DD");
    } else {
      delete finalFilter.start;
      delete finalFilter.end;
    }
    if (!value?.account || value?.account === "all") {
      delete finalFilter.account;
    } else {
      finalFilter.account = value?.account;
    }
    if (value?.username) {
      finalFilter.username = value?.username;
    } else {
      delete finalFilter.username;
    }

    setFilter({ ...finalFilter, page: 1, limit: 10 });

    //set here
  };

  const tradingDownloadModal = () => {
    return (
      <Modal
        centered
        footer={null}
        closable={false}
        visible={showTradingModal}
        width={"40vw"}
        onCancel={() => setShowTradingModal(false)}
        destroyOnClose
      >
        <Row justify="center">
          <Space>Select file type</Space>
        </Row>
        <Space
          style={{
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <Button
            className="neumorphic"
            onClick={tradingDownloadHandler.bind(this, true)}
          >
            <Space>
              <DownloadIconDark style={{ marginRight: 0 }} />
              CSV TMS zip (BOT)
            </Space>
          </Button>
          <Button
            className="neumorphic"
            onClick={tradingDownloadHandler.bind(this, false)}
          >
            <Space>
              <DownloadIconDark style={{ marginRight: 0 }} />
              Excel TMS zip (Non-BOT)
            </Space>
          </Button>
        </Space>
      </Modal>
    );
  };

  return (
    <Card>
      {tradingDownloadModal()}
      <Typography.Title level={3} style={{ fontWeight: 800 }}>
        User List
      </Typography.Title>
      <Form onFinish={onFilterSubmit} form={form}>
        <Row gutter={[20, 20]}>
          <Col lg={6}>
            <AntFormItem name="username">
              <Input.Search />
            </AntFormItem>
          </Col>
          {/* <Col lg={5}>
  
                      <AntFormItem>
                          <Select showSearch style={{ minWidth: 200 }}>
                              {USER_DATA.map((user) => {
                                  return (
                                      <Select.Option value={user.first_name + " " + user.last_name}>{user.first_name + " " + user.last_name}</Select.Option>
                                  )
                              })}
                          </Select>
                      </AntFormItem>
                  </Col> */}
          <Col lg={5}>
            <Form.Item hidden name="account">
              <Input />
            </Form.Item>

            <Dropdown trigger={["click"]} overlay={menu}>
              <Row align="middle" justify="space-between">
                <Typography.Text
                  style={{ textTransform: "capitalize" }}
                  strong={!!accType}
                >
                  {accType ? accType : "Choose Account Type"}
                </Typography.Text>
                <ArrowDownIcon />
              </Row>
            </Dropdown>
          </Col>
          <Col lg={6}>
            <AntFormItem name="date">
              <DatePicker.RangePicker style={{ width: "100%" }} />
            </AntFormItem>
          </Col>
          <Col lg={4}>
            <Button htmlType="submit" type="primary" className="primary--alt">
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <Row justify="space-between" style={{ margin: "20px 0px" }}>
        <Col>
          <Space align="center" size={30}>
            <Typography.Title
              level={5}
              style={{ fontWeight: 800, marginBottom: 0 }}
            >
              {selectedRowKeys?.length ?? 0} Selected
            </Typography.Title>
            {/* <Checkbox
              onChange={(e) =>
                e.target.checked
                  ? setSelectedRowKeys(list?.map((key: any) => key?.id))
                  : setSelectedRowKeys([])
              }
            >
              Select All
            </Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space size={45}>
            <Space>
              <Button
                type="default"
                onClick={downloadHandler}
                loading={submitting || loading || updating}
              >
                <DownloadIconDark style={{ marginRight: 0 }} />
              </Button>
              <Typography.Text type="secondary" strong>
                Download
              </Typography.Text>
            </Space>
            {/* <Space>
              <Button type="default">
                <DeleteIcon style={{ marginRight: 0 }} />
              </Button>
              <Typography.Text type="secondary" strong>
                Archieve
              </Typography.Text>
            </Space> */}
          </Space>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={userColumns}
            dataSource={list}
            loading={loading || updating}
            pagination={{
              current: parseInt(pageNumber),
              total: parseInt(totalRecords),
              onChange: (page: number) => {
                onPageChange(page);
              },
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default BatchFileUserList;
