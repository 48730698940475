import './styles.scss';

import React, {
  Suspense,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Menu,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import defaultAvatar from 'Assets/images/defaultAvatar.png';
import MyContent from 'Components/Content/Content';
import { DatePicker } from 'Components/DatePicker';
import DescriptionCard from 'Components/DescriptionCard/DescriptionCard';
import Loading from 'Components/Loading/Loading';
import Modal from 'Components/Modal';
import PageHolder from 'Components/PageHolder/PageHolder';
import PageLoading from 'Components/PageLoading/PageLoading';
import Status from 'Components/Status/Status';
import dayjs from 'dayjs';
import ArchieveIcon from 'Icons/ArchieveIcon/ArchieveIcon';
import ArrowDownIcon from 'Icons/ArrowDownIcon/ArrowDownIcon';
import BackIcon from 'Icons/BackIcon/BackIcon';
import { CsvLogoIcon } from 'Icons/CsvIcon/CsvIcon';
import DownloadIcon, {
  DownloadIconDark,
} from 'Icons/DownloadIcon/DownloadIcon';
import { PdfLogoIcon } from 'Icons/PdfIcon/PdfIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  archiveCustomer,
  downloadCustomerInfo,
  downloadCustomerTransReport,
  fetchCustomerTransaction,
  fetchDPSingleCustomer,
} from 'Redux/DpRedux/Customer/DpActions';
import { dpFilterParams } from 'Utils/dpFilterParams';

const dateType = ["yesterday", "today", "week", "month", "year"];

export const CustomerSingle = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading,
    customerDetails,
    archivingCustomer,
    archivedCustomer,
    downloadingCustomerInfo,
    customerTransactionList,
    customerTransactionLoading,
    customerTransactionTotalRecords,
    downloadingCustomerPdfCsv,
    downloadedCustomerPdfCsv,
  } = useSelector((state: any) => state.dpCustomerReducer);

  const [selectedDateRange, setSelecteDDateRange] = useState("");

  const [filterData, setFilterData] = useState({
    page: 1,
    limit: 10,
    transaction_date_after: "",
    transaction_date_before: "",
    dateRange: "",
  });

  const [showExportModal, setShowExportModal] = useState(false);

  const backHandler = () => {
    history.push("/dp/customer");
  };

  const dateTypeMenu = (
    <Menu
      onClick={(e) => {
        setSelecteDDateRange(e.key);
        setFilterData((prevState) => ({
          ...prevState,
          dateRange: e.key,
          page: 1,
        }));
      }}
    >
      {dateType.map((date) => {
        return (
          <Menu.Item style={{ textTransform: "capitalize" }} key={date}>
            <Typography.Text type="secondary">{date}</Typography.Text>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const transactionColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Voucher Number",
      dataIndex: "voucher_number",
      key: "voucher_number",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{dayjs(text).format("YYYY-MM-DD")}</Typography.Text>
      ),
    },

    {
      title: "Mode",
      dataIndex: "payment_mode",
      key: "payment_mode",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
  ];


  useEffect(() => {
    if (id) {
      dispatch(fetchDPSingleCustomer(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (archivedCustomer) {
      history.push("/dp/customer");
    }
  }, [archivedCustomer]);

  useEffect(() => {
    if (downloadedCustomerPdfCsv) {
      setShowExportModal(false);
    }
  }, [downloadedCustomerPdfCsv]);

  useEffect(() => {
    if (customerDetails?.boid_number) {
      dispatch(
        fetchCustomerTransaction(
          customerDetails.boid_number,
          dpFilterParams(filterData)
        )
      );
    }
  }, [customerDetails?.boid_number, dispatch, filterData]);

  const userDetails = {
    meroshare_expiry_date: customerDetails?.meroshare_expiry_date || "-",
    demat_expiry_date: customerDetails?.demat_expiry_date || "-",
    amount_due: <Typography.Text type="danger">-</Typography.Text>,
    email: customerDetails?.email_id || "-",
    account_type: customerDetails?.account_category || "-",
    joined_date: dayjs(customerDetails?.created_at).format("YYYY-MM-DD") || "-",
    boid_no: customerDetails?.boid_number || "-",
    contact_no: customerDetails?.phone_number || "-",
    permanent_address: customerDetails?.permanent_address || "-",
    temporary_address: customerDetails?.correspondence_address || "-",
    fathers_name: customerDetails?.fathers_name || "-",
    grand_fathers_name: customerDetails?.grand_father_name || "-",
  };

  const referenceDetails = {
    reference_name: customerDetails?.reference?.reference_name || "-",
    reference_contact: customerDetails?.reference?.reference_number || "-",
    address: customerDetails?.reference?.reference_address || "-",
    remarks: customerDetails?.reference?.remarks || "-",
  };

  return (
    <>
      <MyContent>
        <Suspense fallback={<Loading fullScreen />}>
          <PageLoading loading={loading}>
            <Content className="customer-single">
              <Row gutter={[20, 20]} align="middle" justify="space-between">
                <Col>
                  <Row gutter={[20, 20]} align="middle">
                    <Col>
                      <Button
                        onClick={backHandler}
                        icon={
                          <BackIcon
                            style={{ marginRight: 10, paddingTop: 3 }}
                          />
                        }
                        type="default"
                      >
                        Back
                      </Button>
                    </Col>
                    <Col>
                      <Typography.Title level={4} style={{ marginBottom: 0 }}>
                        {customerDetails?.account_holder_name}
                      </Typography.Title>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row gutter={[20, 20]} align="middle">
                    <Col>
                      <Button
                        type="default"
                        icon={<DownloadIcon style={{ fill: "gray" }} />}
                        onClick={() => dispatch(downloadCustomerInfo(id))}
                        loading={downloadingCustomerInfo}
                      >
                        Download User Info
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="default"
                        icon={<ArchieveIcon style={{ fill: "gray" }} />}
                        onClick={() => {
                          dispatch(archiveCustomer(id));
                        }}
                        loading={archivingCustomer}
                      >
                        Archive
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <PageHolder
                loading={!customerDetails && loading}
                empty={!customerDetails}
                style={{ marginTop: 40 }}
              >
                <Row gutter={[60, 40]}>
                  <Col lg={24}>
                    <Card>
                      <Row
                        align="bottom"
                        justify="space-between"
                        style={{ marginBottom: "30px" }}
                      >
                        <Col>
                          <span
                            style={{
                              width: "120px",
                              height: "120px",
                              display: "block",
                              overflow: "hidden",
                              borderRadius: "100%",
                              border: "3px solid white",
                              background: "#f8f8f8",
                              boxShadow:
                                "-3px -3px 6px #ffffff, 3px 3px 6px #d3d3d3",
                            }}
                          >
                            <Image src={defaultAvatar} />
                          </span>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography.Title
                              level={3}
                              style={{ marginTop: 20, marginRight: 18 }}
                            >
                              {customerDetails?.account_holder_name}
                            </Typography.Title>
                            <Status status={customerDetails?.account_status} />
                          </div>
                        </Col>
                        <DescriptionCard data={userDetails} columns={3} />

                        <Typography.Title level={4} style={{ marginTop: 20 }}>
                          Reference
                        </Typography.Title>
                        <DescriptionCard data={referenceDetails} columns={3} />
                      </Row>
                    </Card>
                  </Col>{" "}
                </Row>
                <div className="transaction">
                  <Card>
                    <div className="top-section">
                      <Typography.Title level={4}>Transaction</Typography.Title>
                      <Button
                        icon={<DownloadIconDark />}
                        onClick={() => setShowExportModal(true)}
                        className="export-btn"
                      >
                        <Typography.Text>{"Export"}</Typography.Text>
                      </Button>
                    </div>
                    <div className="date-filters">
                      <Dropdown
                        trigger={["click"]}
                        overlay={dateTypeMenu}
                        className="single-date"
                      >
                        <Row align="middle" justify="space-between">
                          <Typography.Text>
                            {selectedDateRange
                              ? selectedDateRange
                              : "Date Filter"}
                          </Typography.Text>
                          <ArrowDownIcon />
                        </Row>
                      </Dropdown>
                      <DatePicker.RangePicker
                        className="date-range"
                        onChange={(dates) => {
                          setFilterData((prevState) => ({
                            ...prevState,
                            transaction_date_after: dates
                              ? dayjs(dates?.[0]?.toString()).format(
                                  "YYYY-MM-DD"
                                )
                              : "",
                            transaction_date_before: dates
                              ? dayjs(dates?.[1]?.toString()).format(
                                  "YYYY-MM-DD"
                                )
                              : "",
                            page: 1,
                          }));
                        }}
                      />
                    </div>

                    <Table
                      rowKey="id"
                      columns={transactionColumns}
                      dataSource={customerTransactionList}
                      loading={customerTransactionLoading}
                      pagination={{
                        current: Number(filterData.page),
                        total: customerTransactionTotalRecords,
                        onChange: (page: number) => {
                          setFilterData((prevState) => ({
                            ...prevState,
                            page: page,
                          }));
                        },
                        showSizeChanger: false,
                      }}
                    />
                  </Card>
                </div>
              </PageHolder>
            </Content>
          </PageLoading>
        </Suspense>
      </MyContent>

      <Modal
        visible={showExportModal}
        width={"30vw"}
        onCancel={() => {
          setShowExportModal(false);
        }}
      >
        <Space
          direction="vertical"
          align="center"
          size={"large"}
          style={{
            width: "100%",
          }}
        >
          <Spin spinning={downloadingCustomerPdfCsv}>
            <Typography.Title level={4}> Choose File Format</Typography.Title>
            <Row>
              <Button
                style={{
                  marginRight: 16,
                }}
                onClick={() => {
                  dispatch(
                    downloadCustomerTransReport(
                      customerDetails.boid_number,
                      {
                        ...dpFilterParams(filterData),
                      },
                      "pdf"
                    )
                  );
                }}
              >
                <Space>
                  <PdfLogoIcon />
                  <Typography.Text>PDF</Typography.Text>
                </Space>
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    downloadCustomerTransReport(
                      customerDetails.boid_number,
                      {
                        ...dpFilterParams(filterData)
                      },
                      "excel"
                    )
                  );
                }}
              >
                <Space>
                  <CsvLogoIcon />
                  <Typography.Text>CSV</Typography.Text>
                </Space>
              </Button>
            </Row>
          </Spin>
        </Space>
      </Modal>
    </>
  );
};
