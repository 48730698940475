import { message } from 'antd';
import { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  acceptRejectFailure,
  acceptRejectSuccess,
  createKycFailure,
  createKycSuccess,
  deleteKycFailure,
  deleteKycSuccess,
  fetchKycFailure,
  fetchKycRequestsFailure,
  fetchKycRequestsSuccess,
  fetchKycsFailure,
  fetchKycsSuccess,
  fetchKycSuccess,
  fetchUserListVideoKycFailure,
  fetchUserListVideoKycSuccess,
  fetchVideoKycFailure,
  fetchVideoKycSuccess,
  generatePdfFailure,
  generatePdfSuccess,
  rescheduleFailure,
  rescheduleSuccess,
  scheduleFailure,
  scheduleSuccess,
  updateKycFailure,
  updateKycSuccess,
} from './KycActions';
import {
  acceptRejectVideoKyc,
  cutKyc,
  generatePdfApi,
  getUserListVideoKyc,
  getVideoKycDetails,
  makeKyc,
  modifyKyc,
  rescheduleVideoKyc,
  scheduleVideoKyc,
  takeKyc,
  takeKycRequests,
  takeKycs,
} from './KycApis';
import {
  ACCEPT_REJECT_VIDEO_KYC,
  AcceptRejectVideoKyc,
  CREATE_KYC,
  CreateKycParams,
  FETCH_KYC,
  FETCH_KYC_REQUESTS,
  FETCH_KYCS,
  FETCH_VIDEO_KYC,
  GENERATE_PDF,
  KycDetailsParams,
  KycFetchParams,
  REMOVE_KYC,
  RESCHEDULE_VIDEO_KYC,
  RescheduleKyc,
  SCHEDULE_VIDEO_KYC,
  ScheduleKyc,
  UPDATE_KYC,
  USER_LIST_SCHEDULE_VIDEO_KYC,
} from './KycTypes';

//get kyc
const getKycsAsync = async (data: KycFetchParams) => await takeKycs(data);

function* getKycs(params: any) {
  const { payload } = params;
  
  try {
    const Kycs: AxiosResponse = yield call(getKycsAsync, payload);
    if (Kycs) {
      yield put(fetchKycsSuccess(Kycs.data));
    } else {
      yield put(fetchKycsFailure("Could not get list of kyc"));
      message.error("Could not get list of kyc");
    }
  } catch (error) {
    yield put(fetchKycsFailure("Network Error while getting Kycs"));
    message.error("Could not get list of kyc");
  }
}

function* watchGetKycs() {
  yield takeLatest(FETCH_KYCS, getKycs);
}

//get kyc requests
const getKycRequestsAsync = async (data: KycFetchParams) =>
  await takeKycRequests(data);

function* getKycRequests(params: any) {
  const { payload } = params;
  try {
    const Kycs: AxiosResponse = yield call(getKycRequestsAsync, payload);
    if (Kycs) {
      const {
        data: {
          items: forms,
          currentPage: page,
          totalItems: total,
          itemsPerPage: count,
        },
      } = Kycs;

      const updatedData = { forms, page, total, count };

      yield put(fetchKycRequestsSuccess(updatedData));
    } else {
      yield put(fetchKycRequestsFailure("Could not get list of kyc requests"));
      message.error("Could not get list of kyc requests");
    }
  } catch (error) {
    yield put(
      fetchKycRequestsFailure("Network Error while getting Kyc requests")
    );
    message.error("Could not get list of kyc requests");
  }
}

function* watchGetKycRequests() {
  yield takeLatest(FETCH_KYC_REQUESTS, getKycRequests);
}

//get Video Kyc details
const getVideoKycAsync = async (formId: string) =>
  await getVideoKycDetails(formId);

function* getVideoKyc(params: any) {
  const { payload } = params;
  try {
    const videoKyc: AxiosResponse = yield call(getVideoKycAsync, payload);
    if (videoKyc) {
      yield put(fetchVideoKycSuccess(videoKyc.data));
    } else {
      yield put(fetchVideoKycFailure("Could not get video kyc details"));
      message.error("Could not get video kyc details");
    }
  } catch (error) {
    yield put(fetchVideoKycFailure("Network Error while getting Kycs"));
    message.error("Could not get video kyc details");
  }
}

function* watchGetVideoKycs() {
  yield takeLatest(FETCH_VIDEO_KYC, getVideoKyc);
}

//get user details
const getKycAsync = async (data: KycDetailsParams) => await takeKyc(data);

function* getKyc(params: any) {
  const { payload } = params;
  try {
    const Kycs: AxiosResponse = yield call(getKycAsync, payload);
    if (Kycs) {
      yield put(fetchKycSuccess(Kycs.data));
    } else {
      yield put(fetchKycFailure("Could not get list of kyc"));
      message.error("Could not get list of kyc");
    }
  } catch (error: any) {
    yield put(fetchKycFailure("Network Error while getting Kycs"));
    if (error?.response?.data.message) {
      const mainErrorKey = Object.keys(error.response.data.message)[0];
      if (typeof error.response.data.message[mainErrorKey] === "string") {
        message.error(error.response.data.message[mainErrorKey]);
      } else {
        const errorArray: any = Object.entries(
          error.response.data.message[mainErrorKey]
        );
        errorArray.map((each: any) => {
          message.error({
            content: `${each[0]} : ${each[1]}`,
          });
        });
      }
    } else {
      message.error(error?.message ?? "Something went wrong");
    }
  }
}

function* watchGetKyc() {
  yield takeLatest(FETCH_KYC, getKyc);
}

//create Kyc
const generateKycAsync = async (data: CreateKycParams) => await makeKyc(data);

function* generateKyc(params: any) {
  const { payload } = params;
  try {
    const Kycs: AxiosResponse = yield call(generateKycAsync, payload);
    if (Kycs) {
      yield put(createKycSuccess(Kycs.data));
      message.success("Kyc created successfully");
    } else {
      const messages = "Could not create Kyc";
      yield put(createKycFailure(messages));
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error while creating Kyc";
    yield put(createKycFailure(messages));
    message.success(messages);
  }
}

function* watchGenerateKyc() {
  yield takeLatest(CREATE_KYC, generateKyc);
}

//update Kyc
const amendKycAsync = async (data: CreateKycParams) => await modifyKyc(data);

function* amendKyc(params: any) {
  const { payload } = params;
  try {
    const Kycs: AxiosResponse = yield call(amendKycAsync, payload);
    if (Kycs) {
      yield put(updateKycSuccess(Kycs.data));
      message.success("Kyc updated successfully");
    } else {
      const messages = "Could not Update Kyc";
      yield put(updateKycFailure(messages));
      message.error(messages);
    }
  } catch (error: any) {
    const messages = "Network Error while updating Kyc";

    if (error?.response?.data.message) {
      const errors: any = error.response.data.message;
      if (typeof errors === "object") {
        const mainErrorKey = Object.keys(errors);
        mainErrorKey.map((each: any) => {
          if (typeof errors[each] === "object") {
            for (const [key, value] of Object.entries(errors[each])) {
              message.error(`${value}` ?? "Something went wrong");
            }
          } else {
            message.error(`${errors[each]}` ?? "Something went wrong");
          }
        });
      }
      if (typeof errors === "string") {
        message.error(`${errors}` ?? "Something went wrong");
      }
      if (Array.isArray(errors)) {
        errors.map((each: any) => {
          message.error(`${each}` ?? "Something went wrong");
        });
      }
    } else {
      message.error(error?.message ?? "Something went wrong");
    }
    yield put(updateKycFailure(messages));
  }
}

function* watchAmendKyc() {
  yield takeLatest(UPDATE_KYC, amendKyc);
}

//Reschedule Kyc
const resheduleKyc = async (data: RescheduleKyc) =>
  await rescheduleVideoKyc(data);

function* rescheduleVideoKycAdmin(params: any) {
  const { payload } = params;
  try {
    const reschedule: AxiosResponse = yield call(resheduleKyc, payload);
    if (reschedule) {
      yield put(rescheduleSuccess(reschedule.data));
      message.success("Rescheduled successfully");
    } else {
      const messages = "Could not reschedule date";
      yield put(rescheduleFailure(messages));
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error while rescheduling Kyc";
    yield put(rescheduleFailure(messages));
    message.error(messages);
  }
}

function* watchRescheduleKyc() {
  yield takeLatest(RESCHEDULE_VIDEO_KYC, rescheduleVideoKycAdmin);
}

//Schedule Kyc
const scheduleKyc = async (data: ScheduleKyc) => await scheduleVideoKyc(data);

function* scheduleVideoKycAdmin(params: any) {
  const { payload } = params;
  try {
    const schedule: AxiosResponse = yield call(scheduleKyc, payload);
    if (schedule) {
      yield put(scheduleSuccess(schedule.data));
      message.success("Rescheduled successfully");
    } else {
      const messages = "Could not reschedule date";
      yield put(scheduleFailure(messages));
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error while rescheduling Kyc";
    yield put(scheduleFailure(messages));
    message.error(messages);
  }
}

function* watchScheduleKyc() {
  yield takeLatest(SCHEDULE_VIDEO_KYC, scheduleVideoKycAdmin);
}

//Accept Reject Video Kyc
const acceptRejVideoKyc = async (data: AcceptRejectVideoKyc) =>
  await acceptRejectVideoKyc(data);

function* acceptRejectKyc(params: any) {
  const { payload } = params;

  try {
    const videoKyc: AxiosResponse = yield call(acceptRejVideoKyc, payload);
    if (videoKyc) {
      yield put(acceptRejectSuccess(videoKyc.data));
      message.success("Success");
    } else {
      const messages = "Failure";
      yield put(acceptRejectFailure(messages));
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error";
    yield put(acceptRejectFailure(messages));
    message.error(messages);
  }
}

function* watchAceeptRejectVideoKyc() {
  yield takeLatest(ACCEPT_REJECT_VIDEO_KYC, acceptRejectKyc);
}

//Delete Kyc
const removeKycAsync = async (data: KycDetailsParams) => await cutKyc(data);

function* removeKyc(params: any) {
  const { payload } = params;
  try {
    const Kycs: AxiosResponse = yield call(removeKycAsync, payload);
    if (Kycs) {
      yield put(deleteKycSuccess(Kycs.data));
      message.success("Kyc Deleted successfully");
    } else {
      const messages = "Could not delete Kyc";
      yield put(deleteKycFailure(messages));
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error while deleting Kyc";
    yield put(deleteKycFailure(messages));
    message.error(messages);
  }
}

function* watchRemoveKyc() {
  yield takeLatest(REMOVE_KYC, removeKyc);
}

const fetchUserListVideoKycAsync = async (data: any) => await getUserListVideoKyc(data)

function* fetchUserListVideoKyc(params: any) {
  const { payload } = params;
  try {
    const response: AxiosResponse = yield call(fetchUserListVideoKycAsync, payload);
    if (response) {
      yield put(fetchUserListVideoKycSuccess(response.data))
    }
  } catch (error) {
    const messages = "Failed to fetch user list";
    yield put(fetchUserListVideoKycFailure());
    message.error(messages);
  }
}

function* watchFetchUserListVideoKyc() {
  yield takeLatest(USER_LIST_SCHEDULE_VIDEO_KYC, fetchUserListVideoKyc)
}

//generate pdf

const generatePdfAsync = async (data: any) => await generatePdfApi(data)

function* generatedPdf(params: any) {
  const { payload } = params

  try {
    const response: AxiosResponse = yield call(generatePdfAsync, payload)
    if (response) {
      yield put(generatePdfSuccess())
    }

  } catch (error) {
    const messages = "Failed to generate pdf";
    yield put(generatePdfFailure());
    message.error(messages);
  }
}

function* watchGeneratePdf() {
  yield takeLatest(GENERATE_PDF, generatedPdf)
}

export default function* KycSagas() {
  yield all([
    fork(watchGetKycs),
    fork(watchGetKycRequests),
    fork(watchGetKyc),
    fork(watchGenerateKyc),
    fork(watchAmendKyc),
    fork(watchRemoveKyc),
    fork(watchRescheduleKyc),
    fork(watchGetVideoKycs),
    fork(watchAceeptRejectVideoKyc),
    fork(watchScheduleKyc),
    fork(watchFetchUserListVideoKyc),
    fork(watchGeneratePdf)
  ]);
}
