import { EditOutlined } from "@ant-design/icons";
import { Button, Descriptions } from "antd";
import {
  CONTENT_STYLE,
  EDIT_BUTTON,
  LABEL_STYLE,
} from "Components/DescriptionsStyle";
import { useState } from "react";
import { useSelector } from "react-redux";
import ChildrenForm from "./ChildrenForm";

const DisplayChildren = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { details } = useSelector((state: any) => state?.KycReducer);
  const childrenData = details?.family_details?.children;
  return (
    <>
      {!isEditing ? (
        <>
          <Button
            onClick={() => setIsEditing((curr) => !curr)}
            type="default"
            icon={<EditOutlined style={{ fill: "gray" }} />}
            style={EDIT_BUTTON}
          >
            Edit Children Information
          </Button>
          <Descriptions
            column={9}
            labelStyle={LABEL_STYLE}
            contentStyle={CONTENT_STYLE}
            layout="horizontal"
          >
            {childrenData?.map((item: any, idx: number) => (
              <Descriptions.Item
                span={3}
                label={`${idx + 1}) ${item.relation}`}
                key={idx.toString()}
              >
                {item.name}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </>
      ) : (
        <ChildrenForm {...{ setIsEditing }} {...{ childrenData }} />
      )}
    </>
  );
};

export default DisplayChildren;
